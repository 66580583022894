import { Injectable } from '@angular/core';
import { tokenize } from 'ngx-bootstrap/typeahead';
import { UserContext } from '../login/userContext';

@Injectable({
  providedIn: 'root'
})
export class RedirectTokenService {

  constructor() { }
  getRedirectToken(trdPartyConfig: any, userContext:UserContext, userProfile:any, param:any){
    let appToken: string ="";
    switch(trdPartyConfig.srcApp){
      case "cbsp" :{
        let claimNumber = (param["claimNumber"])?param["claimNumber"]:"";
        let vin = (param["vin"])?param["vin"]:"";
        let srcToken = userContext["srcToken"];
        let current_timestamp = new Date().getTime();
        let userId = userProfile.streamAssociations[0].streamId;//this.appointment$.id6Txt;//this.userDetails.claimSystemLoginId;  
        //console.info("streamAssociations", this.userDetails.streamAssociations[0].streamId);
        //let urlParams_wts = (srcToken)?current_timestamp+"/"+userId+"/"+claimNumber+"/"+vin+"/"+srcToken:current_timestamp+"/"+userId+"/"+claimNumber+"/"+vin;
        let urlParams_wts = current_timestamp+"/"+userId+"/"+claimNumber+"/"+vin;
        if(srcToken){
          urlParams_wts = urlParams_wts+"/"+srcToken;
        }
        //let encodedString = btoa(urlParams);
        let encodedString_wts = btoa(urlParams_wts);
        console.log("urlParams_wts",urlParams_wts,"encodedString_wts",encodedString_wts);
        appToken="handshake/"+encodedString_wts;
        break;
      }//cbsp
    }
    return appToken;
  }
}
