import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './shared/login/login.component';
import { AppointmentComponent } from './edispatch/appointment/appointment.component';
import { AppointmentdetailComponent } from './edispatch/appointment/appointmentdetail.component';
import { NotificationComponent } from './edispatch/appointment/notification.component';
import { CheckWriterMakePaymentComponent } from './check-writer/make-payment/make-payment.component';
import { ProfileComponent } from './check-writer/profile/profile.component';
import { LogViewComponent } from './check-writer/log-view/log-view.component';
import { NewVendorComponent } from './check-writer/new-vendor/new-vendor.component';
import { AppraiserComponent } from './edispatch/appointment/appraiser.component';
import { AdminComponent } from './check-writer/admin/admin.component';
import { AdminLoginComponent } from './shared/login/admin-login/admin-login.component';
import { EzeepPrintDemoComponent } from './check-writer/ezeep-print-demo/ezeep-print-demo.component';
import { MapComponent } from './edispatch/appointment/map.component';
import { LetterComponent } from './edispatch/letter/letter.component';

const routes: Routes = [
  {
    path: 'login/:routeTo',
    component: LoginComponent,
    pathMatch: 'full'
  },{
    path: 'adminLogin/:routeTo',
    component: AdminLoginComponent,
    pathMatch: 'full'
  },{
    path: 'login/:srcApp/:srcToken',
    component: LoginComponent,
    pathMatch: 'full'
  },{
    path: 'login',
    component: LoginComponent,
    pathMatch: 'full'
  }, {
    path: 'adminLogin',
    component: AdminLoginComponent,
    pathMatch: 'full'
  },
  {
    path: 'appointments',
    component: AppointmentComponent,
    pathMatch: 'full'
  },  
  {
    path: 'map/:userId/:userName',
    component: MapComponent,
    pathMatch: 'full'
  },  
  {
    path: 'appointmentdetails/:claimNumber/:dispId',
    component: AppointmentdetailComponent,
    pathMatch: 'full'
  },
  {
    path: 'notification/:claimNumber/:dispId/:appointmentDate',
    component: NotificationComponent,
    pathMatch: 'full'
  },
  {
    path: 'appraiser/:appointmentId',
    component: AppraiserComponent,
    pathMatch: 'full'
  },
  {
    path: 'checkWriter',
    component: CheckWriterMakePaymentComponent,
    pathMatch: 'full'
  },
  {
    path: 'checkWriter/:claim',
    component: CheckWriterMakePaymentComponent,
    pathMatch: 'full'
  },
  {
    path: 'checkWriter/:claim/:dispId',
    component: CheckWriterMakePaymentComponent,
    pathMatch: 'full'
  },
  {
    path: 'profile',
    component: ProfileComponent,
    pathMatch: 'full'
  },
  {
    path: 'logView',
    component: LogViewComponent,
    pathMatch: 'full'
  },
  {
    path: 'newVendor',
    component: NewVendorComponent,
    pathMatch: 'full'
  }, 
  {
    path: 'admin',
    component: AdminComponent,
    pathMatch: 'full'
  }, 
  {
    path : 'print',
    component : EzeepPrintDemoComponent,
    pathMatch : 'full'
  }, 
  {
    path : 'letter/:code',
    component : LetterComponent,
    pathMatch : 'full'
  }, 
  {
    path: '**',
    redirectTo: '/login'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, enableTracing: true })],
  //imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
