import { Component, OnInit, AfterViewInit, ElementRef, ViewChild, OnDestroy, Inject, Injectable, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { CheckWriterService } from '../shared/services/check-writer.service';
import { IPayee } from '../shared/models/ipayee';
import { Input } from '@angular/core';
import { IPayeeAddresses } from '../shared/models/ipayee-addresses';
import { IKeyValuePair } from '../shared/models/ikey-value-pair';
import { PaymentDetails } from '../shared/models/payment-details';
import { IPaymentType } from '../shared/models/ipayment-type';
import { ClaimDispatchEstimate } from '../shared/models/claim-dispatch-estimate';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddNoteComponent } from '../add-note/add-note.component';
import { AddMemoComponent } from '../add-memo/add-memo.component';
import { VendorComponent } from '../vendor/vendor.component';
import { LoadingIndicatorService } from '../shared/services/loading-indicator.service';
import { IPayeeAddress } from '../shared/models/ipayee-address';
import { ClaimPayee } from '../shared/models/claim-payee';
import { PrintPreviewComponent } from '../print-preview/print-preview.component';
import { PrintConfirmComponent } from '../print-confirm/print-confirm.component'
import { ClaimProfile } from '../shared/models/claim-profile';
import { PaymentCheck } from '../shared/models/payment-check';

import { Status } from '../shared/models/status';
import { ErrorResponse } from '../shared/models/error-response';
import { StorageService } from '../../shared/service/storage.service';
import { NewVendorComponent } from '../new-vendor/new-vendor.component';
import { Vendor } from '../shared/models/vendor';
import { NewVendorPreviewComponent } from '../new-vendor-preview/new-vendor-preview.component';
import { CurrencyPipe, DecimalPipe } from '@angular/common';
import { CustomPrintComponent } from '../custom-print/custom-print.component';
import { PaymentTransaction } from '../shared/models/payment-transaction';
import { AdjusterProfile } from '../shared/models/adjuster-profile';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subject, merge } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';
import { NgForm } from '@angular/forms';
import { PaymentOptionsComponent } from '../payment-options/payment-options.component';

const STORAGE_KEY = "ADJUSTER_ID";

const PAY_ELE_SEPARATOR = " ~ ";

@Component({
  selector: 'eclaimsonesite-check-writer',
  templateUrl: './make-payment.component.html',
  styleUrls: ['./make-payment.component.scss']
})
@Injectable()
export class CheckWriterMakePaymentComponent implements OnInit {

  @ViewChild('checkWriterContent') checkWriterContent: ElementRef;

  payees;
  paymentElements;
  dispatchEstimate: ClaimDispatchEstimate;
  payeeAddresses: IPayeeAddress[];
  paymentType;
  paymentTypes: IPaymentType[];
  inPaymentOfTypes: IKeyValuePair[];
  payeeContextPath = "/payees?streamUserId=";
  paymentElementContextPath = "/payElement?houseCounsel=n&streamUserId=";
  payeesAddressContextPath = "/payees/address?streamUserId="
  paymentTypeContextPath = "monetaryTypes?companyLobId=";
  inPaymentOfContextPath = "inPaymentOf";

  // Updated Payees with Address
  payeesWithAddress: ClaimPayee[]

  // Payment Submission
  selectedPaymentOptions: PaymentDetails = new PaymentDetails();

  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};

  @Input() claimNumber: string;
  @Input() dispatchId: string;
  //companyLobId: string = "6001400";
  noteContent: string;
  memoContent: string;
  loading: boolean = false;
  showCovAType: boolean = false;
  showCovBType: boolean = false;
  showCovCType: boolean = false;
  showCovDType: boolean = false;
  paymentElementSelected = false;
  payeesSelected = false;
  payeeAddressSelected = false;
  paymentTypeSelected = false;
  inPaymentOfSelected = false;
  formSubmitAttempted = false;

  // Validation related entries
  claimEstimateAmount: number;
  customInPaymentOf: string;

  claimProfile: ClaimProfile;
  paymentCheckData: PaymentCheck;
  errorOccurred: boolean = false;
  errorMessage: string = "";
  errorResponse: ErrorResponse;
  paymentTypeManualOrHybrid: string = "M";
  printTranxStatuses: Status[];
  userId: string;
  newVendor: Vendor;

  // To store selected vendor in order manage in case multiple vendor search.
  previouslySelectedVendor: ClaimPayee;
  firstTimeVendorSelected: boolean = true;
  vendorSelected: boolean = false;
  dispatchEstimateAmount: any;
  
  // Counter to hold Print Failures.
  //gcpPrintFailedCount: number = 0;
  // Change gcpPrintFailedCount to cloudPrintFailedCount for generalization.
  cloudPrintFailedCount: number = 0;
  gcpFailedPrintJobId: string;
  loggedIndAdjuster: AdjusterProfile;
  lastSelectedPaymentElement : string;
  isLossReservePaymentElementSelected: boolean = false;
  vendorSelectedTemporaryHolder: string;

  // Payment Options holder.
  paymentOptionSelected: string = "Paper";
  optedPaymentAccount: string;
  ePayActiveModel: any;

  // This flag will be used for deleting the failed print jobs with GCP.
  isGcpEnabled: boolean = false;

  @ViewChild(NgForm, { static: true }) makePaymentForm: NgForm;

  constructor(private restUtil: CheckWriterService, public route: ActivatedRoute
    , private modalService: NgbModal
    , private loadingIndSvc: LoadingIndicatorService
    , public router: Router
    , private storageService: StorageService
    , private currencyPipe: CurrencyPipe
    , private decPipe: DecimalPipe
    , public changeDetectorRef: ChangeDetectorRef) {
    // change loading status whenever notified
    loadingIndSvc.onLoadingChanged.subscribe(isLoading => this.loading = isLoading);
  }

  ngOnInit() {

    // Get the adjuster id from session. if not found redirect to Login page.
    var key = 'User_Id';
    if (!this.storageService.get(key) || !this.storageService.get("X_Token")) {
      this.router.navigateByUrl('/login/checkWriter');
    } else {
      this.userId = JSON.parse(this.storageService.get(key));
      this.loggedIndAdjuster = JSON.parse(this.storageService.get("User_Profile"));
      console.log("User Id : " + this.userId);
    }
    this.cloudPrintFailedCount = 0;
    this.dropdownSettings = {
      singleSelection: false,
      //idField: 'item_id',
      // textField: 'item_text',
      idField: 'name_type',
      textField: 'name_type',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 1,
      limitSelection: 4,
      allowSearchFilter: false,
      closeDropDownOnSelection: true,
      noDataAvailablePlaceholderText: 'Payees not listed on Claim'
    };
    this.route.paramMap.subscribe((params) => {
      this.dispatchEstimate = new ClaimDispatchEstimate();
      this.dispatchEstimate.amount = 0.00;
      console.log(" Path Params Claim number : " + params.get('claim') + " - dispaid: " + params.get('dispId'));
      if (params.get('claim')) {
        this.selectedPaymentOptions.claimNumber = params.get('claim');
      }
      // Commented to account user updates after defaulting paymentelements.
      /*if (params.get('dispId')) {
        this.paymentTypeManualOrHybrid = "B";
      }*/

      this.populatePaymentFormFields(params.get('claim'), params.get('dispId'));
    });

    // set default option values for dropdowns
    this.selectedPaymentOptions.inPaymentOfMap = null;
    this.selectedPaymentOptions.payeeAddress = null;
    this.selectedPaymentOptions.paymentTypeCode = null;
    this.selectedPaymentOptions.paymentElement = null;
    this.loadInPaymentOfStore();
  }

  populatePaymentFormFields(clmNumber: string, dispId: string) {
    this.claimNumber = clmNumber;
    this.dispatchId = dispId;
    // reset the payment elements drop down.
    this.paymentElements = [];
    this.paymentTypes = [];
    this.selectedPaymentOptions = new PaymentDetails();
    // End of reset changes.
    console.log("Model Claim number/DispatchId : " + this.claimNumber + "/" + this.dispatchId);
    // Populate form fields for given Claim & Dispatch. Otherwise show error message.
    if (this.claimNumber) {
      //Get Complete Claim Profile in single API call.
      this.restUtil.getClaimProfile(this.claimNumber, this.dispatchId, JSON.parse(this.storageService.get("X_Token")), this.loggedIndAdjuster.houseCounsel).subscribe((data) => {
        console.log("Full Claim Profile : " + data);
        this.errorOccurred = false;
        this.claimProfile = data;
        this.payeesWithAddress = data.payees;
        this.dropdownList = data.payees;
        this.paymentElements = data.paymentElements;
        // this.selectedPaymentOptions.amount = (Math.round((data.estimateAmount * 1000)/10)/100);
        if(data.estimateAmount || data.estimateAmount == 0) {
          this.dispatchEstimateAmount = data.estimateAmount.toFixed(2);
          // Set the amount to this.selectedPaymentOptions.amount
          this.selectedPaymentOptions.amount = this.dispatchEstimateAmount;
        }
          
        if (this.claimProfile.defaultPaymentElement) {
          console.log("Payment element defaulted, get Payment Types.");
          this.selectedPaymentOptions.paymentElement = this.claimProfile.defaultPaymentElement;
          this.onPaymentElementSelection(this.claimProfile.defaultPaymentElement);
          this.paymentTypeManualOrHybrid = "B";
        }
      }, error => {
        console.log("Error Occurred : Claims Profile AOI call. " + error.error.message);
        this.errorOccurred = true;
        this.errorResponse = this.restUtil.handleErrorResponse(error);
      });

      // Update the selected payment options model.
      this.selectedPaymentOptions.claimNumber = this.claimNumber;
    } else {
      console.log("Claim number is mandatory!");
    }
  }

  /**
   * Reloads the Check Writer form for given claim.
   * @param event
   */
  populateClaimSpecificFormFields(claimNumber: string) {
    console.log("Claim number : " + claimNumber);
    if (claimNumber.length == 12) {
      console.log("Reset selected dropdown options.")
      this.selectedItems = [];
      this.paymentTypeManualOrHybrid = "M";
      this.claimProfile = null;
      this.selectedPaymentOptions.inPaymentOfMap = null;
      this.selectedPaymentOptions.payeeAddress = null;
      this.selectedPaymentOptions.amount = 0.00;
      // route to same page with different claim number
      this.router.navigateByUrl('/checkWriter/' + claimNumber);
      // this.populatePaymentFormFields(claimNumber, null);
    }
  }

  /**
   * Reloads the Check Writer form for given claim.
   * @param event
   */
  onClaimNumberUpdated(claimNumber: string) {
    console.log("On Event Claim#:" + claimNumber + " length : " + claimNumber.length);
    if (claimNumber.length == 12) {
      // Reset the selected payment options.
      this.onPayeeDeSelect(this.selectedPaymentOptions.payees);
      this.paymentTypeManualOrHybrid = "M";
      //this.selectedPaymentOptions = new PaymentDetails();
      this.resetAllFlagAfterPageLoad(claimNumber);
      this.router.navigateByUrl('/checkWriter/' + claimNumber);
      this.selectedPaymentOptions.claimNumber = claimNumber;
    } else {
      return;
    }

  }

  onBlurClaimNumberUpdated(claimNum: string) {
    console.log("on blur claim number: " + claimNum);
    this.onClaimNumberUpdated(claimNum);
  }

  onPayeesSelect(item: any) {
    // remove tha last added payee if selectedPayees array has 4 elements.
    if(this.selectedPaymentOptions.payees.length == 4){
      console.log("Reached maximum payees limit, Removing last added payee from payment payees: " + this.selectedPaymentOptions.payees.pop());
    } else if(item instanceof Object){
      this.selectedPaymentOptions.payees.push(item.name_type);
    } else {
      //this.selectedPaymentOptions.payees.push(item);

      // Remove existing elements and add the vendor first.
      // Then add removed elements.
      let removedItems = this.selectedPaymentOptions.payees.splice(0);
      this.selectedPaymentOptions.payees.push(item);
      removedItems.forEach(insertEle => {
        this.selectedPaymentOptions.payees.push(insertEle);
      });

    }
    //this.selectedPaymentOptions.payees.push(item);
    console.log(this.selectedPaymentOptions.payees);
    this.payeesSelected = true;
  }

  onPayeesSelectAll(items: any) {
    if (items instanceof Array) {
      this.selectedPaymentOptions.payees = items;
    }
    console.log(this.selectedPaymentOptions.payees);
    this.payeesSelected = true;
  }

  onPayeeDeSelect(items: any) {
    if (items instanceof Array) {
      items.forEach(item => {
        this.updateUnSelectedPayee(item);
      });
    } else {
      this.updateUnSelectedPayee(items);
    }
    if (this.selectedPaymentOptions.payees.length == 0) {
      this.payeesSelected = false;
    }
  }

  onPayeeDeSelectAll() {
    console.log("Unselect all Payees...");
    this.selectedPaymentOptions.payees = [];
    console.log(this.selectedPaymentOptions.payees);
    console.log("onPayeeDeSelectAll::Payees array length : " + this.selectedPaymentOptions.payees.length);
    this.payeesSelected = false;
  }

  updateUnSelectedPayee(item: any) {
    console.log("Remove payee : " + item);
    if(item instanceof Object)
      this.selectedPaymentOptions.payees = this.selectedPaymentOptions.payees.filter(data => data !== item.name_type);
    else 
      this.selectedPaymentOptions.payees = this.selectedPaymentOptions.payees.filter(data => data !== item);
    this.selectedItems = this.selectedItems.filter(ele => ele !== item);
    console.log("updateUnSelectedPayee:: Payees array length : " + this.selectedPaymentOptions.payees.length);
    // Reset selected vendor if payee = vendor.providername.
    let payeeTmp: string = item;
    if(payeeTmp === this.vendorSelectedTemporaryHolder) {
      console.log("Reset selected vendor : " + this.vendorSelectedTemporaryHolder);
      this.selectedPaymentOptions.vendor = null;
      this.dropdownList = this.claimProfile.payees;
    }
    // Set selected flag when payees array empty.
    if (this.selectedPaymentOptions.payees.length == 0) {
      this.payeesSelected = false;
    }
  }

  onPaymentElementSelection(item: any) {
    //this.selectedPaymentOptions.paymentElement = item;
    console.log("Payment Element Selected : " + this.selectedPaymentOptions.paymentElement);
    // display split coverages.
    this.showEligibleSplitCoveragetypes(this.selectedPaymentOptions.paymentElement);
    // update Payment Tyoe code for selected Reserve 
    // first get splitted payment element array then get reserve code.
    let paymentElements = this.selectedPaymentOptions.paymentElement.split(PAY_ELE_SEPARATOR);
    let queryParams: IKeyValuePair[] = [];
    let reserveCode = paymentElements[2];
    // Set the isLossReservePaymentElementSelected=true if loss selected.
    if(reserveCode.includes("loss")) {
      this.isLossReservePaymentElementSelected = true;
      this.selectedPaymentOptions.isExpenseReserve = false;
    } else
      this.isLossReservePaymentElementSelected = false;

    // Default the reserveCode to clm_exp in case of house counsel user.
    // check if use is house counsel to default reserve code.
    if (this.loggedIndAdjuster) {
      console.log("Is User House Counsel ? " + this.loggedIndAdjuster.houseCounsel);
      if (this.loggedIndAdjuster.houseCounsel == 'Y' && reserveCode == 'loss') {
        reserveCode = "clm_exp";
      }
    }
    console.log("Selected ReserveCode: " + reserveCode);
    // reset the paymentType if already selected.
    this.selectedPaymentOptions.paymentTypeCode = null;
    this.restUtil.getPaymentTypeList(this.paymentTypeContextPath + this.claimProfile.policyCompany.companyLobId + "&reserveCd=" + reserveCode, queryParams, queryParams).subscribe((data) => {
      this.paymentTypes = data;
      console.log(this.paymentTypes);
    }, error => {
      this.errorOccurred = true;
      this.errorResponse = this.restUtil.handleErrorResponse(error);
    });
    this.paymentElementSelected = true;
    // Set the paymentTypeManualOrHybrid to "M"
    this.paymentTypeManualOrHybrid = "M";
  }

  showEligibleSplitCoveragetypes(payElementType: string) {
    this.resetAllSplitCovTypes();
    if (payElementType.includes("cova_") || payElementType.includes("add_alt")) {
      console.log("contains CovA or add_alt, display remaining types");
      this.showCovBType = true;
      this.showCovCType = true;
      this.showCovDType = true;
    } else if (payElementType.includes("covb_")) {
      console.log("contains COVB, display remaining types");
      this.showCovAType = true;
      this.showCovCType = true;
      this.showCovDType = true;
    } else if (payElementType.includes("covc_")) {
      console.log("contains COVC, display remaining types");
      this.showCovBType = true;
      this.showCovAType = true;
      this.showCovDType = true;
    } else if (payElementType.includes("covd_")) {
      console.log("contains COVD, display remaining types");
      this.showCovBType = true;
      this.showCovCType = true;
      this.showCovAType = true;
    }
  }

  resetAllSplitCovTypes() {
    this.showCovAType = false;
    this.selectedPaymentOptions.covAAmount = 0.00;
    this.showCovBType = false;
    this.selectedPaymentOptions.covBAmount = 0.00;
    this.showCovCType = false;
    this.selectedPaymentOptions.covCAmount = 0.00;
    this.showCovDType = false;
    this.selectedPaymentOptions.covDAmount = 0.00;
  }

  onPayeeAddressSelection(item: any) {
    console.log("this.selectedPayeeAddress; : " + JSON.stringify(this.selectedPaymentOptions.payeeAddress));
    this.payeeAddressSelected = true;
  }

  amountChanged(item: any) {
    try {
      let formatedAmount: any = 0.00;
      let currencyAmount = this.currencyPipe.transform(item, 'USD', 'symbol', '1.2-2');
      currencyAmount = currencyAmount.replace("$", "");
      currencyAmount = currencyAmount.replace(",", "");
      //this.setEstimateAmountUpdated(currencyAmount);
    } catch (error) {
      console.log("Error : " + error.errorMessage);
    }
  }

  onEstimateAmountUpdated(amount: any) {
    this.selectedPaymentOptions.amount = amount;
  }

  getDecimalFractionedAmount(amnt: any) {
    let updatedAmount = amnt.replace(/[$,]/g, "");
    updatedAmount = amnt.replace(".", "");
    updatedAmount = Number(updatedAmount);
    return updatedAmount / 100;
  }

  covAAmountChanged(item: any) {
    this.selectedPaymentOptions.covAAmount = item;
    console.log("Payment Cov A amount changed : " + this.selectedPaymentOptions.covAAmount);
  }

  covBAmountChanged(item: any) {
    this.selectedPaymentOptions.covBAmount = item;
    console.log("Payment Cov B amount changed : " + this.selectedPaymentOptions.covBAmount);
  }

  covCAmountChanged(item: any) {
    this.selectedPaymentOptions.covCAmount = item;
    console.log("Payment Cov C amount changed: " + this.selectedPaymentOptions.covCAmount)
  }

  covDAmountChanged(item: any) {
    this.selectedPaymentOptions.covDAmount = item;
    console.log("Payment Cov D amount changed : " + this.selectedPaymentOptions.covDAmount)
  }

  onPaymentTypeSelection(item: any) {
    //this.selectedPaymentOptions.paymentTypeCode = item;
    console.log(this.selectedPaymentOptions.paymentTypeCode + " vs Selected Payment Type : " + item);
    this.paymentTypeSelected = true;
  }

  onInPaymentOfSelection(item: any) {
    console.log("selected inpaymentofmap: " + this.selectedPaymentOptions.inPaymentOfMap);
    this.selectedPaymentOptions.inPaymentOfCode = item;
    this.selectedPaymentOptions.memoContent = this.selectedPaymentOptions.inPaymentOfMap.value;
    console.log(" In Payment Of Code : " + this.selectedPaymentOptions.inPaymentOfCode);
    this.inPaymentOfSelected = true;
  }

  updateEditableInPaymentOf(item: string) {
    console.log("update inpayment of " + item);
    this.selectedPaymentOptions.inPaymentOfMap.value = item;
  }

  onFormSubmit() {
    this.formSubmitAttempted = true;
    // Check selection of required fields.
    if (!this.paymentElementSelected || !this.payeesSelected || !this.payeeAddressSelected || !this.paymentTypeSelected || !this.inPaymentOfSelected) {
      console.error("Select all required fields.");
      this.errorOccurred = true;
      this.errorResponse = new ErrorResponse();
      this.errorResponse.message = "Please select all the fields for Check Preview.";
    } else {
      this.errorOccurred = false;
      console.log("Form has been submitted, will post to Service...")
      this.selectedPaymentOptions.memoContent = this.selectedPaymentOptions.memoContent ? this.selectedPaymentOptions.memoContent : this.selectedPaymentOptions.inPaymentOfMap.value;
      console.log("Selected Payment options : " + this.selectedPaymentOptions.toString());
      // Check number payees before calling virtual payment options.
      // if payees.length ==1 then call virtual payment otherwise regular paper check flow.
      /*
      if (this.selectedPaymentOptions.payees.length >  2 || this.selectedPaymentOptions.vendor || this.isSelectedPayeesUnInsured(this.selectedPaymentOptions.payees)) {
        this.handleClaimDeluxePaymentsOrShowPreview();
        // Commenting below line as its taken care in handleClaimDeluxePaymentsOrShowPreview function.  
        //this.submitPaymentOptionsAndCallPreviewModal();
      } else {
        // Add ePayActive status check call. 
        // If Active show the Payment Options confirmation modal.
        // Based on user selection process the payment.
        this.checkAndShowPaymentOptionsModal();
      }
      */
      // Commented above if & else block for 
      // Reversing the logic to check the EFT/Zelle options first then check for Deluxe followed by Paper.
      // Added on Aug-21 as part of Deluxe Payments.
      if(!this.isSelectedPayeesUnInsured(this.selectedPaymentOptions.payees)) {
        this.checkAndShowPaymentOptionsModal();
      } else {
        // handleClaimDeluxePaymentsOrShowPreview function take care of 
        // PAper option when Deluxe not eligible or disabled.
        this.handleClaimDeluxePaymentsOrShowPreview();
      }
    }
  }

  /**
   * This function take care of Deluxe & Paper option (when Deluxe not eligible or disabled.)
   */
  handleClaimDeluxePaymentsOrShowPreview() {
    let isDexEnabled: boolean = false;
    // Extract coverage code from payment elemnt.
    let paymentElements = this.selectedPaymentOptions.paymentElement.split(PAY_ELE_SEPARATOR);
    let covCode = paymentElements[3];
    // Get the first Payee (Vendor/normal Payee party id).
    // Check if Vendor is added to check. if Vendor added then use vendor Id/Party Id to check Deluxe Payments.
    let primaryPayeePartyId : number;
    if(this.selectedPaymentOptions.vendor  != undefined && this.selectedPaymentOptions.vendor != null
        && this.selectedPaymentOptions.vendor.vendorId != undefined && this.selectedPaymentOptions.vendor.vendorId != null){
      primaryPayeePartyId = this.selectedPaymentOptions.vendor.vendorId;
    } else {
      let primaryPayeeNameType = this.selectedPaymentOptions.payees[0];
      let primaryPayeeObj = this.dropdownList.find(payee => payee.name_type == primaryPayeeNameType);
      primaryPayeePartyId = primaryPayeeObj.partyId;
    }
    console.log("Primary Payee name : " + this.selectedPaymentOptions.payees[0]);
    console.log("Primary Payee Party ID : " + primaryPayeePartyId);
    // Call Service API to find out the Deluxe payment eligiblity.
    this.checkAndShowPaymentOptionsModal(primaryPayeePartyId, covCode, 'Deluxe');
  }

  isSelectedPayeesUnInsured(payees) {
    let unInsuredFlag = false;
    if (payees instanceof Array) {
      payees.forEach(payee => {
        // Check if payee not insured or secondary.
        console.log(payee + " - Insured ?" + payee.includes("Insured") + " or Secondary?" + payee.includes("Secondary"));
        if (!payee.includes("Insured") && !payee.includes("Secondary")) {
          unInsuredFlag = true;
        }
      });
      /*for(var payee of payees){
        //let payee : string = payees[i];
        console.log(payee.name_type + " - Insured ?" + payee.name_type.includes("Insured") + " or Secondary?" + payee.name_type.includes("Secondary"));
        if (!payee.name_type.includes("Insured") && !payee.name_type.includes("Secondary")) {
          unInsuredFlag = true;
        }
      }*/
    } else {
      console.log(payees + " - Insured ?" + payees.includes("Insured") + " or Secondary?" + payees.includes("Secondary"));
      if (!payees.includes("Insured") && !payees.includes("Secondary")) {
        unInsuredFlag = true;
      }
    }
    return unInsuredFlag;
  }

  openNoteModalPopup() {
    const modalRef = this.modalService.open(AddNoteComponent, { centered: true });
    modalRef.componentInstance.claimNumber = this.claimNumber;
    let closeResult: string;
    modalRef.result.then((note) => {
      if (note !== 'Note Modal Closed' && note !== 'Note Modal Cancelled') {
        this.noteContent = note;
        this.selectedPaymentOptions.noteContent = note;
      } else {
        console.log("Note Modal has closed/cancelled without any update.");
      }
    }, (reason) => {
      closeResult = `Modal Dismissed ${this.restUtil.getDismissReason(reason)}`;
      console.log("note modal closed : " + closeResult);
    });
  }

  openMemoModalPopup() {
    const modalRef = this.modalService.open(AddMemoComponent, { centered: true });
    let closeResult: string;
    modalRef.componentInstance.claimNumber = this.claimNumber;
    // send the selected InPayment Of option.
    modalRef.componentInstance.selectedInPaymentOf = this.selectedPaymentOptions.inPaymentOfMap.value;
    modalRef.result.then((memo) => {
      if (memo !== 'Memo Modal Closed' && memo !== 'Memo Modal Cancelled') {
        this.memoContent = memo;
        this.selectedPaymentOptions.memoContent = memo;
      } else {
        console.log("Memo Modal has closed/cancelled without any update.");
      }
    }, (reason) => {
      closeResult = `Modal Dismissed ${this.restUtil.getDismissReason(reason)}`;
      console.log("Memo modal closed : " + closeResult);
    });
  }

  openVendorSearchModalPopUp() {
    const vendorModalRef = this.modalService.open(VendorComponent, { size: 'lg' });
    let closeResult: string;
    vendorModalRef.componentInstance.claimNumber = this.claimNumber;
    vendorModalRef.result.then((selectedVendor) => {
      if (selectedVendor !== 'Vendor Search Modal Closed' && selectedVendor !== 'Vendor Search Modal Cancelled' && selectedVendor !== 'undefined' && selectedVendor !== 'Show New Vendor Page') {
        // this.selectedPaymentOptions.vendor = selectedVendor;
        let vendorToPush = new ClaimPayee();
        vendorToPush.name = selectedVendor.providerName;
        vendorToPush.name_type = selectedVendor.providerName;
        vendorToPush.partyId = selectedVendor.vendorId;
        let vendorAddress = new IPayeeAddress();
        vendorAddress.addressLine1 = selectedVendor.street1;
        vendorAddress.addressLine2 = selectedVendor.street2;
        vendorAddress.city = selectedVendor.city;
        vendorAddress.state = selectedVendor.state;
        vendorAddress.postalCode = selectedVendor.zip;
        vendorToPush.address = vendorAddress;
        // Push the vendor address to PayeesWthAddress
        // this.payeesWithAddress.push(vendorToPush);
        // Push the vendor to selected Payees.
        //this.selectedPaymentOptions.payees.push(selectedVendor.providerName);

        // Remove the previously selected vendor from Mail Address and Payees.
        // then Set the previouslySelectedVendor to currently selected vendor 
        if (this.firstTimeVendorSelected) {
          // set the selected times and dropdown list & Payee Address.
          this.onPayeeDeSelect(vendorToPush.name);
          this.dropdownList = this.dropdownList.filter(prevVendor => prevVendor !== vendorToPush);
          this.selectedItems = this.selectedItems.filter(selEle => selEle !== vendorToPush);
          this.payeesWithAddress = this.payeesWithAddress.filter(selEle => selEle !== vendorToPush);
          this.payeesWithAddress.push(vendorToPush)
          // Remove if the payee address selected from selectedPaymentOptions.payeeAddress
          if (this.selectedPaymentOptions.payeeAddress === vendorToPush) {
            this.selectedPaymentOptions.payeeAddress = null;
          }
          this.firstTimeVendorSelected = false;
        } else if (this.vendorSelected) {
          this.onPayeeDeSelect(this.previouslySelectedVendor.name);
          this.dropdownList = this.dropdownList.filter(prevVendor => prevVendor !== this.previouslySelectedVendor);
          this.selectedItems = this.selectedItems.filter(selEle => selEle !== this.previouslySelectedVendor);
          this.payeesWithAddress = this.payeesWithAddress.filter(selEle => selEle !== this.previouslySelectedVendor);
          this.payeesWithAddress.push(vendorToPush)
          // Remove if the payee address selected from selectedPaymentOptions.payeeAddress
          if (this.selectedPaymentOptions.payeeAddress === this.previouslySelectedVendor) {
            this.selectedPaymentOptions.payeeAddress = null;
          }
        }
        this.previouslySelectedVendor = vendorToPush;
        this.vendorSelected = true;
        this.dropdownList.push(vendorToPush);
        // remove tha last added payee if selectedPayees array has 4 elements.
        if(this.selectedItems.length == 4){
          console.log("Removing last added payee to add selected vendor : " + this.selectedItems.pop());
        }
        this.selectedItems.push(vendorToPush);
        this.onPayeesSelect(vendorToPush.name);
        this.selectedPaymentOptions.vendor = selectedVendor;
        this.vendorSelectedTemporaryHolder = selectedVendor.providerName;
        console.log("Selected Vendor from search : " + this.selectedPaymentOptions.vendor.providerName);
      } else if (selectedVendor == 'Show New Vendor Page') {
        this.openPopulateNewVendor();
      } else {
        console.log("Vendor Search modal closed/cancelled without any selection. " + selectedVendor);
      }
    }, (reason) => {
      closeResult = `Modal Dismissed ${this.restUtil.getDismissReason(reason)}`;
      console.log("Vendor Search modal closed : " + closeResult);
    });
  }

  openPopulateNewVendor() {
    console.log("Opening new vendor populate screen...");
    let closeResult: string;
    let newVendorModalRef = this.modalService.open(NewVendorComponent, { size: 'lg' });
    newVendorModalRef.componentInstance.claimNumber = this.claimNumber;
    newVendorModalRef.result.then(populatedNewVendor => {
      console.log("New Vendor Populated.")
      if (populatedNewVendor !== 'New Vendor Modal Closed' && populatedNewVendor !== 'New Vendor Modal Cancelled' && populatedNewVendor !== 'undefined') {
        this.newVendor = populatedNewVendor;
      } else {
        console.log("New Vendor population window closed.");
      }
    }, (reason) => {
      closeResult = `New Vendor Modal Dismissed ${this.restUtil.getDismissReason(reason)}`;
      console.log("New Vendor Populate modal closed : " + closeResult);
    });
  }

  openPreviewNewVendor() {
    console.log("Opening new vendor populate screen...");
    let closeResult: string;
    let previewNewVendorModalRef = this.modalService.open(NewVendorPreviewComponent, { size: 'lg', 'keyboard': false, backdrop: 'static' });
    previewNewVendorModalRef.componentInstance.newVendor = this.newVendor;
    previewNewVendorModalRef.result.then(actionOnPreviewNewVendor => {
      console.log("New Vendor Preview Action : " + actionOnPreviewNewVendor);
    }, (reason) => {
      closeResult = `Preview New Vendor Modal Dismissed ${this.restUtil.getDismissReason(reason)}`;
      console.log("Preview New Vendor Populate modal closed : " + closeResult);
    });
  }

  // Actual print-preview modal function.
  // Commented to test preview.
  openPreviewModalPopup(fileUrl: string, pdfBlob: Blob) {
    console.log("Opening preview modal popup...");
    let closeResult: string;
    const previewModalRef = this.modalService.open(PrintPreviewComponent, { size: 'lg', backdrop: 'static', keyboard: false });
    previewModalRef.componentInstance.pdfFileUrl = fileUrl;
    previewModalRef.componentInstance.pdfBlob = pdfBlob;
    previewModalRef.componentInstance.checkDetails = this.selectedPaymentOptions;
    previewModalRef.componentInstance.claimNumber = this.claimNumber;
    previewModalRef.componentInstance.inputTypeIndc = this.paymentTypeManualOrHybrid;
    previewModalRef.componentInstance.dispatchId = this.dispatchId;
    previewModalRef.result.then((modalResult: Status) => {
      console.log("Action Taken on Preview Modal : " + modalResult.actionOnModal);
      if (modalResult.actionOnModal == "Print" && (modalResult.performedTask == "GCP_PRINT" || modalResult.performedTask == "CIRRUS_PRINT")) {
        // Here route the user to log view as Print got successful.
        if (modalResult.statusCode == "FAILURE") {
          console.log(modalResult.performedTask + " failed, increment the cloudPrintFailedCount...");
          this.cloudPrintFailedCount = this.cloudPrintFailedCount + 1;
          // statusmessage would have job id in case of failure for GCP Print only.
          if(modalResult.performedTask == "GCP_PRINT") {
            this.gcpFailedPrintJobId = modalResult.statusMessage;
            this.isGcpEnabled = true;
          }
        }
        console.log(modalResult.performedTask + "-Triggered, show the print confirm modal...");
        this.openPrintConfirmModal(modalResult.checkNumber, "true");
      } else if (modalResult.actionOnModal == "Cancel") {
        console.log(" User cancelled preview screen.");
      } else if (modalResult.actionOnModal == "FALLBACK_ENABLED"){
        console.log("CloudPrint Fallback solution enabled, initiate custom print solution...");
        this.openCustomPrintModel('FALLBACK_ENABLED');
      }
    }, (reason) => {
      closeResult = `Modal Dismissed ${this.restUtil.getDismissReason(reason)}`;
      console.log("Print Preview modal closed : " + closeResult);
    });

  }

  openPrintConfirmModal(checkSeqNum: string, showVoidAndRetry : string) {
    let closeResult: string;
    const printConfirmModalRef = this.modalService.open(PrintConfirmComponent, { centered: true });
    printConfirmModalRef.componentInstance.showVoidAndRetryIn = showVoidAndRetry;
    printConfirmModalRef.result.then((confirmAction) => {
      console.log("Print confirm result : " + confirmAction);
      // Execute required api call based on action.
      if (confirmAction == "PrintSuccess") {
        this.uploadPaymentWithStatus(checkSeqNum, true, "UPLOAD");
      } else if (confirmAction == "VoidAndRetry") {
        // Call Check Void and Retry API here.
        this.uploadPaymentWithStatus(checkSeqNum, false, "VOID");
        // Call the delete job api to delete the print job.
        if (this.gcpFailedPrintJobId && this.isGcpEnabled) {
          console.log("Delete Print job id : " + this.gcpFailedPrintJobId);
          this.restUtil.deleteGCPPrintJob(this.gcpFailedPrintJobId).subscribe(deleted => {
            console.log(this.gcpFailedPrintJobId + " - deleted the job : " + deleted.toString());
          }, error => {
            console.log("Unable to delete the job id : " + this.gcpFailedPrintJobId);
            // Supress the error to proceed with further fall back process.
          });
        }
        // Check if Cloud FailureCount reaches 3. If yes then open the CustomPrint modal 
        // To show the print dialogue and open the pdf in new tab.
        if (this.cloudPrintFailedCount >= 2) {
          console.log("Unable to issue prints via Cloud, open custom print modal.");
          this.openCustomPrintModel('RegularFallBack');
        } else {
          console.log("Retry printing check again...");
          this.retryPrintCheck();
        }
        // Increment gcp failure counter by default assuming that Print didnt come.
        this.cloudPrintFailedCount = this.cloudPrintFailedCount + 1;
      } else if (confirmAction == "TimedOut") {
        this.errorOccurred = false;
        console.log("Print Confirm Modal Timedout, Route user back to logview.");
        // Route user to log view.
        this.router.navigateByUrl("logView");
      } else {
        //"Cancelled  or Destroyed.
        this.uploadPaymentWithStatus(checkSeqNum, true, "VOID");
      }
    }, (reason) => {
      closeResult = `Print Confirm Modal dismissed ${this.restUtil.getDismissReason(reason)}`;
      console.log(closeResult);
      // show print confirm modal if User dismissed the modal with out response.
      this.openPrintConfirmModal(checkSeqNum, "true");
    });
  }

  openCustomPrintModel(userOriginIn:string) {
    // Get the pdf blob and supply for new tab.
    let checkPdfFileBlob = null;
    let checkPdfFileUrl = '';
    this.restUtil.submitPaymentOptions(this.selectedPaymentOptions, JSON.parse(this.storageService.get("X_Token"))).subscribe((data) => {
      checkPdfFileBlob = data;
      checkPdfFileUrl = URL.createObjectURL(checkPdfFileBlob);
      this.errorOccurred = false;
    }, error => {
      console.error("Error OCcured with service call with http status: " + error.status);
      // Set the error message.
      this.errorOccurred = true;
      this.errorResponse = this.restUtil.handleErrorResponse(error);
    });
    // Open the custom print modal to show the message
    let closeResult: string;
    const customPrintModalRef = this.modalService.open(CustomPrintComponent, { size: 'lg', centered: true, 'keyboard': false, backdrop: 'static' });
    customPrintModalRef.componentInstance.claimNumber = this.claimNumber;
    customPrintModalRef.componentInstance.userOrigin = userOriginIn;
    customPrintModalRef.result.then(result => {
      console.log("Custom Print modal closed with Result : " + result);
      if (result == 'Enable Fallback') {
        this.openGCPFailurePDFPrintWindow(checkPdfFileUrl, checkPdfFileBlob);
        // Open the print confirm to capture print status.
        let paymentTranx = new PaymentTransaction();
        paymentTranx.transactionType = "PRINT_ISSUED_ONLY";
        paymentTranx.typeOfPayment = this.paymentTypeManualOrHybrid;
        paymentTranx.paymentDetails = this.selectedPaymentOptions;
        paymentTranx.dispatchId = this.dispatchId;
        let checkSequenceNumber: string;
        console.log("Payment Transaction : " + paymentTranx.toString());
        // Get the check sequence from API. Add Print Issued Transaction.
        this.restUtil.processTransaction(this.claimNumber, paymentTranx).subscribe((statuses) => {
          console.log("Print Issued Transaction added and response : ");
          statuses.forEach(item => {
            console.log("Status : " + item.toString());
            if (item.performedTask = "PRINT_TRANX") {
              checkSequenceNumber = item.checkNumber;
            }
          });
          if (checkSequenceNumber) {
            console.log("Open print confirm modal for check sequence : " + checkSequenceNumber);
            // Send showVoidRetry=false to supress void & retry button as the printing taken care by user outside the app.
            this.openPrintConfirmModal(checkSequenceNumber, "false");
          }
        }, error => {
          console.log("Error Occured while calling adding new print transactions api. " + error.error.message);
          this.errorOccurred = true;
          this.errorResponse = this.restUtil.handleErrorResponse(error);
        });
      } else if (result == 'FALLBACK_ENABLED_Cancel' || result == 'RegularFallBack_Cancel') {
        // This logic will keep them on make payment screen when they cancel on custom print screen.
        console.log("User Cancelled on custom print, take them back to payment screen as userOrigin present.");

      } else {
        console.log("User did not click enable Cloud Print fall back option.");
        // Route user to log view.
        this.router.navigateByUrl("logView");
      }
    }, (reason) => {
      closeResult = `Custom Print Modal closed ${this.restUtil.getDismissReason(reason)}`;
      console.log(closeResult);
    });
  }

  /**
   * Uploads the payment based on the action. Action could be Void/upload.
   * 
   * @param checkSeqNum 
   * @param redirectToLogView 
   * @param action -> VOID / UPLOAD
   */
  uploadPaymentWithStatus(checkSeqNum: string, redirectToLogView: boolean, action: string) {
    console.log("Upload payment for : " + checkSeqNum + " with action:" + action);
    this.restUtil.retryUploadOrVoidPayments(checkSeqNum, action, "true").subscribe((response) => {
      this.errorOccurred = false;
      // Route user to log view.
      if (redirectToLogView) {
        this.router.navigateByUrl("logView");
      }
    }, error => {
      console.log("Error Occured while calling post print transactions api. " + error.error.message);
      this.errorOccurred = true;
      this.errorResponse = this.restUtil.handleErrorResponse(error);
    });
  }

  retryPrintCheck() {
    console.log("Retry again for printing...");
    // Retry api call and upon return open pring confirm.
    // Get previsouly chosen printer at the time of print form storage.

    let printerId: string = JSON.parse(this.storageService.get(this.userId + "_printer"));
    // Feb-05-2019. Updated to use new API's.
    let reprintPaymentDetails = new PaymentTransaction();
    reprintPaymentDetails.paymentDetails = this.selectedPaymentOptions;
    reprintPaymentDetails.printStatus = "VoidAndRetry";
    reprintPaymentDetails.typeOfPayment = this.paymentTypeManualOrHybrid;
    reprintPaymentDetails.printerId = printerId;
    reprintPaymentDetails.dispatchId = this.dispatchId;
    this.restUtil.processCheckPrintTransaction(this.claimNumber, reprintPaymentDetails).subscribe((data) => {
      this.printTranxStatuses = data;
      this.printTranxStatuses[1].actionOnModal = "Print";
      this.errorOccurred = false;
      // statusmessage would have job id in case of failure.
      this.gcpFailedPrintJobId = this.printTranxStatuses[1].statusMessage;
      console.log("Updated Cloud Print Failure Job : " + this.gcpFailedPrintJobId);
      this.openPrintConfirmModal(this.printTranxStatuses[0].checkNumber, "true");
    }, error => {
      this.errorOccurred = true;
      this.errorResponse = this.restUtil.handleErrorResponse(error);
    });
  }

  submitPaymentOptionsAndCallPreviewModal() {
    console.log("submit payment and open preview modal");
    this.restUtil.submitPaymentOptions(this.selectedPaymentOptions, JSON.parse(this.storageService.get("X_Token"))).subscribe((data) => {
      const fileUrl = URL.createObjectURL(data);
      this.errorOccurred = false;
      // To open pdf in modal
      this.openPreviewModalPopup(fileUrl, data);
    }, error => {
      console.error("Error OCcured with service call with http status: " + error.status);
      // Set the error message.
      this.errorOccurred = true;
      this.errorResponse = this.restUtil.handleErrorResponse(error);
    });
  }

  previewCheck(form: any) {
    console.log("Form Subitted!");
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
    }, 200);
  }

  openGCPFailurePDFPrintWindow(pdfFileUrl: string, pdfBlob: Blob) {
    let userAgent = window.navigator.userAgent;
    console.log("Opening new window in user agent : " + userAgent);
    //alert("Opening new window in user agent : " +  userAgent);
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      //alert("Its IE on iOS");
      console.log("IE browser so provide save/download option.");
      window.navigator.msSaveOrOpenBlob(pdfBlob);
    } else if (userAgent.match('CriOS')) { //Chrome iOS
      console.log("Chrome ios browser...");
      var reader = new FileReader();
      //alert("Its Chrome on iOS");
      // uncomment below line to show pdf in new tab.
      //reader.onloadend = function () { window.open(reader.result);};
      reader.readAsDataURL(pdfBlob);
    } else if (userAgent.match('FxiOS')) { //FF iOS
      // alert("Its FF on iOS");
      // window.URL.createObjectURL(this.pdfBlob,{oneTimeOnly:true});
      var url = window.URL.createObjectURL(pdfBlob);
      let pdfWindow = window.open();
      pdfWindow.location.href = url;
    } else if (userAgent.match('EdgiOS')) { //IE iOS
      //alert("Its IE/EDGE on iOS");
      var url = window.URL.createObjectURL(pdfBlob);
      let pdfWindow = window.open(url);
      //pdfWindow.location.href = url;
    } else if (userAgent.match(/iPad/i) || userAgent.match(/iPhone/i)) { //Safari & Opera iOS
      //alert("Its Regular brwser on iOS");
      console.log("iPad/iPhone ios browser...");
      var url = window.URL.createObjectURL(pdfBlob);
      let pdfWindow = window.open();
      pdfWindow.location.href = url;
    } else {
      console.log("else block for opening the window.");
      window.open(pdfFileUrl);
    }
    //let pdfTab = window.open(this.pdfFileUrl,'Print_PDF','toolbar=0,scrollbars=0,location=0,statusbar=0,menubar=0,resizable=0');
    //pdfTab.location.href = this.pdfFileUrl;
    console.log("pdf loadded in another window.");
  }

  /**
   * In Payment Of Changes for TypeAhead with Editable field.
   */
  public inPaymentOfTypesModel: any;
  inPaymentOfTypesStore: IKeyValuePair[];
  inPaymentOfTypesValuesStore: string[] = [];
  inPaymentOfSelectedItem : string;

  inputFormatter = (value : any) => {
    console.log("Input Formater : " + value);
    if (this.inPaymentOfTypesValuesStore.indexOf(value) < 0) {
      this.inPaymentOfTypesValuesStore.push(value);
      console.log("Pushed new element : " + value);
    }
    return value;
  }
  /**
   * InPaymentOf new method.
   */
  loadInPaymentOfStore() {
    let queryParams: IKeyValuePair[] = [];
    this.restUtil.getDropDownOptions(this.inPaymentOfContextPath, queryParams, queryParams).subscribe((data) => {
      // console.log("InPaymentOf: " + data);
      this.inPaymentOfTypesStore = data;
      data.forEach(item => {
        this.inPaymentOfTypesValuesStore.push(item.value);
      })
    });
  }

  @ViewChild('instance', { static: true }) instance: NgbTypeahead;
  focus$ = new Subject<string>();
  click$ = new Subject<string>();

  search = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.instance.isPopupOpen()));
    const inputFocus$ = this.focus$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map(term => (term === '' ? this.inPaymentOfTypesValuesStore
        : this.inPaymentOfTypesValuesStore.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1)))
    );
  }

  selectedItem(item) {
    console.log("Type Ahead selected in payment of : " + item.item + " vs Model : " + this.inPaymentOfTypesModel);
    this.inPaymentOfSelectedItem = item.item;
  }

  onTypeAheadInPaymentOfChanged(optionSelected: string) {
    console.log("Item Searched For : " + optionSelected);
    if (optionSelected != this.inPaymentOfSelectedItem) {
      console.log("Searched Item not in store : " + optionSelected);
      this.inPaymentOfSelectedItem = optionSelected;
    }
    if (!this.selectedPaymentOptions.inPaymentOfMap) {
      this.selectedPaymentOptions.inPaymentOfMap = { "key": "", "value": "" };
    }
    this.selectedPaymentOptions.inPaymentOfMap.value = this.inPaymentOfSelectedItem;
    this.selectedPaymentOptions.memoContent = this.selectedPaymentOptions.inPaymentOfMap.value;
    if (this.inPaymentOfSelectedItem.length != 0)
      this.inPaymentOfSelected = true;
  }

  /**
   * Resets all the flags used for validation.
   */
  resetAllFlagAfterPageLoad(claimNumber: string) {
    this.paymentElementSelected = false;
    this.payeesSelected = false;
    this.payeeAddressSelected = false;
    this.paymentTypeSelected = false;
    this.inPaymentOfSelected = false;
    this.formSubmitAttempted = false;
    console.log("Reseted all the flags!-New Claim:" + claimNumber);
    this.makePaymentForm.resetForm();
    this.changeDetectorRef.detectChanges();
    console.log("Reseted the form fields.");
  }

  /**
   * On expense reserve change.
   * 
   */
  onExpenseReserveSelectionChange() {
    console.log("IsExpenseReserve selected ? " + this.selectedPaymentOptions.isExpenseReserve);
    console.log("Before ExpenseReserve action: " + this.selectedPaymentOptions.paymentElement);
    // Check if Payment Element has been already selected.
    if (this.selectedPaymentOptions.paymentElement) {
      let paymentElements = this.selectedPaymentOptions.paymentElement.split(PAY_ELE_SEPARATOR);
      let prevSelectedPaymentElementTemp = paymentElements[2];
      if(this.selectedPaymentOptions.isExpenseReserve) {
        paymentElements[2] = "clm_exp";
      } else {
        paymentElements[2] = this.lastSelectedPaymentElement;
      }
      let modifiedPayEle = paymentElements.join(PAY_ELE_SEPARATOR);
      // Push this new element to paymentElements for UI selection.
      if(!this.paymentElements.includes(modifiedPayEle)){
        this.paymentElements.push(modifiedPayEle);
      }
      // Update selected Payment Element with modifiedPayEle
      this.selectedPaymentOptions.paymentElement = modifiedPayEle;
      // refresh Payment Types dropdown
      this.onPaymentElementSelection(this.selectedPaymentOptions.paymentElement);
      // Update selected Payment element in paymentOption.
      this.lastSelectedPaymentElement = prevSelectedPaymentElementTemp;
      console.log("After ExpenseReserve action: " + this.selectedPaymentOptions.paymentElement);
    } else {
      console.log("Payment element not selected.");
    }

  }

  /**
   * 
   * @param partyId  - optional
   * @param covCode   - optional
   * @param paymentType   - optional but it defaults to WellsFargo
   */
  checkAndShowPaymentOptionsModal(partyId? : number, covCode? : string, paymentType?:string) {
    console.log("Check if electornic payments active for claim# " + this.claimNumber);
    this.restUtil.getEPaymentActiveStatus(this.claimNumber, partyId, covCode, paymentType).subscribe((response) => {
      console.log("EPay Active : " + response.ePayActive);
      if (response && response.ePayActive && (response.zelleOptedIn || response.directOptedIn || response.deluxeOptedIn)) {
        this.ePayActiveModel = response;
        // Show the payment options modal dialogue
        this.openPaymentSelectionModal();
      } else if (paymentType != 'Deluxe' ) { 
        this.handleClaimDeluxePaymentsOrShowPreview();
      } else {
        // Commented below line to check Deluxe Payments.
        this.submitPaymentOptionsAndCallPreviewModal();
        // this.handleClaimDeluxePaymentsOrShowPreview();
      }
    }), error => {
      console.error("Error OCcured with service call with http status: " + error.status);
      // Set the error message.
      this.errorOccurred = true;
      this.errorResponse = this.restUtil.handleErrorResponse(error);
    }
  }

  openPaymentSelectionModal() {
    const paySelModalRef = this.modalService.open(PaymentOptionsComponent, { centered: true, size: 'lg' });
    paySelModalRef.componentInstance.isZelleDepositOptedIn = this.ePayActiveModel.zelleOptedIn;
    paySelModalRef.componentInstance.isDirectDepositOptedIn = this.ePayActiveModel.directOptedIn;
    paySelModalRef.componentInstance.isDeluxeDepositOptedIn = this.ePayActiveModel.deluxeOptedIn;
    paySelModalRef.componentInstance.directDepositAccount = this.ePayActiveModel.directDepositMaskedAcct;
    paySelModalRef.componentInstance.zelleDepositAccount = this.ePayActiveModel.zelleDepositMaskedAcct;
    paySelModalRef.componentInstance.deluxeDepositAccount = this.ePayActiveModel.deluxeDepositMaskedAcct;
    paySelModalRef.result.then((option) => {
      console.log("Parent component, selected payment option : " + option);
      if (option && option !== 'none') {
        this.paymentOptionSelected = option;
        if (this.paymentOptionSelected === 'EFT_DD') {
          console.log("callimng submitVirtualPayment with EFT_DD.");
          this.optedPaymentAccount = this.ePayActiveModel.directDepositMaskedAcct;
          this.submitVirtualPayment();
        } else if (this.paymentOptionSelected === 'Zelle') {
          console.log("callimng submitVirtualPayment with Zelle.");
          this.optedPaymentAccount = this.ePayActiveModel.zelleDepositMaskedAcct;
          this.submitVirtualPayment();
        } else if (this.paymentOptionSelected === 'Deluxe') {
          console.log("callimng submitVirtualPayment with Deluxe.");
          this.optedPaymentAccount = this.ePayActiveModel.deluxeDepositMaskedAcct;
          this.submitVirtualPayment('Deluxe');
        } else if (this.paymentOptionSelected === 'Paper') {
          console.log("callimng submitPaymentOptionsAndCallPreviewModal.");
          this.submitPaymentOptionsAndCallPreviewModal();
        }
      } else {
        console.log("User cancelled from payment options.");
      }
    });
  }

  submitVirtualPayment(paymentSourceType?: string) {
    console.log("Virtual Payment option selected, processing " + this.paymentOptionSelected);
    let paymentDetails = new PaymentTransaction();
    paymentDetails.paymentDetails = this.selectedPaymentOptions;
    paymentDetails.printStatus = "VirtualPay";
    paymentDetails.typeOfPayment = this.paymentTypeManualOrHybrid;
    paymentDetails.printerId = null;
    paymentDetails.dispatchId = this.dispatchId;
    paymentDetails.paymentTransactionType = this.paymentOptionSelected;
    paymentDetails.paymentAccount = this.optedPaymentAccount;
    if(paymentSourceType != undefined)
      paymentDetails.paymentSourceSystem = paymentSourceType;
    this.restUtil.submitVirtualPayment(this.claimNumber, paymentDetails).subscribe((data) => {
      console.log("Virtual Payment API Response  :" + data);
      // Route user to log view.
      this.router.navigateByUrl("logView");
    }, error => {
      console.error("Error Occured with posting print transaction with http status: " + error.status);
      // Set the error message.
      this.errorOccurred = true;
      this.errorResponse = this.restUtil.handleErrorResponse(error);
    });

  }

  ngOnDestroy(): void {
  }

  /*const qz = require("qz-tray");
 
  qz.websocket.connect().then(() => {
      return qz.printers.find();
  }).then((printers) => {
      console.log(printers);
      let config = qz.configs.create('PDF');
      return qz.print(config, [{
          type: 'pixel',
          format: 'html',
          flavor: 'plain',
          data: '<h1>Hello JavaScript!</h1>'
      }]);
  }).then(() => {
      return qz.websocket.disconnect();
  }).then(() => {
      // process.exit(0);
  }).catch((err) => {
      console.error(err);
      // process.exit(1);
  });*/


}
