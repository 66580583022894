import {Injectable} from "@angular/core";

declare let ga: any;

@Injectable()
export class GoogleAnalyticsService {

  public emitEvent(eventCategory: string,
                   eventAction: string,
                   eventLabel: string = null,
                   eventValue: number = null) {
    ga('send', 'event', {
      eventCategory: eventCategory,
      eventLabel: eventLabel,
      eventAction: eventAction,
      eventValue: eventValue
    });
  }

 public trackPage(pageName: string){
     ga('send', 'pageview', pageName);
 }

}