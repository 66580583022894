import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { AppointmentService } from './appointment.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DatePipe} from '@angular/common/';
import { StorageService } from '../../shared/service/storage.service';
import { User } from '../../shared/login/user';
import { Appointment } from './appointment';
import { Observable, Observer } from 'rxjs';
import { GmDistance } from '../../shared/service/GmDistance';
import { MapKey } from '../../shared/requestTemplate/MapKey';
import { SmsNotificationReq } from '../../shared/requestTemplate/SmsNotificationReq';
import { SendMessageService } from '../../shared/service/send.message.service';
import { environment } from '../../../environments/environment';


declare var require: any

@Component({
  selector: 'eclaimsonesite-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {

  public pictureUrl: string;
  public notificationScript : String = '';
  public appointment: Appointment;
  public appointmentDate: string;
  public user: User;
  public appraiserBusinessPhone: string;
  public appraiserCellPhone: string;
  public appraiserPhone: string;
  public appraiserContactsAdded: boolean;
  public appraiserUrl: string;
  public appointment$: any;
  public showSMSNotitfication : boolean;
  
  currentLocation = {lat: 0, lng: 0};
  public durationText:string;
  public distanceText:string;
  public gmDistance:GmDistance;
  public keys:MapKey[] = [];
  public claimNumber:string;

  public isMessageSent:boolean = false;
  public messageStatus:string = "Send Notification";

  constructor(private router: Router ,
    private route: ActivatedRoute,
    private appointmentService: AppointmentService,
    private storageService: StorageService,
    private sendMessageService: SendMessageService,
    public dialog: MatDialog) { }

  ngOnInit() {
    
    // set params
    this.route.paramMap.subscribe( params => {
      this.claimNumber = params.get('claimNumber') ;
      let dispId:string = params.get('dispId') ;
      console.log( this.claimNumber );      
      this.appointment = this.appointmentService.getSelectedAppointment(this.claimNumber,dispId);
      if ( this.appointment == null ) {
        this.router.navigateByUrl('/login');
      } 
      this.appointmentDate = params.get('appointmentDate');                                                       
      this.storageService.save('appraiserFullName', this.appointment.appraiserName );
      this.gmDistance = JSON.parse(this.storageService.get(this.appointment.appointmentID+"-distanceInfo"));
      console.info("this.gmDistance",this.gmDistance);
      this.durationText= this.gmDistance.durationText;
      this.distanceText = this.gmDistance.distanceText;
      this.isMessageSent=false;
      this.messageStatus = "Send Notification";
    });

    console.log( "AppointmentdetailComponent  ngOnInit=" + JSON.stringify( this.appointment ));

    let key = 'user-details';
    if ( !this.storageService.get(key) ){
     this.router.navigateByUrl('/login');
    }
    else {
        let user:User = JSON.parse(this.storageService.get(key));
        this.user = user;
        let appraiserId:string = user.userId;

        // get script
        this.getScriptByName('CLAIMS_EDISPATCH_NOTIFY_CUSTOMER_SMS_sms_body');
   }
  }


  getUserPictureUrl(user: User){
    this.appointmentService.getUserPictureUrl(user.claimSystemLoginId).subscribe( ( data: any ) => { 
      this.pictureUrl = data.url;
      this.storageService.save('pictureUrl', this.pictureUrl );
      console.log(data);
      }, error => {
        if ( error.status == "200" ){
          this.pictureUrl = error.url;
          this.storageService.save('pictureUrl', this.pictureUrl );
        }
        else {
           console.log('Failed to get the picture url');
        }
      });
  }

  getScriptByName(scriptName: string){
    if( !this.storageService.get('script-' + scriptName) ) {
      if(!this.storageService.get('scripts')) {
        this.appointmentService.getScripts(scriptName).subscribe( ( scripts: any ) => { 
          if(scripts && scripts.scriptList){
            if( !this.storageService.get('scripts') ) {
              this.storageService.save('scripts', scripts);
            }
            
            var counter = scripts.scriptList.length;
            while(--counter){
              if(scripts.scriptList[counter].scriptName == scriptName){
                if( !this.storageService.get('script-' + scriptName) ) {
                  this.storageService.save('script-' + scriptName, scripts.scriptList[counter]);
                }
                this.notificationScript = new String(scripts.scriptList[counter].scriptText);
                this.populateScriptParameters();
                break;
              };
            }
          }
        }, error => {
          console.log('Failed to get the scripts');
        });
      }
    }
    else{
      var scripts = JSON.parse(this.storageService.get('scripts'));
        var counter = scripts.scriptList.length;
        while(--counter){
          if(scripts.scriptList[counter].scriptName == scriptName){
            this.notificationScript = new String(scripts.scriptList[counter].scriptText);
            this.populateScriptParameters();
            break;
          };
        }
    }  
   }

   populateScriptParameters(){
    //this.notificationScript = this.notificationScript.replace(/CRLF/g, '<br/>').replace(/{{eclaim_insured_name}}/,'{{appointment.insuredName}}');
    let insuredNameTxt = (this.appointment.insuredName)?this.appointment.insuredName:this.appointment.claimantName;
    this.keys.push(new MapKey("stream_claim_nbr",this.claimNumber));
    this.keys.push(new MapKey("eclaim_insured_name",insuredNameTxt));
    this.keys.push(new MapKey("eclaim_appraiser_name",this.appointment.appraiserName));
    this.keys.push(new MapKey("driving_time",this.durationText));
    this.keys.push(new MapKey("driving_distance",this.distanceText));

    this.notificationScript = this.notificationScript.replace(/CRLF/g, '<br/>').replace(/{{eclaim_insured_name}}/,insuredNameTxt)
                                                    .replace(/{{eclaim_appraiser_name}}/g, this.appointment.appraiserName)
                                                    .replace(/{{driving_time}}/g, this.durationText)                          
                                                    .replace(/{{driving_distance}}/g, this.distanceText);
    
                         
    this.getUserContacts(); 
    this.getAppraiserLink();
    this.getUserPictureUrl(this.user);

    this.keys.push(new MapKey("stream_payee_name","NA"));
    this.keys.push(new MapKey("skip_filter","yes"));
    //this.keys.push(new MapKey("sms_to","6414517515"));
    console.info("this.keys",this.keys);
   }

   getAppraiserLink(){
    //this.appraiserUrl = window.location.href.substring(0, window.location.href.indexOf("#")) + '#/appraiser/' +  this.appointment.appointmentID;
    this.appraiserUrl = environment.CONTACT_DETAILS_URL + '#/appraiser/' +  this.appointment.appointmentID;
    
    this.keys.push(new MapKey("appraiser_detail_link",this.appraiserUrl));
    this.notificationScript = this.notificationScript.replace(/{{appraiser_detail_link}}/g, '<a href="' + this.appraiserUrl + '">' + this.appraiserUrl + '</a>');     
    this.storageService.save('appraiserLink', this.appraiserUrl);
  }

  getUserContacts(){
     this.appointmentService.getUserContacts(this.user.claimSystemLoginId).subscribe( ( userContacts: any ) => { 
      //this.pictureUrl = data.url;
      console.log(userContacts);
      var contactsIndex = userContacts.userCommInformationCompositeDTO.userPhoneInfo.userPhoneDTO.length;
            		
      while(contactsIndex--){
        if(userContacts.userCommInformationCompositeDTO.userPhoneInfo.userPhoneDTO[contactsIndex].phoneType == "busn_phn"){
          this.appraiserBusinessPhone = userContacts.userCommInformationCompositeDTO.userPhoneInfo.userPhoneDTO[contactsIndex].phoneNo.toString();
        }
        if(userContacts.userCommInformationCompositeDTO.userPhoneInfo.userPhoneDTO[contactsIndex].phoneType == "cell_phn"){
          this.appraiserCellPhone = userContacts.userCommInformationCompositeDTO.userPhoneInfo.userPhoneDTO[contactsIndex].phoneNo.toString();
        }
      }
      
      if(this.appraiserCellPhone){
        this.appraiserPhone = this.appraiserCellPhone;
        this.storageService.save('appraiserCellPhone', this.appraiserCellPhone);
      }
      else{
        this.appraiserPhone = this.appraiserBusinessPhone;
        this.storageService.save('appraiserBusinessPhone', this.appraiserBusinessPhone);
      }
      
      this.storageService.save('appraiserEmail',  userContacts.userInfoDTO.userEmailAddr);

      this.keys.push(new MapKey("eclaim_appraiser_phone",this.appraiserPhone));

      this.notificationScript = this.notificationScript.replace(/{{eclaim_appraiser_phone}}/g, '<a href="tel:' + this.scrubPhoneNumber(this.appraiserPhone) + '" class="appointment-phone"> ' + this.formatPhone(this.appraiserPhone) + '<span class="glyphicon glyphicon-earphone"></span></a>');
      this.appraiserContactsAdded = true;      
      }, error => {
        if ( error.status == "200" ){
          //this.pictureUrl = error.url;
        }
        else {
           console.log('Failed to get the user contacts');
        }
      });
   }
   calculateDistanceMatrix(currentLocation, inspectionLocation, appointment){
                
    // var service = new google.maps.DistanceMatrixService;
    // service.getDistanceMatrix(currentLocation, inspectionLocation, google.maps.TrafficModel.BEST_GUESS).then(function (distanceMatrixResponse) {
    //     appointment.distanceText = distanceMatrixResponse.rows[0].elements[0].distance.text;
    //     appointment.distanceValue = distanceMatrixResponse.rows[0].elements[0].distance.value;
    //     appointment.durationTextOptimistic = distanceMatrixResponse.rows[0].elements[0].duration.text;
    //     appointment.durationValueOptimistic = distanceMatrixResponse.rows[0].elements[0].duration.value;
    //     this.notificationScript = this.notificationScript.replace(/{{driving_time}}/g, appointment.durationTextOptimistic);
    //     this.notificationScript = this.notificationScript.replace(/{{driving_distance}}/g, appointment.distanceText);
    //   });

  }

  backToAppointmentsList(){
    this.router.navigateByUrl('/appointments'); 
  }  
  
              
  scrubPhoneNumber(phoneNumber){
    if(phoneNumber){
      return phoneNumber.replace(/\D/g,'');
    }
    else{
      return '';
    }
    
  };
  
  formatPhone(phoneNumber){
    return this.scrubPhoneNumber(phoneNumber).replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
  };

  getFirstName = function(fullName){
    if(fullName){
      return fullName.split(" ", 1)[0];
    }
    else{
      return '';
    }
  };

  notifyCustomer(){
    var smsNotificationReq = new SmsNotificationReq();
    smsNotificationReq.notificationRequest.keys.key.push(this.keys);
    this.messageStatus = "Sending SMS ...";
    //smsNotificationReq.keys.push(this.keys);
    console.info("smsNotificationReq",smsNotificationReq);
    console.info("smsNotificationReq",JSON.stringify(smsNotificationReq));
    var js2xmlparser = require("js2xmlparser");
    var message = js2xmlparser.parse("notificationRequests", smsNotificationReq);
    console.log(message);

    this.sendMessageService.sendMessage(message).subscribe(response => {
        this.messageStatus = "Message Sent!";
        this.isMessageSent=true;
    },error=>{
      if( error.status == "200" ){
        this.messageStatus = "Message Sent!";
        this.isMessageSent=true;
      }else{
      this.messageStatus = 'Error while sending message!' + error;
      this.isMessageSent=false;
      console.info("error",error);
      }
    })
  }
}
