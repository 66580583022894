import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { MatTable } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatTab } from '@angular/material/tabs';
import { MatSort } from '@angular/material/sort';

import { SelectionModel } from '@angular/cdk/collections';
import { CheckWriterService } from '../shared/services/check-writer.service';
import { AdjusterActivity } from '../shared/models/adjuster-activity';
import { Router } from '@angular/router';
import { Status } from '../shared/models/status';
import { ErrorResponse } from '../shared/models/error-response';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'eclaimsonesite-retry-payment',
  templateUrl: './retry-payment.component.html',
  styleUrls: ['./retry-payment.component.scss']
})
export class RetryPaymentComponent implements OnInit {

  @Input('adjusterId')
  adjusterId: string;

  activityLogs: AdjusterActivity[] = [];
  isErrorOccured: boolean = false;
  paymentUploaded: boolean = false;
  paymentUploadedResp: Status;
  errorResponse: ErrorResponse;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatTable, { static: true }) table: MatTable<AdjusterActivity>;

  displayedColumns: string[] = ['select', 'date', 'checkNumber', 'claimNumber', 'payee', 'amount', 'paymentStatus'];
  paymentsDataSource = new MatTableDataSource<AdjusterActivity>(this.activityLogs);
  selection = new SelectionModel<AdjusterActivity>(true, []);

  constructor(private restUtil: CheckWriterService
    , public router: Router
    , public activeModal: NgbActiveModal) { }

  ngOnInit() {
    this.getPaymentsNotUploaded();
  }

  getPaymentsNotUploaded() {
    console.log("Get Adjuster activity for : " + this.adjusterId);
    let excludeUploadPayments = 'Yes';
    this.restUtil.getUserActivityLogs(this.adjusterId, excludeUploadPayments).subscribe((data) => {
      this.isErrorOccured = false;
      this.activityLogs = data;
      console.log("Updated activity logs from services, render the rows.");
      this.paymentsDataSource = new MatTableDataSource<AdjusterActivity>(data);
      this.table.renderRows();
      this.paymentsDataSource.paginator = this.paginator;
      this.paymentsDataSource.sort = this.sort;
      console.log("Retrieved eligible payments size : " + this.activityLogs.length);
      if(this.activityLogs.length == 0){
        this.setWarningMessage("No Payments Found for Upload Or Void transactions.");
      }
      // To Test Void Use Case.
      this.selection.clear();
      console.log("Selected Ids : " + this.selection.selected.length);
    }, error => {
      console.log("Adjuster eligible payments errored response : " + error.error.errorMessage);
      this.isErrorOccured = true;
      this.resetWarningMessage();
      this.errorResponse = this.restUtil.handleErrorResponse(error);
    });
  }

  uploadPayment() {
    console.log('Uploading selected payments : ' + this.selection.selected.length);
    let selectedTransIds = this.getSelectedIds(this.selection.selected);
    if(!selectedTransIds) {
      this.setWarningMessage("Please select at least one of the Payment.");
    } else {
      this.resetWarningMessage();
      console.log("ids selected : " + selectedTransIds);
      this.restUtil.retryUploadOrVoidPayments(selectedTransIds, "RETRY_UPLOAD", "false").subscribe((response) => {
        response.forEach(sts => {
          console.log("Status response : " + sts.statusMessage);
        });
        this.setWarningMessage("Processed selected Payments Upload.");
        this.getPaymentsNotUploaded();
      }, error => {
        console.log("Upload payments errored response : " + error.error.errorMessage);
        this.isErrorOccured = true;
        this.resetWarningMessage();
        this.errorResponse = this.restUtil.handleErrorResponse(error);
      });
    }
    
  }

  voidPayment() {
    console.log('Void selected payments : ' + this.selection.selected.length);
    let selectedTransIds = this.getSelectedIds(this.selection.selected);
    if(!selectedTransIds) {
      this.setWarningMessage("Please select at least one of the Payment.");
    } else {
      this.resetWarningMessage();
      console.log("ids selected : " + selectedTransIds);
      this.restUtil.retryUploadOrVoidPayments(selectedTransIds, "VOID", "false").subscribe((response) => {
        response.forEach(sts => {
          console.log("Status response : " + sts.statusMessage);
        });
        this.setWarningMessage("Voided selected Payments.");
        this.getPaymentsNotUploaded();
      }, error => {
        console.log("Upload payments errored response : " + error.error.errorMessage);
        this.isErrorOccured = true;
        this.resetWarningMessage();
        this.errorResponse = this.restUtil.handleErrorResponse(error);
      });
    }
  }

  setWarningMessage(mesg: string){
    this.paymentUploaded= true;
    this.paymentUploadedResp = new Status();
    this.paymentUploadedResp.statusMessage = mesg;
  }

  resetWarningMessage(){
    this.paymentUploaded= false;
  }

  /**
   * returns comma separated ids.
   * 
   * @param inData 
   */
  getSelectedIds(inData:AdjusterActivity[]) {
    let selectedIds = '';
    let notFirstElement = false;
    inData.forEach(activity => {
      if(notFirstElement) {
        selectedIds = selectedIds + ',';
      }
      selectedIds = selectedIds + activity.tranxId;
      notFirstElement = true;
    });
    console.log("Selected Ids : " + selectedIds);
    return selectedIds;
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.paymentsDataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.paymentsDataSource.data.forEach(row => this.selection.select(row));
  }

  onClose() {
    this.activeModal.close('Closed Retry Payments Upload.');
  }

}
