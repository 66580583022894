<div #mainDiv class="container">
  <button type="button" class="btn-primary" (click)="backToAppointmentsList()">
    My Asgns List
  </button>
  <button type="button" class="btn-primary margin-x" (click)="showMap(userDetails.userId, userDetails.fullName)">
    Asgns Map
  </button>
  <button type="button" class="margin-x btn-warning" (click)="issuePayment()" style="background-color: #ff6319">
    Make Payment
  </button>
  <button type="button" class="btn-primary margin-x" [useExistingCss]="true" printSectionId="printCanvas" ngxPrint>
    Print
  </button>
  <button type="button" class="btn-primary" (click)="openSalvageAssignment()">
    Salvage Assign
  </button>
  <button type="button" class="margin-x btn-primary" (click)="issueLetter()">
    Letters
  </button>
  <br />
  <mat-expansion-panel [expanded]="true">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <dt class="col-sm-9"><u>APPOINTMENT INFO</u></dt>
      </mat-panel-title>
      <mat-panel-description> </mat-panel-description>
    </mat-expansion-panel-header>

    <dl class="row">
      <dt class="col-sm-3">Claim Number</dt>
      <!-- <dd class="col-sm-9">{{ appointment$.claimNumber }} - {{appointment$.claimSequence }} - {{appointment$.vendorSequence}}</dd> -->
      <dd [hidden]="appointment$.vehicleVin == ''" class="col-sm-9">
        <a href="javascript:void(0)" (click)="getCBSPLink()">{{ appointment$.claimNumber }} - {{
          appointment$.claimSequence }} -
          {{ appointment$.vendorSequence }}</a>
      </dd>
      <dd [hidden]="appointment$.vehicleVin != ''" class="col-sm-9">
        {{ appointment$.claimNumber }} - {{ appointment$.claimSequence }} -
        {{ appointment$.vendorSequence }}
      </dd>

      <dt [hidden]="appointment$.vehicleVin == ''" class="col-sm-3">
        CBSP Email Opt-Out
      </dt>
      <!-- checkbox is not checked, send email  -->
      <dd [hidden]="appointment$.vehicleVin == ''" class="col-sm-9">
        <input type="checkbox" [(ngModel)]="CBSPOptInStatusChecked" (change)="onCBSPOptInChange()" />
      </dd>

      <dt class="col-sm-3">Insured Info</dt>
      <dd class="col-sm-9">
        <span>{{ appointment$.insuredName }}</span>
        <ng-container *ngIf="appointment$.secondInsuredName"><br /><span>{{ appointment$.secondInsuredName
            }}</span></ng-container>
        <br /><span>{{ appointment$.insuredAddress }}</span>
      </dd>

      <dt class="col-sm-3">Appt Start</dt>
      <dd class="col-sm-9">
        <p-calendar #startDateTime id="startDateTime" [(ngModel)]="appointment$.startDateTime" placeholder="(not set)"
          showTime="true" hourFormat="12" [minDate]="currentDate" [defaultDate]="currentDate" [stepMinute]="15"
          [stepHour]="1" readonlyInput="true" baseZIndex="2050" [appendTo]="'mainDiv'" showButtonBar="false"
          [selectionMode]="'single'" (onFocus)="onFocus($event)" (onSelect)="onCalendarSelect($event, 'startDateTime')">
        </p-calendar>
      </dd>
      <dt class="col-sm-3">Appt End</dt>
      <dd class="col-sm-9">
        <p-calendar #endDateTime id="endDateTime" [(ngModel)]="appointment$.endDateTime" placeholder="(not set)"
          showTime="true" hourFormat="12" [minDate]="currentDate" [defaultDate]="appointment$.startDateTime"
          [stepMinute]="15" [stepHour]="1" readonlyInput="true" baseZIndex="2050" [appendTo]="'mainDiv'"
          showButtonBar="false" [selectionMode]="'single'" (onFocus)="onFocus($event)"
          (onSelect)="onCalendarSelect($event, 'endDateTime')">
        </p-calendar>
        &nbsp;<button type="button" class="btn-primary" [hidden]="appointment$.dataChanged == false"
          (click)="updateAppointment()">
          Update Date/Time
        </button>
      </dd>
      <dt class="col-sm-3">Inspection Location</dt>
      <dd class="col-sm-9">
        <div class="locationTextArea">
          <textarea class="ui-widget" rows="2" [(ngModel)]="appointment$.locationFormattedAddr"
            (keydown.enter)="$event.preventDefault()" placeholder="Inspection Location" autocorrect="off"
            autocapitalize="off" spellcheck="off" onClick="this.setSelectionRange(0, this.value.length)"
            #search></textarea>
        </div>
      </dd>
      <dt class="col-sm-3">Addr1</dt>
      <dd class="col-sm-9">
        <input (change)="this.onLocationComponentChange('locationAddrLn1Txt')"
          onClick="this.setSelectionRange(0, this.value.length)" class="ui-widget" type="text"
          [(ngModel)]="appointment$.locationAddrLn1Txt" />&nbsp;<button type="button" class="btn-primary"
          [hidden]="appointment$.locationChanged == false" (click)="updateLocation()">
          Update Location
        </button>
      </dd>
      <dt class="col-sm-3">Addr2</dt>
      <dd class="col-sm-9">
        <input (change)="this.onLocationComponentChange('locationAddrLn2Txt')"
          onClick="this.setSelectionRange(0, this.value.length)" class="ui-widget" type="text"
          [(ngModel)]="appointment$.locationAddrLn2Txt" />
      </dd>
      <dt class="col-sm-3">City</dt>
      <dd class="col-sm-9">
        <input (change)="this.onLocationComponentChange('locationCityName')"
          onClick="this.setSelectionRange(0, this.value.length)" class="ui-widget" type="text"
          [(ngModel)]="appointment$.locationCityName" />
      </dd>
      <dt class="col-sm-3">State</dt>
      <dd class="col-sm-9">
        <input (change)="this.onLocationComponentChange('locationStateCd')"
          onClick="this.setSelectionRange(0, this.value.length)" class="ui-widget" type="text"
          [(ngModel)]="appointment$.locationStateCd" />
      </dd>
      <dt class="col-sm-3">County</dt>
      <dd class="col-sm-9">
        <input (change)="this.onLocationComponentChange('locationSubdName')"
          onClick="this.setSelectionRange(0, this.value.length)" class="ui-widget" type="text"
          [(ngModel)]="appointment$.locationSubdName" />
      </dd>
      <dt class="col-sm-3">Zip</dt>
      <dd class="col-sm-9">
        <input (change)="this.onLocationComponentChange('locationZipCd')"
          onClick="this.setSelectionRange(0, this.value.length)" class="ui-widget" type="text"
          [(ngModel)]="appointment$.locationZipCd" />
      </dd>
      <!-- <div><span>Lat:</span>&nbsp;<input class="ui-input-text" type="text" [(ngModel)]="appointment$.locationLat"/></div>
              <div><span>Long:</span>&nbsp;<input class="ui-input-text" type="text" [(ngModel)]="appointment$.locationLong"/></div>
              <div><span>Precision:</span>&nbsp;<input class="ui-input-text" type="text" [(ngModel)]="appointment$.locationPrecision"/></div> -->
      <dt class="col-sm-3">Contact Info</dt>
      <dd class="col-sm-9">
        <span [hidden]="appointment$.contactName == ''"><br />{{ appointment$.contactName }}</span>
        <span [hidden]="
            appointment$.contactPhoneDay == '() -' ||
            appointment$.contactPhoneDay == ''
          "><br />Day Phone:
          <a href="tel:{{ appointment$.contactPhoneDay }}">{{
            appointment$.contactPhoneDay
            }}</a></span>
        <span [hidden]="
            appointment$.contactPhoneNight == '() -' ||
            appointment$.contactPhoneNight == ''
          "><br />Night Phone:
          <a href="tel:{{ appointment$.contactPhoneNight }}">{{
            appointment$.contactPhoneNight
            }}</a></span>
        <span [hidden]="
            appointment$.contactPhoneOther == '() -' ||
            appointment$.contactPhoneOther == ''
          "><br />Other Phone:
          <a href="tel:{{ appointment$.contactPhoneOther }}">{{
            appointment$.contactPhoneOther
            }}</a></span>
      </dd>

      <dt class="col-sm-3">Travel Time/Distance</dt>
      <dd class="col-sm-9">
        {{ travelText | async }} -
        <a class="appointment-map-link" href="https://maps.google.com/?q={{
            appointment$.inspectionLocation
          }}">Map Route
          <span class="glyphicon glyphicon-map-marker">&nbsp;</span></a>
      </dd>

      <dt class="col-sm-3">Loss Detail</dt>
      <dd class="col-sm-9">
        Loss Date: {{ appointment$.lossDate | date : "MM/dd/yyyy" }}
        <br />Notice Date: {{ appointment$.noticeDate | date : "MM/dd/yyyy" }}
        <br />Caller Contact Info:
        <span [innerHTML]="appointment$.callerContactInfo"> </span> <br />Loss
        Type: {{ appointment$.claimType }} / {{ appointment$.claimantNumber }}
      </dd>

      <dt class="col-sm-3">Policy Number</dt>
      <dd class="col-sm-9">
        {{ appointment$.policyNumber }}
        <span [hidden]="appointment$.underwritingCo == ''"><br />Underwriting Company: {{ appointment$.underwritingCo
          }}</span>
      </dd>

      <dt class="col-sm-3" [hidden]="
          appointment$.id10Txt != '' &&
          appointment$.id10Txt != 'PA' &&
          appointment$.id10Txt != 'CA'
        ">
        Vehicle Info
      </dt>
      <dd class="col-sm-9" [hidden]="
          appointment$.id10Txt != '' &&
          appointment$.id10Txt != 'PA' &&
          appointment$.id10Txt != 'CA'
        ">
        {{ appointment$.vehicleYear }} {{ appointment$.vehicleMakeModel }}
        <span [hidden]="appointment$.vehicleVin == ''"><br />VIN: {{ appointment$.vehicleVin }}</span>
        <span [hidden]="appointment$.vehicleColor == ''"><br />Color: {{ appointment$.vehicleColor }}</span>
        <span [hidden]="appointment$.vehiclePlate == ''"><br />Plate: {{ appointment$.vehiclePlate }}</span>
      </dd>

      <dt class="col-sm-3" [hidden]="appointment$.owner == ''">Owner Info</dt>
      <dd class="col-sm-9" [hidden]="appointment$.owner == ''">
        {{ appointment$.owner }}
        <span [hidden]="appointment$.ownerAddress == ''"><br />{{ appointment$.ownerAddress }}</span>
        <span [hidden]="appointment$.ownerBusPhone.trim() == ''"><br />Business:
          <span ng-repeat=" phoneNumber in appointment$.ownerBusPhones track by $index">
            <a ng-show="phoneNumber" href="tel:{{ phoneNumber }}">{{
              formatPhone(phoneNumber)
              }}</a>
          </span>
        </span>
        <span [hidden]="appointment$.ownerResPhone.trim() == ''"><br />Residence:
          <span ng-repeat=" phoneNumber in appointment$.ownerResPhone track by $index">
            <a ng-show="phoneNumber" href="tel:{{ phoneNumber }}">{{ formatPhone(phoneNumber) }}
            </a>
          </span>
        </span>
      </dd>

      <dt class="col-sm-3">Accident Description</dt>
      <dd class="col-sm-9" [innerHTML]="appointment$.accidentDesc"></dd>

      <dt class="col-sm-3">Damage Description</dt>
      <dd class="col-sm-9" [innerHTML]="appointment$.damageDetail"></dd>

      <dt class="col-sm-3" [hidden]="
          appointment$.id10Txt != '' &&
          appointment$.id10Txt != 'PA' &&
          appointment$.id10Txt != 'CA'
        ">
        Driveable
      </dt>
      <dd class="col-sm-9" [hidden]="
          appointment$.id10Txt != '' &&
          appointment$.id10Txt != 'PA' &&
          appointment$.id10Txt != 'CA'
        ">
        {{ appointment$.driveableInd }}
      </dd>

      <dt class="col-sm-3">Coverages</dt>
      <dd class="col-sm-9">
        {{ appointment$.coverages }}
        <span [hidden]="appointment$.coverages == ''"><br /></span>
        <span [hidden]="appointment$.lienHolder == ''">Lein Holder: {{ appointment$.lienHolder }}</span>
        <span [hidden]="appointment$.deductibleInstr == ''"><br />Deductible Instr: {{ appointment$.deductibleInstr
          }}</span>
        <span [hidden]="appointment$.deductible == ''"><br />Deductible: {{ appointment$.deductible }}</span>
        <span [hidden]="appointment$.policyDeductible == ''"><br />Policy Deductible: {{ appointment$.policyDeductible
          }}</span>
      </dd>

      <dt class="col-sm-3" [hidden]="appointment$.endorsements == ''">
        Endorsements
      </dt>
      <dd class="col-sm-9" [hidden]="appointment$.endorsements == ''">
        {{ appointment$.endorsements }}
      </dd>

      <dt class="col-sm-3" [hidden]="
          appointment$.id10Txt != '' &&
          appointment$.id10Txt != 'PA' &&
          appointment$.id10Txt != 'CA'
        ">
        Rental Limit
      </dt>
      <dd class="col-sm-9" [hidden]="
          appointment$.id10Txt != '' &&
          appointment$.id10Txt != 'PA' &&
          appointment$.id10Txt != 'CA'
        ">
        {{ appointment$.rentalLimit }}
      </dd>

      <dt class="col-sm-3" [hidden]="
          appointment$.id10Txt != '' &&
          appointment$.id10Txt != 'PA' &&
          appointment$.id10Txt != 'CA'
        ">
        DTD Services
      </dt>
      <dd class="col-sm-9" [hidden]="
          appointment$.id10Txt != '' &&
          appointment$.id10Txt != 'PA' &&
          appointment$.id10Txt != 'CA'
        ">
        {{ appointment$.dtdServices }}
      </dd>

      <dt class="col-sm-3" [hidden]="appointment$.payStatus == ''">
        Pay Status
      </dt>
      <dd class="col-sm-9" [hidden]="appointment$.payStatus == ''">
        {{ appointment$.payStatus }}
      </dd>

      <dt class="col-sm-3" [hidden]="appointment$.claimOwner == ''">
        Responsible Rep
      </dt>
      <dd class="col-sm-9" [hidden]="appointment$.claimOwner == ''">
        <div class="appointment-detail-value">
          {{ appointment$.claimOwner }}
          <span ng-repeat=" phoneNumber in appointment$.claimOwnerPhones track by $index">
            <a [hidden]="phoneNumber == ''" href="tel:{{ getPhoneWithoutExtenstion(phoneNumber) }}">{{
              formatPhone(phoneNumber) }}
            </a>
          </span>
        </div>
      </dd>
      <dt class="col-sm-3" [hidden]="appointment$.claimOwner == ''">
        Appraiser Name
      </dt>
      <dd class="col-sm-9" [hidden]="appointment$.claimOwner == ''">
        {{ appointment$.appraiserName }}
      </dd>

      <dt class="col-sm-3" [hidden]="appointment$.dispatchStatus == ''">
        Dispatch Status
      </dt>
      <dd class="col-sm-9" [hidden]="appointment$.dispatchStatus == ''">
        {{ appointment$.dispatchStatus }}
      </dd>

      <dt class="col-sm-3">OPT-In SMS</dt>
      <dd class="col-sm-9" *ngIf="!showSMSNotitfication">No</dd>
      <dd class="col-sm-9" *ngIf="showSMSNotitfication">Yes</dd>

      <!-- <dt class="col-sm-3">OPT-In EMAIL</dt>
              <dd class="col-sm-9" *ngIf="!(showSMSNotitfication)">No</dd>
              <dd class="col-sm-9" *ngIf="(showSMSNotitfication)">Yes</dd> -->

      <dt class="col-sm-3" [hidden]="
          appointment$.damageEstimateAmount == '' ||
          appointment$.damageEstimateAmount == '0.00'
        ">
        Damage Estimate Amt
      </dt>
      <dd class="col-sm-9" [hidden]="
          appointment$.damageEstimateAmount == '' ||
          appointment$.damageEstimateAmount == '0.00'
        ">
        {{ appointment$.damageEstimateAmount }}
      </dd>

      <dt class="col-sm-3" [hidden]="
          appointment$.inspectionEstimateAmount == '' ||
          appointment$.inspectionEstimateAmount == '0.00'
        ">
        Inspection Estimate Amt
      </dt>
      <dd class="col-sm-9" [hidden]="
          appointment$.inspectionEstimateAmount == '' ||
          appointment$.inspectionEstimateAmount == '0.00'
        ">
        {{ appointment$.inspectionEstimateAmount }}
      </dd>

      <button type="button" class="btn-primary" [hidden]="
          appointment$.status == 'C' ||
          appointment$.appraiserName != userDetails.fullName
        " (click)="transmitToVendor()" [disabled]="isTransmitDisabled">
        {{ transmitBtnLabel }}
      </button>
      <button type="button" class="btn-primary" [hidden]="
          appointment$.status == 'C' ||
          appointment$.appraiserName == userDetails.fullName
        " (click)="transferToMyself(appointment$)" [disabled]="isTransferDisabled">
        {{ transferBtnLabel }} {{ userDetails.fullName }}
      </button>
      <button type="button" (click)="issuePayment()" class="margin-x btn-warning" style="background-color: #ff6319">
        Make Payment
      </button>
      <button type="button" class="btn-primary margin-x" [useExistingCss]="true" printSectionId="printCanvas" ngxPrint>
        Print
      </button>
      <!-- div style="border: 1px solid red">{{true || showSMSNotitfication && appointment$.status == 'C'}} -- {{travelText | async}} </div-->
      <!-- button [hidden]="false || showSMSNotitfication && appointment$.status == 'C'" mat-button (click)="notifyCustomer()" >Notify via SMS Message</button -->
      <!-- showSMSNotitfication: {{showSMSNotitfication}} appointment$.status: {{appointment$.status}} : {{true || showSMSNotitfication && appointment$.status != 'C'}} -->
      <button type="button" class="btn-primary" [hidden]="
          !(showSMSNotitfication && appointment$.status != 'C') ||
          appointment$.appraiserName != userDetails.fullName
        " (click)="notifyCustomer()">
        Notify via SMS Msg
      </button>
      <button type="button" class="btn-primary" (click)="openSalvageAssignment()">
        Salvage Assign
      </button>
      <button type="button" (click)="issueLetter()" class="margin-x btn-primary">
        Letters
      </button>
    </dl>
  </mat-expansion-panel>
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        <dt class="col-5"><u>NOTES</u></dt>
      </mat-panel-title>
      <mat-panel-description> </mat-panel-description>
    </mat-expansion-panel-header>

    <dl class="row">
      <dt class="col-sm-3">Current Notes</dt>
      <dd class="col-sm-9" [innerHTML]="appointment$.notes"></dd>
      <dt class="col-sm-3">Create Note</dt>
      <dd class="col-sm-9">
        <div>
          <label for="selNoteType">Note Type:</label>
          <select name="selNoteType" class="form-control" title="Note Type" class="noteTypeDropdown"
            [(ngModel)]="rentalNoteOptions.specialNoteType" #selNoteType="ngModel" (change)="noteTypeChanged()"
            [hidden]="!hideRentalOption">
            <option value="general">General Note</option>
            <option value="days">Rental: Days To Repair</option>
            <option value="repair">Rental: Appraisal Escalation - Repair</option>
            <option value="total">Rental: Appraisal Escalation - Total Loss</option>
          </select>
          <select name="selNoteType" class="form-control" title="Note Type" class="noteTypeDropdown"
            [(ngModel)]="rentalNoteOptions.specialNoteType" #selNoteType="ngModel" (change)="noteTypeChanged()"
            [hidden]="hideRentalOption">
            <option value="general">General Note</option>
          </select>
          <textarea id="noteTextAreaId" class="form-control"></textarea>
        </div>
      </dd>
      <button type="button" class="btn-secondary" (click)="saveNote()">
        Save Note
      </button>
      <button type="button" class="btn-secondary" style="margin-left: 5px;" (click)="noteCancelClear()">
        Cancel/Clear
      </button>
    </dl>
  </mat-expansion-panel>
  <!-- {{window.pageYOffset}}
        {{window.innerHeight}}
        {{window.outerHeight}} -->

  <div #loadingDiv *ngIf="loading" class="loaderContainer" style="width: 0px; height: 0px"
    [ngStyle]="{ top: (2 * window.pageYOffset).toString() + 'px' }">
    <div class="loader loaderContainer"></div>
  </div>
</div>

<!-- Days To Repair PopUp -->
<p-dialog header="Days to Repair" [visible]="displayDaysToRepair" [modal]="true" [draggable]="false" [resizable]="false"
  [closable]="false" style="text-align: center;">
  <label for="selDays" class="font-weight-bold" style="float:left">Select:</label>
  <select name="selDays" class="form-control" [(ngModel)]="rentalNoteOptions.daysToRepair" #selDays="ngModel">
    <option *ngFor="let i of rentalNoteOptions.listofvalues;" value="{{i}}">{{ i }}</option>
  </select>

  <p-footer>
    <button name="buttonOK" type="button" pButton icon="pi pi-check" label="Ok"
      [disabled]="selDays.value == '' || selDays.value == null"
      (click)="noteFunction(false, selDays.value, '<b>'+appointment$.owner+'</b> Days to Repair:<b>' + selDays.value+'</b>; ', true);"></button>
    <button type="button" pButton icon="pi pi-times" (click)="noteCancelClear()" label="Cancel"
      class="ui-button-secondary"></button>
  </p-footer>
</p-dialog>

<p-toast #toast class="p-toast" position="top-right">
  <ng-template let-message pTemplate="message">
    <div class="toast-summary" innerHtml="{{ message.summary }}"></div>
    <p class="toast-detail" innerHtml="{{ message.detail }}"></p>
  </ng-template>
</p-toast>
<p-confirmDialog #confirm class="p-confirmDialog" header="Confirmation" [appendTo]="'mainDiv'"
  icon="pi pi-exclamation-triangle" [baseZIndex]="10000"
  maskStyleClass="ui-dialog-mask ui-widget-overlay ui-dialog-visible ui-dialog-mask-scrollblocker">
</p-confirmDialog>
<div id="printCanvas" class="print-canvas">
  <hr class="print-hr" />
  <div id="printPage" class="print-page" [innerHTML]="appointment$.description"></div>
  <hr class="print-hr" />
</div>