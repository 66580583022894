<div class="container" align="center" id="adminViewContainer" style="width : 70%;">
    <div align="center">
        <h3 style="color: #FF6319" class="py-3" id="header">Eclaims-Onesite-Admin</h3>
    </div>
    <p-toast position="top-right" [showTransitionOptions]="'0ms'" [hideTransitionOptions]="'0ms'"></p-toast>
    <div *ngIf="errorOccurred" style="width: 80%;">
        <div class="alert alert-danger">
            <strong>Error! </strong>{{ errorResponse.message }}
        </div>
    </div>
    <div *ngIf="!errorOccurred && (isUserAdmin || isSupportUser)" class="alert alert-info" style="width: 65%;">
        <strong>Choose required Tab to perform the necessary actions.</strong>
    </div>
    <div *ngIf="isUserAdmin" class="content-section implementation">
        <p-tabView (onChange)="handleTabChange($event)">
            <p-tabPanel header="Activate Profile" [selected]="true">
                <div *ngIf="showAdminMessage" style="width: 65%;">
                    <div class="alert alert-warning">
                        <strong>Info </strong>{{ adminInfoMessage }}
                    </div>
                </div>
                <div class="form-group" align="center" style="width: 55%;">
                    <div class="row" align="left">
                        <div class="cold-md-12">
                            <ul>
                                <li>Adjuster profile must exist in the application before activation.</li>
                                <li>Please do not try to activate same profile multiple times.</li>
                                <li>First check if the profile is already active using Check Profile Status button.</li>
                                <li><strong> Enter Adjuster network id then click Activate button to activate user profile.</strong></li>
                            </ul>
                        </div>
                    </div>
                    <div class="row" align="left" style="margin-top: 1%;">
                        <div class="col-md-12">
                            <input name="adjuster-id" type="text" size="20" pInputText [(ngModel)]="adjusterId" placeholder="Adjuster Network ID">
                        </div>
                    </div>
                </div>
                <div class="form-group" align="center" style="width: 55%;">
                    <div class="row" align="left">
                        <div class="col-md-6">
                            <button pButton type="button" label="Check Profile Status" class="ui-button-raised" (click)="checkProfileStatus()" [disabled]="disableActivateButton()"></button>
                        </div>
                        <div class="col-md-6">
                            <button pButton type="button" label="Activate" class="ui-button-raised" (click)="activateProfile($event)" [disabled]="disableActivateButton()"></button>
                        </div>
                    </div>
                </div>
            </p-tabPanel>
            <p-tabPanel header="Add Profile">
                <div *ngIf="showMessage" style="width: 65%;">
                    <div class="alert alert-warning">
                        <strong>Info </strong>{{ adminInfoMessage }}
                    </div>
                </div>
                <div class="form-group" align="center" style="width: 55%;">
                    <div class="row" align="left">
                        <div class="cold-md-12">
                            <ul>
                                <li>Enter valid adjuster network id</li>
                                <li>Enter the check sequence, Default one will be 10,000</li>
                                <li>Select required groups</li>
                            </ul>
                        </div>
                    </div>
                    <div class="row" align="left" style="margin-top: 1%;">
                        <div class="col-md-12">
                            <input id="adjuster-id" type="text" size="20" pInputText [(ngModel)]="adjusterId" placeholder="Adjuster Network ID" required>
                        </div>
                    </div>
                    <div class="row" align="left" style="margin-top: 1%;">
                        <div class="col-md-12">
                            <input id="check-sequence" type="text" size="20" pInputText [(ngModel)]="checkSequence" placeholder="check sequence" required>
                        </div>
                    </div>
                    <div class="row" align="left" style="margin-top: 1%;">
                        <div class="col-md-12">
                            <ng-multiselect-dropdown [placeholder]="'Select Groups'" [settings]="companyGroupsSettings" [data]="companyGroupsRepo" [(ngModel)]="selectedCompanyGroups" (onSelect)="onGroupSelect($event)" (onDeSelect)="onGroupDeSelect($event)" name="groupMultiSelect">
                            </ng-multiselect-dropdown>
                        </div>
                    </div>
                </div>
                <div class="form-group" align="center" style="width: 55%;">
                    <div class="row" align="left">
                        <div class="col-md-6">
                            <button pButton type="button" label="Add Profile" class="ui-button-raised" (click)="addUserProfile($event)" [disabled]="disableAddProfileButton()"></button>
                        </div>
                    </div>
                </div>
            </p-tabPanel>
        </p-tabView>
    </div>
    <div *ngIf="isSupportUser && !isCirrusCloudEnabled" class="content-section implementation">
        <div>
            <strong>Google Cloud Print Enabled!</strong>
        </div>
        <p-tabView (onChange)="tabViewChanged($event)">
            <p-tabPanel header="Assign Printer" [selected]="true">
                <div *ngIf="showMessage" style="width: 50%;">
                    <div class="alert alert-warning">
                        <strong>Info </strong>{{ infoMessage }}
                    </div>
                </div>
                <div class="form-group" align="center" style="width: 70%;">
                    <div class="row" align="left">
                        <p>Enter below details and click appropriate button to complete the action.</p>
                        <ul>
                            <li>User must have been activated before performing this action.</li>
                            <li>Copy the Printer Id from google cloud console from server to find printer. Refer instruction manual for how to find printer id.</li>
                            <li>Printer name has 3 text fields. First field pre-filled with Environment, second field would be filled with Adjuster Id but it can be updated for network priner with location details. Third field shold be filled as Check Writer
                                printer with printer number</li>
                            <li>Printer naming convention is : Test-jsoderstrom-<strong>Check Writer Printer 1.</strong> Or for Network/Cloud Not Ready Printer : Test-Woodbridge-5-037-<strong>Check Writer Printer.</strong></li>
                            <li><strong>Assign Printer</strong>- Adds printers to adjuster.</li>
                            <li><strong>Refresh Printers</strong>- Refreshes google cloud registered printer id's dropdown.</li>
                        </ul>
                    </div>
                </div>
                <div class="form-group" align="center" style="width: 50%;">
                    <div class="row" align="left">
                        <div class="col-md-12">
                            <label for="adjuster-id" class="font-weight-bold">Adjuster Id</label>
                        </div>
                    </div>
                    <div class="row" align="left">
                        <div class="col-md-12">
                            <input name="adjuster-id" type="text" size="20" pInputText [(ngModel)]="adjusterId" placeholder="Adjuster Network ID">
                        </div>
                    </div>
                    <div class="row" align="left">
                        <div class="col-md-12">
                            <label for="typeaheadPrinters" class="font-weight-bold">Printer Id</label>
                        </div>
                    </div>
                    <div class="row" align="left">
                        <div class="col-md-12">
                            <input name="typeaheadPrinters" type="text" class="form-control" [(ngModel)]="printersModel" [ngbTypeahead]="search" (focus)="focus$.next($event.target.value)" (click)="click$.next($event.target.value)" placeholder="Paste printer id copied from Google cloud console."
                                (selectItem)="selectedItem($event)" (blur)="onTypeAheadPrinterChanged($event.target.value)" #instance="ngbTypeahead" size="40" maxlength="40" required />
                        </div>
                    </div>
                    <div class="row" align="left">
                        <div class="col-md-12">
                            <p>Selected Printer Id: {{printersModel || 'none'}}</p>
                        </div>
                    </div>
                    <div class="row" align="left">
                        <div class="col-md-12"><label for="adjusterPrinterName" class="font-weight-bold">Printer Name</label></div>
                    </div>
                    <div class="row" align="left">
                        <!--<div class="col-md-3">
              <input id="env-name" type="text" size="6" pInputText [(ngModel)]="environmentName"
                placeholder="environment" disabled>
            </div>
            <div class="col-md-4">
              <input id="adjuster-id" type="text" size="14" pInputText [(ngModel)]="adjusterId"
                placeholder="Adjuster ID/ Printer Location" required>
            </div> -->
                        <div class="col-md-12">
                            <input name="adjusterPrinterName" type="text" size="20" pInputText [(ngModel)]="adjusterPrinterName" placeholder="Check Writer Printer" required>
                        </div>
                    </div>
                    <div class="row" align="left">
                        <div class="col-md-12"></div>
                    </div>
                </div>
                <div class="form-group" align="center" style="width: 45%;">
                    <div class="row" align="left">
                        <div class="col-md-6">
                            <button name="refreshPrintersButton" pButton type="button" label="Refresh Printers" class="ui-button-raised ui-button-secondary" (click)="getGCPPrinters()"></button>
                        </div>
                        <div class="col-md-6">
                            <button pButton type="button" label="Assign Printer" class="ui-button-raised" (click)="assignAdjusterPrinter($event)" [disabled]="disableAssignPrinterButton()"></button>
                        </div>
                    </div>
                </div>
            </p-tabPanel>
            <p-tabPanel header="Update Printer Name">
                <div *ngIf="showMessage" style="width: 65%;">
                    <div class="alert alert-warning">
                        <strong>Info </strong>{{ infoMessage }}
                    </div>
                </div>
                <div class="form-group" align="center" style="width: 45%;">
                    <div class="row" align="left">
                        <p>Enter below details and click appropriate button to complete the action.</p>
                        <ul>
                            <li><strong>Update Printer Name</strong>- Updates existing printer name of adjuster printer.</li>
                            <li><strong>Refresh Printers</strong>- Refreshes adjuster assigned printer id's dropdown.</li>
                        </ul>
                    </div>
                    <div class="row" align="left">
                        <div class="col-md-6">
                            <label for="adjuster-id" class="font-weight-bold">Adjuster Id</label>
                            <input name="adjuster-id" type="text" size="40" pInputText [(ngModel)]="adjusterId" placeholder="Adjuster Network ID">
                        </div>
                    </div>
                    <div class="row" align="left">
                        <div class="col-md-12">
                            <label for="existingPrinters" class="font-weight-bold">Existing Printers</label>
                            <select name="existingPrinters" class="form-control" align="left" (change)="onExistingPrinterSelection($event.target.value)" [(ngModel)]="selectedExistingPrinter" required>
                <option [value]="none" [selected]="true" disabled="false" [hidden]="false">Select Printer for update</option>
                <option [ngValue]="printerInfo" *ngFor="let printerInfo of adjusterExistingPrinters">
                  {{printerInfo.printerName}}</option>
              </select>
                        </div>
                    </div>
                    <div class="row" align="left">
                        <div class="col-md-12">
                            <label for="adjusterPrinterName" class="font-weight-bold">Printer Name</label>
                        </div>
                    </div>
                    <div class="row" align="left">
                        <div class="col-md-3">
                            <input id="env-name" type="text" size="6" pInputText [(ngModel)]="environmentName" placeholder="environment" disabled>
                        </div>
                        <div class="col-md-4">
                            <input id="adjuster-id" type="text" size="14" pInputText [(ngModel)]="adjusterId" placeholder="Adjuster ID/ Printer Location" required>
                        </div>
                        <div class="col-md-5">
                            <input name="adjusterPrinterName" type="text" size="30" pInputText [(ngModel)]="updatedAdjusterPrinterName" placeholder="Updated Printer Name" required>
                        </div>
                    </div>
                </div>
                <div class="form-group" align="center" style="width: 45%;">
                    <div class="row" align="left">
                        <div class="col-md-6">
                            <button name="refreshPrintersButton" pButton type="button" label="Refresh Printers" class="ui-button-raised ui-button-secondary" (click)="getAdjusterAssignedPrinters()"></button>
                        </div>
                        <div class="col-md-6">
                            <button pButton type="button" label="Update Printer Name" class="ui-button-raised" (click)="updateExistingPrinterMapping()" [disabled]="disableUpdatePrinterButton()"></button>
                        </div>
                    </div>
                </div>

            </p-tabPanel>
            <p-tabPanel header="Remove Printer">
                <div *ngIf="showMessage" style="width: 65%;">
                    <div class="alert alert-warning">
                        <strong>Info </strong>{{ infoMessage }}
                    </div>
                </div>
                <div class="form-group" align="center" style="width: 45%;">
                    <div class="row" align="left">
                        <p>Select below details and click appropriate button to complete the action.</p>
                        <ul>
                            <li><strong>Remove Printer</strong>- Removes printer from adjuster assigned printers.</li>
                            <li><strong>Refresh Printer</strong>- Refreshes adjuster assigned printer id's dropdown.</li>
                        </ul>
                    </div>
                    <div class="row" align="left">
                        <div class="col-md-12">
                            <label for="adjuster-id" class="font-weight-bold">Adjuster Id</label>
                            <input name="adjuster-id" type="text" size="50" pInputText [(ngModel)]="adjusterId" placeholder="Adjuster Network ID">
                        </div>
                    </div>
                    <div class="row" align="left">
                        <div class="col-md-12">
                            <label for="existingPrinters" class="font-weight-bold">Existing Printers</label>
                            <select name="existingPrinters" class="form-control" align="left" (change)="onExistingPrinterSelection($event.target.value)" [(ngModel)]="selectedExistingPrinter" (blur)="onExistingPrinterSelection($event.target.value)" required>
                <option [value]="none" [selected]="true" disabled="false" [hidden]="false">Select Printer to Remove
                </option>
                <option [ngValue]="printerInfo" *ngFor="let printerInfo of adjusterExistingPrinters">
                  {{printerInfo.printerName}}</option>
              </select>
                        </div>
                    </div>
                </div>
                <div class="form-group" align="center" style="width: 45%;">
                    <div class="row" align="left">
                        <div class="col-md-6">
                            <button name="refreshPrintersButton" pButton type="button" label="Refresh Printers" class="ui-button-raised ui-button-secondary" (click)="getAdjusterAssignedPrinters()"></button>
                        </div>
                        <div class="col-md-6">
                            <button pButton type="button" label="Remove Printer" class="ui-button-raised" (click)="deleteAssignedAdjusterPrinter()" [disabled]="disableDeletePrinterButton()"></button>
                        </div>
                    </div>
                </div>
            </p-tabPanel>
            <p-tabPanel header="Test Cloud Printer" [disabled]="false">
                <div *ngIf="showMessage" style="width: 65%;">
                    <div class="alert alert-warning">
                        <strong>Info </strong>{{ infoMessage }}
                    </div>
                </div>
                <div class="form-group" align="center" style="width: 45%;">
                    <div class="row" align="left">
                        <p>Select the required printer to send test print.</p>
                    </div>
                    <div class="row" align="left">
                        <div class="col-md-12">
                            <label for="typeaheadPrinters" class="font-weight-bold">Printer Id</label>
                            <input name="typeaheadPrinters" type="text" class="form-control" [(ngModel)]="printersModel" [ngbTypeahead]="search" (focus)="focus$.next($event.target.value)" (click)="click$.next($event.target.value)" placeholder="Start typing printer id to find it"
                                (selectItem)="selectedItem($event)" (blur)="onTypeAheadPrinterChanged($event.target.value)" #instance="ngbTypeahead" maxlength="50" required />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <p>Selected Printer: {{printersModel || 'none'}}</p>
                        </div>
                    </div>
                </div>
                <div class="form-group" align="center" style="width: 45%;">
                    <div class="row" align="left">
                        <div class="col-md-6">
                            <button name="refreshPrintersButton" pButton type="button" label="Refresh Printers" class="ui-button-raised ui-button-secondary" (click)="getGCPPrinters()"></button>
                        </div>
                        <div class="col-md-6">
                            <button pButton type="button" label="Demo Print" class="ui-button-raised" (click)="sendDemoPrint()" [disabled]="disableDemoPrintButton()"></button>
                        </div>
                    </div>
                </div>
            </p-tabPanel>
        </p-tabView>
    </div>
    <div *ngIf="isSupportUser && isCirrusCloudEnabled" class="content-section implementation">
        <div>
            <strong>Cirrus Cloud Print Enabled!</strong>
        </div>
        <p-tabView (onChange)="cirrusTabViewChanged($event)">
            <p-tabPanel header="Assign Printer" [selected]="true">
                <div *ngIf="showMessage" style="width: 50%;">
                    <div class="alert alert-warning">
                        <strong>Info </strong>{{ infoMessage }}
                    </div>
                </div>
                <div class="form-group" align="center" style="width: 70%;">
                    <div class="row" align="left">
                        <p>Enter below details and click appropriate button to complete the action.</p>
                        <ul>
                            <li>User must have been activated before performing this action.</li>
                            <li>Copy the Printer Id from google cloud console from server to find printer. Refer instruction manual for how to find printer id.</li>
                            <li>Printer name has 2 text fields. First field pre-filled with Adjuster Id but it can be updated for network priner with location details. Third field shold be filled as Check Writer printer with printer name</li>
                            <li>Printer naming convention is : jsoderstrom-<strong>Check Writer Printer 1.</strong> Or for Network/Cloud Not Ready Printer : Woodbridge-5-037-<strong>Check Writer Printer.</strong></li>
                            <li><strong>Assign Printer</strong>- Adds printers to adjuster.</li>
                            <li><strong>Refresh Printers</strong>- Refreshes cloud registered printer id's dropdown.</li>
                        </ul>
                    </div>
                </div>
                <div class="form-group" align="center" style="width: 50%;">
                    <div class="row" align="left">
                        <div class="col-md-12">
                            <label for="adjuster-id" class="font-weight-bold">Adjuster Id</label>
                        </div>
                    </div>
                    <div class="row" align="left">
                        <div class="col-md-12">
                            <input name="adjuster-id" type="text" size="20" pInputText [(ngModel)]="cloudAdjusterId" placeholder="Adjuster Network ID">
                        </div>
                    </div>
                    <div class="row" align="left">
                        <div class="col-md-12">
                            <label for="typeaheadCirrusPrinters" class="font-weight-bold">Printer </label>
                        </div>
                    </div>
                    <div class="row" align="left">
                        <div class="col-md-12">
                            <p-autoComplete [(ngModel)]="selectedCloudPrinter" [suggestions]="filteredCloudPrinters" (completeMethod)="filterCloudPrinters($event)" field="printerName" [minLength]="1" [dropdown]="true" name="typeaheadCirrusPrinters">
                            </p-autoComplete>
                        </div>
                    </div>
                    <div class="row" align="left">
                        <div class="col-md-12">
                            <p>Selected Printer : {{selectedCloudPrinter | json}}</p>
                        </div>
                    </div>
                    <div class="row" align="left">
                        <div class="col-md-12"><label for="adjusterCirrusPrinterName" class="font-weight-bold">Printer Name</label></div>
                    </div>
                    <div class="row" align="left">

                        <div class="col-md-12">
                            <input id="adjusterCirrusPrinterName" type="text" size="20" pInputText [(ngModel)]="adjusterPrinterName" placeholder="Check Writer Printer" required>
                        </div>
                    </div>
                    <div class="row" align="left">
                        <div class="col-md-12"></div>
                    </div>
                </div>
                <div class="form-group" align="center" style="width: 45%;">
                    <div class="row" align="left">
                        <div class="col-md-6">
                            <button name="refreshPrintersButton" pButton type="button" label="Refresh Printers" class="ui-button-raised ui-button-secondary" (click)="getCirrusCloudPrinters()"></button>
                        </div>
                        <div class="col-md-6">
                            <button pButton type="button" label="Assign Printer" class="ui-button-raised" (click)="addOrUpdateAdjusterCloudPrinter('add')" [disabled]="disableCirrusAssignPrinterButton()"></button>
                        </div>
                    </div>
                </div>
            </p-tabPanel>
            <p-tabPanel header="Update Printer Name">
                <div *ngIf="showMessage" style="width: 65%;">
                    <div class="alert alert-warning">
                        <strong>Info </strong>{{ infoMessage }}
                    </div>
                </div>
                <div class="form-group" align="center" style="width: 45%;">
                    <div class="row" align="left">
                        <p>Enter below details and click appropriate button to complete the action.</p>
                        <ul>
                            <li><strong>Update Printer Name</strong>- Updates existing printer name of adjuster printer.</li>
                            <li><strong>Refresh Printers</strong>- Refreshes adjuster assigned printer id's dropdown.</li>
                        </ul>
                    </div>
                    <div class="row" align="left">
                        <div class="col-md-6">
                            <label for="adjuster-id" class="font-weight-bold">Adjuster Id</label>
                            <input name="adjuster-id" type="text" size="40" pInputText [(ngModel)]="cloudAdjusterId" placeholder="Adjuster Network ID" (blur)="getAdjusterAssignedPrinters()">
                        </div>
                    </div>
                    <div class="row" align="left">
                        <div class="col-md-12">
                            <label for="existingPrinters" class="font-weight-bold">Existing Printers</label>
                            <select name="existingPrinters" class="form-control" align="left" (change)="onExistingPrinterSelection($event.target.value)" [(ngModel)]="selectedExistingPrinter" required>
                <option [value]="none" [selected]="true" disabled="false" [hidden]="false">Select Printer for update</option>
                <option [ngValue]="printerInfo" *ngFor="let printerInfo of adjusterExistingPrinters">
                  {{printerInfo.printerName}}</option>
              </select>
                        </div>
                    </div>
                    <div class="row" align="left">
                        <div class="col-md-12">
                            <label for="adjusterPrinterName" class="font-weight-bold">Printer Name</label>
                        </div>
                    </div>
                    <div class="row" align="left">
                        <!--  
                        <div class="col-md-3">
                            <input id="env-name" type="text" size="6" pInputText [(ngModel)]="environmentName" placeholder="environment" disabled>
                        </div>
                        <div class="col-md-4">
                        <input type="text" size="14" pInputText [(ngModel)]="adjusterId"
                          placeholder="Adjuster ID/ Printer Location" required>
                      </div>-->
                        <div class="col-md-5">
                            <input name="adjusterPrinterName" type="text" size="30" pInputText [(ngModel)]="adjusterPrinterName" placeholder="Updated Printer Name" required>
                        </div>
                    </div>
                </div>
                <div class="form-group" align="center" style="width: 45%;">
                    <div class="row" align="left">
                        <div class="col-md-6">
                            <button name="refreshPrintersButton" pButton type="button" label="Refresh Printers" class="ui-button-raised ui-button-secondary" (click)="getAdjusterAssignedPrinters()"></button>
                        </div>
                        <div class="col-md-6">
                            <button pButton type="button" label="Update Printer Name" class="ui-button-raised" (click)="addOrUpdateAdjusterCloudPrinter('update')" [disabled]="disableCirrusUpdatePrinterButton()"></button>
                        </div>
                    </div>
                </div>

            </p-tabPanel>
            <p-tabPanel header="Remove Printer">
                <div *ngIf="showMessage" style="width: 65%;">
                    <div class="alert alert-warning">
                        <strong>Info </strong>{{ infoMessage }}
                    </div>
                </div>
                <div class="form-group" align="center" style="width: 45%;">
                    <div class="row" align="left">
                        <p>Select below details and click appropriate button to complete the action.</p>
                        <ul>
                            <li><strong>Remove Printer</strong>- Removes printer from adjuster assigned printers.</li>
                            <li><strong>Refresh Printer</strong>- Refreshes adjuster assigned printer id's dropdown.</li>
                        </ul>
                    </div>
                    <div class="row" align="left">
                        <div class="col-md-6">
                            <label for="adjuster-id" class="font-weight-bold">Adjuster Id</label>
                            <input type="text" size="50" pInputText [(ngModel)]="cloudAdjusterId" placeholder="Adjuster Network ID" (blur)="getAdjusterAssignedPrinters()">
                        </div>
                    </div>
                    <div class="row" align="left">
                        <div class="col-md-12">
                            <label for="existingPrinters" class="font-weight-bold">Existing Printers</label>
                            <select name="existingPrinters" class="form-control" align="left" (change)="onExistingPrinterSelection($event.target.value)" [(ngModel)]="selectedExistingPrinter" (blur)="onExistingPrinterSelection($event.target.value)" required>
                <option [value]="none" [selected]="true" disabled="false" [hidden]="false">Select Printer to Remove
                </option>
                <option [ngValue]="printerInfo" *ngFor="let printerInfo of adjusterExistingPrinters">
                  {{printerInfo.printerName}}</option>
              </select>
                        </div>
                    </div>
                </div>
                <div class="form-group" align="center" style="width: 45%;">
                    <div class="row" align="left">
                        <div class="col-md-6">
                            <button name="refreshPrintersButton" pButton type="button" label="Refresh Printers" class="ui-button-raised ui-button-secondary" (click)="getAdjusterAssignedPrinters()"></button>
                        </div>
                        <div class="col-md-6">
                            <button pButton type="button" label="Remove Printer" class="ui-button-raised" (click)="addOrUpdateAdjusterCloudPrinter('remove')" [disabled]="disableDeletePrinterButton()"></button>
                        </div>
                    </div>
                </div>
            </p-tabPanel>
            <p-tabPanel header="Test Cloud Printer" [disabled]="false">
                <div *ngIf="showMessage" style="width: 65%;">
                    <div class="alert alert-warning">
                        <strong>Info </strong>{{ infoMessage }}
                    </div>
                </div>
                <div class="form-group" align="center" style="width: 45%;">
                    <div class="row" align="left">
                        <p>Select the required printer to send test print.</p>
                    </div>
                    <div class="row" align="left">
                        <div class="col-md-12">
                            <label for="typeaheadCirrusPrinters" class="font-weight-bold">Printer Id</label>
                            <!--<input id="typeaheadPrinters" name="typeaheadPrinters" type="text" class="form-control"
                [(ngModel)]="printersModel" [ngbTypeahead]="search" (focus)="focus$.next($event.target.value)"
                (click)="click$.next($event.target.value)" placeholder="Start typing printer id to find it"
                (selectItem)="selectedItem($event)" (blur)="onTypeAheadPrinterChanged($event.target.value)"
                #instance="ngbTypeahead" maxlength="50" required /> -->
                            <p-autoComplete [(ngModel)]="selectedCloudPrinter" [suggestions]="filteredCloudPrinters" (completeMethod)="filterCloudPrinters($event)" field="printerName" [minLength]="1" [size]="20" [dropdown]="true" name="typeaheadCirrusPrinters">
                            </p-autoComplete>
                        </div>
                    </div>
                    <div class="row" *ngIf="selectedCloudPrinter">
                        <div class="col-md-12">
                            <p>Selected Printer: {{selectedCloudPrinter || 'none'}}</p>
                        </div>
                    </div>
                </div>
                <div class="form-group" align="center" style="width: 45%;">
                    <div class="row" align="left">
                        <div class="col-md-6">
                            <button name="refreshPrintersButton" pButton type="button" label="Refresh Printers" class="ui-button-raised ui-button-secondary" (click)="getCirrusCloudPrinters()"></button>
                        </div>
                        <div class="col-md-6">
                            <button pButton type="button" label="Demo Print" class="ui-button-raised" (click)="sendCirrusDemoPrint()" [disabled]="disableDemoPrintButton()"></button>
                        </div>
                    </div>
                </div>
            </p-tabPanel>
        </p-tabView>
    </div>
</div>