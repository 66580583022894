<div class="container" align="center" id="checkWriterMainContainer">
  <div class="modal-header" style="justify-content : center;">
    <h4 id="modal-basic-title" class="content-centered">New Provider/Vendor</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('New Vendor Modal Closed')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <form #newVendorForm="ngForm" (ngSubmit)="previewVendor(newVendorForm)">
    <div class="alert alert-warning" style="width: 40%;">
      Note: Payment cannot be issued on new vendor.
    </div>
    <div *ngIf="isValidForm" style="width: 40%;">
      <div class="alert alert-danger">
        <strong>Error! </strong>{{ errorMessage }}
      </div>
    </div>
    <!-- Tax Identification with Validations -->
    <div class="form-group" style="width: 40%;" align="left">
      <label for="taxId" class="font-weight-bold">Tax Identification</label>
      <input type="text" class="form-control" autofocus name="taxId" placeholder="Tax Identification" [(ngModel)]="newVendor.taxId"
        #taxId="ngModel" required pattern="^\d{9}$" minlength="9" maxlength="9" [ngClass]="{'border border-danger' : taxId.errors && (taxId.dirty || taxId.touched)}">
      <div *ngIf="taxId.errors && (taxId.dirty || taxId.touched)" class="alert alert-danger">
        Valid 9 digits Tax Identification is required!
      </div>
    </div>
    <!-- Owner/Business -->
    <div class="form-group" style="width: 40%;" align="left">
      <label for="owner" class="font-weight-bold">Owner / Business</label>
      <input type="text" class="form-control" name="owner" placeholder="Owner/Business" [(ngModel)]="newVendor.vendorName"
        #owner="ngModel" required minlength="1" maxlength="30" [ngClass]="{'border border-danger' : owner.errors && (owner.dirty || owner.touched)}">
      <div *ngIf="owner.errors && (owner.dirty || owner.touched)" class="alert alert-danger">
        Owner / Business name is required!
      </div>
    </div>

    <div class="form-group" style="width: 40%;" align="left">
      <label for="dbaName" class="font-weight-bold">Doing Business As</label>
      <input type="text" class="form-control" name="dbaName" placeholder="Doing Business as" [(ngModel)]="newVendor.dbaName"
        #dbaName="ngModel" required minlength="1" maxlength="30" [ngClass]="{'border border-danger' : dbaName.errors && (dbaName.dirty || dbaName.touched)}">
      <div *ngIf="dbaName.errors && (dbaName.dirty || dbaName.touched)" class="alert alert-danger">
        Doing Business As is required!
      </div>
    </div>

    <!-- Address-->
    <div class="form-group" style="width: 40%;" align="left">
      <div class="row">
        <div class="col-md-6" id="addressLine1Div">
          <label for="addressLine1" class="font-weight-bold">Mailing Address</label>
          <input type="text" class="form-control" name="addressLine1" placeholder="Address line1" [(ngModel)]="newVendor.street1"
            #addressLine1="ngModel" required minlength="1" maxlength="50" [ngClass]="{'border border-danger' : addressLine1.errors && (addressLine1.dirty || addressLine1.touched)}">
        </div>
        <div class="col-md-6" id="addressLine2Div">
          <label for="addressLine2" class="font-weight-bold">&nbsp;</label>
          <input type="text" class="form-control" name="addressLine2" placeholder="Address line2" [(ngModel)]="newVendor.street2"
            #addressLine2="ngModel" minlength="0" maxlength="50">
        </div>
        <div *ngIf="addressLine1.errors && (addressLine1.dirty || addressLine1.touched)" class="alert alert-danger ml-3">
          Address is required!
        </div>
      </div>
      <div class="row">
        <div class="col-md-6" id="cityDiv">
          <label for="city" class="font-weight-bold">City</label>
          <input type="text" class="form-control" name="city" placeholder="City" [(ngModel)]="newVendor.city" #city="ngModel"
            required minlength="1" maxlength="50" [ngClass]="{'border border-danger' : city.errors && (city.dirty || city.touched)}">
          <div *ngIf="city.errors && (city.dirty || city.touched)" class="alert alert-danger">
            City is required!
          </div>
        </div>
        <div class="col-md-6" id="stateDiv">
          <label for="state" class="font-weight-bold">State</label>
          <select name="state" class="form-control" align="center" (change)="onStateSelection($event.target.value)"
            [ngClass]="{'border-highlight' : !stateSelected && formSubmitAttempted}" required>
            <option disabled>Select State</option>
            <option [value]="state.key" *ngFor="let state of statesMap">
              {{state.value}}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div class="form-group" style="width: 40%;" align="left">
      <div class="row">
        <div class="col-md-7" id="zipDiv">
          <label for="zip1" class="font-weight-bold">Zip Code</label>
          <input type="text" class="form-control" name="zip1" placeholder="Zip code" [(ngModel)]="newVendor.zip" #zip1="ngModel"
            required pattern="^\d{5}$" minlength="5" maxlength="5" [ngClass]="{'border border-danger' : zip1.errors && (zip1.dirty || zip1.touched)}">
        </div>
        <div class="col-md-5" id="zip2Div">
          <label for="zip2" class="font-weight-bold">&nbsp;</label>
          <input type="text" class="form-control" name="zip2" placeholder="" [(ngModel)]="newVendor.zip2" #zip2="ngModel"
            pattern="^\d{4}$" minlength="0" maxlength="4" [ngClass]="{'border border-danger' : zip1.errors && (zip1.dirty || zip1.touched)}">
        </div>
        <div *ngIf="zip1.errors && (zip1.dirty || zip1.touched)" class="alert alert-danger ml-3">
          Zip has to be 5 to 9 numbers!
        </div>
      </div>
    </div>

    <div class="form-group" style="width: 40%;" align="left">
      <div class="row">
        <div class="col-md-4" id="phoneDiv">
          <label for="phone1" class="font-weight-bold">Phone Number</label>
          <input type="text" class="form-control" name="phone1" placeholder="Area code" [(ngModel)]="newVendor.phone1"
            #phone1="ngModel" required pattern="^\d{3}$" minlength="3" maxlength="3" [ngClass]="{'border border-danger' : phone1.errors && (phone1.dirty || phone1.touched)}">
        </div>
        <div class="col-md-4" id="phone2Div">
          <label for="phone2" class="font-weight-bold">&nbsp;</label>
          <input type="text" class="form-control" name="phone2" placeholder="3 digit prefix" [(ngModel)]="newVendor.phone2"
            #phone2="ngModel" required pattern="^\d{3}$" minlength="3" maxlength="3" [ngClass]="{'border border-danger' : phone2.errors && (phone2.dirty || phone2.touched)}">
        </div>
        <div class="col-md-4" id="phone3Div">
          <label for="phone3" class="font-weight-bold">&nbsp;</label>
          <input type="text" class="form-control" name="phone3" placeholder="last 4 digits" [(ngModel)]="newVendor.phone3"
            #phone3="ngModel" required pattern="^\d{4}$" minlength="4" maxlength="4" [ngClass]="{'border border-danger' : phone3.errors && (phone3.dirty || phone3.touched)}">
        </div>
        <div *ngIf="(phone1.errors && (phone1.dirty || phone1.touched)) || (phone2.errors && (phone2.dirty || phone2.touched)) || (phone3.errors && (phone3.dirty || phone3.touched))"
          class="alert alert-danger ml-3">
          Phone has to be 10 digits long (No special characters)!
        </div>
      </div>
    </div>

    <div class="form-group" style="width: 40%;" align="left">
      <label for="rsNumber" class="font-weight-bold">R.S.#</label>
      <input type="text" class="form-control" name="rsNumber" placeholder="Owner/Business" [(ngModel)]="newVendor.rsNumber"
        #rsNumber="ngModel" minlength="10" maxlength="10" [ngClass]="{'border border-danger' : rsNumber.errors && (rsNumber.dirty || rsNumber.touched)}">
    </div>

    <hr style="width: 40%;">

    <div class="form-group" style="width: 40%;" align="left">
      <div class="row">
        <div class="col-md-7" id="corpDiv">
          <label for="corporation" class="font-weight-bold">Corporation?</label>
          <select name="corporation" class="form-control" align="center" (change)="onCorpSelect($event.target.value)">
            <option [value]="dp.key" *ngFor="let dp of dropDownOptions">{{dp.value}}</option>
          </select>
        </div>
        <div class="col-md-5" id="attorneyDiv">
          <label for="attorney" class="font-weight-bold">Attorney?</label>
          <select name="attorney" class="form-control" align="center" (change)="onAttorneySelect($event.target.value)">
            <option [value]="dp.key" *ngFor="let dp of dropDownOptions">{{dp.value}}</option>
          </select>
        </div>
      </div>
      <div class="row">
        <div class="col-md-8" id="healthProvDiv">
          <label for="healthProvider" class="font-weight-bold">Medical or Health provider?</label>
          <select name="healthProvider" class="form-control" align="center" (change)="onHealthProviderSelect($event.target.value)">
            <option [value]="dp.key" *ngFor="let dp of dropDownOptions2">{{dp.value}}</option>
          </select>
        </div>
      </div>
      <div class="row">
        <div class="col-md-7" id="solePropDiv">
          <label for="soleProp" class="font-weight-bold">Sole Proprietor?</label>
          <select name="soleProp" class="form-control" align="center" (change)="onSolePropSelect($event.target.value)">
            <option [value]="dp.key" *ngFor="let dp of dropDownOptions2">{{dp.value}}</option>
          </select>
        </div>
        <!--<div class="col-md-4" id="partnerDiv">
          <label for="partner" class="font-weight-bold">Partner?</label>
          <select name="partner" class="form-control" align="center" (change)="onPartnerSelect($event.target.value)">
              <option [value]="dp.key" *ngFor="let dp of dropDownOptions2">{{dp.value}}</option>
          </select>
        </div>-->
        <div class="col-md-5" id="tinTypeDiv">
          <label for="tinType" class="font-weight-bold">TIN Type</label>
          <select name="corporation" class="form-control" align="center" (change)="onTinTypeSelect($event.target.value)">
            <option [value]="tin.key" *ngFor="let tin of tinTypesRef">{{tin.value}}</option>
          </select>
        </div>
      </div>
    </div>
    <div class="form-group " style="width: 40%;" align="center">
      <button type="button" class="btn btn-warning  font-weight-bold" style="background-color:#FF6319; width : 40%;"
        (click)="onFormSubmit()" [disabled]="!newVendorForm.form.valid">Preview</button>
    </div>
  </form>
  <div class="modal-footer" style="justify-content : center;" >
      <a (click)="activeModal.close('New Vendor Modal Cancelled')" style="text-decoration-line: underline;">Close</a>
    </div>
</div>