<div class="modal-header" style="justify-content : center;">
  <h4 id="modal-basic-title" class="content-centered">Search Vendor</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Vendor Search Modal Closed')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" align="center">
  <div class="form-group" style="width: 100%;">
    <div *ngIf="errorOccurred" class="alert alert-warning" style="width: 80%;"> {{ errorResponse.message }} </div>
    <div *ngIf="!showNewVendorButton">
      <div class="row w-100 m-4" align="center">
        <div class="col-md-5">
          <input type="text" class="form-control" autofocus required name="searchText" [(ngModel)]="searchText"
            placeholder="Search Vendor by name or tax id" maxlength="50">
        </div>
        <div class="col-md-4">
          <select name="state" id="stateSelection" class="form-control" align="center" [(ngModel)]="selectedState">
            <option [ngValue]="UNDEFINED" disabled>Select State</option>
            <option [ngValue]="stateObj.key" *ngFor="let stateObj of statesMap">
              {{stateObj.value}}
            </option>
          </select>
        </div>
        <div class="col-md-3">
          <button type="button" class="btn btn-primary" (click)="searchVendor(searchText)">Search</button>
        </div>
      </div>
    </div>
    <div *ngIf="showNewVendorButton">
      <div class="row w-100 m-4" align="center">
        <div class="col-md-7">
          <input type="text" class="form-control" autofocus required name="searchText" [(ngModel)]="searchText"
            placeholder="Search Vendor by name or tax id" maxlength="50" (change)="searchVendor($event.target.value)">
        </div>
        <div class="col-md-5">
          <select name="state" class="form-control" align="center" [(ngModel)]="selectedState">
            <option [ngValue]="UNDEFINED" disabled>Select State</option>
            <option [ngValue]="stateObj.key" *ngFor="let stateObj of statesMap">
              {{stateObj.value}}
            </option>
          </select>
        </div>
      </div>
      <div class="row w-100 m-4" align="center">
        <div class="col-md-6">
          <button type="button" class="btn btn-primary" (click)="searchVendor(searchText)">Search</button>
        </div>
        <div class="col-md-6">
          <button type="button" class="btn btn-primary" (click)="showNewVendor()">W-9 New Provider</button>
        </div>
      </div>
    </div>
    <hr />
    <div *ngIf="vendorReturnedInSearchResult">
      <div class="my-1 pl-5 content-align-start">{{ vendorSearchResults?.length || '0' }} RESULTS</div>
      <div class="form-group scrollbar" style="width: 80%;">
        <div class="row shadow-sm scroll-item" *ngFor="let vendor of vendorSearchResults" (click)="onVendorSelect(vendor)">
          <div class="col-md-7 border-top-0 border-right-0 border-bottom-2 border-left-0 my-2 scroll-item" style="text-align: start;">
            <div class="pl-2 highAlert" *ngIf="vendor.status == 'BLOCK'">
              Payment blocked. Please contact vendor coordinator.
            </div>

            <div class="pl-2">
              {{vendor.providerName}}
            </div>
            <div class="pl-2">
              {{vendor.dbaName}} {{vendor.city}} - {{vendor.state}} {{vendor.zip}}
            </div>
          </div>
          <div class="col-md-5 align-baseline my-3">
            <button type="button" class="btn">Tax ID: ****{{vendor.taxId | slice:-3}}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer" style="justify-content : center;">
  <a (click)="activeModal.close('Vendor Search Modal Cancelled')" style="text-decoration-line: underline;">Close</a>
</div>