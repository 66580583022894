import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { MessageService } from '../service/message.service';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { User } from "../login/user";
import { UserContext } from "../login/userContext";
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { StorageService } from '../../shared/service/storage.service';
import { environment } from '../../../environments/environment';
import { EventEmitterService } from '../service/event-emitter.service';
import { AdjusterProfile } from "../../check-writer/shared/models/adjuster-profile";
import { RedirectTokenService } from "../service/redirect-token.service";
const httpOptions = {
	headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable()
export class LoginService {

	public loginData: User;
	public loginContext: UserContext;

	constructor(
		public http: HttpClient,
		public messageService: MessageService,
		public storageService: StorageService,
		public eventService: EventEmitterService,
		public router: Router,
		public route: ActivatedRoute,
		public redirectTokenService: RedirectTokenService) { }

	/*
 * Authenticate user using NE authetication services through profile-ws 
 * 	and then get the detail for user from Profile.
 */
	loginUser(user: User, userContext: UserContext) {

		this.loginData = user;
		this.loginContext = userContext;
		//return this.http.post(environment.PROFILE_SERVICE_BASE_URL + "auth/authentication", user, httpOptions )
		//return this.http.post(environment.PROFILE_SERVICE_BASE_URL + "auth/oauth/authentication", user, {
		return this.http.post(environment.PROFILE_SERVICE_BASE_URL + "auth/oauth/authentication", user, {			
			
			headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
			observe: 'response'
		}).subscribe(
			(resp: HttpResponse<any>) => {
				// resp => {
				console.log(resp);
				if (resp != null && resp.body["validCredential"] == false) {
					console.log("Invalid Username  & Password for user id : " + user.userId);
					userContext.authError = true;
					userContext.authErrorMessage = "Invalid user name or password. Please correct user name/password and try again.";
				} else {
					// Set User Id & Access Token in Storage.
					var accessTokenKey = "X_Token";
					this.storageService.save(accessTokenKey, resp.headers.get('X_Token'));
					var userIdKey = "User_Id";
					this.storageService.save(userIdKey, user.userId);
					userContext.authError = false;
					userContext.authErrorMessage = "";
					
					// Set the user login event
					this.eventService.onUserLoginLogout(true);
					this.getStreamUserProfile(user, resp.headers.get('X_Token'));
					//var key = 'user-details-' + user.userId;
					//this.storageService.save(key, user);
					// Commented below line to eliminate error due to stream profile population delay, Jul-2019
					//this.getProfileUserInfo(user, userContext);
				}
			}
		);

	}


	/*
 *  get the detail for user from Profile.
 */
	getProfileUserInfo(user: User, userContext: UserContext) {

		let userNameParam = (userContext.impersonateUser == true && userContext.impersonateUserName != '') ? userContext.impersonateUserName : user.userId;

		let data = { "streamLoginId": userNameParam };

		return this.http.post(environment.PROFILE_SERVICE_BASE_URL + 'users', data, httpOptions)
			.subscribe(
				(resp: any) => {
					console.log(resp);
					if (resp && resp.users[0]) {
						userContext.authError = false;
						userContext.authErrorMessage = "";
						var key = 'user-details';
						this.storageService.save(key, resp.users[0]);

						//make sure components (like header) can read our info
						//after user-details gets filled in
						this.eventService.onUserLoginLogout(true);						
						if(userContext.srcApp !=null && userContext.srcApp == 'cbsp' && userContext.srcToken!=null){
							let trdPartyConfig = environment.TRD_PARTY_LOGIN_MAP.find(ele => ele.srcApp===userContext.srcApp);
							console.info(trdPartyConfig);
							//(trdPartyConfig: any, userContext:UserContext, userProfile:any, param:any[])
							//let param = {"claimNumber":1234355,"vin":"ddfsdrertrdgd"};
							let trdPartyToken = this.redirectTokenService.getRedirectToken(trdPartyConfig,userContext,resp.users[0],{});
							window.location.href = trdPartyConfig.onSuccessUrl+trdPartyToken;
						}
						// Redirect the user to postLoginRoutePath if available.
						if(userContext.postLoginRoutePath) {
							console.log("reroute the user back to intented path : " + userContext.postLoginRoutePath);
							this.router.navigateByUrl('/'+userContext.postLoginRoutePath);
						} else {
							this.router.navigateByUrl('/appointments');
						}
					}
					else {
						if (userContext.impersonateUser) {
							userContext.authError = true;
							userContext.authErrorMessage = "Invalid impersonate user name. Please correct impersonate user name and try again.";
						}
						else {
							userContext.authError = true;
							userContext.authErrorMessage = "Could not find user in Profile. Please contact administrator.";
						}
					}
				}
			);

	}

	/**
	 * Gets Stream user profile and sets the profile in storageService.
	 */
	private getStreamUserProfile(user: User, token : string) {
		console.log("Get Stream user profile to find House Counsel status of User : " + user.userId);
		let apiUrl = environment.ECLAIMS_ONESITE_WS_BASE_URL + 'adjuster/' + user.userId+ "?requestime=" + (new Date().getTime());
		this.http.get<AdjusterProfile>(apiUrl, { headers : new HttpHeaders().set('Authorization','Bearer ' + token)}).subscribe((adjuster) => {
			// Set the user house counsel status event
			console.log("Aduster : " + adjuster.id + " - " + adjuster.houseCounsel);
			if(adjuster && adjuster.houseCounsel == "Y") {
				this.eventService.onHouseCounselLogin(true);
			} else {
				this.eventService.onHouseCounselLogin(false);
			}
			// Set user profle in storage.
			this.storageService.save("User_Profile" , adjuster);
			// Call the Profile API and routing logic. July-2019
			this.getProfileUserInfo(this.loginData, this.loginContext);
		}, err => {
			console.log("Error with Stream User Details API : " + err.status);
			this.eventService.onHouseCounselLogin(false);
			// Call the Profile API and routing logic. July-2019
			this.getProfileUserInfo(this.loginData, this.loginContext);
		});
	}


  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
	private handleError<T>(operation = 'operation', result?: T) {
		return (error: any): Observable<T> => {

			// TODO: send the error to remote logging infrastructure
			console.error(error); // log to console instead

			// TODO: better job of transforming error for user consumption
			this.log(`${operation} failed: ${error.message}`);

			// Let the app keep running by returning an empty result.
			return of(result as T);
		};
	}

	/** Log a HeroService message with the MessageService */
	private log(message: string) {
		this.messageService.add(`HeroService: ${message}`);
	}

	loginAdminUser(user: User, userContext: UserContext) {
		console.log("Admin login initiated...");
		this.loginData = user;
		this.loginContext = userContext;
		return this.http.post(environment.PROFILE_SERVICE_BASE_URL + "auth/authentication", user, {
			headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
			observe: 'response'
		}).subscribe(
			(resp: HttpResponse<any>) => {
				// resp => {
				console.log(resp);
				if (resp != null && resp.body["validCredential"] == false) {
					console.log("Invalid Username  & Password for user id : " + user.userId);
					userContext.authError = true;
					userContext.authErrorMessage = "Invalid user name or password. Please correct user name/password and try again.";
				} else {
					console.log("Admin user logged in.");
					var userIdKey = "User_Id";
					userContext.authError = false;
					userContext.authErrorMessage = "";
					//first save into storage service and then emit event
					//since event can trigger components (like the header)
					//read from storage about the user
					this.storageService.save(userIdKey, user.userId);
					// Defaulting to false in order to hide the links.
					this.eventService.onUserLoginLogout(false);
					this.router.navigateByUrl('/'+userContext.postLoginRoutePath);
				}
			}
		);
	}

}