import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { throwMatDuplicatedDrawerError } from '@angular/material/sidenav';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageService } from '../../shared/service/storage.service';
import { LetterData } from './letter-data';
import { environment } from '../../../environments/environment';
import { error } from '@angular/compiler/src/util';
import { ClaimData } from './claim-data';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { debounceTime, distinctUntilChanged, pairwise } from "rxjs/operators";

interface DeliveryType {
  value: string;
  viewValue: string;
}
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Component({
  selector: 'eclaimsonesite-letter',
  templateUrl: './letter.component.html',
  styleUrls: ['./letter.component.scss']
})
export class LetterComponent implements OnInit {
  unitId: string;

  constructor(public route: ActivatedRoute, public router: Router
    , private storageService: StorageService
    , public http: HttpClient
    , private fb: FormBuilder) { }

  claimNumber: string;
  @Input() code: string;

  userId: string;
  isErrorOccured: boolean = false;
  letterData: LetterData;
  storageChargesUpToDate: Date;
  lossTypeCode: string;
  errorMessage: string;
  streamUnitId: string;
  claimUnitAssignId: string;
  claimUnit: ClaimData;
  deliveryTypes: DeliveryType[] = [
    { value: 'email', viewValue: 'Email Real Time' },
    { value: 'fax', viewValue: 'Fax Real Time' },
    { value: 'Print', viewValue: 'Print Vendor' }
  ];

  lettersForm: FormGroup;
  isDeliveryTypeSelected: boolean = false;
  letterApiBaseUrl: string = environment.ECLAIMS_ONESITE_WS_LETTER_URL;

  ngOnInit(): void {
    // Check user Login session. If not found, redirect user to login.
    var key = 'User_Id';
    this.route.paramMap.subscribe((params) => {
      if (params.get('code')) {
        this.code = params.get('code');
        this.storageService.remove('letter_code');
        this.storageService.save('letter_code', this.code);
      }
    });
    // Check user login.
    if (!this.storageService.get(key) || !this.storageService.get("X_Token")) {
      this.router.navigateByUrl('/login/letter');
    } else {
      this.userId = JSON.parse(this.storageService.get(key));
      console.log("User Id : " + this.userId);
    }
    this.letterData = new LetterData();
    this.claimUnit = new ClaimData();
    // If code & claim number is null, get them from storage.
    if (!this.claimNumber && this.storageService.get('letter_claimNumber')) {
      this.claimNumber = JSON.parse(this.storageService.get('letter_claimNumber'));
      this.letterData.letterCode = JSON.parse(this.storageService.get('letter_code'));
      this.letterData.userId = this.userId;
      this.letterData.claimNumber = this.claimNumber;
      this.letterData.underWritingCompany = JSON.parse(this.storageService.get('letter_claimCompnay'));
      this.claimUnit.claimNumber = this.claimNumber;
      this.claimUnit.unitId = JSON.parse(this.storageService.get('letter_streamUnitId'));
      this.claimUnit.claimUnitAssignId = JSON.parse(this.storageService.get('letter_claimUnitId'));
      this.claimUnit.lossTypeCd = JSON.parse(this.storageService.get('letter_lossTypeCd'));
      console.log('Claim number : ' + this.claimNumber + " letter data : " + this.claimUnit.toString());
    }
    this.letterData.claimUnit = this.claimUnit;
    this.initiateForm();
    // Initiate the Letter Data;
    this.setLetterClaimData();
  }

  initiateForm() {
    this.lettersForm = new FormGroup({
      claimNumber: new FormControl(""),
      vehicleLocation: new FormControl("", [
        Validators.required,
        Validators.maxLength(149),
      ]),
      storageChargesPerDay: new FormControl("", [
        Validators.required,
        Validators.pattern("([0-9]{1,})([.])([0-9]{2})"),
        Validators.maxLength(11),
      ]),
      storageChargesUpTo: new FormControl("", [
        Validators.required,
        // Validators.pattern("/^02\/(?:[01]\d|2\d)\/(?:[12][0-9])|(?:0[13578]|10|12)\/(?:[0-2]\d|3[01])\/(?:[1-2][0-9])|(?:0[469]|11)\/(?:[0-2]\d|30)\/(?:[1-2][0-9])|02\/(?:[0-1]\d|2[0-8])\/(?:19|20)$/"),
        // Validators.maxLength(10),
      ]),
      type: new FormControl("", Validators.required)
    });
    // Subscribe to Diagnosis code A value changes to mark entire Diagnosis group as valid.
    //this.subscribeToDiagnosisAChanges();
    this.lettersForm
      .get("claimNumber")
      .setValue(this.claimNumber);
  }

  getGenericHeadersWithToken(token: string) {
    let oAuthToken: string = token;
    if (!oAuthToken) {
      console.log("Get Token from Storage...");
      if (this.storageService.get("X_Token")) {
        oAuthToken = JSON.parse(this.storageService.get("X_Token"));
      }
    }
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
        .append("Authorization", "Bearer " + oAuthToken)
        .append("Access-Control-Allow-Origin", "*")
        .append('Accept', 'application/json')
    };
    return httpOptions;
  }

  setLetterClaimData() {
    console.log("Initiate the LetterData from WS call. " + this.claimNumber);
    let letterClaimDataApi = this.letterApiBaseUrl + "claimUnitData?claim=" + this.letterData.claimNumber
      + "&lossType=" + this.letterData.claimUnit.lossTypeCd
      + "&unitId=" + this.letterData.claimUnit.unitId
      + "&unitAssignId=" + this.letterData.claimUnit.claimUnitAssignId
      + "&reqTime=" + (new Date().getTime());
    console.log("GetClaimUnitData API URL : " + letterClaimDataApi);
    console.log("Letter data for Claim : " + this.letterData.toString());
    this.http.get(letterClaimDataApi, httpOptions).subscribe((res: ClaimData) => {

      console.log("ClaimUnitData response -> " + JSON.stringify(res));
      if (res && res && res != null) {
        if (!this.isValidClaimData(res)) {
          this.isErrorOccured = true;
          this.errorMessage = "Claim has incomplete data, cannot issue letter for this claim : " + this.claimNumber;
        } else {
          this.isErrorOccured = false;
          this.letterData.claimUnit = res;
        }
        console.log("After api call letter data : " + this.letterData.toString());
      } else {
        this.isErrorOccured = true;
        this.errorMessage = "Error while trying to get Claim specific data for letter.";
      }
    }, error => {
      this.isErrorOccured = true;
      this.errorMessage = "Unable to get Claim specific data to proceed with letter.";
      console.warn("API : errored " + letterClaimDataApi + ", " + error);
    });
  }

  isValidClaimData(clmDt: ClaimData) {
    if (clmDt.claimNumber && clmDt.lossTypeCd 
        && (clmDt.unitId || clmDt.claimUnitAssignId) 
        && clmDt.claimRepUserId && clmDt.claimantName 
        && clmDt.claimantNumber && clmDt.policyNumber && clmDt.dateOfLoss)
      return true;
    console.log(" Invalid ClaimDataq : " + this.toString());
    return false;
  }

  onDeliveryTypeChange() {
    this.isDeliveryTypeSelected = true;
    console.log("On delivery method changes : " + this.lettersForm.get("type").value + " vs the binded object : " + this.letterData.deliveryMethod);
    if (this.lettersForm.get("type").value && this.lettersForm.get("type").value == 'email')
      this.addEmailFormControl();
    else if (this.lettersForm.get("type").value && this.lettersForm.get("type").value == 'fax')
      this.addFaxFormControl();
    else {
      if (this.lettersForm.contains('attentionTo'))
        this.lettersForm.removeControl("attentionTo");
      if (this.lettersForm.contains('fax'))
        this.lettersForm.removeControl("fax");
      if (this.lettersForm.contains('email'))
        this.lettersForm.removeControl("email");
    }
    console.log("Is form valid : " + this.lettersForm.valid)
    Object.keys(this.lettersForm.controls).forEach(key => {
      console.log(key + " - Is formControl valid : " + this.lettersForm.controls[key].valid);
    });
  }

  addEmailFormControl() {
    this.lettersForm.addControl("email", new FormControl("", [Validators.required, Validators.email]));
    // Remove the fax control if exists
    if (this.lettersForm.contains('fax'))
      this.lettersForm.removeControl("fax");
    if (!this.lettersForm.contains('attentionTo'))
      this.lettersForm.addControl("attentionTo", new FormControl("", Validators.required));
  }

  addFaxFormControl() {
    this.lettersForm.addControl("fax", new FormControl("", [Validators.pattern('[- +()0-9]{10,}'), Validators.required]));
    // Remove the email control if exists
    if (this.lettersForm.contains('email'))
      this.lettersForm.removeControl("email");
    if (!this.lettersForm.contains('attentionTo'))
      this.lettersForm.addControl("attentionTo", new FormControl("", Validators.required));
  }

  processLetter() {
    // Check if the delivery is Fax and fax is a valid 10 digit numeric after replacements.
    if(this.letterData.deliveryMethod == 'fax'){
      let tempFaxHolder = this.letterData.faxNumber;
      // Strip all non numerics
      tempFaxHolder = tempFaxHolder.replace(/\D/g,'');
      console.log("Fax number after form submit : " + tempFaxHolder);
      // check the fax number length.
      if(tempFaxHolder.length != 10){
        // show the error and invalidate the form.
        this.lettersForm.get('fax').setErrors({ invalid: true }, { emitEvent: true });
        return;
      } else {
        this.letterData.faxNumber = this.letterData.faxNumber.replace(/\D/g, '');
        console.log("valid fax number : " + this.letterData.faxNumber);
      }

    }
    let letterSaveDataApi = this.letterApiBaseUrl + "process";
    this.letterData.tranxId = 0;
    let reqBody = JSON.stringify(this.letterData);
    console.log("Process letter data : " + reqBody);
    console.log("Process letter with API url : " + letterSaveDataApi);

    this.http.post(letterSaveDataApi, reqBody, this.getGenericHeadersWithToken(null)).subscribe((res: LetterData) => {
      console.log("Response : " + res);
      if (res && res != null) {
        console.log("Received succesful response : " + res.claimNumber + " - transaction : " + res.tranxId);
        this.isErrorOccured = false;
        this.clearLetterStorage();
        // Redirect user back to the appointment details.
        this.router.navigateByUrl('/appointments');
      } else {
        this.isErrorOccured = true;
        this.errorMessage = "Unable to issue letter for claim : " + this.claimNumber;
      }
    }, error => {
      console.log(this.claimNumber + " - Error occured while processing letter.");
      this.isErrorOccured = true;
      this.errorMessage = "Error occured whilte trying to process letter for claim : " + this.claimNumber;
    });
  }

  clearLetterStorage() {
    console.log("Clearing storage elements for Letter.");
    this.storageService.remove('letter_code');
    this.storageService.remove('letter_claimNumber');
    this.storageService.remove('letter_streamUnitId');
    this.storageService.remove('letter_claimUnitId');
    this.storageService.remove('letter_lossTypeCd');
    this.storageService.remove('letter_claimCompnay');
    console.log("Cleared storage elements for Letter!");
  }

  /**
   * Sets the selected date to letterData.storageChargesUpTo
   */
  onDateSelect() {
    console.log("Date selected : " + this.storageChargesUpToDate);
    // set the date to letterData when its complete date.
    if (this.storageChargesUpToDate)
      this.letterData.storageChargesUpTo = ("0" + (this.storageChargesUpToDate.getMonth() + 1)).slice(-2) + "/" 
        + ("0" + this.storageChargesUpToDate.getDate()).slice(-2) + "/" 
        + this.storageChargesUpToDate.getFullYear();
    console.log("on selection this.letterData.storageChargesUpTo " + this.letterData.storageChargesUpTo);
  }

  onDateBlur() {
    console.log("Date onblur : " + this.letterData.storageChargesUpTo);
    if (this.storageChargesUpToDate && (this.letterData.storageChargesUpTo == undefined || this.letterData.storageChargesUpTo.length < 10))
      this.letterData.storageChargesUpTo = this.storageChargesUpToDate.getMonth() + "/" 
        + this.storageChargesUpToDate.getDate() + "/" 
        + this.storageChargesUpToDate.getFullYear();
    console.log("on blur this.letterData.storageChargesUpTo " + this.letterData.storageChargesUpTo);
  }

  formatDateFormControl(){
    console.log("Monitor & format date form control...");
    let dateRangeFC = this.lettersForm.controls["storageChargesUpTo"];
    dateRangeFC.valueChanges.pipe(debounceTime(25), distinctUntilChanged()).subscribe((val) => {
      console.log("Date form control Value : " + val);
      if (val && val != "" && (val.length == 2 || val.length == 5)) {
        dateRangeFC.setValue(val + "/");
        dateRangeFC.updateValueAndValidity({
          onlySelf: true,
          emitEvent: true,
        });
      }
      if (val && val != "" && val.length > 10) {
        dateRangeFC.setValue(val.slice(0, 10));
        //dateRangeFC.updateValueAndValidity({onlySelf :true, emitEvent : true});
      }
      console.log("Monitored & format date form control value : " + this.lettersForm.controls["storageChargesUpTo"].value);
    });
  }
  

}

