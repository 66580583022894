export class ClaimData {

    claimNumber: string;
    policyNumber: string;
    claimantNumber: string;
    dateOfLoss: string;
    unitId: string;
    claimantFirstName: string;
    claimantMiddleName: string;
    claimantLastName: string;
    claimantBusName: string;
    participantId: string;
    participantType: string;
    claimRepUserId: string;
    claimRepName: string;
    claimRepEmail: string;
    reserveId: string;
    lossTypeCd: string;
    claimantName: string;
    claimUnitAssignId: string;

    constructor() { };

    toString(): string {
        return "ClaimData { claimNumber: " + this.claimNumber + ", lossTypeCd : " + this.lossTypeCd + ", unitId : " + this.unitId + ", claimUnitAssignmentId : " + this.claimUnitAssignId + "}";
    }

}
