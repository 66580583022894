export class ErrorResponse {
    timestamp: string;
    accessedURI: string;
    status: string;
    trackingId: string;
    message: string;
    detail: string;

    constructor() {};

    toString() {
        return "timestamp : " + this.timestamp
            + ", accessedURI : " + this.accessedURI
            + ", status : " + this.status
            + ", trackingId : " + this.trackingId
            + ", message : " + this.message
            + ", detail : " + this.detail
    }
}
