import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'eclaimsonesite-custom-print',
  templateUrl: './custom-print.component.html',
  styleUrls: ['./custom-print.component.scss']
})
export class CustomPrintComponent implements OnInit {

  @Input("claimNumber")
  claimNumber: string;

  @Input("userOrigin")
  userOrigin: string;

  onCancelDestination: string = "Log View";

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {
    if(this.userOrigin && this.userOrigin == 'FALLBACK_ENABLED')
      this.onCancelDestination = "Make Payment";
  }

  onCancel() {
    console.log("User cancelled on custom print solution screen, userOrigin : " + this.userOrigin);
    this.activeModal.close(this.userOrigin + '_Cancel'); 
  }

}
