<div class="container" align="center" id="letterContainer">
  <form [formGroup]="lettersForm" (ngSubmit)="processLetter()">
    <div align="center" style="width: 40%;">
      <h3 style="color: #FF6319" class="py-3" id="header">Letters</h3>
    </div>
    <div *ngIf="isErrorOccured" style="width: 40%;">
      <div class="alert alert-danger">
        {{ errorMessage }}
      </div>
    </div>
    <div class="form-group" style="width: 40%;" align="left">
      <label for="claimNumber" class="font-weight-bold">Claim Number</label>
      <input type="text" disabled class="form-control" id="claimNumber" formControlName="claimNumber"  [(ngModel)]="letterData.claimNumber" readonly>
    </div>
    
    <div class="form-group" style="width: 40%;" align="left">
        <label for="vehicleLocation" class="font-weight-bold">Vehicle location at</label>
        <input type="text" class="form-control"  id="vehicleLocation" formControlName="vehicleLocation" 
          placeholder="Location of the Vehicle" [(ngModel)]="letterData.vehicleLocation" 
          [ngClass]="{'border border-danger' : lettersForm.get('vehicleLocation').invalid && (lettersForm.get('vehicleLocation').dirty || lettersForm.get('vehicleLocation').touched)}"
          required>
        <div *ngIf="lettersForm.get('vehicleLocation').invalid && (lettersForm.get('vehicleLocation').dirty || lettersForm.get('vehicleLocation').touched)" class="alert alert-danger">
          Vehcile location is required.
        </div>
    </div>

    <div class="form-group" style="width: 40%;" align="left">
        <label for="storageChargesPerDay" class="font-weight-bold">Storage charges per day</label>
        <input type="text" class="form-control" id="storageChargesPerDay" formControlName="storageChargesPerDay" placeholder="Charges in format 0.00" 
          [ngClass]="{'border border-danger' : lettersForm.get('storageChargesPerDay').invalid && (lettersForm.get('storageChargesPerDay').dirty || lettersForm.get('storageChargesPerDay').touched)}"
          pattern="^\d+\.\d{2}$" [(ngModel)]="letterData.storageChargesPerDay" required>
        <div *ngIf="lettersForm.get('storageChargesPerDay').invalid && (lettersForm.get('storageChargesPerDay').dirty || lettersForm.get('storageChargesPerDay').touched)" class="alert alert-danger">
          Charges required in format 1.99.
        </div>
    </div>

    <div class="form-group" style="width: 40%;" align="left">
      <label for="storageChargesUpTo" class="font-weight-bold">Storage charges up to</label>
      <div style="width: 100%;">
        <p-calendar name="storageChargesUpTo" 
          formControlName="storageChargesUpTo" 
          [(ngModel)]="storageChargesUpToDate"
          dateFormat="mm/dd/yy" [showIcon]="true" inputId="icon"
          (onSelect)="onDateSelect()" (onBlur)="onDateBlur()">
        </p-calendar>
      </div>
        
      <div *ngIf="lettersForm.get('storageChargesUpTo').invalid && (lettersForm.get('storageChargesUpTo').dirty || lettersForm.get('storageChargesUpTo').touched)" class="alert alert-danger">
        Charges required in format mm/dd/yy.
      </div>
    </div>

    <!-- 
    <div class="form-group" style="width: 40%;" align="left">
        <label for="storageChargesUpTo" class="font-weight-bold">Storage charges up to</label>
        <input type="text" class="form-control" name="storageChargesUpTo" formControlName="storageChargesUpTo" placeholder="Charges in format 0.00" 
          pattern="^\d+\.\d{2}$" [(ngModel)]="letterData.storageChargesUpTo" 
          [ngClass]="{'border border-danger' : lettersForm.get('storageChargesUpTo').invalid && (lettersForm.get('storageChargesUpTo').dirty || lettersForm.get('storageChargesUpTo').touched)}"
          required>
        <div *ngIf="lettersForm.get('storageChargesUpTo').invalid && (lettersForm.get('storageChargesUpTo').dirty || lettersForm.get('storageChargesUpTo').touched)" class="alert alert-danger">
          Charges required in format 2.99.
        </div>
    </div>
    -->
    <div class="form-group" style="width: 40%;" align="left">
        <mat-form-field appearance="fill">
            <mat-label>Select delivery method</mat-label>
            <mat-select (selectionChange)="onDeliveryTypeChange()" [(ngModel)]="letterData.deliveryMethod" name="type" formControlName="type">
                <mat-option *ngFor="let type of deliveryTypes" [value]="type.value">
                    {{type.viewValue}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    
    <div class="form-group" style="width: 40%;" align="left" *ngIf="isDeliveryTypeSelected && letterData.deliveryMethod == 'email'">
      <label for="email" class="font-weight-bold">Email</label>
      <input type="text" class="form-control" id="email" formControlName="email"  placeholder="Enter email" [(ngModel)]="letterData.email"
        [ngClass]="{'border border-danger' : lettersForm.get('email').invalid && (lettersForm.get('email').dirty || lettersForm.get('email').touched)}" required>
      <div *ngIf="lettersForm.get('email').invalid && (lettersForm.get('email').dirty || lettersForm.get('email').touched)" class="alert alert-danger">
        Email is required.
      </div>
    </div>
    
    <div class="form-group" style="width: 40%;" align="left" *ngIf="isDeliveryTypeSelected && letterData.deliveryMethod == 'fax'">
      <label for="fax" class="font-weight-bold">Fax Number</label>
      <input type="text" class="form-control" name="fax" formControlName="fax"  placeholder="Enter 10 digit numeric fax number" [(ngModel)]="letterData.faxNumber"
        [ngClass]="{'border border-danger' : lettersForm.get('fax').invalid && (lettersForm.get('fax').dirty || lettersForm.get('fax').touched)}"
        required>
      <div *ngIf="lettersForm.get('fax').invalid && (lettersForm.get('fax').dirty || lettersForm.get('fax').touched)" class="alert alert-danger">
        10 digit numeric Fax number is required.
      </div>
    </div>

    <div class="form-group" style="width: 40%;" align="left" *ngIf="lettersForm.contains('attentionTo')">
      <label for="attentionTo" class="font-weight-bold">Attention to</label>
      <input type="text" class="form-control" name="attentionTo" formControlName="attentionTo"  placeholder="Letter attention to" [(ngModel)]="letterData.attentionTo"
      [ngClass]="{'border border-danger' : lettersForm.get('attentionTo').invalid && (lettersForm.get('attentionTo').dirty || lettersForm.get('attentionTo').touched)}" required>
      <div *ngIf="lettersForm.get('attentionTo').invalid && (lettersForm.get('attentionTo').dirty || lettersForm.get('attentionTo').touched)" class="alert alert-danger">
        Attention to is required.
      </div>
    </div>

    <div class="form-group" style="width: 40%;" align="center">
      <button type="button" (click)="processLetter()" [disabled]="(!lettersForm.valid || !isDeliveryTypeSelected)" [ngClass]="{'margin-x btn-secondary' : lettersForm.invalid, 'margin-x btn-primary' : lettersForm.valid}" >Submit</button>
    </div>
  </form>
    
  </div>