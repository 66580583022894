import { HttpClient, HttpHeaders, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { AdjusterProfile } from '../models/adjuster-profile';
import { ClaimDispatchEstimate } from '../models/claim-dispatch-estimate';
import { ClaimProfile } from '../models/claim-profile';
import { ErrorResponse } from '../models/error-response';
import { IKeyValuePair } from '../models/ikey-value-pair';
import { IPayeeAddress } from '../models/ipayee-address';
import { IPaymentType } from '../models/ipayment-type';
import { IVendor } from '../models/ivendor';
import { PaymentCheck } from '../models/payment-check';
import { PaymentDetails } from '../models/payment-details';
import { Status } from '../models/status';
//import { HandleError, HttpErrorHandler } from '../services/http-error-handler.service';
import { AdjusterActivity } from '../models/adjuster-activity';
import { environment } from '../../../../environments/environment';
import { map, tap, switchMap, retry, catchError } from 'rxjs/operators';
import { pipe, bindNodeCallback, Observable, throwError } from 'rxjs';
import { PrinterInfo } from '../models/printer-info';
import { StorageService } from '../../../shared/service/storage.service';
import { PaymentTransaction } from '../models/payment-transaction';
import { GCPPrinter } from '../models/gcpprinter';

import {Parser} from 'xml2js';
import * as xml2js from 'xml2js';
import { Router } from '@angular/router';

@Injectable()
export class CheckWriterService {

  url;
  baseUrl;
  claimRelatedUrl;
  private oauthToken:string;
  private PREIVEW_CHECK_URI : string ="/previewCheck";

  private ONESITE_API_BASE_URL: string = environment.ECLAIMS_ONESITE_WS_BASE_URL;
  private ONESITE_CLAIM_API_URL: string = environment.ECLAIMS_ONESITE_WS_BASE_URL + 'claim/';
  private ONESITE_ADJ_API_URL: string = environment.ECLAIMS_ONESITE_WS_BASE_URL + 'adjuster/';
  private ONESITE_ADMIN_UL : string = environment.ECLAIMS_ONESITE_WS_ADMIN_URL;

  constructor(public http: HttpClient
    , private storageService: StorageService
    ,public router: Router) {}

  callGetApi(claimNumber: string, contextPath: string) {
    let apiUrl = this.ONESITE_CLAIM_API_URL + claimNumber + contextPath;
    console.log("Get Claim number details for url : " + apiUrl);
    return this.http.get(apiUrl);
  }

  callGetApiForContextPath(contextPath: string) {
    let apiUrl = this.ONESITE_API_BASE_URL + contextPath;
    console.log("Call Get API : " + apiUrl);
    //const headerParams = new HttpHeaders().set('Access-Control-Allow-Origin', '*');
    //return this.http.get(this.url, {headers: headerParams});
    return this.http.get(apiUrl);
  }

  getDispatchEstimate(apiContext: string) {
    console.log(" Environment specific Dispatch Estimate Amount API : " + this.ONESITE_API_BASE_URL + apiContext);
    return this.http.get<ClaimDispatchEstimate>(this.ONESITE_API_BASE_URL + apiContext, this.getGenericHeadersWithToken(null));
  }

  callGetPayeeAddressAPI(claimNumber: string, contextPath: string) {
    let apiUrl = this.ONESITE_CLAIM_API_URL + claimNumber + contextPath;
    console.log("Get Claim number Payee Address with url : " + apiUrl);
    return this.http.get<IPayeeAddress[]>(apiUrl, this.getGenericHeadersWithToken(null));
  }

  getClaimProfile(claimNumber: string, dispId: string, token : string, isHouseCounsel:string) {
    let apiPath = this.ONESITE_CLAIM_API_URL + claimNumber + "/profile?dispId=" + dispId +"&isHusCnl=" + isHouseCounsel + "&requestime=" + (new Date().getTime());
    console.log("Claim Profile URL : " + apiPath);
    return this.http.get<ClaimProfile>(apiPath, {headers : new HttpHeaders().set("Authorization", "Bearer "+token)});
  }

  getDropDownOptions(apicontext: string, inQueryParams: IKeyValuePair[], headerParams: IKeyValuePair[]) {
    console.log("InPayment of context : " + apicontext);
    let apiUrl = this.ONESITE_API_BASE_URL + apicontext+ "?requestime=" + (new Date().getTime());
    console.log("Call api url : " + apiUrl);
    this.getQueryParams(inQueryParams);
    this.getHeaderParams(headerParams);
    return this.http.get<IKeyValuePair[]>(apiUrl, this.getGenericHeadersWithToken(null));
  }

  getPaymentTypeList(apiContext: string, inQueryParamsMap: IKeyValuePair[], headerParamsMap: IKeyValuePair[]) {
    console.log(" Payment Type List Url : " + this.ONESITE_API_BASE_URL + apiContext+ "?requestime=" + (new Date().getTime()));
    let queryParams = this.getQueryParams(inQueryParamsMap);
    let headers = this.getHeaderParams(headerParamsMap);
    return this.http.get<IPaymentType[]>(this.ONESITE_API_BASE_URL + apiContext, this.getGenericHeadersWithToken(null));
  }

  submitPaymentOptions(paymentOptions: PaymentDetails, token : string) {
    let apiUrl = this.ONESITE_CLAIM_API_URL + paymentOptions.claimNumber + this.PREIVEW_CHECK_URI + "?requestime=" + (new Date().getTime());
    let reqBody = JSON.stringify(paymentOptions);
    console.log("Submitting payment options to API URL : " + apiUrl);
    //return this.http.post(apiUrl, reqBody, this.getGenericHeaders());
    // return this.http.post(apiUrl, reqBody,  { responseType: 'blob' }).map( res => {
    return this.http.post(apiUrl, reqBody,
      { headers: new HttpHeaders().set('Content-Type', 'application/json').set('Authorization','Bearer '+token), responseType: 'blob' })
      .pipe(map(res => {
        console.log("processing pdf blob...");
        return new Blob([res], { type: 'application/pdf' });
      }));
  }

  postPaymentAndGetCheck(paymentOptions: PaymentDetails, token : string) {
    let apiUrl = this.ONESITE_CLAIM_API_URL + paymentOptions.claimNumber + "/paymentCheck"+ "?requestime=" + (new Date().getTime());
    let reqBody = JSON.stringify(paymentOptions);
    console.log("Posting Payment details to API URL : " + apiUrl);
    return this.http.post<PaymentCheck>(apiUrl, reqBody, this.getGenericHeadersWithToken(token));
  }

  /*addCheckPrintTransaction(paymentOptions: PaymentDetails) {
    let apiUrl = this.ONESITE_CLAIM_API_URL + paymentOptions.claimNumber + "/addPrintTranx"+ "?requestime=" + (new Date().getTime());
    let reqBody = JSON.stringify(paymentOptions);
    console.log("Posting add print transaction details to API URL : " + apiUrl);
    return this.http.post(apiUrl, reqBody, this.getGenericHeadersWithToken(null));
  }

  processPrintTransactions(paymentOptions: PaymentDetails, claimNumber: string, printStatus: string, inputTypeIndc: string, token : string, printerId: string) {
    let apiUrl = this.ONESITE_CLAIM_API_URL + claimNumber + "/printTranx?printStatus=" + printStatus + "&paymentInputType=" + inputTypeIndc + "&printerId=" + printerId+ "&requestime=" + (new Date().getTime());
    let reqBody = JSON.stringify(paymentOptions);
    console.log("Posting Print transaction to API URL : " + apiUrl);
    return this.http.post<Status[]>(apiUrl, reqBody, this.getGenericHeadersWithToken(token));
  }*/

  processPostPrintTransactions(paymentOptions: PaymentDetails, claimNumber: string, checkNum: string, checkStatus: string, token : string) {
    let apiUrl = this.ONESITE_CLAIM_API_URL + claimNumber + "/transaction?checkNumber=" + checkNum + "&checkStatus=" + checkStatus+ "&requestime=" + (new Date().getTime());
    let reqBody = JSON.stringify(paymentOptions);
    console.log("Posting PostPrint transaction details to API URL : " + apiUrl);
    return this.http.post(apiUrl, reqBody, this.getGenericHeadersWithToken(token));
  }

  getGenericHeaders() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
        .append("Access-Control-Allow-Origin", "*")
        .append('Accept', 'application/json')
    };

    return httpOptions;
  }

  getGenericHeadersWithToken(token : string) {
    let oAuthToken:string = token;
    if(!oAuthToken){
      console.log("Get Token from Storage...");
      if(this.storageService.get("X_Token")){
        oAuthToken = JSON.parse(this.storageService.get("X_Token"));
      }
    }
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
        .append("Authorization", "Bearer " + oAuthToken)
        .append("Access-Control-Allow-Origin", "*")
        .append('Accept', 'application/json')
    };

    return httpOptions;
  }

  getQueryParams(queryParams: IKeyValuePair[]): HttpParams {
    const params: HttpParams = new HttpParams();

    if (params) {
      queryParams.forEach(keyValuePair => {
        console.log(" Add Query param : " + keyValuePair.key + " with value : " + keyValuePair.value);
        params.append(keyValuePair.key, keyValuePair.value);
      });
    }
    return params;
  }

  getHeaderParams(headerParams: IKeyValuePair[]): HttpHeaders {
    const headers: HttpHeaders = new HttpHeaders();

    if (headerParams) {
      headerParams.forEach(keyValuePair => {
        console.log(" Add header param : " + keyValuePair.key + " with value : " + keyValuePair.value);
        headers.append(keyValuePair.key, keyValuePair.value);
      });
    }
    return headers;
  }

  public getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  public searchVendors( searchText: string, state: string,) {
    console.log("Search vendor : " + searchText + " in state : " + state);
    return this.http.get<IVendor[]>(this.ONESITE_API_BASE_URL + "vendor?vendor=" + searchText + "&stateCode=" + state + "&requestime=" + (new Date().getTime()), this.getGenericHeadersWithToken(null));
  }

  public callGenericGetAPI<T>(claim: string, path: string) {
    let apiUrl = this.ONESITE_CLAIM_API_URL + claim + path;
    console.log("Claim # : " + claim + " path : " + apiUrl);
    return this.http.get<T>(apiUrl, this.getGenericHeadersWithToken(null));
  }

  public getUserDetails(userId: string) {
    let apiUrl = this.ONESITE_ADJ_API_URL + userId+ "?requestime=" + (new Date().getTime());
    console.log("Get user details : " + apiUrl);
    return this.http.get<AdjusterProfile>(apiUrl, this.getGenericHeadersWithToken(null));
  }

  public getUserDetailsWithToken(userId: string, token : string) {
    let apiUrl = this.ONESITE_ADJ_API_URL + userId+ "?requestime=" + (new Date().getTime());
    console.log("Get user details with Token : " + apiUrl);
    return this.http.get<AdjusterProfile>(apiUrl, { headers : new HttpHeaders().set('Authorization','Bearer ' + token)});
  }

  /**
   * 
   * @param userId - Adjuster id
   * @param excludeUploaded - Yes, would exclude already uploaded payments. 
   */
  public getUserActivityLogs(userId: string, excludeUploaded:string) {
    let apiUrl = this.ONESITE_ADJ_API_URL + userId + "/activity" + "?excludeUploaded="+excludeUploaded+"&requestime=" + (new Date().getTime());
    console.log("Get user activity : " + apiUrl);
    return this.http.get<AdjusterActivity[]>(apiUrl, this.getGenericHeadersWithToken(null));
  }

  public retryPaymentSubmission(adjusterId: string) {
    let apiUrl = this.ONESITE_ADJ_API_URL + adjusterId + "/retryPayment";
    console.log("Retry adjuster payments submission : " + apiUrl);
    return this.http.put<Status>(apiUrl, null, this.getGenericHeadersWithToken(null));
  }

  /**
   * Process the transaction foe given transactions. 
   * if userCheckNum=true then transactionIds contain check num.
   * @param tranxIds 
   * @param action 
   * @param useCheckNum 
   */
  public retryUploadOrVoidPayments(tranxIds: string, action: string, useCheckNum: string) {
    let apiUrl = this.ONESITE_API_BASE_URL + "transactions?tranxIds="+tranxIds+"&action="+action+"&useCheckNumber="+useCheckNum+"&requestime=" + (new Date().getTime());
    console.log("Retry Upload or Void payments submission : " + apiUrl);
    return this.http.put<Status[]>(apiUrl, null, this.getGenericHeadersWithToken(null));
  }

  /**
   * Deletes GCP Print job.
   * @param jobId 
   */
  public deleteGCPPrintJob(jobId: string) {
    let apiUrl = this.ONESITE_API_BASE_URL + "gcp/print/deletejob?jobId="+jobId;
    console.log("update GCP Print job status : " + apiUrl);
    return this.http.delete<Status>(apiUrl, this.getGenericHeadersWithToken(null));
  }

  /**
   * Process the payment transaction.
   * 
   * @param claimNumber 
   * @param paymentTranx 
   */
  public processTransaction(claimNumber: string, paymentTranx: PaymentTransaction) {
    let apiUrl = this.ONESITE_CLAIM_API_URL + claimNumber + "/transaction?requestime=" + (new Date().getTime());
    let reqBody = JSON.stringify(paymentTranx);
    return this.http.put<Status[]>(apiUrl, reqBody, this.getGenericHeadersWithToken(null));
  }

  /**
   * Process the printing check and adding required  print transaction.
   * 
   * @param claimNumber 
   * @param PaymentTransaction 
   */
  public processCheckPrintTransaction(claimNumber: string, paymentTranx: PaymentTransaction) {
    let apiUrl = this.ONESITE_CLAIM_API_URL + claimNumber + "/checkPrint?requestime=" + (new Date().getTime());
    console.log("Calling checkPrint API : " + apiUrl);
    let reqBody = JSON.stringify(paymentTranx);
    console.log("Check Print API Payload : " + reqBody);
    return this.http.post<Status[]>(apiUrl, reqBody, this.getGenericHeadersWithToken(null));
  }

  handleErrorResponse(error: HttpErrorResponse) {
    let errorObject: ErrorResponse;
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
      errorObject = new ErrorResponse();
      errorObject.message = error.error.message;
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.log(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}` + `, accessed url : ${error.url}`);
        errorObject = error.error;
        if(error.status == 0) {
          console.log("Server is down...");
          errorObject.message = "Application server is down, please contact help desk.";
        } else if (error.status == 500 && error.url.indexOf(this.PREIVEW_CHECK_URI) > -1) {
          console.log("preview pdf api errored with 500 status.");
          errorObject = new ErrorResponse();          
          errorObject.message = "Unable to create Check preview, please contact help desk.";
        } else if (error.status == 412 && error.url.indexOf(this.PREIVEW_CHECK_URI) > -1) {
          console.log("preview pdf api errored with 412 status.");
          errorObject = new ErrorResponse();          
          errorObject.message = "Unable to create Check preview, due to invalid Payment Element and Payment Type selection.";
        } else if (error.status == 400 && error.url.indexOf(this.PREIVEW_CHECK_URI) > -1) {
          console.log("preview pdf api errored with 400 status due to amount exceeding the limit.");
          errorObject = new ErrorResponse();          
          errorObject.message = "Amount exceeding the permitted limit, please enter appropriate amount.";
        } else if (error.status == 500) {
          console.log("api errored with 500 status. " + errorObject.message);
          if(errorObject) {
            errorObject = new ErrorResponse();          
            errorObject.message = "Unable complete the request now, please contact help desk.";
          }
        } else if (error.status == 404 && error.url.indexOf(this.PREIVEW_CHECK_URI) > -1) {
          console.log("preview pdf api errored with 404 status.");
          errorObject = new ErrorResponse();          
          errorObject.message = "Unable to create Check preview, due to either user profile not found Or missing Claim information.";
        } else if (error.status == 417 && error.url.indexOf(this.PREIVEW_CHECK_URI) > -1) {
          console.log("preview pdf api errored with 417 status.");
          errorObject = new ErrorResponse();          
          errorObject.message = "Unable to create Check preview, due to Payee(80)/CoPayee(50) names exceeding length limits";
        } else if (error.status == 401) {
          console.log("Api errored with 401 status.");
          //errorObject = new ErrorResponse();          
          //errorObject.message = "Token Expired OR User unauthorized to access this resource.";
          // Commented above 2 lines and below changes as part of June-2019 Release.
          // Route the user back to login page for relogin.
          this.returnToLogin();
        } else if (error.status == 404) {
          errorObject = new ErrorResponse();          
          errorObject.status = '404';
          errorObject.message = (error.error && error.error.message) ? error.error.message : "No results found for this request. Try again.";
        } else {
          console.log("Response status  : " + error.status + " message : " + error.error.message);
          console.log("other than 500. errorObject : " + errorObject.toString());
        }
      return errorObject;
    }
    if (errorObject.message) {
      errorObject = new ErrorResponse();
      errorObject.message = "Unable to process the request at this time, Please contact help desk."
    }
    
    // return an observable with a user-facing error message
    //return throwError(
    //  'Something bad happened; please try again later.');
    return errorObject;
  }

  getRefStates() {
    console.log(" Reference States api Url : " + this.ONESITE_API_BASE_URL + "states");
    return this.http.get<IKeyValuePair[]>(this.ONESITE_API_BASE_URL + "states", this.getGenericHeadersWithToken(null));
  }

  getAdjusterAssignedPrinters(adjusterId: string) {
    let apiUrl = this.ONESITE_ADJ_API_URL + adjusterId + "/printers" + "?requestime=" + (new Date().getTime());
    console.log(" Adjuster assigned printers api Url : " + apiUrl);
    return this.http.get<PrinterInfo[]>(apiUrl, this.getGenericHeadersWithToken(null));
  }

  getAdjusterAssignedPrinters_Admin(adjusterId: string) {
    let apiUrl = this.ONESITE_ADMIN_UL + 'util/' + adjusterId + "/printers" + "?requestime=" + (new Date().getTime());
    console.log(" Adjuster assigned printers api Url : " + apiUrl);
    return this.http.get<PrinterInfo[]>(apiUrl, this.getGenericHeaders()).pipe(retry(0), catchError(this.handleError));
  }

  getGCPPrinters() {
    let apiUrl = this.ONESITE_ADMIN_UL + "gcp/printers?requestime=" + (new Date().getTime());
    console.log(" GCP Printers api Url : " + apiUrl);
    return this.http.get<GCPPrinter[]>(apiUrl, this.getGenericHeaders()).pipe(retry(0), catchError(this.handleError));
  }

  activateUserProfile(id: string) {
    let apiUrl = this.ONESITE_ADMIN_UL + "util/activate?requesttime=" + (new Date().getTime());
    let reqBody = "{ \"login\" : \"" + id +"\"}";
    console.log("Activate profile body : " + reqBody);
    return this.http.post(apiUrl, reqBody, this.getGenericHeaders()).pipe(retry(0), catchError(this.handleError));
  }
  
  assignAdjusterPrinter(id:string, printerId: string, printerName: string) {
    let printerObj = [{"id" : printerId, "assignToId" : id, "name" : printerName}];
    console.log("Printer Obj :" + printerObj);
    let printerJsonReqBody = JSON.stringify(printerObj);
    console.log("printerJsonReqBody :" + printerJsonReqBody);
    let apiUrl = this.ONESITE_ADMIN_UL + "util/printers?requesttime=" + (new Date().getTime());
    return this.http.post<Status>(apiUrl, printerJsonReqBody, this.getGenericHeaders()).pipe(retry(0), catchError(this.handleError));
  }

  updateAssignAdjusterPrinter(adjId:string, printerId: string, printerName: string) {
    let printerObj = [{"id" : printerId, "proxy" : printerId, "assignToId" : adjId, "name" : printerName}];
    console.log("update Printer Obj :" + printerObj);
    let printerJsonReqBody = JSON.stringify(printerObj);
    console.log("update printerJsonReqBody :" + printerJsonReqBody);
    let apiUrl = this.ONESITE_ADMIN_UL + "util/printers?requesttime=" + (new Date().getTime());
    return this.http.put<Status>(apiUrl, printerJsonReqBody, this.getGenericHeaders()).pipe(retry(0), catchError(this.handleError));
  }

  removeAdjusterPrinter(adjId: string, printerId: string) {
    let apiUrl = this.ONESITE_ADMIN_UL + "util/printers?adjusterId=" + adjId + "&printerId=" + printerId + "&requesttime=" + (new Date().getTime());
    return this.http.delete(apiUrl, this.getGenericHeaders()).pipe(retry(0), catchError(this.handleError));
  }

  /**
   * Checks if user is in Admin group.
   * 
   * @param userId - Admin userId
   * @returns - Observable<any[]>
   */
  /*isUserInAdminLDAPGroups2(userId: string) {
    let apiUrl = environment.PROFILE_LDAGP_GROUPS_URL + userId + "?requesttime=" + (new Date().getTime());
    console.log("Profile User Groups API : " + apiUrl);
    return this.http.get(apiUrl, { headers: new HttpHeaders().set('Accept', 'text/xml'), responseType: 'text' }).pipe(switchMap(res => bindNodeCallback(xml2js.parseString)(res)));
  }*/

  isUserAdminPersonnel(userId: string) {
    return this.http.get<any>(environment.ECLAIMS_ONESITE_WS_ADMIN_URL+"util/user/"+userId+"/admin", this.getGenericHeaders());
  }

  /**
   * Sends demo print job.
   * 
   * @param printerId 
   */
  sendGCPDemoPrintJob(printerId: string) {
    let printerObj = { "id": printerId };
    console.log("Demo Printer Obj :" + printerObj);
    let printerJsonReqBody = JSON.stringify(printerObj);
    console.log("Demo printerJsonReqBody :" + printerJsonReqBody);
    let apiUrl = this.ONESITE_ADMIN_UL + "gcp/print?requesttime=" + (new Date().getTime());
    return this.http.post<Status>(apiUrl, printerJsonReqBody, this.getGenericHeaders()).pipe(retry(0), catchError(this.handleError));
  }

  returnToLogin(){
    console.log("User current context : " + this.router.url);
    this.router.navigateByUrl("/login"+this.router.url);
  }

  /**
   * Gets ePayment status.
   * @param claim 
   * @param covCode 
   * @param paymentType 
   * @returns 
   */
  getEPaymentActiveStatus(claim : string, partyId? : number, covCode? : string, paymentType?:string){
    let apiUrl = this.ONESITE_API_BASE_URL + "epay/" + claim + "/status" 
      + "?requestime=" + (new Date().getTime())
      if(paymentType != undefined)
        apiUrl = apiUrl + "&paymentType="+paymentType;
      if(covCode != undefined)
        apiUrl = apiUrl + "&covCode="+covCode;
      if(partyId != undefined)
        apiUrl = apiUrl + "&partyId="+partyId;
    console.log(" epayment active status api url : " + apiUrl);
    return this.http.get<any>(apiUrl, this.getGenericHeadersWithToken(null));
  }

  submitVirtualPayment(claimNumber: string, virtualPayment: PaymentTransaction) {
    let apiUrl = this.ONESITE_API_BASE_URL + "epay/" + claimNumber + "?requestime=" + (new Date().getTime());
    console.log("Calling virtual payment API : " + apiUrl);
    let reqBody = JSON.stringify(virtualPayment);
    console.log("Virtual Payment API Payload : " + reqBody);
    return this.http.post<Status[]>(apiUrl, reqBody, this.getGenericHeadersWithToken(null));
  }

  /**
   * Adds user profile.
   * 
   * @param userProfile 
   */
  addUserProfile(userProfile: any) {
    let apiUrl = this.ONESITE_ADMIN_UL + "util/adjuster?requesttime=" + (new Date().getTime());
    console.log("Activate profile body : " + userProfile);
    return this.http.post(apiUrl, userProfile, this.getGenericHeaders()).pipe(retry(0), catchError(this.handleError));
  }

  /**
   * gcp.fallbak.pdf.enabled changed to fallbak.pdf.enabled since ezeep soluition implemented.
   */
  getGCPFallbackFlagStatus(){
    let apiUrl = this.ONESITE_ADMIN_UL + "util/property/?key=fallbak.pdf.enabled&requestime=" + (new Date().getTime());
    console.log(" Get gcp fallback flag active status api url : " + apiUrl);
    return this.http.get<any>(apiUrl, this.getGenericHeadersWithToken(null));
  }

  getCloudPrinters(){
    let apiUrl = this.ONESITE_ADMIN_UL + "cp/printers?requestime=" + (new Date().getTime());
    console.log(" CirrusPrint Get Printers api Url : " + apiUrl);
    return this.http.get(apiUrl, this.getGenericHeaders()).pipe(
      map((results:any[]) => {
        if(!results)
          return []
        else {
          return results.map(itm => {
            let printer = new PrinterInfo();
            printer.printerId = itm.locid+":"+itm.devid;
            printer.printerName = itm.locid+":"+itm.devid;
            printer.active = true;
            printer.cloudType = 'CIRRUS';
            printer.cloudAccount = environment.CIRRUS_CLOUD_ACCOUNT;
            return printer;
          });
        }
      }),
      map(response => response as PrinterInfo[]),
      catchError(this.handleError)) as Observable<PrinterInfo[]>;
  }

  submitCirrusPrintDemo(printerId:string, userId: string, filePath : string){
    let apiUrl = this.ONESITE_ADMIN_UL + "cp/print?printerId=" + printerId +"&userId=" + userId + "&requesttime=" + (new Date().getTime());
    let demoPrintObj = {
      "printerId" : printerId,
      "adminId" : userId,
      "filePath" : filePath,
      "cloudType" : "CIRRUS",
      "fileName" : "DemoPrint"
    }
    let reqBody = JSON.stringify(demoPrintObj);
    apiUrl = filePath ? apiUrl + "&filePath= " + filePath : apiUrl;
    console.log("CirrusPrint- Demo Print api url :" + apiUrl + " , Req Body : " + reqBody);
    return this.http.post(apiUrl, reqBody, this.getGenericHeaders()).pipe(retry(0), catchError(this.handleError));
  }

  /**
   * Gets property file entry value from service.
   */
  getPropertyEntryValue(key:string){
    let apiUrl = this.ONESITE_ADMIN_UL + "util/property/?key=" + key + "&requestime=" + (new Date().getTime());
    console.log(" Get property file entry value api url : " + apiUrl);
    return this.http.get<any>(apiUrl, this.getGenericHeadersWithToken(null));
  }

  /**
   * This function calls generic api to add or update cloud printer.
   * @param cloudPrinter 
   * @param adminId 
   */
  updateAdjusterCloudPrinter(cloudPrinter : PrinterInfo, adminId: string) {
    let printerObj:PrinterInfo[] = [cloudPrinter];
    console.log("update Printer Obj :" + printerObj);
    let printerJsonReqBody = JSON.stringify(printerObj);
    console.log("update printerJsonReqBody :" + printerJsonReqBody);
    let apiUrl = this.ONESITE_ADMIN_UL + "util/" + cloudPrinter.adjusterId + "/printers?adminId=" + adminId + "&requesttime=" + (new Date().getTime());
    return this.http.post<PrinterInfo[]>(apiUrl, printerJsonReqBody, this.getGenericHeaders()).pipe(retry(0), catchError(this.handleError));
  }

  handleError(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      //errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
      errorMessage = error.error;
    }
    return throwError(errorMessage);
  }

}
