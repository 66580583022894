import { Component, OnInit, Input } from '@angular/core';
import { CheckWriterService } from '../shared/services/check-writer.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PrinterInfo } from '../shared/models/printer-info';
import { ErrorResponse } from '../shared/models/error-response';
import { StorageService } from '../../shared/service/storage.service';
import { Router } from '@angular/router';

@Component({
  selector: 'eclaimsonesite-choose-printer',
  templateUrl: './choose-printer.component.html',
  styleUrls: ['./choose-printer.component.scss']
})
export class ChoosePrinterComponent implements OnInit {

  adjusterId: string;

  selectedPrinterId: string;
  assignedPrinters: PrinterInfo[];
  foundAssignedPrinter: boolean = false;
  errorOccured: boolean = false;
  errorResponse: ErrorResponse;

  constructor(private storageService: StorageService, private restUtil: CheckWriterService, public activeModal: NgbActiveModal, private router: Router) { }

  ngOnInit() {
    if (!this.storageService.get("User_Id")) {
      this.router.navigateByUrl('/login/checkWriter');
    } else {
      this.adjusterId = JSON.parse(this.storageService.get("User_Id"));
      console.log("Get Assigned GCP Printers for Adjuster : " + this.adjusterId);
      this.restUtil.getAdjusterAssignedPrinters(this.adjusterId).subscribe((printers) => {
        if (printers && printers.length > 0) {
          printers.forEach(printer => {
            console.log(printer.toString());
          });
          this.foundAssignedPrinter = true;
          this.errorOccured = false;
          this.assignedPrinters = printers;
        } else {
          console.log("0 Printers returned.");
          this.foundAssignedPrinter = false;
          this.errorOccured = true;
          this.errorResponse = new ErrorResponse();
          this.errorResponse.message = "Adjuster has no assigned Printers, please register printer prior to Print.";
        }
      }), error => {
        this.foundAssignedPrinter = false;
        this.errorOccured = true;
        console.log("Error occured with Get Adjuster Printers API.");
        this.errorResponse = this.restUtil.handleErrorResponse(error);
      };
    }
  }

  onPrinterSelect(printer: PrinterInfo) {
    console.log("Selected Printer : " + printer.toString());
    // store user selected printer in case needed for void and retry.
    this.storageService.save(this.adjusterId+"_printer", printer.printerId);
    this.activeModal.close(printer);
  }

  onCancel() {
    console.log("Adjuster cancelled on choose Printer");
    this.activeModal.close("Adjuster closed Choose Printer Modal");
  }

}
