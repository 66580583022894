<div class="container" align="center" id="adjusterLogViewContainer">
  <div align="center" style="width: 40%;">
    <h3 style="color: #FF6319" class="py-3" id="header">Log View</h3>
  </div>
  <div *ngIf="isErrorOccured" style="width: 40%;">
    <div class="alert alert-danger">
      <strong>Error! </strong>{{ errorResponse.message }}
    </div>
  </div>
  <div *ngIf="paymentUploaded" style="width: 40%;">
    <div class="p-3 mb-2 bg-info text-white">
      {{ paymentUploadedResp.statusMessage }}
    </div>
  </div>
  <div class="form-group" align="right" style="width: 100%">
    <button type="button" class="btn btn-warning  font-weight-bold but-blue" 
    (click)="openRetryPaymentsModal()" >Retry Payment Upload</button>
  </div>
  <div class="mat-elevation-z8 mat-table-container">
      <mat-table #table [dataSource]="dataSource" matSort>

        <!-- Date Column -->
        <ng-container matColumnDef="date">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Date </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.date}} </mat-cell>
        </ng-container>
    
        <!-- Check# Column -->
        <ng-container matColumnDef="checkNumber">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Check # </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.checkNumber}} </mat-cell>
        </ng-container>
    
        <!-- Claim# Column -->
        <ng-container matColumnDef="claimNumber">
          <mat-header-cell *matHeaderCellDef  mat-sort-header> Claim # </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.claimNumber}} </mat-cell>
        </ng-container>

        <!-- PaymentType# Column -->
        <ng-container matColumnDef="paymentType">
          <mat-header-cell *matHeaderCellDef  mat-sort-header> Payment Type </mat-header-cell>
          <mat-cell *matCellDef="let element" title="{{element.paymentInfo}}"> {{element.paymentType}} </mat-cell>
        </ng-container>
    
        <!-- Payee Column -->
        <ng-container matColumnDef="payee">
          <mat-header-cell *matHeaderCellDef  mat-sort-header> Payee </mat-header-cell>
          <mat-cell *matCellDef="let element" title="{{element.coPayee_1}} - {{element.coPayee_2}} - {{element.coPayee_3}}"> {{element.payee}} </mat-cell>
        </ng-container>
      
         <!-- Amount Column -->
        <ng-container matColumnDef="amount">
          <mat-header-cell *matHeaderCellDef  mat-sort-header> Amount </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.amount}} </mat-cell>
        </ng-container>
    
        <!-- Print Status Column -->
        <ng-container matColumnDef="printStatus">
          <mat-header-cell *matHeaderCellDef  mat-sort-header> Print Status </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.printStatus}} </mat-cell>
        </ng-container>
    
        <!-- Payment Status Column -->
        <ng-container matColumnDef="paymentStatus">
          <mat-header-cell *matHeaderCellDef  mat-sort-header> Payment Status </mat-header-cell>
          <mat-cell *matCellDef="let element" title="{{element.paymentMessage}}"> {{element.paymentStatus}} </mat-cell>
        </ng-container>
        
      
        <!-- IR Status Column -->
        <ng-container matColumnDef="irUploadStatus">
          <mat-header-cell *matHeaderCellDef  mat-sort-header> IR Status </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.irUploadStatus}} </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
      </mat-table>
  
      <mat-paginator #paginator 
          [pageSize]="10" 
          [showFirstLastButtons]="true">
      </mat-paginator>
  </div>
  <div *ngIf="apiCallInFlight" class="loaderContainer" style="height: 100%;">
    <div class="loader loaderContainer"></div>
  </div>
</div>
