<div class="container">
	<h1>Appraiser Detail</h1>

	<div class="appointment-container">
		<div class="appointment-detail appraiser-img" [hidden]="!showPictureUrl">
			<img [src]="getPictureUrl()" width="100px"/>
  		</div>
		<div>
			<div class="appraiser-name">{{appraiserFullName}}</div>
			<div class="appraiser-contact" [hidden]="!appraiserBusinessPhone">
				<div class="appraiser-detail-label">Business Phone: </div>
				<div class="appraiser-detail-value appraiser-phone"><a href="tel:{{scrubPhoneNumber(appraiserBusinessPhone)}}" class="appraiser-phone">{{formatPhoneNumber(appraiserBusinessPhone)}} <span class="glyphicon glyphicon-earphone"></span> </a></div>
				
			</div>
			<div class="appraiser-contact" [hidden]="!appraiserCellPhone">
				<div class="appraiser-detail-label">Cell Phone: </div>
				<div class="appraiser-detail-value appraiser-phone"><a href="tel:{{scrubPhoneNumber(appraiserCellPhone)}}" class="appraiser-phone">{{formatPhoneNumber(appraiserCellPhone)}} <span class="glyphicon glyphicon-earphone"></span></a></div>	
			</div>
			<div class="appraiser-contact" [hidden]="!appraiserEmail">
				<div class="appraiser-detail-label">Email: </div>
				<div class="appraiser-detail-value appraiser-phone"><a href="mailto:{{appraiserEmail}}" class="appraiser-phone">{{appraiserEmail}} <span class="glyphicon glyphicon-envelope"></span></a></div>	
			</div>
			
		</div>
	</div>
</div>
<!--end home-columns-group-->
