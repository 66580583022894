import { Router } from '@angular/router';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { EdispatchModule } from './edispatch/edispatch.module';
import { AppRoutingModule } from './app-routing.module';
import { CheckWriterMakePaymentComponent } from './check-writer/make-payment/make-payment.component';
import { CheckWriterService } from './check-writer/shared/services/check-writer.service';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
//import { HttpErrorHandler } from './check-writer/shared/services/http-error-handler.service';
//import { MessageService } from './check-writer/shared/services/message.service';
import { AddNoteComponent } from './check-writer/add-note/add-note.component';
import { AddMemoComponent } from './check-writer/add-memo/add-memo.component';
import { VendorComponent } from './check-writer/vendor/vendor.component';
import { LoadingIndicatorService } from './check-writer/shared/services/loading-indicator.service';
import { LoadingIndicatorInterceptor} from './check-writer/shared/services/LoadingIndicatorInterceptor';
import { PrintPreviewComponent } from './check-writer/print-preview/print-preview.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { PrintConfirmComponent } from './check-writer/print-confirm/print-confirm.component';
import { ProfileComponent } from './check-writer/profile/profile.component';
import { StorageServiceModule } from 'ngx-webstorage-service';
import { LogViewComponent } from './check-writer/log-view/log-view.component';
import { AngularMaterialModule } from '../material-module';
import { NewVendorComponent } from './check-writer/new-vendor/new-vendor.component';
import { NewVendorPreviewComponent } from './check-writer/new-vendor-preview/new-vendor-preview.component';
import { ChoosePrinterComponent } from './check-writer/choose-printer/choose-printer.component';
import { CurrencyPipe, DecimalPipe } from '@angular/common';
import { AgmCoreModule } from '@agm/core';
import { RetryPaymentComponent } from './check-writer/retry-payment/retry-payment.component';
import { CustomPrintComponent } from './check-writer/custom-print/custom-print.component';
import { TabViewModule } from 'primeng/tabview';
import { AdminComponent } from './check-writer/admin/admin.component';
import { InputTextModule } from 'primeng/inputtext';
import { RadioButtonModule } from 'primeng/radiobutton';
import { CheckboxModule } from 'primeng/checkbox';
import { ButtonModule } from 'primeng/button';
import { PaymentOptionsComponent } from './check-writer/payment-options/payment-options.component';
import { MessageService} from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { TableModule } from 'primeng/table';
import { EzeepPrintDemoComponent } from './check-writer/ezeep-print-demo/ezeep-print-demo.component';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { MultiSelectModule } from 'primeng/multiselect';
import { HeaderComponent } from './shared/header/header.component';
import { environment } from '../environments/environment';
import { EditorModule } from '@tinymce/tinymce-angular';

@NgModule({
  declarations: [
    AppComponent,
    CheckWriterMakePaymentComponent,
    AddNoteComponent,
    AddMemoComponent,
    VendorComponent,
    PrintPreviewComponent,
    PrintConfirmComponent,
    ProfileComponent,
    LogViewComponent,
    NewVendorComponent,
    NewVendorPreviewComponent,
    ChoosePrinterComponent,
    RetryPaymentComponent,
    CustomPrintComponent,
    AdminComponent,
    PaymentOptionsComponent,
    EzeepPrintDemoComponent
  ],
  imports: [
    BrowserModule,
    NoopAnimationsModule,
    HttpClientModule,
    FormsModule,
    SharedModule,
    AppRoutingModule,
    SharedModule,
    EdispatchModule,
    NgMultiSelectDropDownModule.forRoot(),
    NgbModule,
    PdfViewerModule,
    StorageServiceModule,
    AngularMaterialModule,
    TabViewModule,
    InputTextModule,
    RadioButtonModule,
    CheckboxModule,
    ButtonModule,
    ToastModule,
    TableModule,
    AutoCompleteModule,
    MultiSelectModule,
    EditorModule
  ],
  providers: [
    CheckWriterService,
    MessageService,
    LoadingIndicatorService,
    CurrencyPipe,
    DatePipe,
    DecimalPipe,
    HeaderComponent,
    {
      provide: HTTP_INTERCEPTORS,
      useFactory: (service: LoadingIndicatorService) => new LoadingIndicatorInterceptor(service),
      multi: true,
      deps: [LoadingIndicatorService]
    }
  ],
  entryComponents: [
    AddNoteComponent,
    AddMemoComponent,
    VendorComponent,
    PrintPreviewComponent,
    PrintConfirmComponent,
    NewVendorPreviewComponent,
    ChoosePrinterComponent,
    RetryPaymentComponent,
    CustomPrintComponent,
    PaymentOptionsComponent
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule { 
  // Diagnostic only: inspect router configuration
  constructor(router: Router) {
    console.log('Routes: ', JSON.stringify(router.config, undefined, 2));
  }  
}
