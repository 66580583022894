export class RentalNote {
    public specialNoteType: string = "general";
    public daysToRepair: number;
    public listofvalues: string[] = [];
    public claimNumber: string;
    public rentalNoteOptions: string;
    public dispatchID: number;
    public assignmentID: number;
    public noteText: string;
  
    constructor(){
        //Drop-down values
        for(let i = 1; i <= 31; i++) {
            this.listofvalues.push(i.toString());
          }
    }

    public getNotePayloadToSend(): string {
      return '{ "claimNumber" : "' + this.claimNumber + '", '
       // + '"rentalNoteOptions" : ' + this.rentalNoteOptions + ', ' 
        + '"tasks" : ["note","workItem"], '
        + '"taskType" : "' + this.specialNoteType + '", '
        + '"dispatchId" : ' + this.dispatchID.toString() + ', ' 
        + '"assignmentId" : ' + this.assignmentID.toString() + ', '
        + '"noteText" : "' + this.noteText + '"}';
    }
  }
