<div style="margin: 10px; border:2px darkslategray solid;">
<div id="new-vendor-page" style="padding-left: 20px; padding-right: 20px; background-color:white;">

  <table class="border1" style="width:100%;vertical-align: top;">
    <tbody>
      <tr>
        <td colspan="2">
          <img src="assets/img/praclogo.jpg/" alt="praclogo" align="top" height="30"></td>
        <td align="right"> <b>Claim Number: {{newVendor.claimNumber}}</b> </td>
      </tr>
      <tr>
        <td colspan="3">
          <hr>
        </td>
      </tr>
      <tr>
        <td colspan="3" style="text-align: center;font-weight:bold">
          IMPORTANT TAX INFORMATION-PLEASE READ CAREFULLY
        </td>
      </tr>

      <tr>
        <td colspan="3">
          Plymouth Rock is required to file Forms 1099 with respect
          to certain types of reportable payments, such as payments for
          services performed in connection with automobile repairs, home
          repairs, medical/health services, attorney's fees, legal fees,
          appraisal services, household services, transportation services,
          billing services, or interest paid. Our files indicate that payment
          will be made to you/your business for services provided to our
          insured/claimant and that you may receive a Form 1099 for the
          current tax year. Please provide the following information as
          certified on IRS records (please PRINT or TYPE):</td>
      </tr>
      <tr valign="top">
        <td>1.</td>
        <td>NAME CERTIFIED ON IRS RECORDS<br>
          <div style="font-weight:bold">(Either Owner's
            Name or Corporate Name)</div>
        </td>
        <td align="left">{{newVendor.vendorName}}
          <hr>
        </td>
      </tr>
      <tr valign="top">
        <td>2.</td>
        <td>DOING BUSINESS AS NAME(DBA):</td>
        <td>{{newVendor.dbaName}}
          <hr>
        </td>
      </tr>
      <tr valign="top">
        <td>3.</td>
        <td>STREET ADDRESS:</td>
        <td>{{newVendor.street1}}
          <hr>
          <br>
          {{newVendor.street2}}
          <hr>
        </td>
      </tr>
      <tr valign="top">
        <td></td>
        <td>CITY,STATE,ZIP Code:</td>
        <td>{{newVendor.city}}, {{newVendor.state}} {{newVendor.getZipCode()}}
          <hr>
        </td>
      </tr>
      <tr valign="top">
        <td>4.</td>
        <td>R.S.#:</td>
        <td>
          {{newVendor.rsNumber}}
          <hr>
        </td>
      </tr>
      <tr>
        <td valign="top">5.</td>
        <td colspan="2"><b>Federal Taxpayer Identification Number (9
            digits):</b> If business or corporation, please furnish the taxpayer
          identification number of the business or corporation shown in item
          1, above. If individual business owner, please furnish social
          security number.</td>
      </tr>
      <tr>
        <td></td>
        <td>Employee Identification Number(EIN)<br>
          OR <br> Social Security Number(SSN):</td>
        <td>{{newVendor.tempTaxId}}<br>
          <br>
          {{newVendor.ssn}}
        </td>
      </tr>
      <tr>
        <td valign="top"> 6.</td>
        <td>
          <div style="font-weight:bold">Type of entity(check All those that apply):</div>
        </td>
        <td></td>
      </tr>
      <tr>
        <td></td>
        <td colspan="2">
          <table style="width: 100%;">
            <tbody>
              <tr>
                <td>
                  <div id="soleF"><u>&nbsp;{{newVendor.soleProp}}&nbsp;</u></div>
                </td>
                <td>Sole proprietorship/individual
                </td>
                <td>
                  <div id="medPF"><u>&nbsp;{{newVendor.healthProvider}}&nbsp;</u></div>
                </td>
                <td>Medical/health provider</td>
                <td>
                  <div id="taxF"><u>&nbsp;&nbsp;&nbsp;</u></div>
                </td>
                <td>Tax-exempt/non profit organization</td>
              </tr>
              <tr>
                <td>
                  <div id="corpF"><u>&nbsp;{{newVendor.corporate}}&nbsp;</u></div>
                </td>
                <td>Corporation</td>
                <td>
                  <div id="medCF"><u>&nbsp;&nbsp;&nbsp;</u></div>
                </td>
                <td> Medical/health corporation</td>
                <td>
                  <div id="govtF"><u>&nbsp;&nbsp;&nbsp;</u></div>
                </td>
                <td>Government agency</td>
              </tr>
              <tr>
                <td>
                  <div id="partF"><u>&nbsp;{{newVendor.partner}}&nbsp;</u></div>
                </td>
                <td>Partnership</td>
                <td>
                  <div id="attrF"><u>&nbsp;{{newVendor.attorney}}&nbsp;</u></div>
                </td>
                <td> Attorney</td>
                <td>
                  <div id="othrF"><u>&nbsp;&nbsp;&nbsp;</u></div>
                </td>
                <td>Other (specify)</td>
              </tr>

            </tbody>
          </table>
        </td>
      </tr>
      <tr>
        <td> 7.</td>
        <td colspan="2"><b>Certification:</b> Under penalties of perjury, I certify that: </td>

      </tr>
      <tr>
        <td></td>
        <td colspan="2"> 1)The number shown on this form is my correct taxpayer identification number (or I am waiting
          for a number to be issued to me), and</td>
      </tr>
      <tr>
        <td></td>
        <td colspan="2"> 2) I am not subject to backup withholding because: (a) I am exempt from backup withholding, or
          (b) I have not been notified by the Internal Revenue Service (IRS) that I am subject to backup withholding as
          a result of a
          failure to report all interest or dividends, or (c) the IRS has notified me that I am no longer subject to
          backup withholding, and</td>
      </tr>
      <tr>
        <td></td>
        <td colspan="2"> 3) I am a U.S. person (including a U.S. resident alien).
        </td>
      </tr>
      <tr>
        <td colspan="3"><br><b>Certification Instructions:</b> You must cross out item 2 above if you have been
          notified by the IRS that you are currently subject to backup withholding because you have failed to
          report all interest and dividends on your tax return. For real estate transactions, item 2 does not apply.
          For mortgage interest paid, acquisition or abandonment of secured property, cancellation of debt,
          contributions to an individual retirement arrangement (IRA), and generally, payments other than interest and
          dividends,
          you are not required to sign the Certification, but you must provide your correct TIN.</td>
      </tr>
      <tr>
        <td colspan="3" style="font-weight:bold">Return Instructions: Please return this IRS document to your Claims
          adjuster by mail or fax. Fax #:
          ({{newVendor.phone1}}) {{newVendor.phone2}}-{{newVendor.phone3}}
          <br> <br></td>
      </tr>
      <tr>
        <td colspan="3">
          <table style="width:100%;">
            <tbody>
              <tr>
                <td width="15%">Signature</td>
                <td width="20%" align="left">&nbsp;
                  <hr>
                </td>
                <td width="15%" align="right">Date</td>
                <td align="left">{{newVendor.vendorAddDate}}
                  <hr>
                </td>
                <td width="15%" align="right">Phone Number</td>
                <td>({{newVendor.phone1}}) {{newVendor.phone2}}-{{newVendor.phone3}}
                  <hr>
                </td>
              </tr>
              <tr>
                <td>Print or Type name</td>
                <td>&nbsp;
                  <hr>
                </td>
                <td width="15%" align="right">Title</td>
                <td align="left">
                  <hr>
                </td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
    </tbody>
  </table>
</div>
<div align="center" style="width:100%">
  <table>
    <tbody>
      <tr>
        <td>
          <a autofocus (click)="onPrint()" style="background-color: #FF6319;color:white;padding: 15px 28px; text-align: center;text-decoration: none;display: inline-block;font-size: 16px;margin: 4px 2px;cursor: pointer;">Print</a>
        </td>
        <td>
          <a autofocus (click)="clsoePreviewNewVendor()" style="background-color:#87928c;color:white;padding: 15px 28px; text-align: center;text-decoration: none;display: inline-block;font-size: 16px;margin: 4px 2px;cursor: pointer;">Cancel</a>
        </td>
      </tr>
    </tbody>
  </table>
</div>
</div>