export class ClaimDispatchEstimate {
    dispatchId: number;
    sequenceNumber: number;
    amount: number;
    typeCode: string;
    typeDesc: string;
    totalLossInd: string;
    inspectionTypeCode: string;
    inspectionTypeDesc: string;
    claimNumber: string;
    policyNumber: string;
    claimantType: string;
    constructor(){};
}
