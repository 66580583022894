<div class="modal-header" style="justify-content : center;">
  <h4 id="modal-basic-title">Check Writer Print Message</h4>
</div>
<div class="modal-body" align="center">
  <div class="form-group" style="width: 80%;">
    <div class="row">
      <div class="col-md-12" align="left">
        <div class="alert alert-danger">Please follow below steps to Print Check for Claim : {{claimNumber}}. </div>
        <ul>
          <li>Check will be opened in new tab.</li>
          <li>Select appropriate printer and make sure the printer is accessible to collect the printed check.</li>
          <li>Do not forget to close the new tab after printing the check.</li>
          <li>Please confirm the Print status after closing the new tab to submit the payment or void the payment.</li>
        </ul>
        <strong>Please click Show Check PDF button to proceed to Print Check, otherwise Cancel to go {{onCancelDestination}}.</strong>
      </div>
    </div>
    <div class="row">&nbsp;</div>
    <div class="row">
      <div class="col-md-12">
        <button type="button" class="btn btn-warning  font-weight-bold" style="background-color:#FF6319; width : 50%;"
          (click)="activeModal.close('Enable Fallback')">Show Check PDF</button>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer" style="justify-content : center;" >
  <a (click)="onCancel()" style="text-decoration-line: underline;">Cancel</a>
</div>