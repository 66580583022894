  // just an interface for type safety.
  export interface oneMarker {
    lat: number;
    lng: number;
    info?: boolean;
    label?: string;
    message?: string;
    dynamicMessage?: string;
    draggable?: boolean;
    isClicked?: boolean;
    iconUrl?: string;
    iconSize?: any;
    distanceFromCenter?: number;
    googleMapsUrl?: string;
    claimNumber?: string;
    dispatchId?: string;
    appointmentId?: string;
    lob?: string;
    appraiserType?: string;
    age?: string;
    appraiserName?: string;
    channel?: string;
    startDate?: string;
    startTime?: string;
    status?: string;
    appraiserId?: number;
    locationType?: string;
    tag?: string;
    route?: any;
    appt?: Appointment;
    locAppt?: Appointment;
    driveable?: string;
  }

  export interface oneCircle {
    latitude: number;  
    longitude: number; 
    radius: number;
    radiusInMiles: number;
    editable: boolean;
    fillColor: string;
    fillOpacity?: number;
  }

  export interface oneDirMarker {
    lat: number;
    lng: number;
    label?: string;
  }

  export interface oneWaypoint {
    location?: oneDirMarker;
  }

  export interface oneDirection {
    origin?: oneDirMarker;
    destination?: oneDirMarker;
    waypoints?: oneWaypoint[];
  }

  export interface oneCustomSvgMarker {
    svg: string;
    scaledSize: any;
    anchor: any;
  }

  export interface oneFilter {
    AttributeValueDisplayName: string; 
    AttributeValue: string;
    FilterType: string;
    Id?: number;
    GroupHeader?: string;
  };

  export interface oneFilterTag {
    Tag: string;
    Filters: oneFilter[];
    AtServer?: boolean;
  }
  
  export interface oneGroupedFilter {  
      label: string; 
      value: string;
      items?: oneFilter[];
  };

  export class filterFieldsMapping{
    public locationTypeAPPRAISER: any = {
      label: "Type", 
      modifier: undefined, 
      forcedFilters: "[{ AttributeValue: 'type|donotshow', AttributeValueDisplayName: 'Display: OFF', GroupHeader: 'Type', FilterType: 'APPRAISER'}]",
      display: false
    }
    public locationTypeASSIGNMENT: any = {
      label: "Type", 
      modifier: undefined, 
      forcedFilters: "[{ AttributeValue: 'type|donotshow', AttributeValueDisplayName: 'Display: OFF', GroupHeader: 'Type', FilterType: 'ASSIGNMENT'}]",
      display: false
    }      
    public lob: any = {
      label: "LOB", 
      modifier: undefined
    }                
    public status: any = {
      label: "Status", 
      modifier: undefined
    }
    public statusASSIGNMENT: any = {
      label: "Status", 
      modifier: undefined
    }
    public statusAPPRAISER: any = {
      label: "Profile", 
      modifier: undefined
    }    
    public channel: any = {
      label: "Channel", modifier: undefined
    }
    public channelAPPRAISER: any = {
      label: "Office", modifier: undefined
    }        
    public appraiserType: any = {
      label: "Appr Grp", modifier: undefined
    }
    public age: any = {
      label: "Asgn/Update", modifier: undefined
    }
    public ageAPPRAISER: any = {
      label: "Seen Last", modifier: undefined
    }
    public ageASSIGNMENT: any = {
      label: "Asgn Age", modifier: undefined
    }        
    public startDate: any = {
      label: "Appt Date", 
      modifier: undefined, 
      forcedFilters: "[{ AttributeValue: 'startDate|TODAY', AttributeValueDisplayName: 'Appt Date: TODAY', GroupHeader: 'Appt Date', FilterType: 'ASSIGNMENT'}, { AttributeValue: 'startDate|TOMORROW', AttributeValueDisplayName: 'Appt Date: TOMORROW', GroupHeader: '', FilterType: 'ASSIGNMENT'}]"
    }
    public startDateAPPRAISER: any = {
      label: "Seen Date", 
      modifier: undefined
    }    
    public startTime: any = {
      label: "Appt Time", 
      modifier: undefined, 
      forcedFilters: "[{ AttributeValue: 'startTime|MORNING', AttributeValueDisplayName: 'Appt Time: MORNING', GroupHeader: 'Appt Time', FilterType: 'ASSIGNMENT'}, { AttributeValue: 'startTime|AFTERNOON', AttributeValueDisplayName: 'Appt Time: AFTERNOON', GroupHeader: '', FilterType: 'ASSIGNMENT'}, { AttributeValue: 'startTime|ALL DAY', AttributeValueDisplayName: 'Appt Time: ALL DAY', GroupHeader: '', FilterType: 'ASSIGNMENT'}]"
    }
    public startTimeAPPRAISER: any = {
      label: "Seen Time", 
      modifier: undefined,
      display: false
    }          
    public appraiserName: any = {
      label: "Appraiser", 
      modifier: undefined, 
      forcedFilters: "[{ AttributeValue: 'appraiserName|' + this.userName, AttributeValueDisplayName: 'Appraiser: ' + this.userName, GroupHeader: 'Appraiser', FilterType: 'ASSIGNMENT/APPRAISER'}]"
    }    
    //modifier can be dynamic evaluated functions, just like forcedFilters are (strings evaluated as javascript to be returned by dynamically created function)
    //public age: any = {label: "Asgn Age", modifier: "(-1 * fieldValue).toString() + ' days'"}
    public claimNumber = {
      label: "Claim", 
      modifier: undefined
    }
    public driveable: any = {
      label: "Driveable", 
      modifier: undefined
    }
    public route: any = {
      label: "Route", 
      modifier: undefined, 
      forcedFilters: "[{ AttributeValue: 'route|none', AttributeValueDisplayName: 'Route: NONE', GroupHeader: 'Route', FilterType: 'ASSIGNMENT/APPRAISER'},{ AttributeValue: 'route|origin', AttributeValueDisplayName: 'Route: START', GroupHeader: '', FilterType: 'ASSIGNMENT/APPRAISER'},{ AttributeValue: 'route|destination', AttributeValueDisplayName: 'Route: END', GroupHeader: '', FilterType: 'ASSIGNMENT/APPRAISER'},{ AttributeValue: 'route|waypoint', AttributeValueDisplayName: 'Route: STOP', GroupHeader: '', FilterType: 'ASSIGNMENT/APPRAISER'}]"
    }
    
  }

  export class keyfilters {
    usZip: RegExp = /(^\d{5}(-\d{4})?$)|(^[ABCEGHJKLMNPRSTVXY]{1}\d{1}[A-Z]{1} *\d{1}[A-Z]{1}\d{1}$)/;
    phoneNumber: RegExp = /^\+?(\d.*){3,}$/; 
    userName: RegExp = /^[a-zA-Z0-9_-]{3,16}$/; 
    email: RegExp = /^.+@.+$/; 
    normalChars: RegExp = /[A-Za-z0-9'\.\-\s\,]/;
    upperChars: RegExp = /[A-Z0-9'\.\-\s\,]/;
    dtLoadFind: RegExp = /(\d{4})(\d{2})(\d{2})/;
    dtLoadReplace: String = '$1/$2/$3';
    dtSaveFind: RegExp = /(\d{2})\/(\d{2})\/(\d{4})/;
    dtSaveReplace: String = '$3$1$2';
  }

  export enum iconType { 
    default = "",
    current = "./assets/img/map/Dev/home_pin.svg",
    home = "./assets/img/map/Dev/HomeClaim_Blue_BlackBorder.png",
    auto = "./assets/img/map/Dev/Crashbuster_Blue_BlackBorder.png",
    other = "./assets/img/map/Dev/map-pin.svg",
    purpleCar = "./assets/img/map/Dev/PurpleCar.svg",
    pinkCar = "./assets/img/map/Dev/PinkCar.svg",
    orangeCar = "./assets/img/map/Dev/OrangeCar.svg",
    greenCar = "./assets/img/map/Dev/GreenCar.svg",
    yellowCar = "./assets/img/map/Dev/YellowCar.svg",
    turquoiseCar = "./assets/img/map/Dev/TurquoiseCar.svg",
    appraiser = "./assets/img/map/Dev/AutoAppraiser_Blue_BlackBorder.png"
  }

  export enum messageType {
    dispatchActions = 1 ,
    defaultMessage = 2
  }

  export enum dateFormatType {
    ISO8601 = 1,
    ODBC = 2,
    ISO = 3,
    DATETIME_HOURS_ONLY = 4,
    DATETIME_MINS_ONLY = 5,
    ISO08601_DATE_ONLY = 6,
    ISO08601_TIME_ONLY = 7
  }

export class Appointment{

    public appointmentID: string;
    public claimantId: string;
    public appointmentTime: string;
    public assignmentDate: string;
    public travelTime: string;

    public insuredName: string;
    public startDateTime: Date;
    public startDate: string;
    public startTime: string;
    public endDateTime: Date;
    public endDate: string;
    public endTime: string;
    public appointmentTimeFormatted: string;
    public assignDateTime: Date;
    public assignDateTimeFormatted: string;
    public insertTmstmp: string;
    public dispatchStatus: string;
    public insuredAddress: string;
    public policyNumber: string;
    public claimNumber: string;
    public claimType: string;
    public lossDate: string;
    public lossDateObject: Date;
    public noticeDate: string;
    public claimOwner: string;
    public vehicleYear: string;
    public vehicleMakeModel: string;
    public vehicleColor: string;
    public vehiclePlate: string;
    public vehicleVin: string;
    public damageDetail: string;
    public damagedParts: string;
    public coverages: string;
    public endorsements: string;
    public deductible: string;
    public policyDeductible: string;
    public deductibleInstr: string;
    public payStatus: string;
    public rentalLimit: string;
    public inspectionLocation: string;
    public inspectionLocationDesc: string;
    public accidentDesc: string;
    public contactName: string;
    public contactPhoneDay: string;
    public contactPhoneNight: string;
    public contactPhoneOther: string;
    public dtdServices: string;
    public underwritingCo: string;
    public secondInsuredName: string;
    public dateOfNotice: string;
    public owner: string;
    public ownerAddress: string;
    public ownerBusPhone: string;
    public ownerBusPhones: string[];
    public ownerResPhone: string;
    public ownerResPhones: string[]; 

    public lienHolder: string;
    public damageEstimateAmount: string;
    public inspectionEstimateAmount: string;
    public driveableInd: string;
    public notes: string;
    public claimSequence: string;
    public vendorSequence: string;
    public claimantNumber: string;
    public callerContactInfo: string;
    public claimantName: string;
    public appraiserName: string;
    public id4Txt: string;
    public id6: string;
    //Process Multiple Owner Phones
    //ownerBusPhone:string[]; 
    public claimOwnerPhone: string;
    public claimOwnerPhones: string[];
    public appointmentDetailHtml: string;
    public description: string;
    public locationAddrLn1Txt: string;
    public locationCityName: string;
    public locationStateCd: string;
    public locationZipCd: string;     
   
    public status:string;
    public distanceText:string;
    public durationText:string;
    public travelText:any;

    public age: string;
    public locationLat: number;
    public locationLong: number;
    public lob: string;
    public distance: number;
    public appraiserType: string;
    public unitId: number;
    public appraiserId: number;
    public channel: string;
    public locationType: string;
    public iconUrl: string;
    public iconSize: string;
    public dataChanged: boolean;
    public locationChanged: boolean;
    public driveable: string;
}

export class SpinnerAppraiser {
  public assignTypeCd: string;
  public amLimitAmt: string;
  public companies: string;
  public parties: string;
  public coverages: string;
  public fullDayLimitAmt: string;
  public fullName: string;
  public intervalCountToday: string;
  public intervalCountYearMonthDay: string;
  public intervalCountYearWeek: string;
  public lobs: string;
  public losstypes: string;
  public offices: string;
  public pmLimitAmt: string;
  public referenceDate: string;
  public regionLob: string;
  public regions: string;
  public specialScheduleEvents: string;
  public specialtyMake: string;
  public streamRegionCd: string;
  public streamUserId: string;
  public suspendUserInd: string;
  public userId: string;
  public usergroups: string;
  public vendors: string;
  public workHolidayInd: string;
  public workSaturdayInd: string;
  public workSundayInd: string   
}