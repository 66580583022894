import { PaymentDetails } from "./payment-details";

export class PaymentTransaction {
    printerId: string;
    printStatus: string;
    checkSeqNumber: string;
    checkTransId: string;
    paymentDetails: PaymentDetails;
    transactionType: string;
    typeOfPayment: string = "M";
    dispatchId: string;
    paymentTransactionType: string;
    paymentAccount: string;
    paymentSourceSystem : string;
    constructor() { }

    toString() {
        return ("PaymentTransaction : transactionType - " 
        + this.transactionType + ", typeOfPayment - " 
        + this.typeOfPayment + ", dispatch id - " 
        + this.dispatchId + ", PayType - "
        + this.paymentTransactionType + ", payAccount - "
        + this.paymentAccount + ", payment - "
        + this.paymentDetails.toString());
    }
}
