export class PrinterInfo {
    adjusterId: string;
    printerId: string;
    printerName: string;
    printerGCPId: string;
    cloudType : string;
    cloudAccount : string;
    active : boolean;
    constructor() { }

    toString() {
        return "Adjuster ID: " + this.adjusterId + ", Printer Id: " + this.printerId + ", printer name : " + this.printerName + " and is Active : " + this.active;
    }
}
