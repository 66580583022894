<block-ui #block class="block-ui">
  <div class="container">
    <div class="basic-container">
        <div class="mat-app-background">
          <mat-button-toggle-group name="navigationButtons1">
            <mat-button-toggle value="all" (change)="backToAppointmentsList()">My Asgns List</mat-button-toggle>
            <mat-button-toggle value="scheduled"  (change)="getMyAppointments()">My Asgns</mat-button-toggle>
            <mat-button-toggle value="scheduled"  (change)="clearAllFilters()">Clear Filters</mat-button-toggle>
            <mat-button-toggle value="locassign" (change)="getAppointmentsByLocation()">Refresh</mat-button-toggle>          
          </mat-button-toggle-group> 
        </div>          
        <div class="mat-app-background">
          <mat-button-toggle-group name="navigationButtons2">
            <mat-button-toggle value="route" (change)="getDirections()">Route</mat-button-toggle>
            <mat-button-toggle value="autoroute" (change)="getAutomaticDirections()">Route All</mat-button-toggle>
            <mat-button-toggle value="autoroute" (change)="clearDirections()">Clear Route</mat-button-toggle>          
            <mat-button-toggle value="reset" (change)="resetZoom()">Reset View</mat-button-toggle>
          </mat-button-toggle-group> 
        </div>          
    </div>   
    <div class="mat-app-background ui-g basic-container">
      <div class="ui-g-4 ui-lg-4 ui-md-8 ui-sm-8">
        <span class="ui-widget">Appr Filters (matched {{filterApprMatchCount}}{{(filterApprMaxCount != filterApprMatchCount ? '/' + filterApprMaxCount : '')}})</span>
        <p-multiSelect #mfAPPRAISER
          class="multiselect-filter"
          [options] = "filtersAPPRAISER"
          [(ngModel)]="selectedFiltersAPPRAISER"
          [maxSelectedLabels] = "10"
          defaultLabel = "Select Filter(s)"
          optionLabel = "AttributeValueDisplayName" 
          dataKey = "AttributeValue"
          appendTo = "body"
          baseZIndex = "2050"        
          (onChange)="onFilterSelected($event,'APPRAISER')">
          <p-header class=multiselect-filter-header>
            <button 
              type="button" 
              class="btn-primary multiselect-header-button-left" 
              (click)="clearFilters($event,'APPRAISER')">Clear
            </button>
          </p-header>
          <ng-template let-value pTemplate="selectedItems">
            <div *ngIf="selectedFiltersAPPRAISER && selectedFiltersAPPRAISER.length <= 20" class="multiselect-selection">
              <ng-container *ngFor="let filter of selectedFiltersAPPRAISER; let s=index;">
                <ng-container *ngIf="(s>0?selectedFiltersAPPRAISER[s-1].AttributeValueDisplayName.split(':')[0]:'') !== filter.AttributeValueDisplayName.split(':')[0]"><b>{{filter.AttributeValueDisplayName.split(':')[0]}}:</b></ng-container>{{filter.AttributeValueDisplayName.split(':')[1]}}; 
              </ng-container> 
            </div>
            <div class="multiselect-selection" *ngIf="selectedFiltersAPPRAISER && selectedFiltersAPPRAISER.length > 20">
              {{selectedFiltersAPPRAISER.length}} filters selected
            </div>        
            <div *ngIf="!selectedFiltersAPPRAISER || selectedFiltersAPPRAISER.length === 0">Select Filter</div>
          </ng-template>
          <ng-template let-filter let-i="index" pTemplate="item">
              <span>
                <span [ngClass]="(filter.value.GroupHeader === '' ? 'multiselect-line' : 'multiselect-header')">
                  <b>{{filter.value.AttributeValueDisplayName.split(':')[0]}}&nbsp;&nbsp;</b>
                </span>            
                <!-- <span style="font-size:12px;float: left"><b>{{filter.value.AttributeValueDisplayName.split(':')[0]}}&nbsp;&nbsp;</b></span>             -->
                <span class="multiselect-value">{{filter.value.AttributeValueDisplayName.split(':')[1]}}</span>
              </span>
          </ng-template>
        </p-multiSelect>   
      </div>     
      <div class="ui-g-2 ui-lg-2 ui-md-4 ui-sm-4">
        <span class="ui-widget">Max Asgns</span>
        <input
          type="text"
          class="form-control"
          (keydown.enter)="$event.preventDefault()"
          (change)="onMaxMarkersChange($event)"
          [value]="maxMarkers"
          autocorrect="off"
          autocapitalize="off"
          spellcheck="off"
          onClick="this.setSelectionRange(0, this.value.length)"
          #markersMax
        />
      </div>      
      <div class="ui-g-4 ui-lg-4 ui-md-8 ui-sm-8">
        <span class="ui-widget">Asgn Filters (matched {{filterAsgnMatchCount}}{{(filterAsgnMaxCount != filterAsgnMatchCount ? '/' + filterAsgnMaxCount : '')}})
        </span> 
        <p-multiSelect #mfASSIGNMENT
          class="multiselect-filter"
          [options] = "filtersASSIGNMENT"
          [(ngModel)]="selectedFiltersASSIGNMENT"
          [maxSelectedLabels] = "10"
          defaultLabel = "Select Filter(s)"
          optionLabel = "AttributeValueDisplayName" 
          dataKey = "AttributeValue"
          appendTo = "body"
          baseZIndex = "2050"        
          (onChange)="onFilterSelected($event,'ASSIGNMENT')">
        <p-header class=multiselect-filter-header>
          <button 
            type="button" 
            class="btn-primary multiselect-header-button-left" 
            (click)="clearFilters($event,'ASSIGNMENT')">Clear
          </button>
        </p-header>
        <ng-template let-value pTemplate="selectedItems">
          <div *ngIf="selectedFiltersASSIGNMENT && selectedFiltersASSIGNMENT.length <= 20" class="multiselect-selection">
            <ng-container *ngFor="let filter of selectedFiltersASSIGNMENT; let s=index;">
              <ng-container *ngIf="(s>0?selectedFiltersASSIGNMENT[s-1].AttributeValueDisplayName.split(':')[0]:'') !== filter.AttributeValueDisplayName.split(':')[0]"><b>{{filter.AttributeValueDisplayName.split(':')[0]}}:</b></ng-container>{{filter.AttributeValueDisplayName.split(':')[1]}}; 
            </ng-container> 
          </div>
          <div class="multiselect-selection" *ngIf="selectedFiltersASSIGNMENT && selectedFiltersASSIGNMENT.length > 20">
            {{selectedFiltersASSIGNMENT.length}} filters selected
          </div>        
          <div *ngIf="!selectedFiltersASSIGNMENT || selectedFiltersASSIGNMENT.length === 0">Select Filter</div>
        </ng-template>
        <ng-template let-filter let-i="index" pTemplate="item">
            <span>
              <span [ngClass]="(filter.value.GroupHeader === '' ? 'multiselect-line' : 'multiselect-header')">
                <b>{{filter.value.AttributeValueDisplayName.split(':')[0]}}&nbsp;&nbsp;</b>
              </span>            
              <!-- <span style="font-size:12px;float: left"><b>{{filter.value.AttributeValueDisplayName.split(':')[0]}}&nbsp;&nbsp;</b></span>             -->
              <span class="multiselect-value">{{filter.value.AttributeValueDisplayName.split(':')[1]}}</span>
            </span>
        </ng-template>
        </p-multiSelect>   
      </div>
      <div class="ui-g-2 ui-lg-2 ui-md-4 ui-sm-4">
        <span class="ui-widget">Filter Groups</span>
        <p-multiSelect #mt
          class="multiselect-tag"
          [options] = "filterTags"
          [(ngModel)]="selFilterTags"
          [maxSelectedLabels] = "10"
          defaultLabel = "Tag(s)"
          optionLabel = "Tag" 
          dataKey = "Tag"
          appendTo = "body"
          baseZIndex = "2050"        
          (onChange)="onTagSelected($event)">
          <p-header class="multiselect-tag-header">
            <p-splitButton label="New" (onClick)="createTag(false)" [model]="tagMenuItems" styleClass="ui-button-primary" class="multiselect-header-button-right"></p-splitButton>
          </p-header>
          <ng-template let-value pTemplate="selectedItems">
            <div *ngIf="selFilterTags && selFilterTags.length <= 5" class="multiselect-selection">
              <ng-container *ngFor="let tag of selFilterTags">{{tag.Tag + (tag.AtServer ? ' (@Server)' : '')}}; </ng-container>  
            </div>
            <div class="multiselect-selection" *ngIf="selFilterTags && selFilterTags.length > 5">
              {{selFilterTags.length}} groups
            </div>        
            <div *ngIf="!selFilterTags || selFilterTags.length === 0">Select Group</div>
          </ng-template>
          <ng-template let-tag let-i="index" pTemplate="item">
            <span>           
              <span class="multiselect-tag">{{tag.value.Tag}}</span>
              <span [ngClass]="(tag.value.AtServer ? 'multiselect-tag-server' : 'multiselect-tag-local')">
                &nbsp;{{(tag.value.AtServer ? '(@Server)' : '')}}
              </span>               
            </span>
        </ng-template>                            
        </p-multiSelect>                  
      </div>        
    </div>
    <div class="ui-g basic-container">
      <div *ngIf="showDirections" class="dirPanel ui-g-12 ui-lg-12 ui-md-12 ui-sm-12" [innerHTML]="directionsSummary"></div>
      <div [ngClass]="showDirections && directionsValidated ? 'ui-g-9 ui-lg-9 ui-md-12 ui-sm-12' : 'ui-g-12 ui-lg-12 ui-md-12 ui-sm-12'">
        <agm-map 
          #map
          class="agm-map"
          [latitude]="lat" 
          [longitude]="lng" 
          [zoom]="zoom" 
          [disableDefaultUI]="false" 
          (mapReady)="mapReadyHandler($event)">
          <agm-marker-cluster imagePath="./assets/img/map/markerclusterer/m">
            <agm-marker
                *ngFor="let m of filteredMarkers; let i = index"
                (markerClick)="markerClicked({m:m, $event:$event, i:i, infoWindow: infoWindow})"
                [latitude]="m.lat"
                [longitude]="m.lng"
                [label]="m.label"
                [markerDraggable]="m.draggable"
                (dragStart)="markerDragStart(m, $event, infoWindow)"
                (dragEnd)="markerDragEnd(m, $event, infoWindow)"
                (mouseOver)="markerMouseOver(m, $event, infoWindow)" 
                (mouseOut)="markerMouseOut(m, $event, infoWindow)"      
                [iconUrl]="
                {
                  url: m.iconUrl,
                  scaledSize: {
                      width: (m.iconSize ? m.iconSize.width : defaultMarkerIconSize.width),
                      height: (m.iconSize ? m.iconSize.height : defaultMarkerIconSize.height)
                  }
                }"      
              >
              <agm-info-window
                #infoWindow 
                [latitude]="m.lat" 
                [longitude]="m.lng"
                (infoWindowClose)="onInfoWindowClose($event, m)"
                [isOpen]="m.isClicked">
                <div class="info-window" *ngIf="m.locAppt">
                  <strong><u>{{(m.locAppt.locationType==='ASSIGNMENT'?'CLAIM ' + m.locAppt.claimNumber + '-' + m.locAppt.claimSequence + '-' + m.locAppt.vendorSequence:'')}}</u></strong> 
                  <ng-container *ngIf="m.locAppt.locationType !== 'ASSIGNMENT'"><br><b>Appraiser:&nbsp;</b><font color='green'>{{m.locAppt.appraiserName}}</font></ng-container>
                  <ng-container *ngIf="m.locAppt.locationType == 'ASSIGNMENT'">
                    <div class="info-window-button-row">
                      <b>Appr:&nbsp;</b>
                      <p-autoComplete class="info-window-autocomplete"                      
                      id="spinnerAppraisersLkup" #spinnerAppraisersLkup
                      placeholder="{{m.locAppt.appraiserName}}"
                      [(ngModel)]="appointmentService.selectedSpinnerAppraiser" 
                      field='fullName' 
                      dataKey='userId' 
                      [suggestions]="appointmentService.dataStore.spinnerAppraisers" 
                      (completeMethod)="spinAppraisers(m.appt, m, infoWindow)" 
                      [readonly] = "true"
                      [minLength]="1" 
                      [autoHighlight]="true" 
                      [forceSelection]="true" 
                      (onSelect)="onSpinnerAppraiserSelected($event, m.appt)" 
                      (onUnselect)="onSpinnerAppraiserSelected('-1', m.appt)" 
                      [multiple] = "false" 
                      [dropdown]="true">
                        <ng-template let-spinnerAppraiser pTemplate="item">
                          <div [pTooltip]=
                          "'&nbsp;' + spinnerAppraiser.fullName + '&lt;br&gt;&nbsp;•&nbsp;' + (spinnerAppraiser.assignTypeCd ? spinnerAppraiser.assignTypeCd + ', ' : '')
                            + 'group '
                            + spinnerAppraiser.usergroups.usergroup.userGroupCd 
                            + ', lob '
                            + spinnerAppraiser.regionLob 
                            + '&lt;br&gt;&nbsp;•&nbsp;asgned today-' + spinnerAppraiser.intervalCountToday 
                            + ' daily-' + spinnerAppraiser.intervalCountYearMonthDay 
                            + ' weekly-' + spinnerAppraiser.intervalCountYearWeek 
                            + '&lt;br&gt;&nbsp;•&nbsp;'.concat(
                            'am-' 
                            ,spinnerAppraiser.amLimitAmt
                            ,' pm-'
                            ,spinnerAppraiser.pmLimitAmt
                            ,' day-' 
                            ,spinnerAppraiser.fullDayLimitAmt
                            ,' sat-'
                            ,spinnerAppraiser.workSaturdayInd
                            ,' sun-'
                            ,spinnerAppraiser.workSundayInd
                            ,' hol-'
                            ,spinnerAppraiser.workHolidayInd
                            )" [tooltipPosition]="(googleMapFullscreen === true ? 'bottom' : 'top')" [escape]="false" [hideDelay]="0" [appendTo]="parentizeSpinnerTooltip()">
                          <span class="info-window-autocomplete tooltip-big-width">{{spinnerAppraiser.fullName}}</span></div>
                        </ng-template>                                                        
                      </p-autoComplete>                      
                      <button type="button" class="btn-primary info-window-button-right" [hidden]="m.label == centerLocationName || m.locationType !=='ASSIGNMENT' || m.appraiserId == (appointmentService.selectedSpinnerAppraiser ? appointmentService.selectedSpinnerAppraiser.userId : m.appraiserId)" (click)="transferToSpinnerAppraiser(m.appt, m, infoWindow)" [disabled]="isTransferDisabled">{{transferBtnLabel}}</button>      
                    </div>
                  </ng-container>                
                  <ng-container *ngIf="m.locAppt.locationType !== 'ASSIGNMENT'">&nbsp;&nbsp;&nbsp;&nbsp;
                    <b>&nbsp;&nbsp;&nbsp;&nbsp;LOB:&nbsp;</b>
                    <font color="#007ad9">{{m.locAppt.lob}}</font>                                    
                    <br>
                  </ng-container>
                  <!-- <ng-container *ngIf="m.locAppt.locationType === 'ASSIGNMENT'">&nbsp;&nbsp;&nbsp;&nbsp;</ng-container> -->
                  <b>{{(m.locAppt.locationType==='ASSIGNMENT'?'Status:':'Profile:')}}&nbsp;</b>
                    <font color="red">{{m.locAppt.status}}</font>          
                  <br><b>{{(m.locAppt.locationType==='ASSIGNMENT'?'Channel:':'Office:')}}&nbsp;</b>
                    <font color="#007ad9">{{m.locAppt.channel}}</font>
                  <b>&nbsp;&nbsp;&nbsp;&nbsp;{{(m.locAppt.locationType === 'ASSIGNMENT'?'LOB:':'Updated:')}}&nbsp;</b>
                    <font color="#007ad9">{{(m.locAppt.locationType==='ASSIGNMENT'?m.locAppt.lob:m.locAppt.startDate)}}</font>                  
                  <br><b>Group:&nbsp;</b>
                    <font color="#007ad9">{{m.locAppt.appraiserType}}</font> 
                  <b>&nbsp;&nbsp;&nbsp;&nbsp;{{(m.locAppt.locationType==='ASSIGNMENT'?'Asgn Age':'Update Age')+':'}}&nbsp;</b>
                    <font color="red">{{m.locAppt.age}}</font>                
                </div>

                <div class="info-window" [innerHTML]="m.message"></div>
                <div class="info-window" [innerHTML]="m.dynamicMessage"></div>
                
                <div class="info-window" *ngIf="m.appt">
                  <b>Claim Type:</b> {{m.appt.claimType}}
                  <br><b>Appt Start:</b><p-calendar 
                    #startDateTime
                    id="startDateTime" 
                    class="p-calendar info-window"
                    [disabled]="m.status == 'CLOSED' || m.appraiserId != (appointmentService.selectedSpinnerAppraiser ? appointmentService.selectedSpinnerAppraiser.userId : m.appraiserId)" 
                    [(ngModel)]="m.appt.startDateTime"
                    placeholder="(not set)"
                    showTime="true"
                    hourFormat="12"
                    [minDate]="currentDate"
                    [defaultDate]="currentDate"
                    [stepMinute]=15
                    [stepHour]=1
                    readonlyInput="true"
                    [appendTo] = "(googleMapFullscreen === true ? googleMap.getDiv().firstChild : 'body')"
                    baseZIndex="2050" 
                    showButtonBar="false" 
                    [showIcon]="false" [selectionMode] = "'single'" 
                    (onFocus)="onFocus($event)"                     
                    (onSelect)="onCalendarSelect($event, m, 'startDateTime')">
                  </p-calendar>               
                  <br><b>Appt End:</b><p-calendar
                    #endDateTime 
                    id="endDateTime" 
                    class="p-calendar info-window"
                    [disabled]="m.status == 'CLOSED' || m.appraiserId != (appointmentService.selectedSpinnerAppraiser ? appointmentService.selectedSpinnerAppraiser.userId : m.appraiserId)" 
                    [(ngModel)]="m.appt.endDateTime"
                    placeholder="(not set)"
                    showTime="true"
                    hourFormat="12"
                    [minDate]="currentDate"
                    [defaultDate]="m.appt.startDateTime"
                    [stepMinute]=15
                    [stepHour]=1
                    readonlyInput="true"
                    [appendTo] = "(googleMapFullscreen === true ? googleMap.getDiv().firstChild : 'body')" 
                    baseZIndex="2050" 
                    showButtonBar="false" 
                    [showIcon]="false" [selectionMode] = "'single'" 
                    (onFocus)="onFocus($event)" 
                    (onSelect)="onCalendarSelect($event, m, 'endDateTime')">
                  </p-calendar>               
                  <button type="button" class="btn-primary" [hidden]="m.label == centerLocationName || m.locationType !=='ASSIGNMENT' || m.appt.dataChanged == false" (click)="updateAppointment(m, infoWindow)">Update</button>
                  <br><b>Inspection Location:</b>
                  <br>&nbsp;&nbsp;&nbsp;&nbsp;{{m.appt.locationAddrLn1Txt}}<div class="curPosIconsAsgn"><img class="centerMarkerImgAsgn" src="./assets/img/map/Other/cur_loc.png" alt="Cur Loc" (click)="setCurrentCenter(m)"/><a target="_blank" rel="noopener noreferrer" [href]="'https://maps.google.com/?q=' + m.appt.inspectionLocation"><img class="dirImgAsgn" src="./assets/img/map/Other/directions.png" alt="Dir"/></a></div>
                  <br>&nbsp;&nbsp;&nbsp;&nbsp;{{m.appt.locationCityName}} {{m.appt.locationStateCd}}, {{m.appt.locationZipCd}} 
                </div>
                <div class="info-window info-window-selbutton">
                  <b>Route:&nbsp;</b>                                  
                  <p-selectButton appendTo = "body" baseZIndex = "2050" [options]="directionsOptions" [(ngModel)]="m.route" optionLabel="AttributeValueDisplayName" optionValue="AttributeValue" id={{m.appointmentId}} styleClass='buttonSize'></p-selectButton>                      
                </div>
                <div class="info-window" *ngIf="m.appt">  
                  <ng-container *ngIf="m.appt.contactName !== '' || m.appt.contactPhoneDay !== '() -' || m.appt.contactPhoneNight !== '() -' || m.appt.contactPhoneOther !== '() -'">
                    <b>Contact Info:</b>
                    <ng-container *ngIf="m.appt.contactName !== ''">
                      <br/>&nbsp;&nbsp;&nbsp;&nbsp;{{m.appt.contactName}} 
                    </ng-container>
                    <ng-container *ngIf="m.appt.contactPhoneDay !== '() -'">
                      <br/>&nbsp;&nbsp;&nbsp;&nbsp;Day Phone: <a [href]="'tel:' + m.appt.contactPhoneDay">{{m.appt.contactPhoneDay}}</a> 
                    </ng-container>
                    <ng-container *ngIf="m.appt.contactPhoneNight !== '() -'">
                      <br/>&nbsp;&nbsp;&nbsp;&nbsp;Night Phone: <a [href]="'tel:' + m.appt.contactPhoneNight">{{m.appt.contactPhoneNight}}</a> 
                    </ng-container>
                    <ng-container *ngIf="m.appt.contactPhoneOther !== '() -'">
                      <br/>&nbsp;&nbsp;&nbsp;&nbsp;Other Phone: <a [href]="'tel:' + m.appt.contactPhoneOther">{{m.appt.contactPhoneOther}}</a> 
                    </ng-container>                                                                       
                  </ng-container> 
                  <ng-container *ngIf="m.appt['id10Txt'] && (m.appt['id10Txt'] === 'PA' || m.appt['id10Txt'] === 'CA')">
                    <br/><b>Driveable: </b>{{m.appt.driveableInd}} 
                  </ng-container>
                </div>             
                <div class="info-window-button-row">
                    <div [hidden]="m.label == centerLocationName || m.locationType =='ASSIGNMENT'" class="curPosIconsAppr"><img class="centerMarkerImgAppr" src="./assets/img/map/Other/cur_loc.png" alt="Cur Loc" (click)="setCurrentCenter(m)"/><a target="_blank" rel="noopener noreferrer" [href]="'https://maps.google.com/?q=' + m.lat.toString() + ',' + m.lng.toString()"><img class="dirImgAppr" src="./assets/img/map/Other/directions.png" alt="Dir"/></a></div>
                    <button type="button" class="btn-primary info-window-button-right" (click)="zoomIn(m, infoWindow)">Zoom In</button>                        
                    <button type="button" class="btn-primary info-window-button-right margin-x" [hidden]="m.label == centerLocationName || m.locationType !=='ASSIGNMENT'" (click)="showAppointmentDetails(m.claimNumber, m.dispatchId, m.appointmentId)">All Details</button>
                    <button type="button" class="btn-primary info-window-button-left" [hidden]="m.label == centerLocationName || m.locationType !=='ASSIGNMENT' || m.status == 'CLOSED' || m.appraiserId != (appointmentService.selectedSpinnerAppraiser ? appointmentService.selectedSpinnerAppraiser.userId : m.appraiserId)" (click)="transmitToVendor(m.appt, m, infoWindow)" [disabled]="isTransmitDisabled">{{transmitBtnLabel}}</button>
                </div>
              </agm-info-window>
            </agm-marker>
          </agm-marker-cluster>
          <agm-circle
            #circle 
            *ngIf = "mainCircle"
            [latitude]="mainCircle.latitude" 
            [longitude]="mainCircle.longitude" 
            [radius]="mainCircle.radius"
            [editable]="mainCircle.editable"
            [fillColor]="mainCircle.fillColor"
            [fillOpacity]="mainCircle.fillOpacity"
            (radiusChange)="event('radiusChange',$event)"
            (centerChange)="event('centerChange',$event)"
            (dragEnd)="radiusDragEnd($event)"      
            >
          </agm-circle>
          <agm-direction
            [visible]="showDirections"
            *ngFor = "let dir of directions"
            [origin] = "dir.origin"
            [destination] = "dir.destination"
            [waypoints]="dir.waypoints"
            [renderOptions]="renderOptions"
            [panel]="dirPanel" 
            (onResponse)="onDirectionsResponse($event)"         
          >
          </agm-direction>            
        </agm-map> 
      </div>
        <div #dirPanel [ngClass]="showDirections && directionsValidated ? ' dirPanel ui-g-3 ui-lg-3 ui-md-12 ui-sm-12' : 'dirPanel ui-g-12 ui-lg-12 ui-md-12 ui-sm-12'">
        </div>
    </div>         
  </div>
  <p-toast #toast class="p-toast" position="top-right">
    <ng-template let-message pTemplate="message">
      <div class="toast-summary" innerHtml="{{message.summary}}"></div>        
      <p class="toast-detail" innerHtml="{{message.detail}}"></p>
    </ng-template>
  </p-toast>
  <p-dialog #dialog class="p-dialog" 
    position="topright"
    [appendTo] = "(googleMapFullscreen === true ? googleMap.getDiv().firstChild : 'body')" 
    [(visible)]="showDialog" 
    [header]="dialogSummary"
    [draggable]="false" 
    [resizable]="true"
    >
    <p class="toast-detail" innerHtml="{{dialogDetail}}"></p>
  </p-dialog>     
  <p-confirmDialog
    #confirm
    class="p-confirmDialog"
    header="Confirmation"
    [appendTo] = "(googleMapFullscreen === true ? googleMap.getDiv().firstChild : 'body')" 
    icon="pi pi-exclamation-triangle"
    [baseZIndex]="10000"
    maskStyleClass="ui-dialog-mask ui-widget-overlay ui-dialog-visible ui-dialog-mask-scrollblocker">
  </p-confirmDialog>
  <div #location class="ui-g basic-container locationDiv">
    <div class="ui-g-8">
      <span class="ui-widget">{{centerLocationName}}</span>
      <input
        type="text"
        class="form-control"
        (keydown.enter)="$event.preventDefault()"
        placeholder="Search Nearest Location"
        autocorrect="off"
        autocapitalize="off"
        spellcheck="off"
        type="text"
        onClick="this.setSelectionRange(0, this.value.length)"
        #search
      />
    </div>
    <div class="ui-g-3">
      <span class="ui-widget">Radius</span>
      <input
        type="text"
        [disabled]="false"
        class="form-control"
        (change)="radiusOnChange($event, mainCircleUpdateRadius)"
        (keydown.enter)="$event.preventDefault()"
        [value]="mainCircle ? mainCircle.radiusInMiles : defaultRadiusInMiles"
        autocorrect="off"
        autocapitalize="off"
        spellcheck="off"
        onClick="this.setSelectionRange(0, this.value.length)"
        #radius
      />      
    </div>
    <div class="ui-g-1">
      <span class="ui-widget">&nbsp;</span>
      <img class="curLocImg" src="./assets/img/map/Other/cur_loc.png" alt="Cur Loc" (click)="setCurrentLocation()"/>
    </div>    
  </div>           
</block-ui>