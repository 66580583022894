import {Component, Inject} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

export interface DialogData {
  header: string;
  message: string;
  retry: boolean;
}


@Component({
    selector: 'transmit-dialog',
    templateUrl: 'transmit.dialog.component.html',
    styleUrls: ['transmit.dialog.component.scss']
  })
export class TransmitDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<TransmitDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  onNoClick(): void {
    this.dialogRef.close('close');
  }

  onOkClick(): void {
    this.dialogRef.close('close');
  }

  onRetryClick(): void {
    this.dialogRef.close('retry');
  }
    
}