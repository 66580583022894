import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { MessageService } from '../../shared/service/message.service';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { catchError, map, share, shareReplay, tap } from 'rxjs/operators';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { Appointment, SpinnerAppraiser, dateFormatType } from './appointment';
import { User } from '../../shared/login/user';
import { environment } from '../../../environments/environment';
import { StorageService } from '../../shared/service/storage.service';
import { DatePipe} from '@angular/common/';
import { stringify } from "@angular/compiler/src/util";
import { oneCircle, oneMarker, iconType } from './appointment';
import { RentalNote } from "./appointmentdetail.rentalnote";
declare var ga: any;
//var moment = require('moment');

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
const phoneRegExp = new RegExp(/(\()?\d{3}(\))?(-|.|\s)?\d{3}(-|.|\s)?\d{4}/g);
const url = {
  claimDetailEndpoint: environment.DISPATCH_BASE_URL + "restproxydispatchws",
  appraiserEndpoint: environment.DISPATCH_BASE_URL + "restproxydispatchws",
  appointmentEndpoint: environment.DISPATCH_BASE_URL + "restproxywebcal",
  dispatchEndpoint: environment.DISPATCH_BASE_URL + "restproxydispatchws",
  appraisersEndpoint: environment.ESB_URL + "dispatch/getAppraisers"  
};

@Injectable({
  providedIn: 'root',
})

export class AppointmentService {
  public _apts: BehaviorSubject<Appointment[]>;
  public _spinnerapprs: BehaviorSubject<SpinnerAppraiser[]>;

  public dataStore: {
     appointments: Appointment[],
     spinnerAppraisers: SpinnerAppraiser[] 
  };

  public _selectedAppointment: Appointment;
  public _selectedSpinnerAppraiser: SpinnerAppraiser;

  set selectedSpinnerAppraiser(newSpinnerAppraiser: SpinnerAppraiser) {
    this._selectedSpinnerAppraiser = newSpinnerAppraiser;
  }

  get selectedSpinnerAppraiser() {
    return this._selectedSpinnerAppraiser;
  }

  public datePipe: DatePipe = new DatePipe('en-US');

  //when the mapping component is active, there is no need to do the normal appointment
  //GPS activity, so we enable/disable from there towards the appointment and appointmentdetails components
  public gpsServicesEnabled: boolean = true;

  private ONESITE_CLAIM_IMPORT_API_URL: string = environment.ECLAIMS_ONESITE_WS_BASE_URL + 'claimImport/';

  constructor(
    public http: HttpClient,
    public storageService: StorageService,
    public messageService: MessageService) { 
      this._apts = <BehaviorSubject<Appointment[]>> new BehaviorSubject([]);
      this._spinnerapprs = <BehaviorSubject<SpinnerAppraiser[]>> new BehaviorSubject([]);
      this.dataStore = { appointments: [], spinnerAppraisers: [] };
    }

    getSelectedAppointment( claimNumber: string, dispId:string ){
      // if ( this.storageService.get('appointments-' + appraiserIds + '-NP')) {
      //   var appointments = JSON.parse(this.storageService.get('appointments-' + appraiserIds + '-NP'));
      //   return appointments;
      // }
      var key = 'selectedAppointment-' + claimNumber + '-' + dispId;

      if ( this.storageService.get(key)) {
        this._selectedAppointment = JSON.parse(this.storageService.get(key));
        //console.log(this._selectedAppointment.callerContactInfo);
        //console.log(this._selectedAppointment.callerContactInfo.match(phoneRegExp) )
        this._selectedAppointment.callerContactInfo=this.getUpdatedContactInfo(this._selectedAppointment.callerContactInfo);
        //since we parse from JSON, the default Date conversion seem to inherit a string and we need a date 
        //for the calendar control we edit with
        this._selectedAppointment.startDateTime = (this._selectedAppointment.startDateTime ? new Date(this._selectedAppointment.startDateTime) : null);
        this._selectedAppointment.endDateTime = (this._selectedAppointment.endDateTime ? new Date(this._selectedAppointment.endDateTime) : null);
        this._selectedAppointment.dataChanged = false;
        this._selectedAppointment.locationChanged = false;
      }

      return this._selectedAppointment;
    }
    
    getUpdatedContactInfo(inStr: string){
      if(phoneRegExp.test(inStr)){
        var phoneNums = inStr.match(phoneRegExp);
        var uniquePhoneNums = phoneNums.filter( this.onlyUnique );
        uniquePhoneNums.forEach(phoneNum => {
          let repStr = '<a href="tel:'+phoneNum+'">'+phoneNum+'</a>';
          inStr = inStr.split(phoneNum).join(repStr);
          //console.log(inStr);
          //console.log(phoneNum);
          //console.log(repStr);
        });
      }
      return inStr;
    }

    get12HTime( time: string){
      if(time.indexOf(":")<0){
        return time;
      }

      let ar = time.split(":");
      let formatedTime = ar[0]+":"+ar[1];

      if(time!=null){
        let sfx = "AM";        
        let h = parseInt(ar[0], 10);

        if(h>12){
          h=h-12;
          sfx = "PM";
          let hTxt = (h<10)?"0"+h:h+"";        
          formatedTime = formatedTime.split(ar[0]).join(hTxt);
          //formatedTime = hTxt+":"+ar[1];
        }
        else if(h==12){
          sfx = "PM";
        }else{
          sfx = "AM";
        }

        formatedTime= formatedTime+" "+sfx;
      //console.info(formatedTime);
      }
      return formatedTime;
    }

    onlyUnique(value, index, self) { 
      return self.indexOf(value) === index;
    }
  
    setSelectedAppointment(newval: any, forceRefresh: boolean = false) {
      var key = 'selectedAppointment-' + newval.claimNumber + '-' + newval.id6;

      if ( this.storageService.get(key) && forceRefresh === false) {
        this._selectedAppointment = JSON.parse(this.storageService.get(key));
      }
      else {
        this._selectedAppointment = newval;
        this.storageService.removeByKeyPrefix("selectedAppointment-");
        this.storageService.save(key, newval);
      }
      //once we change the current appointment, we reset the spinner appraisers
      this._selectedSpinnerAppraiser = undefined;
    }

    get appointments() {
      return this._apts.asObservable();
    }

    get spinnerAppraisers() {
      return this._spinnerapprs.asObservable();
    }
 
    getClaimInfo(claimNumber: string):any{
			
      
      let apiUrl = this.ONESITE_CLAIM_IMPORT_API_URL + "claim/"+claimNumber+ "?requestime=" + (new Date().getTime());
      //let apiUrl = "http://localhost:8080/eclaimsonesite/claimImport/claim/"+claimNumber+ "?requestime=" + (new Date().getTime());
      //let reqBody = JSON.stringify(paymentOptions);
      console.log("Get Claim Info from API URL : " + apiUrl);
      return this.http.get(apiUrl, this.getGenericHeadersWithToken(null));
    }// get claim Info


    getScheduledAppointments(appraiserId: string, appointmentDate) {
      var appraiserIds = appraiserId;
      //var appraiserIds = 14;
      // if ( this.storageService.get('appointments-' + appraiserIds + '-NP')) {
      //   var appointments = JSON.parse(this.storageService.get('appointments-' + appraiserIds + '-NP'));
      //   return appointments;
      // }
			var options = {
        params: {
            proxyurlrelative: "dispatchappointments",
            //orderBy: "startDate asc, startTime asc, id1 desc",
            orderBy: "startDate+asc,+startTime+asc,+id1+desc",
            top: "100 PERCENT",
            filter: "id3 in ( " + appraiserIds + " ) and status ne 'L' and startDate ge '" + appointmentDate + "' and startDate le '" + appointmentDate + "' and startDate is not null",
            format: "json"
        },
        headers: {
            Accept: "application/json"
        }
      };
      
      this.http.get(url.appointmentEndpoint + "?requestime=" + (new Date().getTime()), options).subscribe( ( data: any ) => { 

        // console.log(data.results); 

        let apts: Appointment[] = [];
        for (var i=0; i< data.results.length; i++){
          apts[i] = this.processAppointmentDetails(data.results[i]);
        }

        apts.sort((a,b) => b.assignDateTime.getTime() - a.assignDateTime.getTime());

        this.dataStore.appointments = apts;
        this._apts.next( Object.assign({}, this.dataStore).appointments );
      }, 
         error => { 
            console.log('Failed to load the appointments');       
         }
      );
    }

  getAppointements(appraiserId: string = undefined, hideClosed: boolean = true) {
    var appointmentDate = 'NULL';
    var appraiserIds = appraiserId;

    //since here we may want to see how we just transferred to ourselves
    //this.storageService.clearAll();

      //var appraiserIds = 14;
      // if ( this.storageService.get('appointments-' + appraiserIds + '-NP')) {
      //   var appointments = JSON.parse(this.storageService.get('appointments-' + appraiserIds + '-NP'));
      //   return appointments;
      // }
      console.log("getAppointements ID to get dispatches : " + appraiserId);
      //let filterByStatus = ( hideClosed == true ) ? "+status+in+(+'N','P'+)+" : "( status in ( 'N','P') or ( status in ( 'C' ) and  startDate gt dateadd(month,-6,getdate())))";
      let filterByStatus = ( hideClosed == true ) ? " status+in+('N','P') " : " status in ( 'N','P','C') ";
      
      let options: any
      
      if (appraiserId) {
        options = {
          params: {
            proxyurlrelative: "dispatchappointments",
            orderBy: "startDate+asc,+startTime+asc,+id1+desc",
            //orderBy: "startDate asc, startTime asc, id1 desc",
            top: "100+PERCENT",
            filter: "+id3+in+(+" + appraiserIds + "+)+and+" + filterByStatus+" and insertTmstmp gt dateadd(month,-6,getdate()) ",
            format: "json"
          },
          headers: {
            Accept: "application/json"
          }
        };  
      }
     
      let appointmentsObservable = this.http.get(url.appointmentEndpoint + "?requestime=" + (new Date().getTime() ) , options
      // "&format=json&filter=id3 in ( " + appraiserIds + " ) and " + filterByStatus +
      // "&proxyurlrelative=dispatchappointments" +
      // "&orderBy=startDate asc, startTime asc, id1 desc" +
      // "&top=100 PERCENT"
      )
      appointmentsObservable.subscribe((data: any) => { 

        // console.log(data.results); 

        let apts: Appointment[] = [];
        for (var i=0; i< data.results.length; i++){
          apts[i] = this.processAppointmentDetails(data.results[i]);
        }
        apts.sort((a,b) => b.assignDateTime.getTime() - a.assignDateTime.getTime()); // order by 
        this.dataStore.appointments = apts;
        this._apts.next( Object.assign({}, this.dataStore).appointments );
      }, error => {
        console.log('Failed to load the appointments');
      });
  };

  getAppointment(appointmentId: string = undefined) {
    var appointmentDate = 'NULL'; 
    let options: any

    //since here we may want to see how we just transferred to ourselves
    //this.storageService.clearAll();

    if (appointmentId) {
      options = {
        params: {
          proxyurlrelative: "dispatchappointments",
          orderBy: "appointmentID",
          //orderBy: "startDate asc, startTime asc, id1 desc",
          top: "100+PERCENT",
          filter: "+appointmentID eq " + appointmentId,
          format: "json"
        },
        headers: {
          Accept: "application/json"
        }
      };  
    }
   
    let appointmentsObservable = this.http.get(url.appointmentEndpoint + "?requestime=" + (new Date().getTime() ) , options
    // "&format=json&filter=id3 in ( " + appraiserIds + " ) and " + filterByStatus +
    // "&proxyurlrelative=dispatchappointments" +
    // "&orderBy=startDate asc, startTime asc, id1 desc" +
    // "&top=100 PERCENT"
    )
    return appointmentsObservable.pipe(map(data => {
      // console.log(data['results']); 

      let apts: Appointment[] = [];
      for (var i=0; i< data['results'].length; i++){
        apts[i] = this.processAppointmentDetails(data['results'][i]);
      }
      apts.sort((a,b) => b.assignDateTime.getTime() - a.assignDateTime.getTime()); // order by 
      this.dataStore.appointments = apts;
      this._apts.next( Object.assign({}, this.dataStore).appointments);
      if (apts.length === 1) {
        this.setSelectedAppointment(apts[0], true);
      }
    })); 
};  

getSpinnerAppraisers(appointment: Appointment) {
  let claimId = appointment.claimSequence;
  let claimNum = appointment.claimNumber;

  let appraiserRequest = `<response>
    <claims>
      <claim>
        <claimNum>` + claimNum + `</claimNum>
        <claimId>` + claimId + `</claimId>
        <regionStateCd></regionStateCd>
        <regionCounty></regionCounty>
        <regionZipCd></regionZipCd>
        <maxRecords></maxRecords>
      </claim>
    </claims>
  </response>`;

  let headerObject = {
    'Content-Type' : 'application/xml', 
    'Accept':  'application/json' 
  };

  //dealing with development environments became annoying
  //since we normally use the direct url, not the proxy
  if (environment.ESB_URL.search("eway") !== -1) {
    headerObject["Authorization"] = "Basic c3RnOnN0Zw==";
    headerObject["Response-Type"] = "text";
  } 
  const httpOptions = { headers: new HttpHeaders(headerObject)};              
  let appraisersObservable = this.http.post( url.appraisersEndpoint + '?requestime=' + (new Date().getTime()),
  appraiserRequest,
    httpOptions);

  return appraisersObservable.pipe(map(data => {
      //console.log(data.results); 
      let apprs: SpinnerAppraiser[] = [];
      if (data[0]['appraisers']['appraiser']) {
        for (var i=0; i< data[0]['appraisers']['appraiser'].length; i++){
          apprs[i] = new SpinnerAppraiser();
          let oneDataResult = data[0]['appraisers']['appraiser'][i];
          apprs[i].assignTypeCd = oneDataResult['assignTypeCd'];
          apprs[i].amLimitAmt = oneDataResult['amLimitAmt'];
          apprs[i].companies = oneDataResult['companies'];
          apprs[i].parties = oneDataResult['parties'];
          apprs[i].coverages = oneDataResult['coverages'];
          apprs[i].fullDayLimitAmt = oneDataResult['fullDayLimitAmt'];
          apprs[i].fullName = oneDataResult['fullName'] 
          apprs[i].intervalCountToday = oneDataResult['intervalCountToday'];
          apprs[i].intervalCountYearMonthDay = oneDataResult['intervalCountYearMonthDay'];
          apprs[i].intervalCountYearWeek = oneDataResult['intervalCountYearWeek'];
          apprs[i].lobs = oneDataResult['lobs'];
          apprs[i].losstypes = oneDataResult['losstypes'];
          apprs[i].offices = oneDataResult['offices'];
          apprs[i].pmLimitAmt = oneDataResult['pmLimitAmt'];
          apprs[i].referenceDate = oneDataResult['referenceDate'];
          apprs[i].regionLob = oneDataResult['regionLob'];
          apprs[i].regions = oneDataResult['regions'];
          apprs[i].specialScheduleEvents = oneDataResult['specialScheduleEvents'];
          apprs[i].specialtyMake = oneDataResult['specialtyMake'];
          apprs[i].streamRegionCd = oneDataResult['streamRegionCd'];
          apprs[i].streamUserId = oneDataResult['streamUserId'];
          apprs[i].suspendUserInd = oneDataResult['suspendUserInd'];
          apprs[i].userId = oneDataResult['userId'];
          apprs[i].usergroups = oneDataResult['usergroups'];
          apprs[i].vendors = oneDataResult['vendors'];
          apprs[i].workHolidayInd = oneDataResult['workHolidayInd'];
          apprs[i].workSaturdayInd = oneDataResult['workSaturdayInd'];
          apprs[i].workSundayInd = oneDataResult['workSundayInd'];
        }
      }
      this.dataStore.spinnerAppraisers = apprs;
      this._spinnerapprs.next( Object.assign({}, this.dataStore).spinnerAppraisers );
      return data;
  })); 
}; 


  getAppointementsByClaimNumber(inputValue, hideClosed: boolean) {
    console.log("getAppointementsByClaimNumber", inputValue);

    //since here we may want to see how we just transferred to ourselves
    //this.storageService.clearAll();

    var appointmentDate = 'NULL';
      let filterByStatus = ( hideClosed == true ) ? " status+in+('N','P') " : " status in ( 'N','P','C') ";      
      var options = {
        params: {
          proxyurlrelative: "dispatchappointments",
          orderBy: "startDate+asc,+startTime+asc,+id1+desc",
          //orderBy: "startDate asc, startTime asc, id1 desc",
          top: "100+PERCENT",
          //filter: " id1Txt in ('" + inputValue.join("','") + "') and " + filterByStatus+" and insertTmstmp gt dateadd(month,-6,getdate()) ",
          filter: " id1Txt in ('" + inputValue.join("','") + "') ",
          format: "json"
        },
        headers: {
          Accept: "application/json"
        }
      };

     
       this.http.get(url.appointmentEndpoint + "?requestime=" + (new Date().getTime() ) , options
      ).subscribe( ( data: any ) => { 

        // console.log(data.results); 

        let apts: Appointment[] = [];
        for (var i=0; i< data.results.length; i++){
          apts[i] = this.processAppointmentDetails(data.results[i]);
        }
        apts.sort((a,b) => b.assignDateTime.getTime() - a.assignDateTime.getTime()); // order by 
        this.dataStore.appointments = apts;
        this._apts.next( Object.assign({}, this.dataStore).appointments );
      }, error => {
        console.log('Failed to load the appointments');
      });

  }; //getAppointementsByClaimNumber
  transmitAssignmentToVendor(appointment: Appointment){

    var claimId = appointment.claimSequence;

    // var transferData = '<results> <result><claimId>'+claimId+'</claimId> <autoAcknowledge>1</autoAcknowledge>' + 
    //            '<autoTransmit>1</autoTransmit><placeholder1>0</placeholder1><stopAllCommInd>N</stopAllCommInd> '+
    //            '<sendEmail>1</sendEmail><sendEmailWithCalendar>1</sendEmailWithCalendar> ' +
    //            '<sendNotification>0</sendNotification> <sendText>1</sendText> </result> </results>';

    var transferData = '<results> <result><claimId>'+claimId+'</claimId></result> </results>';

    let headerObject = {
      'Content-Type' : 'application/xml', 
      'Accept':  'application/xml' 
    };
    //dealing with development environments became annoying
    //since we normally use the direct url, not the proxy
    if (environment.DISPATCH_BASE_URL.search("eway") !== -1) {
      headerObject["Authorization"] = "Basic c3RnOnN0Zw==";
      headerObject["Response-Type"] = "text";
    } 

    const httpOptions = { headers: new HttpHeaders(headerObject)};
                
    return this.http.post( environment.ESB_URL + 'dispatch/dispatchSilent' + '?requestime=' + (new Date().getTime()),
      transferData,
      httpOptions);
};

transferAssignmentToUser(appointment, userId){

  var claimId = appointment.id1;
  var transferData = '<results><result><claimId>' + claimId.toString() + '</claimId><userId>' + userId.toString() + '</userId></result></results>';

  let headerObject = {
    'Content-Type' : 'application/xml', 
    'Accept':  'application/xml'
  };
  //dealing with development environments became annoying
  //since we normally use the direct url, not the proxy
  if (environment.DISPATCH_BASE_URL.search("eway") !== -1) {
    headerObject["Authorization"] = "Basic c3RnOnN0Zw==";
    headerObject["Response-Type"] = "text";    
  } 

  const httpOptions = { headers: new HttpHeaders(headerObject)};                      
  return this.http.post( environment.ESB_URL + 'dispatch/transferSilent' + '?requestime=' + (new Date().getTime()),
    transferData,
    httpOptions);
}; 

pad = function (number) {
  if (number < 10) {
    return '0' + number;
  }
  return number;
};  
formatDate = function(format:dateFormatType = dateFormatType.ISO8601, dateToFormat: Date = new Date()) {
  let formattedDate: string = '';
  // 2019-06-03T15:33:38.100
  switch (format) {
    case dateFormatType.ISO8601:
      formattedDate = dateToFormat.getFullYear() +
      '-' + this.pad(dateToFormat.getMonth() + 1) +
      '-' + this.pad(dateToFormat.getDate()) +
      'T' + this.pad(dateToFormat.getHours()) +
      ':' + this.pad(dateToFormat.getMinutes()) +
      ':' + this.pad(dateToFormat.getSeconds());
      break;
    case dateFormatType.ODBC:
      formattedDate = dateToFormat.getFullYear() +
      '-' + this.pad(dateToFormat.getMonth() + 1) +
      '-' + this.pad(dateToFormat.getDate()) +
      ' ' + this.pad(dateToFormat.getHours()) +
      ':' + this.pad(dateToFormat.getMinutes()) +
      ':' + this.pad(dateToFormat.getSeconds());
      break;
    case dateFormatType.ISO:
        formattedDate = dateToFormat.getFullYear() +
        '' + this.pad(dateToFormat.getMonth() + 1) +
        '' + this.pad(dateToFormat.getDate());
        break;
    case dateFormatType.DATETIME_HOURS_ONLY:
      formattedDate = dateToFormat.getFullYear() +
      '-' + this.pad(dateToFormat.getMonth() + 1) +
      '-' + this.pad(dateToFormat.getDate()) +
      'T' + this.pad(dateToFormat.getHours()) +
      ':00' +
      ':00';
      break;
    case dateFormatType.DATETIME_MINS_ONLY:
      formattedDate = dateToFormat.getFullYear() +
      '-' + this.pad(dateToFormat.getMonth() + 1) +
      '-' + this.pad(dateToFormat.getDate()) +
      'T' + this.pad(dateToFormat.getHours()) +
      ':' + this.pad(dateToFormat.getMinutes()) +
      ':00';
      break;
    case dateFormatType.ISO08601_DATE_ONLY:
      formattedDate = dateToFormat.getFullYear() +
      '-' + this.pad(dateToFormat.getMonth() + 1) +
      '-' + this.pad(dateToFormat.getDate());
      break;           
    case dateFormatType.ISO08601_TIME_ONLY:
      formattedDate = this.pad(dateToFormat.getHours()) +
      ':' + this.pad(dateToFormat.getMinutes()) +
      ':' + this.pad(dateToFormat.getSeconds());
      break; 
    default:
      formattedDate = dateToFormat.getFullYear() +
      '-' + this.pad(dateToFormat.getMonth() + 1) +
      '-' + this.pad(dateToFormat.getDate()) +
      'T' + this.pad(dateToFormat.getHours()) +
      ':' + this.pad(dateToFormat.getMinutes()) +
      ':' + this.pad(dateToFormat.getSeconds());
  }    
  return formattedDate;
}

updateAppointment(appt: Appointment){

  let apptId: string = appt.appointmentID;
  let startDate: string = this.formatDate(dateFormatType.ISO08601_DATE_ONLY, appt.startDateTime);
  let endDate: string = this.formatDate(dateFormatType.ISO08601_DATE_ONLY, appt.endDateTime);
  let startTime: string = this.formatDate(dateFormatType.ISO08601_TIME_ONLY, appt.startDateTime);
  let endTime: string = this.formatDate(dateFormatType.ISO08601_TIME_ONLY, appt.endDateTime);

  let apptData:any = {
    startDate: startDate,
    endDate: endDate,
    startTime: startTime,
    endTime: endTime
  };

  let headerObject = {
    'Content-Type' : 'application/json', 
    'Accept':  'application/json'
  };
  //dealing with development environments became annoying
  //since we normally use the direct url, not the proxy
  if (url.appointmentEndpoint.search("eway") !== -1) {
    headerObject["Authorization"] = "Basic c3RnOnN0Zw==";
    headerObject["Response-Type"] = "text";    
  } 

  let options = {
    headers: new HttpHeaders(headerObject)
  };  
                     
  return this.http.post( url.appointmentEndpoint + '?proxyurlrelative=appointments/' + apptId,
    apptData,
    options);
}; 

updateLocation(appt: any){
  let claimId: string = appt.id1;
  let unitId: string = appt.id2;
  let user:User = JSON.parse(this.storageService.get('user-details'));
  let locationData: any;
  let unitType: string; 
  //in case the address returned by google maps autocomplete was modified
  //manually, we need to sync the formatted address
  appt.locationFormattedAddr = [[appt.locationCityName, appt.locationStateCd, appt.locationZipCd].join(" ") , [appt.locationAddrLn1Txt, appt.locationAddrLn2Txt].join(" ")].join(", ");
  if (!appt.vehicleYear && !appt.vehicleMakeModel && !appt.vehicleVin) {
    unitType = 'unitProperties';
    locationData = {
      "claimId": claimId,
      "unitId": unitId,
      "propLocFormattedAddr": appt.locationFormattedAddr,
      "propLocAddrLn1Txt": appt.locationAddrLn1Txt,
      "propLocAddrLn2Txt": (appt.locationAddrLn2Txt.trim() ? appt.locationAddrLn2Txt.trim() : ' '), 
      "propLocStateCd": appt.locationStateCd,
      "propLocCityName": appt.locationCityName,
      "propLocZipCd": appt.locationZipCd,
      "propLocSubdName": appt.locationSubdName,
      "propLocLat": appt.locationLat,
      "propLocLong": appt.locationLong,
      "updateId": ( user != null ) ? user.claimSystemLoginId : 'system',
      "updateTmstmp": this.formatDate(dateFormatType.ISO8601)
    }
  } else {
    unitType = 'unitVehicles';
    locationData = {
      "claimId": claimId,
      "unitId": unitId,
      "vehLocFormattedAddr": appt.locationFormattedAddr,
      "vehLocAddrLn1Txt": appt.locationAddrLn1Txt,
      "vehLocAddrLn2Txt": (appt.locationAddrLn2Txt.trim() ? appt.locationAddrLn2Txt.trim() : ' '),
      "vehLocStateCd": appt.locationStateCd,
      "vehLocCityName": appt.locationCityName,
      "vehLocZipCd": appt.locationZipCd,
      "vehLocSubdName": appt.locationSubdName,
      "vehLocLat": appt.locationLat,
      "vehLocLong": appt.locationLong,
      "updateId": ( user != null ) ? user.claimSystemLoginId : 'system',
      "updateTmstmp": this.formatDate(dateFormatType.ISO8601)
    };
  } 

  let headerObject = {
    'Content-Type' : 'application/json', 
    'Accept':  'application/json'
  };
  //dealing with development environments became annoying
  //since we normally use the direct url, not the proxy
  if (url.appointmentEndpoint.search("eway") !== -1) {
    headerObject["Authorization"] = "Basic c3RnOnN0Zw==";
    headerObject["Response-Type"] = "text";    
  } 

  let options = {
    headers: new HttpHeaders(headerObject)
  };  
                     
  return this.http.post(url.dispatchEndpoint + '?proxyurlrelative=dispatch/' + unitType + '/' + claimId + ',' + unitId,
    locationData,
    options);
};

getAppointmentsByLocation(mainCircle: oneCircle, maxAppointments: number, userId: number, serverFilter: string = ''){

  let locationData = {maxRecords: maxAppointments, locLat: mainCircle.latitude, locLong: mainCircle.longitude, radius: mainCircle.radius, userId: userId, filter: serverFilter};
  
  //since here we may want to see how we just transferred to ourselves
  //this.storageService.clearAll();

  const httpOptions = { headers: new HttpHeaders({
                                       'Content-Type' : 'application/json', 
                                       'Accept':  'application/json'})
                      };
   let apptLocationObservable = this.http.post( url.appointmentEndpoint + '?proxyurlrelative=dispatchapptsbyloc' + '&requestime=' + (new Date().getTime()),
      locationData,
      httpOptions);
      
    return apptLocationObservable.pipe(map(data => {
        //console.log(data.results); 
        let apts: Appointment[] = [];
        if (data['results']) {
          for (var i=0; i< data['results'].length; i++){
            apts[i] = new Appointment();
            let oneDataResult = data['results'][i];
            apts[i].appointmentID = oneDataResult['appointmentID'];          
            apts[i].age = oneDataResult['age'];
            apts[i].locationLat = oneDataResult['locationLat'];
            apts[i].locationLong = oneDataResult['locationLong'];
            apts[i].lob = oneDataResult['lob'];
            apts[i].distance = oneDataResult['distance'];
            apts[i].appraiserType = oneDataResult['appraiserType'];
            apts[i].vendorSequence = oneDataResult['dispatchId'];
            apts[i].claimNumber = oneDataResult['claimNumber'];
            apts[i].claimSequence = oneDataResult['claimId'];
            apts[i].unitId = oneDataResult['unitId'];
            apts[i].channel = oneDataResult['channel'];
            apts[i].appraiserName = oneDataResult['appraiserName'];
            apts[i].startDate = oneDataResult['startDate'];
            apts[i].startTime = oneDataResult['startTime'];
            apts[i].status = oneDataResult['status'];
            apts[i].appraiserId = oneDataResult['appraiserId'];
            apts[i].locationType = oneDataResult['locationType'];
            apts[i].iconUrl = oneDataResult['iconUrl'];
            apts[i].iconSize = oneDataResult['iconSize'];
            apts[i].driveable = oneDataResult['driveable'];
          }
        }
        this.dataStore.appointments = apts;
        this._apts.next( Object.assign({}, this.dataStore).appointments );
      return data;
    }));    
};  


getLookupResults(query: any) {
  //let apiUrl = url.dispatchEndpoint
  var options = {
    params: {
      proxyurlrelative: "dispatch/codes",
      select: "fieldCode",
      //orderBy: "startDate asc, startTime asc, id1 desc",
      noSpinner:"true",
      top: "20",
      filter: "fieldName eq 'claimNum' and fieldCode bw '"+query+"' ",
      format: "json"
    },
    headers: {
      Accept: "application/json"
    }
  };

 
  return this.http.get(url.dispatchEndpoint + "?_=" + (new Date().getTime() ) , options);
}

saveNoteToStream(notes, claimNumber, user){
  let noteXml = this.prepareNotePayloadToSend(notes,claimNumber,user);
  console.info(noteXml);
  const httpOptions = {    
                           headers: new HttpHeaders({'Content-Type' : 'application/xml' })                          
                      };

   return this.http.post( environment.ESB_URL + 'dispatch/claimImportNotesAsync/nj?requestime=' + (new Date().getTime()),
          noteXml,
           httpOptions);  
}
getGenericHeadersWithToken(token : string) {
  let oAuthToken:string = token;
  if(!oAuthToken){
    console.log("Get Token from Storage...");
    if(this.storageService.get("X_Token")){
      oAuthToken = JSON.parse(this.storageService.get("X_Token"));
    }
  }
  const httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
      .append("Authorization", "Bearer " + oAuthToken)
      .append("Access-Control-Allow-Origin", "*")
      .append('Accept', 'application/json')
  };

  return httpOptions;
}
saveNoteToStream_Rental(options:RentalNote) {
  let noteJSON = options.getNotePayloadToSend();
  console.info(noteJSON);

  const httpOptions = {
    params: {
      //proxyurlrelative: "claim/importrental",
    },
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
  };
  let apiUrl = this.ONESITE_CLAIM_IMPORT_API_URL + "create/dispatch/workItemAndNote"+ "?requestime=" + (new Date().getTime());
  //let reqBody = JSON.stringify(paymentOptions);
  console.log("Posting Create workItemAndNote details to API URL : " + apiUrl);
  return this.http.post(apiUrl, noteJSON, this.getGenericHeadersWithToken(null)); 
}

encodeHTML(text:string){
  if(text == null){
    return text;
  }else{
  return text.toString().replace(/&/g, '&amp;')
             .replace(/</g, '&lt;')
             .replace(/>/g, '&gt;')
             .replace(/"/g, '&quot;');
  }
}
prepareNotePayloadToSend(notes,claimNumber,user){
  return '<response><claims><claim><claimNum>'+claimNumber
  +'</claimNum><notes><subjectText>E-Dispatch note added by '+user
  +' on '+this.datePipe.transform(new Date().getTime(), 'EEE, yyyy-MM-dd h:mm a')
  +'</subjectText><noteText>'+this.encodeHTML(notes)
  +'</noteText></notes></claim></claims></response>';
}

saveNoteToEClaim(notes, appointment:Appointment) {
  let noteContentRequestEClaim: any = {};

  noteContentRequestEClaim.applicationId1Text = appointment.claimNumber;
  noteContentRequestEClaim.applicationId1 =  appointment.claimSequence;
  noteContentRequestEClaim.applicationTag =  'EDISPATCH';
  noteContentRequestEClaim.actionName =  'NOTE';
  noteContentRequestEClaim.actionDesc = '<p>' +  notes + '<p>';
  let user:User = JSON.parse(this.storageService.get('user-details'));
  noteContentRequestEClaim.insertId = ( user!=null ) ? user.claimSystemLoginId : '';
 
  const httpOptions = {    params: {
    proxyurlrelative: "dispatch/actions" 
  }, 
  headers: new HttpHeaders({
    'Content-Type' : 'application/json', 
    'Accept':  'application/json' })   
  };

  return this.http.post( environment.DISPATCH_BASE_URL + 'restproxydispatchws?proxyurlrelative=dispatch%2Factions' + '?requestime=' + (new Date().getTime()),
     noteContentRequestEClaim,
     httpOptions);  
}



getUserPictureUrl(userName:string){
  
  const httpOptions = {    
  headers: new HttpHeaders({
    'Content-Type' : 'application/json', 
    'Accept':  'application/json' })   
  };
  return this.http.get(environment.PROFILE_SERVICE_BASE_URL + 'picture/' + userName + "?requestime=" + (new Date().getTime()) );
}

getScripts(scriptName:string){
  const httpOptions = {    
    headers: new HttpHeaders({
      'Content-Type' : 'application/json', 
      'Accept':  'application/json' })   
    };

  return this.http.get(environment.FNOL_SERVICE_BASE_URL + 'scripts' + "?requestime=" + (new Date().getTime()), httpOptions );
}

getUserContacts(userName: string){

  const httpOptions = {    
    headers: new HttpHeaders({
      'Content-Type' : 'application/json', 
      'Accept':  'application/json' })   
    };

  return this.http.get(environment.PROFILE_SERVICE_BASE_URL + 'claimsystem/contacts/' + userName, httpOptions );

}

getNotificationOptInFlags(claimNumber: string){

  const httpOptions = {    
    headers: new HttpHeaders({
      'Content-Type' : 'application/json', 
      'Accept':  'application/json' })   
    };

  return this.http.get(environment.NOTIFICATION_BASE_URL + '/streamClaim/optStatus?claimNumber=' + claimNumber + '&requestTime=' + (new Date()).getTime(), httpOptions );

}

getClaimsBodyShopOptInFlagByclaimId(claimId: string){

  const httpOptions = {    
    headers: new HttpHeaders({
      'Content-Type' : 'application/json', 
      'Accept':  'application/json' })   
    };

  return this.http.get(environment.DISPATCH_BASE_URL + '/restproxydispatchws?proxyurlrelative=dispatch/claims&expand=assignments'+
  '&orderBy=dispatchId+asc&top=100+PERCENT&format=xml&filter=claimId+eq+' + claimId + '&_=' + (new Date()).getTime(), httpOptions );

}

saveClaimsBodyShopOptInFlag(cbspOptFlag:string, dispatchId:string) {
  let user:User = JSON.parse(this.storageService.get('user-details'));
  let payload: any = {};
  payload.dispatchId=dispatchId;
  payload.updateId=( user!=null ) ? user.claimSystemLoginId : '';
  payload.cbspOptFlag=cbspOptFlag;

  console.info("saveClaimsBodyShopOptInFlag",payload);

  const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type' : 'application/json', 
    'Accept':  'application/json' })   
  };

  return this.http.post( environment.DISPATCH_BASE_URL +'/restproxydispatchws?proxyurlrelative=dispatch/assignments/'+payload.dispatchId
    + '?requestime=' + (new Date().getTime()),
  payload);  
}
getDistanceMatrix(origin:string, destination:string, trafficModel:string, trackingId:string) {
						
  // var service = new google.maps.DistanceMatrixService;
  
  //  service.getDistanceMatrix({
  //       origins: [origin],
  //       destinations: [destination],
  //       travelMode: google.maps.TravelMode.DRIVING,
  //       unitSystem: google.maps.UnitSystem.IMPERIAL,
  //       avoidHighways: false,
  //       avoidTolls: false,
  //       drivingOptions : {trafficModel: trafficModel, departureTime: new Date(Date.now() + 10000)}
  //     }, function(response, status) {
  //       //$log.info("TrackingId= " + trackingId + ", Current Location= " + JSON.stringify(origin) + ", inspectionLocation= " + destination + ", distance=" + JSON.stringify(response) + ", status=" + status);
  //       if(status == 'OK'){
  //         deferred.resolve(response);
  //       }else{
  //         deferred.reject(status);
  //       }
        //$scope.responseFromGoogle = response;
      //alert($scope.responseFromGoogle);
   // });
      
  //return deferred.promise;									
}
  //
  // parsing and foramtting of the payload
  //
  parseAppointmentPartDetail(appointmentDesc: string, partName: string) {
    var partDetail = '';
    var indexOfPartName = appointmentDesc.indexOf(partName);
    if (indexOfPartName > -1) {
      partDetail = appointmentDesc.substring(indexOfPartName + partName.length + 5);
      var indexOfBR = partDetail.indexOf('<br/><b>');
      //SP Look for the end if the field is at the end
      if (indexOfBR < 0) {
        indexOfBR = partDetail.indexOf('</BODY>');
      }
      partDetail = partDetail.substring(0, indexOfBR);
    }
    return partDetail;
  };

  isValidDate(d) {
    return Object.prototype.toString.call(d) === '[object Date]';
  }
  
  processAppointmentDetails(appointment: Appointment) {

    var insuredName = this.parseAppointmentPartDetail(appointment.description, 'INSURED NAME:');
    appointment.insuredName = insuredName;
    appointment.appointmentTimeFormatted = '';
    appointment.assignDateTime = (appointment.insertTmstmp) ? new Date(appointment.insertTmstmp.replace(" ","T")+"Z"): new Date();
    if(!appointment.startTime||appointment.startTime == undefined){
      appointment.startTime = "08:00:00";
    }
    if(!appointment.endTime||appointment.endTime == undefined){
      appointment.endTime = "16:00:00";
    }
    if (appointment.startDate && appointment.startTime && ( appointment.startDate !== undefined && appointment.startTime !== undefined ) )  {
      // format date
      let startDate = new Date(appointment.startDate);
      //let startDate = new Date();
      if ( this.isValidDate( startDate ) ) {
          //let startDateStr = this.datePipe.transform(startDate, 'MM-dd-yyyy');
          // format date/time
          appointment.startDateTime = new Date(appointment.startDate + 'T' + appointment.startTime);
          appointment.assignDateTime = appointment.startDateTime;
          if ( this.isValidDate(appointment.startDateTime) ) {
              //appointment.appointmentTimeFormatted =  this.datePipe.transform (appointment.startDateTime, 'h:mm a')
          }
      }
    }

    if ( (appointment.endDate && appointment.endTime) && ( appointment.endDate !== undefined && appointment.endTime !== undefined ) )  {
      // format date
      let endDate = new Date(appointment.endDate);
      //let endDate = new Date();
      if ( this.isValidDate( endDate ) ) {
          //let endDateStr = this.datePipe.transform (endDate, 'MM-dd-yyyy');
          // format date/time
          appointment.endDateTime = new Date(appointment.endDate + 'T' + appointment.endTime);
          if ( this.isValidDate(appointment.endDateTime) ) {
             // appointment.appointmentTimeFormatted =  appointment.appointmentTimeFormatted +  ' - ' + this.datePipe.transform (appointment.endDateTime, 'h:mm a')
          }
      }
    }


    appointment.assignDateTime = (appointment.insertTmstmp) ? new Date(appointment.insertTmstmp.replace(" ","T")+"Z"): new Date();
    if ( this.isValidDate(appointment.assignDateTime) ) {
        //appointment.assignDateTimeFormatted = (appointment.assignDateTime) ? this.datePipe.transform (appointment.assignDateTime, 'MM/dd/yyyy') : '';
        //appointment.assignDateTimeFormatted = (appointment.assignDateTime) ? moment(appointment.assignDateTime).format('YYYY-MM-DD'): '';
    }
 
    //appointment.dispatchStatus = StringUtil.titleCase(this.parseAppointmentPartDetail(appointment.description, 'DISPATCH STATUS:'));
    appointment.dispatchStatus = this.parseAppointmentPartDetail(appointment.description, 'DISPATCH STATUS:');
    appointment.insuredAddress = this.parseAppointmentPartDetail(appointment.description, 'INSURED ADDRESS:');
    appointment.policyNumber = this.parseAppointmentPartDetail(appointment.description, 'POLICY NUMBER:');
    appointment.claimNumber = this.parseAppointmentPartDetail(appointment.description, 'CLAIM NUMBER:');
    appointment.claimType = this.parseAppointmentPartDetail(appointment.description, 'TYPE OF CLAIM:');
    
    if (  appointment.description ) {
      var d = this.parseAppointmentPartDetail(appointment.description, 'DATE OF LOSS:');
      var r = d.replace("AM", " AM").replace("PM", " PM");        
      appointment.lossDate = r;    

      var dd = this.parseAppointmentPartDetail(appointment.description, 'DATE OF NOTICE:');
      var rr = dd.replace("AM", " AM").replace("PM", " PM");        
      appointment.noticeDate = rr;    
    }
    appointment.startTime = this.get12HTime(appointment.startTime);
    appointment.endTime = this.get12HTime(appointment.endTime);

    //appointment.noticeDate = this.parseAppointmentPartDetail(appointment.description, 'DATE OF NOTICE:');
    appointment.claimOwner = this.parseAppointmentPartDetail(appointment.description, 'RESPONSIBLE REP:');
    appointment.vehicleYear = this.parseAppointmentPartDetail(appointment.description, 'VEHICLE YEAR:');
    appointment.vehicleMakeModel = this.parseAppointmentPartDetail(appointment.description, 'MAKE/MODEL:');
    appointment.vehicleColor = this.parseAppointmentPartDetail(appointment.description, 'COLOR:');
    appointment.vehiclePlate = this.parseAppointmentPartDetail(appointment.description, 'PLATE NUMBER:');
    appointment.vehicleVin = this.parseAppointmentPartDetail(appointment.description, 'VIN:');
    appointment.damageDetail = this.parseAppointmentPartDetail(appointment.description, 'DAMAGE:');
    appointment.damagedParts = this.parseAppointmentPartDetail(appointment.description, 'Damaged Parts:');
    appointment.coverages = this.parseAppointmentPartDetail(appointment.description, 'COVERAGES:');
    appointment.endorsements = this.parseAppointmentPartDetail(appointment.description, 'ENDORSEMENTS:');
    appointment.deductible = this.parseAppointmentPartDetail(appointment.description, '<b>DEDUCTIBLE:');
    appointment.policyDeductible = this.parseAppointmentPartDetail(appointment.description, '<b>POLICY DEDUCTIBLE:');
    appointment.deductibleInstr = this.parseAppointmentPartDetail(appointment.description, '<b>DEDUCTIBLE INSTR:');
    appointment.payStatus = this.parseAppointmentPartDetail(appointment.description, 'PAY STATUS:');
    appointment.rentalLimit = this.parseAppointmentPartDetail(appointment.description, 'RENTAL LIMITS:');
    appointment.inspectionLocation = appointment.locationAddrLn1Txt + ', ' + appointment.locationCityName + ' ' + appointment.locationStateCd + ', ' + appointment.locationZipCd;
    appointment.inspectionLocationDesc = this.parseAppointmentPartDetail(appointment.description, 'LOCATION DESCRIPTION:');
    appointment.accidentDesc = this.parseAppointmentPartDetail(appointment.description, 'ACCIDENT DESCRIPTION:');
    appointment.contactName = this.parseAppointmentPartDetail(appointment.description, 'CONTACT PERSON:');
    appointment.contactPhoneDay = this.parseAppointmentPartDetail(appointment.description, 'CONTACT DAY PH#:');
    appointment.contactPhoneNight = this.parseAppointmentPartDetail(appointment.description, 'CONTACT NIGHT PH#:');
    appointment.contactPhoneOther = this.parseAppointmentPartDetail(appointment.description, 'CONTACT OTHER PH#:');
    appointment.dtdServices = this.parseAppointmentPartDetail(appointment.description, 'DTD SERVICES:');
    appointment.underwritingCo = this.parseAppointmentPartDetail(appointment.description, 'UNDERWRITING CO:');
    appointment.secondInsuredName = this.parseAppointmentPartDetail(appointment.description, '2ND INSURED NAME:');
    appointment.dateOfNotice = this.parseAppointmentPartDetail(appointment.description, 'DATE OF NOTICE:');
    appointment.owner = this.parseAppointmentPartDetail(appointment.description, 'OWNER:');
    appointment.ownerAddress = this.parseAppointmentPartDetail(appointment.description, 'OWNER ADDRESS:');
    appointment.ownerBusPhone = this.parseAppointmentPartDetail(appointment.description, 'OWNER BUS PHONE:');
    appointment.ownerResPhone = this.parseAppointmentPartDetail(appointment.description, 'OWNER RES PHONE:');
    appointment.lienHolder = this.parseAppointmentPartDetail(appointment.description, 'LIEN HOLDER:');
    appointment.damageEstimateAmount = this.parseAppointmentPartDetail(appointment.description, 'DAMAGE ESTIMATE AMOUNT:');
    appointment.inspectionEstimateAmount = this.parseAppointmentPartDetail(appointment.description, 'INSPECTION ESTIMATE AMOUNT:');
    appointment.driveableInd = this.parseAppointmentPartDetail(appointment.description, 'DRIVEABLE IND:');
    appointment.notes = this.parseAppointmentPartDetail(appointment.description, 'NOTES:');
    appointment.claimSequence = this.parseAppointmentPartDetail(appointment.description, 'SEQUENCE:');
    appointment.vendorSequence = this.parseAppointmentPartDetail(appointment.description, 'VENDOR SEQUENCE:');
    appointment.claimantNumber = this.parseAppointmentPartDetail(appointment.description, 'CLAIMANT NUMBER:');
    appointment.callerContactInfo = this.parseAppointmentPartDetail(appointment.description, 'CALLER CONTACT INFO:');
    appointment.claimantName = this.parseAppointmentPartDetail(appointment.description, 'OWNER:');
    appointment.appraiserName = appointment.id4Txt;

    appointment.distanceText = "distance.text";
    appointment.durationText = "duration.text";

    //Process Multiple Owner Phones
    appointment.ownerBusPhones = appointment.ownerBusPhone.split('(');
    if (appointment.ownerBusPhones.length > 0) {
      var iterator = appointment.ownerBusPhones.length;
      while (--iterator) {
        appointment.ownerBusPhones[iterator] = appointment.ownerBusPhones[iterator].replace(/\D/g, '');
      }
    }
    appointment.ownerResPhones = appointment.ownerResPhone.split('(');
    if (appointment.ownerResPhones.length > 0) {
      var iterator = appointment.ownerResPhones.length;
      while (--iterator) {
        appointment.ownerResPhones[iterator] = appointment.ownerResPhones[iterator].replace(/\D/g, '');
      }
    }

    if (appointment.claimOwner.indexOf('PHONE:') > -1) {
      var claimOwnerPhoneString = appointment.claimOwner.substring(appointment.claimOwner.indexOf('PHONE:') + 6, appointment.claimOwner.length);
      var claimOwnerPhones = claimOwnerPhoneString.split('(');
      if (claimOwnerPhones.length > 0) {
        appointment.claimOwnerPhones = [];
        var iterator = claimOwnerPhones.length;
        while (--iterator) {
          appointment.claimOwnerPhones[iterator] = claimOwnerPhones[iterator].replace(/\D/g, '');
        }
      }
      //appointment.claimOwnerPhone = appointment.claimOwner.substring(appointment.claimOwner.indexOf('PHONE:'), appointment.claimOwner.length).replace(/\D/g,'');
      appointment.claimOwner = appointment.claimOwner.substring(0, appointment.claimOwner.indexOf('PHONE:'));
    }

    var indexOfBodyStart = appointment.description.indexOf('<BODY>');
    var bodyHtml = appointment.description.substring(indexOfBodyStart + 6);
    var indexOfBodyEnd = bodyHtml.indexOf('</BODY>');
    appointment.appointmentDetailHtml = bodyHtml.substring(0, indexOfBodyEnd);

    return appointment;

  };


  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  /** Log a HeroService message with the MessageService */
  private log(message: string) {
    this.messageService.add(`HeroService: ${message}`);
  }

  public trackPage(pageName: string) {
    ga('send', 'pageview', pageName);
  }

}