import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'chk-writer-add-memo',
  templateUrl: './add-memo.component.html',
  styleUrls: ['./add-memo.component.scss']
})
export class AddMemoComponent implements OnInit {

  @Input() claimNumber;
  @Input() selectedInPaymentOf;
  memoText: string;


  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {
    this.memoText = this.selectedInPaymentOf ? this.selectedInPaymentOf : "";
    console.log("memoText : " + this.memoText);
  }

  onMemoUpdate(item: string) {
    console.log("Memo text updated to : " + this.memoText);
  }

}
