import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableModule } from 'primeng/table';
import { AppointmentComponent } from './appointment/appointment.component';
import { AppointmentdetailComponent } from './appointment/appointmentdetail.component';
import { TransmitDialogComponent } from './appointment/transmit.dialog.component';
import { MapComponent } from './appointment/map.component';
import { MultiSelectModule } from 'primeng/multiselect';
import { AppointmentService } from './appointment/appointment.service';
import { StorageService } from '../shared/service/storage.service';
import { CdkTableModule } from '@angular/cdk/table';
import { CdkTreeModule } from '@angular/cdk/tree';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxEditorModule } from 'ngx-editor';
import { NgxPrintModule } from 'ngx-print';
import { BrowserModule } from '@angular/platform-browser';
import { AgmCoreModule } from '@agm/core';
import { AgmJsMarkerClustererModule } from '@agm/js-marker-clusterer';
import { AgmDirectionModule } from 'agm-direction';

import { SendMessageService } from '../shared/service/send.message.service'
import { NotificationComponent } from './appointment/notification.component';
import { AppraiserComponent } from './appointment/appraiser.component';
import { ButtonModule } from 'primeng/button';
import { TooltipModule } from 'primeng/tooltip';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { AngularMaterialModule } from '../../material-module';
import { SelectButtonModule } from 'primeng/selectbutton';
import { environment } from '../../environments/environment';
import { BlockUIModule } from "ng-block-ui";
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from "primeng/dialog";
import { ToastModule } from "primeng/toast";
import { CalendarModule } from "primeng/calendar";
import { LetterComponent } from './letter/letter.component';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { SplitButtonModule } from 'primeng/splitbutton';


@NgModule({
  exports: [
    CdkTableModule,
    CdkTreeModule,
    FormsModule, ReactiveFormsModule,
    TransmitDialogComponent,
    NgxEditorModule,
    NotificationComponent,
    AppraiserComponent, 
    MapComponent
  ],
  imports: [
    CommonModule,
    TableModule,
    ButtonModule,
    SplitButtonModule,
    TooltipModule,
    AutoCompleteModule,
    FormsModule, ReactiveFormsModule,
    NgxEditorModule,
    NgxPrintModule,
    MultiSelectModule,
    SelectButtonModule,
    AngularMaterialModule,
    AgmCoreModule.forRoot({
      apiKey:  environment.GOOGLE_MAPS_API_KEY,
      libraries: ["places"]
    }),
    AgmDirectionModule,
    AgmJsMarkerClustererModule,
    ConfirmDialogModule,
    DialogModule,
    ToastModule,
    CalendarModule,  
    BlockUIModule.forRoot({
      delayStart: 0,
      delayStop: 1000
    }),
    MatSelectModule,
    MatInputModule
  ],
  entryComponents: [TransmitDialogComponent],
  declarations: [AppointmentComponent, AppointmentdetailComponent, TransmitDialogComponent, NotificationComponent, AppraiserComponent, MapComponent, LetterComponent],
  providers: [AppointmentService, StorageService, SendMessageService, {provide: Window, useValue: window }]
})
export class EdispatchModule { }
