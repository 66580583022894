import { EventEmitter, Injectable } from '@angular/core';

import { HttpRequest } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class EventEmitterService {

  constructor() { }

  userLoggedIn : EventEmitter<boolean> = new EventEmitter<boolean>();
  userHouseCounsel : EventEmitter<boolean> = new EventEmitter<boolean>();
  apiInFlight : EventEmitter<boolean> = new EventEmitter<boolean>();

  onUserLoginLogout(flag:boolean) :void {
    this.userLoggedIn.emit(flag);
  }

  onHouseCounselLogin(flag:boolean) :void {
    this.userHouseCounsel.emit(flag);
  }

  /**
   * Stores all currently active requests
   */
  private requests: HttpRequest<any>[] = [];
  
  /**
   * Adds request to the storage and notifies observers
   */
  onAPIStarted(req: HttpRequest<any>): void {
    this.requests.push(req);
    this.notifyApiInFlightObservers();
  }
  
  /**
   * Removes request from the storage and notifies observers
   */
  onAPIFinished(req: HttpRequest<any>): void {
    const index = this.requests.indexOf(req);
    if (index !== -1) {
      this.requests.splice(index, 1);
    }
    this.notifyApiInFlightObservers();
  }
  
  /**
   * Notifies observers about whether there are any requests on fly
   */
  public notifyApiInFlightObservers(): void {
    this.apiInFlight.emit(this.requests.length !== 0);
  }
}
