<div class="container">
  <form (ngSubmit)="login(loginForm)" #loginForm="ngForm">
    <div class="alert alert-danger" role="alert" [hidden]="loginContext.authError==false">
      {{loginContext.authErrorMessage}}
    </div>
    <div class="form-group">
      <label for="name">User Name</label>
      <input type="text" class="form-control" required [(ngModel)]="loginData.userId" name="userId" #userId="ngModel">
      <div [hidden]="userId.valid || userId.pristine" class="alert alert-danger">
        User Name is required
      </div>
    </div>
    <div class="form-group">
      <label for="password">Password</label>
      <input type="password" class="form-control" required [(ngModel)]="loginData.password" name="password"
        #password="ngModel">
      <div [hidden]="password.valid || password.pristine" class="alert alert-danger">
        Password is required
      </div>
    </div>
    <button type="button" class="btn btn-default" (click)="loginForm.reset()">Cancel</button>
    <button type="submit" class="btn btn-primary" [disabled]="!loginForm.form.valid">Login</button>
  </form>
</div>