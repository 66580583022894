import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CheckWriterService } from '../shared/services/check-writer.service';
import { Router } from '@angular/router';

@Component({
  selector: 'eclaimsonesite-payment-options',
  templateUrl: './payment-options.component.html',
  styleUrls: ['./payment-options.component.scss']
})
export class PaymentOptionsComponent implements OnInit {

  constructor(public activeModal: NgbActiveModal, private restUtil: CheckWriterService, public router: Router) { }
  disableButtons: boolean = false;
  ngOnInit() {
    console.log("isPaymentSelectionMade : "+ this.disableButtons);
  }

  @Input()
  isDirectDepositOptedIn: boolean = false;
  @Input()
  isZelleDepositOptedIn: boolean = true;
  @Input()
  isDeluxeDepositOptedIn : boolean = false

  @Input()
  directDepositAccount: string;
  @Input()
  zelleDepositAccount: string;
  @Input()
  deluxeDepositAccount : string;
  

  onPaymentSelection(option: string) {
    console.log("Payment option selected : " + option);
    this.disableButtons = true;
    console.log("after selection isPaymentSelectionMade : "+ this.disableButtons);
    this.activeModal.close(option);
  }

}
