import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild, ElementRef, ViewEncapsulation, NgZone, Input} from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { AppointmentService } from './appointment.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TransmitDialogComponent } from './transmit.dialog.component';
import { keyfilters, dateFormatType, messageType, Appointment } from './appointment';
import { switchMap } from 'rxjs/operators';
import { DatePipe } from '@angular/common/';
import { MatExpansionPanel } from '@angular/material/expansion';
import { MatButton } from '@angular/material/button';
import { MatButtonToggle } from '@angular/material/button-toggle';
import { MessageService} from 'primeng/api';
import { ConfirmationService } from 'primeng/api';
import { LoadingIndicatorService } from '../../check-writer/shared/services/loading-indicator.service';

import { Observable, BehaviorSubject, Observer } from 'rxjs';
import { StorageService } from '../../shared/service/storage.service';
import { GmDistance } from '../../shared/service/GmDistance';
import { environment } from '../../../environments/environment';

import { MapsAPILoader} from '@agm/core';
import { FormControl, Validators } from '@angular/forms';
import { RentalNote } from './appointmentdetail.rentalnote';
import { BlockUIModule } from 'ng-block-ui';

declare var tinymce: any;

@Component({
  selector: "eclaimsonesite-appointmentdetail",
  templateUrl: "./appointmentdetail.component.html",
  styleUrls: ["./appointmentdetail.component.scss"],
  providers: [MessageService, ConfirmationService],
  encapsulation: ViewEncapsulation.None,
})
export class AppointmentdetailComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  public appointment$: any;
  public claimNumber: string;
  public dispatchId: string;
  public editorContent: string;
  public editor: any;
  public transmitBtnLabel = "Transmit To Vendor";
  public transferBtnLabel = "Transfer To";
  public isTransmitDisabled = false;
  public isTransferDisabled = false;
  public showSMSNotitfication = false;
  private maxNoteLength: number = 4000;
  private datePipe: DatePipe = new DatePipe("en-US");
  public phoneNumber: string;
  private userInspectionLocation: any;
  private adjusterCurrentLocatoin: any;

  public CBSPOptInStatusChecked: boolean = false;

  public travelText: any;
  public gmDistance: GmDistance;
  public userDetails: any;
  public loading: boolean = false;
  public claimInfo:any=null;
  
  //IA Rental Notes
  public displayDaysToRepair: boolean = false;
  public hideRentalOption: boolean = true;
  public rentalNoteOptions: RentalNote = new RentalNote();

  currentLocation = { lat: 0, lng: 0 };

  @ViewChild("mainDiv", { static: false }) mainDiv: HTMLDivElement;
  @ViewChild("loadingDiv", { static: false }) loadingDiv: HTMLDivElement;
  @ViewChild("search", { static: false }) search: ElementRef;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private appointmentService: AppointmentService,
    private storageService: StorageService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    public loadingService: LoadingIndicatorService,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    public window: Window,
    public dialog: MatDialog
  ) {
    loadingService.onLoadingChanged.subscribe(
      (isLoading) => (this.loading = isLoading)
    );
  }

  ngOnInit() {
    this.route.paramMap.subscribe((params) => {
      console.log(params.get("claimNumber"));
      this.currentDate = this.plusToDate(
        new Date(
          this.appointmentService.formatDate(dateFormatType.DATETIME_HOURS_ONLY)
        ),
        "hour",
        1
      );
      this.appointment$ = this.appointmentService.getSelectedAppointment(
        params.get("claimNumber"),
        params.get("dispId")
      );
      this.claimNumber = params.get("claimNumber");
      this.dispatchId = params.get("dispId");
      this.getClaimInfoByClaimNumber(this.claimNumber);
      this.checkSMSNotificaionEligible(this.appointment$);
      this.userDetails = JSON.parse(this.storageService.get("user-details"));
      this.checkClaimsBodyShopOptInFlagByclaimId(this.appointment$.id1);
      this.hideRentalNoteOptions(this.appointment$.id10Txt,this.claimInfo);
      //console.info(this.userDetails);

      //only AFTER google maps loaded
      this.mapsAPILoader.load().then(() => {
        this.bindAutoComplete();
        try {
          this.gmDistance = JSON.parse(
            this.storageService.get(
              this.appointment$.appointmentID + "-distanceInfo"
            )
          );
          console.info("Travel Distance", this.gmDistance);
          //this.travelText=this.gmDistance.displayText;
          this.travelText = new Observable<string>(
            (observer: Observer<string>) => {
              observer.next(this.gmDistance.displayText.toString());
            }
          );
        } catch (e) {
          //console.error(e);

          console.info(
            "Distance Not Found In Storage, Calling Google API if the appointment is not closed ! [status] = " +
              this.appointment$.status
          );
          var isSetlocation = false;
          this.gmDistance = new GmDistance(
            this.appointment$.appointmentID,
            "",
            "",
            ""
          );
          if (window.navigator && window.navigator.geolocation) {
            window.navigator.geolocation.getCurrentPosition(
              (position) => {
                console.log(position);
                this.currentLocation.lat = position.coords.latitude;
                this.currentLocation.lng = position.coords.longitude;
                console.log("currentLocation", this.currentLocation);

                this.userInspectionLocation =
                  this.appointment$.inspectionLocation;
                this.adjusterCurrentLocatoin = this.currentLocation;
                this.travelText = new Observable<string>(
                  (observer: Observer<string>) => {
                    let travelText =
                      this.appointment$.status != "C" ? "loading..." : "away";

                    var durationText = "";
                    var distanceText = "";
                    //=============================================================
                    if (this.appointment$.status != "C") {
                      var service = new google.maps.DistanceMatrixService();
                      service.getDistanceMatrix(
                        {
                          origins: [this.adjusterCurrentLocatoin],
                          destinations: [this.userInspectionLocation],
                          travelMode: google.maps.TravelMode.DRIVING,
                          unitSystem: google.maps.UnitSystem.IMPERIAL,
                          avoidHighways: false,
                          avoidTolls: false,
                          drivingOptions: {
                            trafficModel: google.maps.TrafficModel.BEST_GUESS,
                            departureTime: new Date(Date.now() + 10000),
                          },
                        },
                        function (response, status) {
                          console.info(
                            "response",
                            response,
                            "status",
                            status,
                            status === "OK"
                          );
                          if (status === "OK") {
                            var durationText =
                              response.rows[0].elements[0].duration.text;
                            var distanceText =
                              response.rows[0].elements[0].distance.text;
                            console.info(
                              response.rows[0].elements[0].distance,
                              response.rows[0].elements[0].duration,
                              response.rows[0].elements[0].duration_in_traffic
                            );
                            travelText =
                              durationText + " - ( " + distanceText + " )";
                            //console.info("travelText",travelText,this.durationText,this.distanceText);
                            //this.session.set("distanceText",this.distanceText);
                            isSetlocation = true;
                          } else {
                            console.info("MAP Distance Service", status);
                          }
                        }
                      );
                    } // Google Distance API CALL
                    //==============================================================

                    console.log(
                      "AppointmentdetailComponent",
                      this.appointment$
                    );
                    setInterval(() => {
                      if (isSetlocation) {
                        var tmpText = travelText
                          .toString()
                          .replace(" - ( ", "|")
                          .replace(" )", "|")
                          .split("|");
                        this.gmDistance.displayText = travelText;
                        this.gmDistance.distanceText = tmpText[1];
                        this.gmDistance.durationText = tmpText[0];
                        //this.storageService.save("traveldistanceInfo", this.gmDistance);
                        this.storageService.save(
                          this.appointment$.appointmentID + "-distanceInfo",
                          this.gmDistance
                        );
                        isSetlocation = false;
                      }
                      observer.next(travelText.toString());
                      //console.info("travelText",travelText,this.durationText,this.distanceText);
                    }, 500);
                  }
                );
              },
              (error) => {
                switch (error.code) {
                  case 1:
                    console.log("Permission Denied");
                    break;
                  case 2:
                    console.log("Position Unavailable");
                    break;
                  case 3:
                    console.log("Timeout");
                    break;
                }
              }
            );
          } else {
            alert("Geolocation is not supported by this browser.");
          }
        }
      });
    });
    //console.log("AppointmentdetailComponent  ngOnInit=" + JSON.stringify(this.appointment$));
  }

  public bindAutoComplete() {
    let autocomplete = new google.maps.places.Autocomplete(
      this.search.nativeElement
    );
    autocomplete.addListener("place_changed", () => {
      this.ngZone.run(() => {
        //get the place result
        let place: google.maps.places.PlaceResult = autocomplete.getPlace();
        //verify result
        if (place.geometry === undefined || place.geometry === null) {
          return;
        }

        let lat_double = place.geometry.location.lat();
        let long_double = place.geometry.location.lng();
        let precision_string = place.geometry["location_type"]
          ? place.geometry["location_type"].toString()
          : place.types.toString();
        let formatted_address_string = place.formatted_address.toString();
        let street_number_string = "";
        let street_string = "";
        let town_string = "";
        let zip_string = "";
        let county_string = "";
        let state_string = "";

        var address = {
          home: { street_number: "" },
          postal_code: { postal_code: "" },
          street: { street_address: "", route: "" },
          region: {
            administrative_area_level_1: "",
            administrative_area_level_2: "",
            administrative_area_level_3: "",
            administrative_area_level_4: "",
            administrative_area_level_5: "",
          },
          city: {
            locality: "",
            sublocality: "",
            neighborhood: "",
            sublocality_level_1: "",
            sublocality_level_2: "",
            sublocality_level_3: "",
            sublocality_level_4: "",
          },
          country: { country: "" },
        };

        this.getAddressObject(place.address_components, address);

        street_number_string = address.home["street_number"];
        street_string = address.street["route"];
        zip_string = address.postal_code["postal_code"];
        state_string = address.region["administrative_area_level_1"];
        let regexQuote = new RegExp(" county", "gi");
        county_string = address.region["administrative_area_level_2"].replace(
          regexQuote,
          ""
        );
        town_string =
          formatted_address_string.indexOf(address.city["locality"]) > 0
            ? address.city["locality"]
            : town_string;
        town_string =
          formatted_address_string.indexOf(address.city["sublocality"]) > 0
            ? address.city["sublocality"]
            : town_string;
        town_string =
          formatted_address_string.indexOf(address.city["neighborhood"]) > 0
            ? address.city["neighborhood"]
            : town_string;
        town_string =
          formatted_address_string.indexOf(
            address.city["sublocality_level_1"]
          ) > 0
            ? address.city["sublocality_level_1"]
            : town_string;
        town_string =
          formatted_address_string.indexOf(
            address.city["sublocality_level_2"]
          ) > 0
            ? address.city["sublocality_level_2"]
            : town_string;
        town_string =
          formatted_address_string.indexOf(
            address.city["sublocality_level_3"]
          ) > 0
            ? address.city["sublocality_level_3"]
            : town_string;
        town_string =
          formatted_address_string.indexOf(
            address.city["sublocality_level_4"]
          ) > 0
            ? address.city["sublocality_level_4"]
            : town_string;
        town_string =
          formatted_address_string.indexOf(
            address.region["administrative_area_level_3"]
          ) > 0
            ? address.region["administrative_area_level_3"]
            : town_string;
        this.appointment$.locationAddrLn1Txt = [
          street_number_string,
          street_string,
        ].join(" ");
        this.appointment$.locationAddrLn2Txt = "";
        this.appointment$.locationCityName = town_string;
        this.appointment$.locationSubdName = county_string;
        this.appointment$.locationStateCd = state_string;
        this.appointment$.locationZipCd = zip_string;
        this.appointment$.locationLat = lat_double;
        this.appointment$.locationLong = long_double;
        this.appointment$.locationPrecision = precision_string;

        this.appointment$.locationChanged = true;
      });
    });
  }

  getAddressObject = function (address_components, address) {
    let ShouldBeComponent = {
      home: ["street_number"],
      postal_code: ["postal_code"],
      street: ["street_address", "route"],
      region: [
        "administrative_area_level_1",
        "administrative_area_level_2",
        "administrative_area_level_3",
        "administrative_area_level_4",
        "administrative_area_level_5",
      ],
      city: [
        "locality",
        "sublocality",
        "neighborhood",
        "sublocality_level_1",
        "sublocality_level_2",
        "sublocality_level_3",
        "sublocality_level_4",
      ],
      country: ["country"],
    };

    address_components.forEach((component) => {
      Object.keys(ShouldBeComponent).forEach((shouldBe) => {
        if (ShouldBeComponent[shouldBe].indexOf(component.types[0]) !== -1) {
          if (
            shouldBe === "country" ||
            component.types[0] === "administrative_area_level_1"
          ) {
            address[shouldBe][component.types[0]] = component.short_name;
          } else {
            address[shouldBe][component.types[0]] = component.long_name;
          }
        }
      });
    });
    return address;
  };

  ngAfterViewInit() {
    const messageEditorComponent = this;

    tinymce.init({
      selector: "#noteTextAreaId",
      plugins: ["link", "paste", "table", "textcolor", "colorpicker"],
      browser_spellcheck: true,
      skin_url: "../assets/skins/lightgray",
      branding: false,
      elementpath: false,
      statusbar: false,
      menubar: false,
      height: 250,
      base_url: "/tinymce",
      suffix: ".min",
      init_instance_callback: (editor) => {},

      setup: (editor) => {
        this.editor = editor;

        editor.on("keyup", () => {
          this.editorContent = editor.getContent();
        });

        editor.on("blur", () => {
          this.editorContent = editor.getContent();
        });
      },
    });
  }

  noteTypeChanged() {
    if (this.rentalNoteOptions.specialNoteType == "general") {
      this.noteFunction(false, null, "", true);
    }
    if (this.rentalNoteOptions.specialNoteType == "days") {
      this.noteFunction(true, null, "", true);
    }
    if (this.rentalNoteOptions.specialNoteType == "repair") {
      this.noteFunction(false, null, "<b>"+this.appointment$.owner+" Repair Escalation</b>", true);
    }
    if (this.rentalNoteOptions.specialNoteType == "total") {
      this.noteFunction(false, null, "<b>"+this.appointment$.owner+" Vehicle is a Total Loss and has an Escalation. Immediate Action is needed.</b>", true);
    }
  }

  noteCancelClear() {
    this.rentalNoteOptions.specialNoteType = "general";
    this.noteFunction(false, null, "", true);
  }
  
  public noteFunction = function(showDaysToRepair: boolean, numDays: string, noteText: string, editable: boolean){
    this.displayDaysToRepair = showDaysToRepair;
    this.rentalNoteOptions.daysToRepair = (numDays == null ? null : Number(numDays));
    this.rentalNoteOptions.NoteText = noteText;
    tinymce.get('noteTextAreaId').setContent(noteText);
    this.editorContent = noteText; //Needed for validation
    tinymce.get('noteTextAreaId').getBody().setAttribute('contenteditable', editable);
  }

  messageApplyCss = function (msgType: messageType, msgHtml: string): string {
    let ret: string = msgHtml;
    switch (msgType) {
      case messageType.dispatchActions:
        ret = ret.replace(
          new RegExp("(.*?)([^a-z])(FAIL.*?)([^a-z]|$)(.*?)", "gi"),
          "$1$2<FONT class='markFail'>$3</FONT>$4$5"
        );
        ret = ret.replace(
          new RegExp("(.*?)([^a-z])(BYPASSED.*?)([^a-z]|$)(.*?)", "gi"),
          "$1$2<FONT class='markBypass'>$3</FONT>$4$5"
        );
        ret = ret.replace(
          new RegExp("(.*?)([^a-z])(SUCCESS.*?)([^a-z]|$)(.*?)", "gi"),
          "$1$2<FONT class='markSuccess'>$3</FONT>$4$5"
        );
        ret = ret.replace(
          new RegExp("(.*?)([^a-z])(ERROR.*?)([^a-z]|$)(.*?)", "gi"),
          "$1$2<FONT class='markError'>$3</FONT>$4$5"
        );
        ret = ret.replace(
          new RegExp("(.*?)([^a-z])(null.*?)([^a-z]|$)(.*?)", "gi"),
          "$1$2<FONT class='markBypass'>N/A</FONT>$4$5"
        );
      default:
    }
    return ret;
  };

  showMessage = function (
    msgSeverity: String,
    msgSummary: String,
    msgDetail: String,
    sticky: boolean = false,
    closable: boolean = true
  ) {
    // this.showMessage('error', 'Error', "No transactions selected to activate");
    this.messageService.add({
      severity: msgSeverity,
      summary: msgSummary,
      detail: msgDetail,
      sticky: sticky,
      closable: closable,
    });
  };

  confirm = function (
    confirmMessage,
    acceptFunction,
    rejectMessage,
    rejectFunction,
    acceptLbl = "Yes",
    rejectLbl = "No",
    rejectVsbl = true
  ) {
    // this.confirm(
    //   "Do you really want to activate the " + (selectedIdsArr.length > 50 ? selectedIdsArr.length : "") + " transactions selected below?"
    //   , "this.activateTransactionsById();"
    //   , "Transactions Action Confirmation"
    //   , "{confirmationAnswer = 'Reject'; return;}"
    //   , "Yes"
    // );

    // if (confirmationAnswer === 'Reject') {
    //   return;
    // }
    this.confirmationService.confirm({
      message: confirmMessage,
      accept: () => {
        if (typeof acceptFunction === "function") {
          acceptFunction();
        } else {
          let tmpFunc = new Function(acceptFunction).bind(this);
          tmpFunc();
        }
      },
      reject: () => {
        this.showMessage("warn", "Rejected", rejectMessage);
        if (typeof rejectFunction === "function") {
          rejectFunction();
        } else {
          let tmpFunc = new Function(rejectFunction).bind(this);
          tmpFunc();
        }
      },
      acceptLabel: acceptLbl,
      rejectLabel: rejectLbl,
      rejectVisible: rejectVsbl,
    });
  };

  keyfilters: keyfilters = new keyfilters();
  DateYearIsLeap = function (year) {
    return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
  };

  DateDaysInMonth = function (year, month) {
    return [
      31,
      this.DateYearIsLeap(year) ? 29 : 28,
      31,
      30,
      31,
      30,
      31,
      31,
      30,
      31,
      30,
      31,
    ][month];
  };

  DateIsLeapYear = function (date) {
    return this.DateYearIsLeap(date.getFullYear());
  };

  DateGetDaysInMonth = function (date) {
    return this.DateDaysInMonth(date.getFullYear(), date.getMonth());
  };

  DateAddMonths = function (date: Date, value) {
    // var n = date.getDate();
    // var newdate = date;
    // newdate.setMonth(date.getMonth() + value);
    // newdate.setDate(Math.min(n, this.DateGetDaysInMonth(date)));
    // return newdate;
    let curMonth: number = date.getMonth();
    let monthSum: number = curMonth + parseInt(value);
    let newMonth: number = (12 + (monthSum % 12)) % 12;
    let newYear: number = date.getFullYear() + Math.floor(monthSum / 12);
    let newDate = new Date(newYear, newMonth, date.getDate());
    return newDate.getMonth() != newMonth
      ? new Date(newDate.setDate(0))
      : newDate;
  };

  plusToDate = function (currentDate, unit, howMuch) {
    //this.plusToDate(date, "day", -1);
    const monthMs =
      86400000 *
      (unit === "month" && howMuch === 1
        ? this.DateDaysInMonth(currentDate.getYear(), currentDate.getMonth())
        : 30);
    const yearMs = 86400000 * (this.DateIsLeapYear(currentDate) ? 366 : 365);
    const config = {
      second: 1000, // 1000 miliseconds
      minute: 60000,
      hour: 3600000,
      day: 86400000,
      week: 604800000,
      month: monthMs,
      year: yearMs,
    };
    const now = new Date(currentDate);
    // override logic for month
    if (unit === "month") {
      return this.DateAddMonths(now, howMuch);
    } else {
      const beforeOrAfter = new Date(now.valueOf() + config[unit] * howMuch);
      return beforeOrAfter;
    }
  };

  dateDiff = function (date1, date2, interval) {
    const second = 1000,
      minute = second * 60,
      hour = minute * 60,
      day = hour * 24,
      week = day * 7;
    date1 = new Date(date1.toUTCString());
    date2 = new Date(date2.toUTCString());

    const timediff = date2 - date1;
    if (isNaN(timediff)) {
      return NaN;
    }
    switch (interval) {
      case "years":
        return date2.getFullYear() - date1.getFullYear();
      case "months":
        return (
          date2.getFullYear() * 12 +
          date2.getMonth() -
          (date1.getFullYear() * 12 + date1.getMonth())
        );
      case "weeks":
        return Math.floor(timediff / week);
      case "days":
        return Math.floor(timediff / day);
      case "hours":
        return Math.floor(timediff / hour);
      case "minutes":
        return Math.floor(timediff / minute);
      case "seconds":
        return Math.floor(timediff / second);
      default:
        return undefined;
    }
  };

  minApptMinutes: number = 60;
  maxApptMinutes: number = 12 * 60;
  minMaxDate = function (fieldName: string, fieldVal: Date): Date {
    let retDate: Date;
    retDate = this.plusToDate(
      fieldVal,
      "minute",
      fieldName == "startDateTime" ? this.minApptMinutes : -this.minApptMinutes
    );
    return retDate;
  };

  //p-calendar doesn't know how to handle a function call for minDate, maxDate, defaultDate, by the way
  currentDate: Date = new Date();

  onFocus(event, prefill: string = "") {
    //console.log(event.target);
    //return true;
    setTimeout(
      function () {
        if (typeof event.target.select === "function") {
          if (event.target.value === "") {
            if (prefill && prefill !== "") {
              event.target.value = prefill;
            }
          } else {
            event.target.select();
          }
        }
      }.bind(this),
      100
    );
  }

  onCalendarSelect(event: any, fieldName: string) {
    console.log("Calendar entry changed", event);
    //next is used to show Update button
    this.appointment$.dataChanged = true;

    //this is to keep the interval under control
    let currentDate = new Date();
    let minMaxDateTime = this.minMaxDate(fieldName, event || currentDate);
    let diffTime: number;
    switch (fieldName) {
      case "startDateTime":
        diffTime = this.dateDiff(
          event,
          this.appointment$.endDateTime || currentDate,
          "minutes"
        );
        if (diffTime < this.minApptMinutes || diffTime > this.maxApptMinutes) {
          this.appointment$.endDateTime = minMaxDateTime;
        }
        break;
      case "endDateTime":
        diffTime = this.dateDiff(
          this.appointment$.startDateTime || currentDate,
          event,
          "minutes"
        );
        if (diffTime < this.minApptMinutes || diffTime > this.maxApptMinutes) {
          this.appointment$.startDateTime = minMaxDateTime;
        }
        break;
      default:
    }
  }

  updateAppointment() {
    if (!(this.appointment$.startDateTime && this.appointment$.endDateTime)) {
      this.showMessage(
        "error",
        "Failed to save appointment",
        "Appointment start/end date/time is missing",
        true,
        true
      );
      return;
    }

    this.appointmentService.updateAppointment(this.appointment$).subscribe(
      (data) => {
        let apptId: string = data["appointmentID"];
        this.showMessage(
          "success",
          "Success",
          "Appointment " + apptId + " update completed"
        );
        //get appt details again, to reflect changes, no matter if success or fail
        this.appointmentService
          .getAppointment(this.appointment$.appointmentID)
          .subscribe(
            (data) => {
              //refresh, if anything has changed by chance
              window.location.reload();
            },
            (error) => {
              console.log("Failed to refresh the appointment");
            }
          );
      },
      (error) => {
        console.log("Failed to save the appointment");
        console.log(error.message);
        this.showMessage(
          "error",
          error.statusText || "Failed to save appointment",
          error.message || JSON.stringify(error),
          true,
          true
        );
        //get appt details again, to reflect changes, no matter if success or fail
        this.appointmentService
          .getAppointment(this.appointment$.appointmentID)
          .subscribe(
            (data) => {
              //refresh, if anything has changed by chance
              window.location.reload();
            },
            (error) => {
              console.log("Failed to refresh the appointment");
            }
          );
      }
    );
  }

  onLocationComponentChange = function (addressComponent: string) {
    this.appointment$.locationChanged = true;
  };

  updateLocation() {
    if (
      ![
        this.appointment$.locationCityName,
        this.appointment$.locationStateCd,
        this.appointment$.locationZipCd,
        this.appointment$.locationAddrLn1Txt,
      ]
        .join(" ")
        .trim()
    ) {
      this.showMessage(
        "error",
        "Cannot save location",
        "Appointment address components missing",
        true,
        true
      );
      return;
    }

    this.appointmentService.updateLocation(this.appointment$).subscribe(
      (data) => {
        this.showMessage("success", "Success", "Location update completed");
        //get appt details again, to reflect changes, no matter if success or fail
        this.appointmentService
          .getAppointment(this.appointment$.appointmentID)
          .subscribe(
            (data) => {
              //refresh, if anything has changed by chance
              window.location.reload();
            },
            (error) => {
              console.log("Failed to refresh the appointment");
            }
          );
      },
      (error) => {
        console.log("Failed to save location");
        console.log(error.message);
        this.showMessage(
          "error",
          error.statusText || "Failed to save location",
          error.message || JSON.stringify(error),
          true,
          true
        );
        setInterval(() => {
          //get appt details again, to reflect changes, no matter if success or fail
          this.appointmentService
            .getAppointment(this.appointment$.appointmentID)
            .subscribe(
              (data) => {
                //refresh, if anything has changed by chance
                window.location.reload();
              },
              (error) => {
                console.log("Failed to refresh the appointment");
              }
            );
        }, 10000);
      }
    );
  }

  getClaimInfoByClaimNumber(claimNumber:string){
    this.appointmentService.getClaimInfo(claimNumber).subscribe( ( data: any ) => { 

      console.log(data.statusCode); 
      if(data.statusCode=="SUCCESS"){
        this.claimInfo= JSON.parse(data.statusMessage);
        console.log("ClaimInfo ["+claimNumber+"]", this.claimInfo);
        
        this.hideRentalNoteOptions(this.appointment$.id10Txt,this.claimInfo);
      }

    }, 
       error => { 
          console.log('Failed to get the claim Info');       
       }
    );
  }

  //Check if claim is egligible for notification
  checkClaimsBodyShopOptInFlagByclaimId(claimId: string): boolean {
    //Check if the Claim's Body Shop Opt-In for EMAIL Notification
    this.appointmentService
      .getClaimsBodyShopOptInFlagByclaimId(claimId)
      .subscribe(
        (response: any) => {
          console.log("Success getting CBSP email optin flag ");
          console.info(response);
          console.log(
            "response.claims[0].cbspOptFlag",
            response.claims[0].assignments[0].cbspOptFlag
          );
          if (
            response &&
            response.claims[0] &&
            response.claims[0].assignments[0].cbspOptFlag
          ) {
            this.CBSPOptInStatusChecked =
              response.claims[0].assignments[0].cbspOptFlag == "Y";
            //console.info("checkClaimsBodyShopOptInFlagByclaimId CBSPOptInStatusChecked", this.CBSPOptInStatusChecked);
          } else {
            //this.CBSPOptInStatusChecked=(environment.envName!='prod');
            this.CBSPOptInStatusChecked = false;
          }
          return this.CBSPOptInStatusChecked;
        },
        (error) => {
          console.log("Failed to get optin CBSP email optin flag");
        }
      );
    return false;
  }

  onCBSPOptInChange() {
    console.log("CBSPOptInStatusChecked", this.CBSPOptInStatusChecked);
    let optStatus = this.CBSPOptInStatusChecked ? "Y" : "N";
    this.appointmentService
      .saveClaimsBodyShopOptInFlag(optStatus, this.appointment$.id6)
      .subscribe(
        (result) => {
          console.log("result", result);
          console.log("Success saving the CBSPOptInStatus");
        },
        (error) => {
          console.log("error", error.status, error);
          console.log("Failed to save CBSPOptInStatus");
        }
      );
  }

  getCBSPLink() {
    let claimNumber = this.appointment$.claimNumber;
    let vin = this.appointment$.vehicleVin;
    let current_timestamp = new Date().getTime();
    let userId = this.userDetails.streamAssociations[0].streamId; //this.appointment$.id6Txt;//this.userDetails.claimSystemLoginId;
    console.info(
      "streamAssociations",
      this.userDetails.streamAssociations[0].streamId
    );

    // Encode the String
    //let urlParams = userId+"/"+claimNumber+"/"+vin;
    let urlParams_wts =
      current_timestamp + "/" + userId + "/" + claimNumber + "/" + vin;
    //let encodedString = btoa(urlParams);
    //console.log("urlParams",urlParams,"encodedString",encodedString);
    let encodedString_wts = btoa(urlParams_wts);
    //console.log("urlParams_wts",urlParams_wts,"encodedString",encodedString_wts);
    // Decode the String
    //let decodedString = atob(encodedString);
    //console.log("decodedString",decodedString);

    //let decodedStringWts = atob(encodedString_wts);
    //console.log("decodedString",decodedStringWts);

    // let url = environment.CBSP_BASE_URL+"handshake/"+encodedString;
    // console.info("CBSP Link",url, "claimNumber",claimNumber, "vin",vin);

    let url_wts = environment.CBSP_BASE_URL + "handshake/" + encodedString_wts;
    //let url_B4 = environment.CBSP_BASE_URL+"handshake/"+urlParams_wts;
    //console.info("CBSP Link B4 encode ",url_B4, "claimNumber",claimNumber, "vin",vin, "User Id",userId);
    console.info(
      "CBSP Link after",
      url_wts,
      "claimNumber",
      claimNumber,
      "vin",
      vin
    );

    if (url_wts != "" && url_wts != undefined) {
      window.open(url_wts, "_blank");
    }
  }

  ngOnDestroy() {
    tinymce.remove(this.editor);
  }

  backToAppointmentsList() {
    //console.log( " row=" + JSON.stringify(row ));
    this.router.navigateByUrl("/appointments");
  }

  showMap(userId, userName) {
    this.router.navigateByUrl("/map/" + userId + "/" + userName);
  }

  scrubPhoneNumber = function (phoneNumber) {
    if (phoneNumber) {
      return phoneNumber.replace(/\D/g, "");
    } else {
      return "";
    }
  };

  formatPhone(phoneNumber) {
    if (phoneNumber && phoneNumber.length > 10) {
      return this.scrubPhoneNumber(phoneNumber).replace(
        /(\d{3})(\d{3})(\d{4})(\d{1})/,
        "($1) $2-$3 x$4"
      );
    } else {
      return this.scrubPhoneNumber(phoneNumber).replace(
        /(\d{3})(\d{3})(\d{4})/,
        "($1) $2-$3"
      );
    }
  }

  getPhoneWithoutExtenstion(phoneNumber) {
    if (phoneNumber && phoneNumber.length > 10) {
      return this.scrubPhoneNumber(phoneNumber).substring(0, 10);
    } else {
      return phoneNumber;
    }
  }

  transmitToVendor() {
    this.transmitBtnLabel = "Transmitting...";
    this.isTransmitDisabled = true;
    this.isTransmitDisabled = true;
    this.appointmentService
      .transmitAssignmentToVendor(this.appointment$)
      .subscribe(
        (result) => {
          this.showMessage(
            "success",
            "Transmission Confirmation",
            this.messageApplyCss(
              messageType.dispatchActions,
              result
                .toString()
                .replace(
                  new RegExp(
                    "(.+?<responseMessage>)(.+?)(</responseMessage>)(.+?)$",
                    "gi"
                  ),
                  "$2"
                )
            ),
            true,
            true
          );
          this.transmitBtnLabel = "Transmit To Vendor";
          this.isTransmitDisabled = false;
        },
        (error) => {
          // FIX THIS
          // handling success in the error clause based on status
          if (error.status == "200") {
            this.showMessage(
              "success",
              "Transmission Confirmation",
              this.messageApplyCss(
                messageType.dispatchActions,
                error.error.text.replace(
                  new RegExp(
                    "(.+?<responseMessage>)(.+?)(</responseMessage>)(.+?)$",
                    "gi"
                  ),
                  "$2"
                )
              ),
              true,
              true
            );
          } else {
            this.showMessage(
              "error",
              error.statusText || "Failed to transmit to vendor",
              error.message || JSON.stringify(error),
              true,
              true
            );
          }
          this.transmitBtnLabel = "Transmit To Vendor";
          this.isTransmitDisabled = false;
        }
      );
  }

  transferToMyself(appointment$: Appointment, doConfirmation: boolean = true) {
    let continueTransferring = function () {
      this.transferToMyself(appointment$, false);
    }.bind(this);

    if (doConfirmation === true) {
      this.confirm(
        "Please confirm that you want to transfer the assignment to " +
          this.userDetails["fullName"],
        continueTransferring,
        "Transfer Confirmation",
        "{return;}",
        "OK",
        "Cancel",
        true
      );
      return;
    }

    this.transferBtnLabel = "Transferring To";
    this.isTransferDisabled = true;
    this.appointmentService
      .transferAssignmentToUser(appointment$, this.userDetails.userId)
      .subscribe(
        (result) => {
          this.showMessage(
            "success",
            "Transfer Confirmation",
            this.messageApplyCss(
              messageType.dispatchActions,
              result
                .toString()
                .replace(
                  new RegExp(
                    "(.+?<responseMessage>)(.+?)(</responseMessage>)(.+?)$",
                    "gi"
                  ),
                  "$2"
                )
            ),
            true,
            true
          );
          this.transferBtnLabel = "Transfer To";
          this.isTransferDisabled = false;
          //get appt details again, to reflect changes
          this.appointmentService
            .getAppointment(this.appointment$.appointmentID)
            .subscribe(
              (data) => {
                setTimeout(
                  function () {
                    //refresh, if anything has changed by chance
                    window.location.reload();
                  }.bind(this),
                  5000
                );
              },
              (error) => {
                console.log("Failed to refresh the appointment");
              }
            );
        },
        (error) => {
          // handling success in the error clause based on status
          if (error.status == "200") {
            this.showMessage(
              "success",
              "Transfer Confirmation",
              this.messageApplyCss(
                messageType.dispatchActions,
                error.error.text.replace(
                  new RegExp(
                    "(.+?<responseMessage>)(.+?)(</responseMessage>)(.+?)$",
                    "gi"
                  ),
                  "$2"
                )
              ),
              true,
              true
            );
          } else {
            this.showMessage(
              "error",
              error.statusText ||
                "Failed to transfer to " + this.userDetails["fullName"],
              error.message || JSON.stringify(error),
              true,
              true
            );
          }
          this.transferBtnLabel = "Transfer To";
          this.isTransferDisabled = false;
          //get appt details again, to reflect changes, no matter if success or fail
          this.appointmentService
            .getAppointment(this.appointment$.appointmentID)
            .subscribe(
              (data) => {
                setTimeout(
                  function () {
                    //refresh, if anything has changed by chance
                    window.location.reload();
                  }.bind(this),
                  5000
                );
              },
              (error) => {
                console.log("Failed to refresh the appointment");
              }
            );
        }
      );
  }

  issuePayment() {
    let claimNumber = this.appointment$.claimNumber;
    if (claimNumber.indexOf("-") > -1) {
      console.log("Claim has the extra fields so remove them...");
      claimNumber = claimNumber.subscribe(0, claimNumber.indexOf("-"));
    } else if (claimNumber.length > 12) {
      claimNumber = claimNumber.subscribe(0, 12);
    }
    console.log(
      this.appointment$.claimNumber +
        " vs trimmed claim number : " +
        claimNumber
    );
    let dispId = this.appointment$.id6;
    console.log(
      "Navigating to Make payment for : Claim : " +
        claimNumber +
        ", dispatch : " +
        dispId
    );
    this.router.navigateByUrl("/checkWriter/" + claimNumber + "/" + dispId);
  }

  issueLetter() {
    console.log(
      "Initiate the Letter for Claim : " + this.appointment$.claimNumber
    );
    let claimNumber: string = this.appointment$.claimNumber;
    if (claimNumber.indexOf("-") > -1) {
      console.log("Claim has the extra fields so remove them...");
      claimNumber = claimNumber.substring(0, claimNumber.indexOf("-"));
    } else if (claimNumber.length > 12) {
      claimNumber = claimNumber.substring(0, 12);
    }
    let letterCode: string = "STOP_STORAGE";
    console.log(this.appointment$.claimNumber + " Letter Code : " + letterCode);
    let lossTypeCd: string = this.appointment$.id11Txt;
    let streamUnitId: string = this.appointment$.id5;
    let claimUnitAssignId: string = this.appointment$.id4;
    let claimCompany: string = this.appointment$.underwritingCo;
    this.storageService.save("letter_claimNumber", claimNumber);
    this.storageService.save("letter_streamUnitId", streamUnitId);
    this.storageService.save("letter_lossTypeCd", lossTypeCd);
    this.storageService.save("letter_claimUnitId", claimUnitAssignId);
    this.storageService.save("letter_claimCompnay", claimCompany);
    // Defaulting the letter code to stop_storage.
    this.storageService.save("letter_code", letterCode);
    console.log(
      "Navigating to issue letter for Claim : " +
        claimNumber +
        ", lossTypeCd : " +
        lossTypeCd +
        ", streamUnitId : " +
        streamUnitId +
        claimUnitAssignId
    );
    this.router.navigateByUrl("/letter/" + letterCode);
  }

  saveNote() {
    if (this.editorContent && this.editorContent.length > this.maxNoteLength) {
      const dialogRef = this.dialog.open(TransmitDialogComponent, {
        width: "250px",
        data: {
          header: "Save Note",
          message:
            "Oops, Note length cannot be more than " +
            this.maxNoteLength +
            " characters.  Please try again.",
          retry: false,
        },
      });
     } else if (!this.editorContent || this.editorContent.length == 0) {
      const dialogRef = this.dialog.open(TransmitDialogComponent, {
        width: "250px",
        data: {
          header: "Save Note",
          message:
            "Oops, Please enter a note and try again.",
          retry: false,
        },
      });
    } else if (this.rentalNoteOptions.specialNoteType != 'general') {
      //IA Rental Note
      this.rentalNoteOptions.claimNumber = this.appointment$.claimNumber;
      this.rentalNoteOptions.dispatchID = this.appointment$.id6;
      this.rentalNoteOptions.assignmentID = this.appointment$.id4;
      this.rentalNoteOptions.noteText = this.editorContent.replace(/\r\n|\r|\n/g,"<br />");
      
      
      this.appointmentService.
      saveNoteToStream_Rental(
        this.rentalNoteOptions
        )
        .subscribe(
          (result) => {
            console.log("result", result);
            if(result['status']=='S'){
            console.log("Success saving the note in stream");  
            //let strmImpResult = JSON.parse(result["result"])
            let strmImpResult = result["result"];
            console.log("stream Import Result", strmImpResult);          
            // const dialogRef = this.dialog.open(TransmitDialogComponent, {
            //   width: "250px",
            //   data: {
            //     header: "Save Note",
            //     message:
            //       "Success saving the note ["+strmImpResult['noteCreated']+"] & WorkItem ["+strmImpResult['workItemCreated']+"]in stream",
            //     retry: false,
            //   },
            // });

            if(strmImpResult['noteCreated']){
              this.appointmentService
                .saveNoteToEClaim(this.editorContent, this.appointment$)
                .subscribe(
                  (data) => {
                    this.editorContent = "";
                    this.editor.setContent(this.editorContent);
                    this.showMessage(
                      "success",
                      "Success",
                      "Success saving the note ["+strmImpResult['noteCreated']+"] & WorkItem ["+strmImpResult['workItemCreated']+"]",
                    );
                    //get appt details again, to reflect changes, no matter if success or fail
                    // this.appointmentService
                    //   .getAppointment(this.appointment$.appointmentID)
                    //   .subscribe(
                    //     (data) => {
                    //       //refresh, if anything has changed by chance
                    //       window.location.reload();
                    //     },
                    //     (error) => {
                    //       console.log("Failed to refresh the appointment");
                    //     }
                    //   );
                    
                  },
                  (error) => {
                    console.log("Failed to save note");
                    console.log(error.message);
                    this.showMessage(
                      "error",
                      error.statusText || "Failed to save note",
                      error.message || JSON.stringify(error),
                      true,
                      true
                    );
                    setInterval(() => {
                      //get appt details again, to reflect changes, no matter if success or fail
                      this.appointmentService
                        .getAppointment(this.appointment$.appointmentID)
                        .subscribe(
                          (data) => {
                            //refresh, if anything has changed by chance
                            window.location.reload();
                          },
                          (error) => {
                            console.log("Failed to refresh the appointment");
                          }
                        );
                    }, 10000);
                  }
                  
                );
            }
            if(!strmImpResult['workItemCreated'] && strmImpResult['tasks'].indexOf("workItem") > -1){
              // const dialogRef = this.dialog.open(TransmitDialogComponent, {
              //   width: "250px",
              //   data: {
              //     header: "Error",
              //     message:
              //       "Cannot Create WorkItem ["+strmImpResult['workItemCreated']+"]in stream, because: "+strmImpResult['statusMessages'].toString(),
              //     retry: false,
              //   },
              // });
              console.error("Cannot Create WorkItem ["+strmImpResult['workItemCreated']+"]in stream, because: "+strmImpResult['statusMessages'].toString());
              this.showMessage(
                "warn",
                "Success",
                "Success saving the note ["+strmImpResult['noteCreated']+"] but "
                +"Cannot Create WorkItem ["+strmImpResult['workItemCreated']+"]in stream, because: "+strmImpResult['statusMessages'].toString(),
              );
            }
            
            setInterval(() => {
              //get appt details again, to reflect changes, no matter if success or fail
              this.appointmentService
                .getAppointment(this.appointment$.appointmentID)
                .subscribe(
                  (data) => {
                    //refresh, if anything has changed by chance
                    window.location.reload();
                  },
                  (error) => {
                    console.log("Failed to refresh the appointment");
                  }
                );
            }, 10000);
          
              }else{
                this.showMessage(
                  "error",
                  "Failed to save note or workItem",
                  JSON.stringify(result),
                  true,
                  true
                );
              }
              // .subscribe(
              //   result => {
              //     let n = this.appointment$.notes;
              //     this.appointment$.notes = n + '<p>' + this.datePipe.transform(new Date(), 'MMM d, y h:mma') + ':</p>' + this.editorContent;
              //     this.editorContent = '';
              //     this.editor.setContent(this.editorContent);
              //     var key = 'selectedAppointment-' + this.appointment$.claimNumber + '-' + 'NEED_DISPATCH_ID';
              //     this.storageService.save(key, this.appointment$);
              //     console.log('Success saving the note in eclaims result');
              //   },
              //   error => {
              //     console.log('Failed to save note in eclaims');
              //   })
            // } else {
            //   console.log("Failed to save note in stream");
            // }
          },
          (error) => {
            console.log("error", error.status, error);
            const dialogRef = this.dialog.open(TransmitDialogComponent, {
              width: "250px",
              data: {
                header: "Save Note",
                message:
                  "Error saving the note in stream",
                retry: false,
              },
            });
          }
        );

    } else  {
      //General Note
      this.appointmentService
      .saveNoteToStream(
          this.editorContent,
          this.appointment$.claimNumber,
          this.userDetails.fullName +
            "[ " +
            this.userDetails.claimSystemLoginId +
            " ]"
        )
        .subscribe(
          (result) => {
            console.log("result", result);
            console.log("Success saving the note in stream");
            this.editorContent = "";
            this.editor.setContent(this.editorContent);
          },
          (error) => {
            console.log("error", error.status, error);
            if (error.status == "200") {
              this.appointmentService
                .saveNoteToEClaim(this.editorContent, this.appointment$)
                .subscribe(
                  (data) => {
                    this.showMessage(
                      "success",
                      "Success",
                      "Saved note successfully"
                    );
                    //get appt details again, to reflect changes, no matter if success or fail
                    this.appointmentService
                      .getAppointment(this.appointment$.appointmentID)
                      .subscribe(
                        (data) => {
                          //refresh, if anything has changed by chance
                          window.location.reload();
                        },
                        (error) => {
                          console.log("Failed to refresh the appointment");
                        }
                      );
                  },
                  (error) => {
                    console.log("Failed to save note");
                    console.log(error.message);
                    this.showMessage(
                      "error",
                      error.statusText || "Failed to save note",
                      error.message || JSON.stringify(error),
                      true,
                      true
                    );
                    setInterval(() => {
                      //get appt details again, to reflect changes, no matter if success or fail
                      this.appointmentService
                        .getAppointment(this.appointment$.appointmentID)
                        .subscribe(
                          (data) => {
                            //refresh, if anything has changed by chance
                            window.location.reload();
                          },
                          (error) => {
                            console.log("Failed to refresh the appointment");
                          }
                        );
                    }, 10000);
                  }
                );
              // .subscribe(
              //   result => {
              //     let n = this.appointment$.notes;
              //     this.appointment$.notes = n + '<p>' + this.datePipe.transform(new Date(), 'MMM d, y h:mma') + ':</p>' + this.editorContent;
              //     this.editorContent = '';
              //     this.editor.setContent(this.editorContent);
              //     var key = 'selectedAppointment-' + this.appointment$.claimNumber + '-' + 'NEED_DISPATCH_ID';
              //     this.storageService.save(key, this.appointment$);
              //     console.log('Success saving the note in eclaims result');
              //   },
              //   error => {
              //     console.log('Failed to save note in eclaims');
              //   })
            } else {
              console.log("Failed to save note in stream");
            }
          }
        );
    }
  }

  hideRentalNoteOptions(claimType, claimInfo){
    if(claimInfo){
      console.log(claimInfo)
      let ccRptCompanyCd = claimInfo['ccRptCompanyCd']?claimInfo['ccRptCompanyCd']:"";
      let stRoutingChannelCd = claimInfo['stRoutingChannelCd']?claimInfo['stRoutingChannelCd']:""; 
      let chk1 = (claimType.toLowerCase()!='pa' && claimType.toLowerCase()!='ca')?false:true;
      let chk2 = (claimInfo['ccRptCompanyCd']=='PILG')?false:true;
      let chk3 = (claimInfo['stRoutingChannelCd']!='ind')?false:true;
      console.info("hideRentalNoteOptions called",claimType, claimInfo['ccRptCompanyCd'], claimInfo['stRoutingChannelCd'], chk1&&chk2&&chk3)
      this.hideRentalOption =  chk1&&chk2&&chk3;
    }else{
      this.hideRentalOption = true;
    }
    
  }
  //Check if claim is egligible for notification
  checkSMSNotificaionEligible(appointment) {
    //Show SMS Notification only when it Coll/Comp/Cov A,B,C,D claim types
    if (
      appointment.claimType.indexOf("Comprehensive") > -1 ||
      appointment.claimType.indexOf("Collision") > -1 ||
      appointment.claimType.indexOf("Coverage A") > -1 ||
      appointment.claimType.indexOf("Coverage B") > -1 ||
      appointment.claimType.indexOf("Coverage C") > -1 ||
      appointment.claimType.indexOf("Coverage D") > -1
    ) {
      //Check if the customer has Opt-In for SMS Notification
      this.appointmentService
        .getNotificationOptInFlags(appointment.claimNumber)
        .subscribe(
          (response: any) => {
            console.log("Success getting optin flag from stream");
            if (response && response.keys && response.keys.length > 0) {
              var index = response.keys.length;
              while (--index) {
                if (response.keys[index].keyCd == "sms_opt_in") {
                  if (response.keys[index].keyVal.toLowerCase() === "y") {
                    this.showSMSNotitfication = true;
                  }
                  break;
                }
              }
            }
          },
          (error) => {
            console.log("Failed to get optin flag from stream");
          }
        );
    }
  }

  notifyCustomer() {
    //FIX THIS APT DATE not  ASIGNMENT DATE
    //console.log( "info", info );
    let aptDate = this.appointment$.assignDateTime
      ? this.datePipe.transform(this.appointment$.assignDateTime, "MM-dd-yyyy")
      : "";
    console.debug("/notification/" + this.claimNumber + "/" + aptDate);
    this.router.navigateByUrl(
      "/notification/" +
        this.claimNumber +
        "/" +
        this.appointment$.id6 +
        "/" +
        aptDate
    );
  }

  openSalvageAssignment() {
    let salvage_url =
      environment.SALVAGE_ASSIGNMENT_URL +
      this.claimNumber +
      "/" +
      this.userDetails.claimSystemLoginId;
    console.info(salvage_url);
    window.open(salvage_url, "_blank");
  }

  previewCheck(form: any) {
    this.window.alert("Form Submitted!");
  }
}
