<div class="container">
  <button mat-button (click)="backToAppointmentsList()">Back to assignments list</button>

  <p class="h4" style="margin:10px 10px 20px 10px">Customer Notification</p>


      <div  [innerHTML]="notificationScript" ></div>
  

			<div class="appointment-detail appraiser-img" [hidden]="!pictureUrl">
				<img [src]="pictureUrl" width="100px"/>
      </div>
      <br/>      
      <button [hidden]="showSMSNotitfication && appointment$.status == 'C'" mat-button (click)="notifyCustomer()" [disabled]="isMessageSent" >{{ messageStatus }}</button>
</div>