<div class="container" align="center" id="adjusterProfileContainer">
  <div align="center" style="width: 40%;">
    <h3 style="color: #FF6319" class="py-3" id="header">Your Profile</h3>
  </div>
  <div *ngIf="isErrorOccured" style="width: 40%;">
    <div class="alert alert-danger">
      <strong>Error! </strong>{{ errorMessage }}
    </div>
  </div>
  <div class="form-group" style="width: 40%;" align="left">
    <label for="adjusterName" class="font-weight-bold">Name</label>
    <input type="text" disabled class="form-control" name="adjusterName" [(ngModel)]="adjuster.name">
  </div>
  <div class="form-group" style="width: 40%;" align="left">
    <label for="adjusterEmail" class="font-weight-bold">Email</label>
    <input type="text" disabled class="form-control" name="adjusterEmail" [(ngModel)]="adjuster.email">
  </div>
  <div class="form-group" style="width: 40%;" align="left">
    <label for="adjusterPhone" class="font-weight-bold">Phone Number</label>
    <input type="text" disabled class="form-control" name="adjusterPhone" [(ngModel)]="adjuster.phone">
  </div>
  <div class="form-group" style="width: 40%;" align="left">
    <label for="adjusterFax" class="font-weight-bold">Fax Number</label>
    <input type="text" disabled class="form-control" name="adjusterFax" [(ngModel)]="adjuster.fax">
  </div>
  <div class="form-group" style="width: 40%;" align="left">
    <label for="adjusterHomePhone" class="font-weight-bold">Home Phone Number</label>
    <input type="text" disabled class="form-control" name="adjusterHomePhone" [(ngModel)]="adjuster.homePhone">
  </div>
  <div class="form-group" style="width: 40%;" align="left">
    <!-- House Counsel indicator -->
    <label class="font-weight-bold">House Counsel User</label>
    <!--<div *ngIf="isHouseCounsel" style="font-weight: bold;">
      <span style="border-style: groove none outset groove; border-color: gray; background-color: #9bccd6; padding-left: 25px; padding-right: 25px;">Yes</span><span style="border-style: outset; border-color: gray;  padding-left: 25px; padding-right: 25px;">No</span>
    </div>
    <div *ngIf="!isHouseCounsel">
      <span style="border-style: groove none outset groove; border-color: gray; padding-left: 25px; padding-right: 25px;">Yes</span><span style="border-style: outset; border-color: gray;  background-color: #9bccd6; padding-left: 25px; padding-right: 25px;">No</span>
    </div>-->
    <div>
      <span class="itemNotSelected" [ngClass]="hCClasses" style="margin-right: -4px;">Yes</span>
      <span class="itemNotSelected" [ngClass]="invertHCClasses">No</span>
    </div>
  </div>
  <div class="form-group" style="width: 40%;" align="left">
    <label for="adjusterNumber" class="font-weight-bold">CheckWriter Adjuster ID</label>
    <input type="text" disabled class="form-control" name="adjusterNumber" [(ngModel)]="adjuster.number">
  </div>
</div>