<div class="container" align="center" id="adjusterRetryPaymentContainer">
  <div align="center" style="width: 70%;">
    <h3 style="color: #FF6319" class="py-3" id="header">Retry Payments</h3>
  </div>
  <div *ngIf="isErrorOccured" style="width: 70%;">
    <div class="alert alert-danger">
      <strong>Error! </strong>{{ errorResponse.message }}
    </div>
  </div>
  <div *ngIf="paymentUploaded" style="width: 70%;">
    <div class="p-3 mb-2 bg-info text-white">
      {{ paymentUploadedResp.statusMessage }}
    </div>
  </div>
  <div class="form-group" id="headerButtons" align="center">
    <div class="row" align="left">
      <div class="col-md-12">
        <p><strong>Previously uploaded & failed  eligible payments are retried for upload.</strong></p>
      </div>
    </div>
    <div class="row"  align="left">
      <div class="col-md-12">
        <p>Here, select payments not uploaded and click Upload Payment button for payment upload Or Void Payment button for making payment void.</p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <button type="button" class="btn btn-warning font-weight-bold" style="width : 45%; background-color: #FF6319;"
          (click)="uploadPayment()">Upload Payment</button>
      </div>
      <div class="col-md-6">
        <button type="button" class="btn btn-warning font-weight-bold" style="width : 45%; background-color:#1371ce; color : white"
          (click)="voidPayment()">Void Payment</button>
      </div>
    </div>
  </div>
  <div class="mat-elevation-z8 mat-table-container">
    <mat-table #table [dataSource]="paymentsDataSource" matSort>
      <!-- Checkbox Column -->
      <ng-container matColumnDef="select">
        <mat-header-cell *matHeaderCellDef>
          <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()">
          </mat-checkbox>
        </mat-header-cell>
        <mat-cell *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)">
          </mat-checkbox>
        </mat-cell>
      </ng-container>

      <!-- Date Column -->
      <ng-container matColumnDef="date">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Date </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.date}} </mat-cell>
      </ng-container>

      <!-- Check# Column -->
      <ng-container matColumnDef="checkNumber">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Check # </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.checkNumber}} </mat-cell>
      </ng-container>

      <!-- Claim# Column -->
      <ng-container matColumnDef="claimNumber">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Claim # </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.claimNumber}} </mat-cell>
      </ng-container>

      <!-- Payee Column -->
      <ng-container matColumnDef="payee">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Payee </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.payee}} </mat-cell>
      </ng-container>

      <!-- Amount Column -->
      <ng-container matColumnDef="amount">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Amount </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.amount}} </mat-cell>
      </ng-container>


      <!-- Payment Status Column -->
      <ng-container matColumnDef="paymentStatus">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Payment Status </mat-header-cell>
        <mat-cell *matCellDef="let element" title="{{element.paymentMessage}}"> {{element.paymentStatus}} </mat-cell>
      </ng-container>



      <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>

    <mat-paginator #paginator [pageSize]="10" [showFirstLastButtons]="true">
    </mat-paginator>
  </div>
  <div class="modal-footer" style="justify-content : center;">
    <a (click)="onClose()" style="text-decoration-line: underline;">Close</a>
  </div>
  <!-- <div *ngIf="apiCallInFlight" class="loaderContainer" style="height: 100%;">
    <div class="loader loaderContainer"></div>
  </div> -->
</div>