export class Vendor {
    groupCd: string;
    taxId: string;
    tempTaxId;string;
    ssn: string;
    vendorId: number;
    vendorName: string;
    dbaName: string;
    bhNlInd: string;
    street1: string;
    street2: string;
    city: string;
    state: string;
    zip: string;
    zip2: string;
    status: string;
    type: string;
    phone1: string;
    phone2: string;
    phone3: string;
    rsNumber: string;
    corporate: string;
    healthProvider: string;
    attorney: string;
    soleProp:string;
    partner:string;
    tinType: string;
    vendorAddDate: string;
    claimNumber: string;

    constructor() {};

    toString() :  string{
        return "taxId - " + this.taxId + ", vendorName - " + this.vendorName + ", dbaName:"+this.dbaName;
    }

    getZipCode(){
        if(this.zip2){
           return this.zip+"-"+this.zip2; 
        }
        return this.zip;
    }

}
