import { Component, OnInit } from '@angular/core';
import { HeaderComponent } from '../header/header.component';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'eclaimsonesite-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor(public associatedHeader: HeaderComponent) {
  this.appraiserId = associatedHeader.appraiserId;
  this.appraiserName = associatedHeader.appraiserName;
  this.userDetails = associatedHeader.userDetails;
  this.userId = associatedHeader.userId;
  }

  ngOnInit() {
  }
  public CURRENT_VERSION = environment.appVersion;
  public userId: string;
  public appraiserId: string;
  public appraiserName: string;
  public userDetails: any;
  objDate: Date = new Date();
  

}
