import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable, of } from "rxjs";
import { environment } from '../../../environments/environment';

declare var ga: any;
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root',
})
export class SendMessageService {

  constructor(public http: HttpClient) { 
    }

  sendMessage(message){


    const httpOptions = { headers: new HttpHeaders({
                                         'Content-Type' : 'application/xml', 
                                         'Accept':  'application/xml',
                                         'Authorization': 'Basic c3RnOnN0Zw=='})                          //responseType: 'text'
                        };

     return this.http.post( environment.NOTIFICATION_BASE_URL + 'notificationService/notifications' + '?requestime=' + (new Date().getTime()),
              message,
             httpOptions);
};  


  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }


  public trackPage(pageName: string) {
    ga('send', 'pageview', pageName);
  }

}