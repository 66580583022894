<div class="modal-header" style="justify-content : center;">
  <h4 id="modal-basic-title" class="content-centered">Select Printer</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.close('Adjuster closed Choose Printer Modal')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" align="center">
  <div class="form-group" style="width: 80%;">
    <div *ngIf="errorOccured" class="alert alert-warning" style="width: 80%;"> {{ errorResponse.message }} </div>
    <div *ngIf="foundAssignedPrinter">
      <div class="my-1 pl-1 content-align-start">{{ assignedPrinters?.length || '0' }} Assigned Printers</div>
      <div class="form-group" style="width: 80%;">
        <div class="row shadow-sm" *ngFor="let printer of assignedPrinters" (click)="onPrinterSelect(printer)">
          <div class="col-md-12 border-top-0 border-right-0 border-bottom-2 border-left-0 scroll-item" style="text-align: start;">
            <div class="pl-2">
              <i class="fa fa-print" aria-hidden="true"></i> {{printer.printerName}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer" style="justify-content : center;">
  <a (click)="activeModal.close('Adjuster closed Choose Printer Modal')" style="text-decoration-line: underline;">Cancel</a>
</div>