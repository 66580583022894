import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';

import { CommonModule } from '@angular/common';
import { LoginComponent } from './login/login.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { HttpClientModule }    from '@angular/common/http';

import { GoogleAnalyticsService } from '../../app/shared/service/google-analytics.service';
import { LoginService } from '../../app/shared/login/login.service';
import { MessageService } from '../../app/shared/service/message.service';
import { AdminLoginComponent } from './login/admin-login/admin-login.component';


  
@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    HttpClientModule
  ],
  
  declarations: [LoginComponent, HeaderComponent, FooterComponent, AdminLoginComponent],

  exports: [LoginComponent, HeaderComponent, FooterComponent, AdminLoginComponent],
  providers: [  GoogleAnalyticsService, LoginService, MessageService ]
})
export class SharedModule { }
