import { Component, OnInit, ViewChild, ElementRef, NgZone, AfterViewInit, ViewEncapsulation, EventEmitter } from '@angular/core';
import { AgmCircle, AgmInfoWindow, AgmMap, AgmMarker, MapsAPILoader, MouseEvent} from '@agm/core';
import { AppointmentService } from './appointment.service';
import { oneCircle, oneMarker, oneDirMarker, oneDirection, oneWaypoint, iconType, messageType, oneFilter, oneFilterTag, filterFieldsMapping, oneGroupedFilter, oneCustomSvgMarker, keyfilters, dateFormatType } from './appointment';
import { StorageService } from '../../shared/service/storage.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingIndicatorService } from '../../check-writer/shared/services/loading-indicator.service';
import { EventEmitterService } from '../../shared/service/event-emitter.service';
import * as jsonPath from 'jsonpath/jsonpath';
import { Appointment, SpinnerAppraiser } from './appointment';
import { MultiSelect } from 'primeng/multiselect';
import { MatExpansionPanel } from '@angular/material/expansion';
import { MatButton } from '@angular/material/button';
import { MatButtonToggle } from '@angular/material/button-toggle';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { MenuItem, MessageService} from 'primeng/api';
import { ConfirmationService } from 'primeng/api';
import { Subject } from 'rxjs/Subject';
import { of, throwError, timer, defer, merge, from, forkJoin, EMPTY } from 'rxjs';
import { map, delay, shareReplay, mergeMap, concatMap, toArray, catchError, switchMap, sample, startWith } from 'rxjs/operators';
import { concat, interval, fromEvent, Observable, Subscription } from 'rxjs';

interface markerClickedParams {
  m: oneMarker; 
  $event?: MouseEvent;
  idx?: number;
  infoWindow?: AgmInfoWindow; 
  callback?: any;
  subscribeLater?: boolean  
}

@Component({
  selector: 'map-root',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
  providers: [MessageService, ConfirmationService],  
  encapsulation: ViewEncapsulation.None
})

export class MapComponent implements OnInit {
  title: string = 'Map Component for EDispatch';
  latitude: number;
  longitude: number;
  zoom: number;
  address: string;
  private geoCoder;

  randomIcon = function(): string {
    let iconUrl: string = Object.values(iconType)[Math.round((Math.random() * Object.values(iconType).length))];
    //console.log(iconUrl);
    return iconUrl || "";
  } 

  @ViewChild('search', { static: false }) search: ElementRef;
  @ViewChild('markersMax', { static: false }) markersMax: ElementRef;
  @ViewChild('radius', { static: false }) radius: ElementRef;
  @ViewChild('mfASSIGNMENT', { static: false }) mfASSIGNMENT: MultiSelect;
  @ViewChild('mfAPPRAISER', { static: false }) mfAPPRAISER: MultiSelect;
  @ViewChild('mt', { static: false }) mt: MultiSelect;
  @ViewChild('map', {static: false}) mapEl: ElementRef;
  @ViewChild('location', {static: false}) location: ElementRef;
  @ViewChild('spinnerAppraisersLkup', {static: false}) spinnerAppraisersLkup: ElementRef;

  @BlockUI() blockUI: NgBlockUI;

  // @ViewChild('map', { static: false }) map: AgmMap;
  // @ViewChild('circle', { static: false }) circle: AgmCircle;
  public searchElementRef: ElementRef;

  constructor(
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    public appointmentService: AppointmentService,
    public storageService: StorageService,
    public router: Router,
    public route: ActivatedRoute,
    public eventService: EventEmitterService,
    public loadingService: LoadingIndicatorService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService) {
      //loadingService.onLoadingChanged.subscribe(isLoading => this.loading = isLoading);
      this.jp = new jsonPath.JSONPath();  
    };

  hd: any;
  jp: any;

  tagMenuItems: MenuItem[] = [
    {label: 'New', command: () => {this.createTag(false);}},
    {label: 'New@Server (asgn)', command: () => {this.createTag(true);}},
    {label: 'Delete', command: () => {this.deleteAllTags();}},
    {label: 'Load', command: () => {this.loadAllTagsFile();}},
    {label: 'Save', command: () => {this.saveAllTags();}}
  ];

  _selFilterTags: oneFilterTag[];
  get selFilterTags():oneFilterTag[] {
    return this._selFilterTags;
  }
  set selFilterTags(newSelFilterTags:oneFilterTag[]) {
    this._selFilterTags = newSelFilterTags;
    if (this.loading === false) {
      //also save into cache, as soon as we modify
      //remember that anything in cache stored with "Persist" at the end, will survive new sessions/logout
      this.appointmentService.storageService.save("selFilterTagsCachePersist", this._selFilterTags);
    }
  }


  _filterTags: oneFilterTag[];
  get filterTags():oneFilterTag[] {
    if (this.filters && this.filters.length>0 && !this._filterTags) {
      this._filterTags = this.getDefaultFilterTags();
    }
    return this._filterTags;
  }
  set filterTags(newFilterTags:oneFilterTag[]) {
    this._filterTags = newFilterTags;
    //also save into cache, as soon as we modify
    //remember that anything in cache stored with "Persist" at the end, will survive new sessions/logout
    this.appointmentService.storageService.save("filterTagsCachePersist", newFilterTags);
  }

  defaultFilterTags: oneFilterTag[];
  getDefaultFilterTags = function() {
    
    if (this.defaultFilterTags) {
      return this.defaultFilterTags;
    }
    
    let defaultTags: oneFilterTag[];
    let foundFilter: oneFilter;
    let defaultFilters: oneFilter[];
    
    defaultTags = [];
    defaultFilters = [];
    let foundUserAssignment:any;
    foundUserAssignment = this.deepSearch(this.filtersASSIGNMENT, 'AttributeValue', (k, v) => v === 'appraiserName|' + this.userName);
    let foundUserAppraiser:any;
    foundUserAppraiser = this.deepSearch(this.filtersAPPRAISER, 'AttributeValue', (k, v) => v === 'appraiserName|' + this.userName);        
    if (foundUserAssignment && foundUserAppraiser) {
      defaultFilters = defaultFilters.concat(foundUserAssignment, foundUserAppraiser);
      defaultTags = defaultTags.concat({Tag: "me", Filters: defaultFilters});      
    }

    defaultFilters = []; 
    foundFilter = this.deepSearch(this.filters, 'AttributeValue', (k, v) => v === 'startDate|TODAY');
    if (foundFilter) {
      defaultFilters = defaultFilters.concat(foundFilter);
      defaultTags = defaultTags.concat({Tag: "today", Filters: defaultFilters});
    }
    
    defaultFilters = [];
    foundFilter = this.deepSearch(this.filters, 'AttributeValue', (k, v) => v === 'startDate|TOMORROW');
    if (foundFilter) {
      defaultFilters = defaultFilters.concat(foundFilter);
      defaultTags = defaultTags.concat({Tag: "tomorrow", Filters: defaultFilters});
    }
    
    defaultFilters = [];
    foundFilter = this.deepSearch(this.filters, 'AttributeValue', (k, v) => v === 'startTime|MORNING');
    if (foundFilter) {
      defaultFilters = defaultFilters.concat(foundFilter);
      defaultTags = defaultTags.concat({Tag: "morning", Filters: defaultFilters});
    } 

    defaultFilters = [];
    foundFilter = this.deepSearch(this.filters, 'AttributeValue', (k, v) => v === 'startTime|AFTERNOON');
    if (foundFilter) {
      defaultFilters = defaultFilters.concat(foundFilter);
      defaultTags = defaultTags.concat({Tag: "afternoon", Filters: defaultFilters});
    } 

    defaultFilters = [];
    foundFilter = this.deepSearch(this.filters, 'AttributeValue', (k, v) => v === 'startTime|ALL DAY');
    if (foundFilter) {
      defaultFilters = defaultFilters.concat(foundFilter);
      defaultTags = defaultTags.concat({Tag: "allday", Filters: defaultFilters});
    } 
    
    this.defaultFilterTags = defaultTags;
    
    return defaultTags;
  }

  onSpinnerAppraiserSelected(event, curAppointment: Appointment) {
    this.appointmentService.selectedSpinnerAppraiser = event;
  }

  onTagSelected = function(event) {
    this.applyTaggedFilters();
    //need to do this to keep the drop-down open
    event.originalEvent.preventDefault();
    event.originalEvent.stopPropagation();       
  }
  applyTaggedFilters = function() {
    if (this.selFilterTags) {
      let appliedFilters: oneFilter[] = [];
      this.selFilterTags.forEach(eachFilterTag => {
        appliedFilters = appliedFilters.concat(eachFilterTag.Filters);
      }); 
      this.selectedFilters = appliedFilters;
      setTimeout(() => {
        this.getFilteredMarkers();
        //this.mt.show();      
      }, 100);  
    }
    return;  
  }
  createTag(atServer: boolean = false) {
    if (!this.mt.filterValue || !this.selectedFilters || this.selectedFilters.length === 0) {
      this.showMessage('warn', "Not Found", 
      "No group to create/No filters to group", false, true); 
      return;
    }

    if (atServer === true 
        && this.deepSearch(this.selectedFilters, 'FilterType', 
        function(k, v) {
          return v.startsWith('APPRAISER');
        })
      ) {
      this.showMessage('warn', "Not Allowed", 
      "Cannot create filter group AT SERVER, `APPRAISER` filters are local only!", false, true); 
      return;
    }    

    //first delete the tag if existing
    let tagToCreate: string = this.mt.filterValue;
    this.deleteTag(tagToCreate, atServer, true);

    let retTags: oneFilterTag[] = this.filterTags || [];
    retTags = retTags.concat({Tag: tagToCreate, Filters: this.selectedFilters, AtServer: atServer});
    this.filterTags = retTags;

    //reset search value to allow all to be seen
    this.mt.filterValue = "";

    this.showMessage('success', "Done", 
    "Created group `" + tagToCreate + '`', false, true);      
  }

  deleteAllTags(noWarn: boolean = false) {
    if (!this.selFilterTags || this.selFilterTags.length === 0) {
      this.showMessage('warn', "Not Found", 
      "No group to delete", false, true); 
      return;
    }    
    this.selFilterTags.forEach(eachFilterTag => {
      this.deleteTag(eachFilterTag.Tag, eachFilterTag.AtServer);
    });  
  }

  deleteTag(tagName: string, atServer: boolean = false, noWarn: boolean = false) {
    if (noWarn === false && !tagName) {
      this.showMessage('warn', "Not Found", 
      "No group to delete", false, true); 
      return;
    }
    let arrIdx: number = 0;
    let arrIdxFound: number = -1;
    let tagToDelete: string = tagName;
    let retTags: oneFilterTag[] = this.filterTags || [];
    retTags.filter(function(eachFilterTag: oneFilterTag) {
      if (eachFilterTag.Tag === tagToDelete && ((eachFilterTag.AtServer == undefined) || eachFilterTag.AtServer === atServer)) {
        arrIdxFound = arrIdx;
        //exit loop
        return;
      }
      arrIdx += 1;
    });
    if (arrIdxFound !== -1) {
      retTags.splice(arrIdxFound, 1);
    }
    if (noWarn === false && arrIdxFound === -1) {
      this.showMessage('warn', "Not Found", 
      "No group to delete", false, true); 
      return;
    } else {
      this.filterTags = [].concat(retTags);
      if (noWarn === false) {
        this.mt.filterValue = "";
        this.selFilterTags = [];
        this.showMessage('success', "Done", 
        "Deleted group `" + tagToDelete + (atServer ? ' (@Server)' : '') + '`', false, true);        
      }       
    }
  }
  
  saveAllTags() {
    let filterTagsCache = this.storageService.get("filterTagsCachePersist");
    let txtData = new Blob([filterTagsCache], {type: 'application/text;charset=utf-8;'});
    
    //In FF link must be added to DOM to be clicked
    let link = document.createElement('a');
    link.href = window.URL.createObjectURL(txtData);
    link.setAttribute('download', 'EDispatch_FilterGroups_' + this.userName +  '.txt');
    link.setAttribute('target', '_blank');
    document.body.appendChild(link);    
    link.click();
    document.body.removeChild(link);   
  }


  loadAllTagsFile() {
    //this.storageService.get("filterTagsCachePersist");

    let inputEl = document.createElement('input');
    inputEl.setAttribute('type', 'file');
    inputEl.addEventListener("change",function(){
    let file = inputEl.files[0];
    if (file) {
        let reader = new FileReader();        
        reader.onload = function (evt) {
          //console.log(evt);
          try{
            let loadedTags: any[] = JSON.parse(evt.target.result);
            if (loadedTags && loadedTags.length > 0) {
              this.filterTags = [].concat(this.filterTags, loadedTags);
              this.showMessage('success', 'Success', "Loaded filter groups from external file successfully.");                      
            } else {
              this.showMessage('error', 'File invalid or no filters to load.', true, true);
            }
          } catch(e) {
            //ignore, just return default false
            this.showMessage('error', 'File invalid or no filters to load: ' + e.toString(), true, true);
          }
          document.body.removeChild(inputEl);                        
        }.bind(this);

        reader.onerror = function (evt) {
          this.showMessage('error', 'An error ocurred reading the file: ' + evt, true, true);
          document.body.removeChild(inputEl);   
        }.bind(this);

        reader.readAsText(file, "UTF-8");
    }
    }.bind(this),false);

    document.body.appendChild(inputEl);    
    inputEl.click();
  }

  showDialog: boolean;
  dialogSummary: string;
  dialogDetail: string;
  showMessage = function (msgSeverity: String, msgSummary: String, msgDetail: String, sticky: boolean = false, closable: boolean = true) {
    // this.showMessage('error', 'Error', "No transactions selected to activate");
    this.dialogSummary = msgSummary;
    this.dialogDetail = msgDetail;  
    if (this.googleMapFullscreen === true) {
      //toast cannot defeat gmaps fullscreen, so we are using p-dialog for that
      this.showDialog = true;
    } else {
      this.showDialog = false;      
      this.messageService.add({ severity: msgSeverity, summary: msgSummary, detail: msgDetail, sticky: sticky, closable: closable });      
    }
  };

  messageApplyCss = function(msgType: messageType, msgHtml: string) :string {
    let ret: string = msgHtml;
    switch (msgType) {
      case messageType.dispatchActions:        
        ret = ret.replace(new RegExp("(.*?)([^a-z])(FAIL.*?)([^a-z]|$)(.*?)", "gi"), "$1$2<FONT class='markFail'>$3</FONT>$4$5");
        ret = ret.replace(new RegExp("(.*?)([^a-z])(BYPASSED.*?)([^a-z]|$)(.*?)", "gi"), "$1$2<FONT class='markBypass'>$3</FONT>$4$5");
        ret = ret.replace(new RegExp("(.*?)([^a-z])(SUCCESS.*?)([^a-z]|$)(.*?)", "gi"), "$1$2<FONT class='markSuccess'>$3</FONT>$4$5");
        ret = ret.replace(new RegExp("(.*?)([^a-z])(ERROR.*?)([^a-z]|$)(.*?)", "gi"), "$1$2<FONT class='markError'>$3</FONT>$4$5");
        ret = ret.replace(new RegExp("(.*?)([^a-z])(null.*?)([^a-z]|$)(.*?)", "gi"), "$1$2<FONT class='markBypass'>N/A</FONT>$4$5");
      default:        
    }
    return ret;   
  }

  confirm = function (confirmMessage, acceptFunction, rejectMessage, rejectFunction, acceptLbl = 'Yes', rejectLbl = 'No', rejectVsbl = true) {
    // this.confirm(
    //   "Do you really want to activate the " + (selectedIdsArr.length > 50 ? selectedIdsArr.length : "") + " transactions selected below?"
    //   , "this.activateTransactionsById();"
    //   , "Transactions Action Confirmation"
    //   , "{confirmationAnswer = 'Reject'; return;}"
    //   , "Yes"
    // );
    
    // if (confirmationAnswer === 'Reject') {
    //   return;
    // }    
    this.confirmationService.confirm(
      {
        message: confirmMessage,
        accept: () => {
          if (typeof acceptFunction === 'function') {
            acceptFunction();
          } else {
            let tmpFunc = new Function(acceptFunction).bind(this);
            tmpFunc();  
          }
        },
        reject: () => {
          this.showMessage('warn', 'Rejected', rejectMessage);
          if (typeof rejectFunction === 'function') {
            rejectFunction();
          } else {
            let tmpFunc = new Function(rejectFunction).bind(this);
            tmpFunc();  
          }          
        },
        acceptLabel: acceptLbl,
        rejectLabel: rejectLbl,
        rejectVisible: rejectVsbl
      });

  };

  _progressSpinnerShown: boolean = false;
  mapSpinner: HTMLImageElement;
  get progressSpinnerShown() {
    return this._progressSpinnerShown;
  }
  set progressSpinnerShown(newProgressSpinnerShown) {
    //to stop, used reset instead of stop method because there could be multiple 
    //counts of blockui instances and stop only kills the last one, top of the stack
    switch (newProgressSpinnerShown) {
      case true:
          if (this.blockUI.isActive == false) {
            this.blockUI.start('');
          }
          if (this.googleMapFullscreen === true) {
            // this.showMessage('info', 'Information', "Server contacted...");
            this.mapSpinner = document.createElement("img");
            this.mapSpinner.src = "./assets/img/map/Misc/Spinner/loading_spinner_animated.gif";
            this.googleMap.controls[google.maps.ControlPosition.LEFT_CENTER].push(this.mapSpinner);
            setTimeout(() => {
              //place spinner in the middle of the screen
              this.mapSpinner.style.left = (window.innerWidth/2 - this.mapSpinner.width/2).toString() + 'px';      
            }, 100);                        
          }           
          break;
      case false:
          this.blockUI.reset();
          if (this.googleMapFullscreen === true) {
            this.googleMap.controls[google.maps.ControlPosition.LEFT_CENTER].clear();
          }                    
          break;
        default:
          this.blockUI.reset();
          if (this.googleMapFullscreen === true) {
            this.googleMap.controls[google.maps.ControlPosition.LEFT_CENTER].clear();
          }           
    }  
  }

  keyfilters: keyfilters = new keyfilters;
  DateYearIsLeap = function (year) { 
      return (((year % 4 === 0) && (year % 100 !== 0)) || (year % 400 === 0)); 
  };

  DateDaysInMonth = function (year, month) {
      return [31, (this.DateYearIsLeap(year) ? 29 : 28), 31, 30, 31, 30, 31, 31, 30, 31, 30, 31][month];
  };

  DateIsLeapYear = function (date) { 
      return this.DateYearIsLeap(date.getFullYear()); 
  };

  DateGetDaysInMonth = function (date) { 
      return this.DateDaysInMonth(date.getFullYear(), date.getMonth());
  };

  DateAddMonths = function (date: Date, value) {
      // var n = date.getDate();
      // var newdate = date;
      // newdate.setMonth(date.getMonth() + value);
      // newdate.setDate(Math.min(n, this.DateGetDaysInMonth(date)));
      // return newdate;
      let curMonth: number = date.getMonth(); 
      let monthSum: number = curMonth + parseInt(value);
      let newMonth: number = (12 + (monthSum % 12)) % 12;
      let newYear: number = date.getFullYear() + Math.floor(monthSum / 12);
      let newDate = new Date(newYear, newMonth, date.getDate());
      return (newDate.getMonth() != newMonth)
          ? new Date(newDate.setDate(0))
          : newDate;      
  };  
  plusToDate = function(currentDate, unit, howMuch) {
    //this.plusToDate(date, "day", -1);
    const monthMs = 86400000 * (unit === 'month' && howMuch === 1 ? this.DateDaysInMonth(currentDate.getYear(), currentDate.getMonth()) : 30);
    const yearMs = 86400000 * (this.DateIsLeapYear(currentDate) ? 366 : 365);
    const config = {
      second: 1000, // 1000 miliseconds
      minute: 60000,
      hour: 3600000,
      day: 86400000,
      week: 604800000,
      month: monthMs, 
      year: yearMs
    };
    const now = new Date(currentDate);
    // override logic for month
    if (unit === 'month') {
      return this.DateAddMonths(now, howMuch);
    } else {
      const beforeOrAfter = new Date(now.valueOf() + config[unit] * howMuch);
      return beforeOrAfter;
    }
  }
  dateDiff = function(date1, date2, interval) {
    const second = 1000, minute = second * 60, hour = minute * 60, day = hour * 24, week = day * 7;
    date1 = new Date(date1.toUTCString());
    date2 = new Date(date2.toUTCString());

    const timediff = date2 - date1;
    if (isNaN(timediff)) { return NaN; }
    switch (interval) {
      case 'years': return date2.getFullYear() - date1.getFullYear();
      case 'months': return (
        (date2.getFullYear() * 12 + date2.getMonth())
        -
        (date1.getFullYear() * 12 + date1.getMonth())
      );
      case 'weeks': return Math.floor(timediff / week);
      case 'days': return Math.floor(timediff / day);
      case 'hours': return Math.floor(timediff / hour);
      case 'minutes': return Math.floor(timediff / minute);
      case 'seconds': return Math.floor(timediff / second);
      default: return undefined;
    }
  }

  minApptMinutes: number = 60;
  maxApptMinutes: number = 12*60;
  minMaxDate = function(fieldName: string, fieldVal: Date):Date {
    let retDate: Date;
    retDate = (this.plusToDate(fieldVal, "minute", (fieldName == 'startDateTime'? this.minApptMinutes : -this.minApptMinutes)));
    return retDate;
  }

  //p-calendar doesn't know how to handle a function call for minDate, maxDate, defaultDate, by the way
  currentDate: Date = new Date();

  get startDate() {
    let fldVal: any = this.currentMarker.appt.startDateTime;
    if (fldVal !== null && fldVal !== '') {
      fldVal = fldVal.toLocaleString('en-us', { year: 'numeric', month: '2-digit', day: '2-digit' })
      .replace(/[^A-Za-z 0-9 \.,\?""!@#\$%\^&\*\(\)-_=\+;:<>\/\\\|\}\{\[\]`~]*/g, '')                    
      .replace(this.keyfilters.dtSaveFind, this.keyfilters.dtSaveReplace);
      return fldVal;  
    };                         
  };                 
  set startDate(newVal: any) {
    //if we receive these values from the URL params, as default
    //they may come encoded, so we need to do something about that
    if (!newVal || newVal instanceof Object) {
      //let it be if sent properly
    } else {
      let parsedVal:any = newVal;
      try{
        parsedVal = JSON.parse(decodeURI(parsedVal));
        newVal = parsedVal;                      
      } catch(e) {
        //ignore, just return default false
        console.log("Error parsing default json string",":",newVal,": ", e.toString());
      } 
      newVal = new Date(newVal);
    }
    newVal = newVal.toLocaleString('en-us', { year: 'numeric', month: '2-digit', day: '2-digit' })
        .replace(/[^A-Za-z 0-9 \.,\?""!@#\$%\^&\*\(\)-_=\+;:<>\/\\\|\}\{\[\]`~]*/g, '')                    
        .replace(this.keyfilters.dtSaveFind, this.keyfilters.dtSaveReplace);
    this.currentMarker.appt.startDate = newVal;
  }                              

  // initial center position for the map
  private _lat: number;
  get lat() {
    return this._lat;
  }
  set lat(newLat: number) {
    this._lat = newLat;
    //if mapCenter came from cache, we are not saving
    //those back into cache
    if (!this.mapCenter) {
      this.storageService.save("latCache", newLat);
    }
  }

  private _lng: number;
  get lng() {
    return this._lng;
  }
  set lng(newLng: number) {
    this._lng = newLng;
    //if mapCenter came from cache, we are not saving
    //those back into cache
    if (!this.mapCenter) {    
      this.storageService.save("lngCache", newLng);
    }
  } 

  //if browser doesn't allow geolocation
  //default location is Boston, MA
  defaultLat: number = 42.3600825;
  defaultLng: number = -71.0588801;

  //when geocoding lat lng, we will get multiple results back
  //so we need to indicate which we would prefer
  defaultPreferredLocationType: string = "ROOFTOP";

  currentLocation: boolean = false;
  googleMap: google.maps.Map;
  mainCircle: oneCircle;
  mainCircleUpdatedManually: boolean = false;
  farthestMarker: oneMarker;
  markers: oneMarker[] = [];
  
  private _filteredMarkers: oneMarker[] = [];
  get filteredMarkers() {
    return this._filteredMarkers;
  }
  set filteredMarkers(newFilteredMarkers: oneMarker[]) { 
    this._filteredMarkers = newFilteredMarkers;
    this.filterApprMatchCount = 0;
    this.filterAsgnMatchCount = 0;    
    this._filteredMarkers.forEach(eachMarker => {
      this.filterApprMatchCount = this.filterApprMatchCount + (eachMarker.locationType === 'APPRAISER' ? 1 : 0);
      this.filterAsgnMatchCount = this.filterAsgnMatchCount + (eachMarker.locationType === 'ASSIGNMENT' ? 1 : 0);
    });
    this.filterApprMaxCount = 0;
    this.filterAsgnMaxCount = 0;    
    this.markers.forEach(eachMarker => {
      this.filterApprMaxCount = this.filterApprMaxCount + (eachMarker.locationType === 'APPRAISER' ? 1 : 0);
      this.filterAsgnMaxCount = this.filterAsgnMaxCount + (eachMarker.locationType === 'ASSIGNMENT' ? 1 : 0);
    });          
    this.filterMatchCount = this._filteredMarkers.length - 1;
    if (this.googleMapFullscreen === true) {
      this.createFullScreenFilterButtons();
    }    
  }

  directions: oneDirection[];
  directionsSummary: string;
  directionsSummaryError: string = '<font color="red"><b>DIRECTIONS ERROR or INCOMPLETE INPUT</b></font><br>';
  directionsSummaryInitialize: string = 'Getting directions...';
  directionsValidated: boolean;

  _showDirections: boolean = false;
  get showDirections() {
    return this._showDirections;
  }
  set showDirections(newShowDirections: boolean) {
    if (newShowDirections === false) {
      //since we handle our own directions markers
      //we must kill them when hiding directions
      this.destroyMarkers();
    }
    if (newShowDirections === true) {
      if (this.googleMapFullscreen === true) {
        this.showMessage('info', 'Directions Summary', this.directionsSummary);
      }      
    } 
    // else {
    //   //hide dialog when toggle
    //   this.showDialog = false;
    // }
    this._showDirections = newShowDirections;
  }   
  
  directionsOptions: oneFilter[] = [
    { AttributeValue: "none", AttributeValueDisplayName: "None", FilterType: "ROUTE" },
    { AttributeValue: "origin", AttributeValueDisplayName: "Start", FilterType: "ROUTE" },
    { AttributeValue: "waypoint", AttributeValueDisplayName: "Stop", FilterType: "ROUTE" },
    { AttributeValue: "destination", AttributeValueDisplayName: "End", FilterType: "ROUTE" }
  ];

  directionsOptionsObject:any = {
    none: { AttributeValue: "none", AttributeValueDisplayName: "None", FilterType: "ROUTE"  },
    origin: { AttributeValue: "origin", AttributeValueDisplayName: "Start", FilterType: "ROUTE"  },
    waypoint: { AttributeValue: "waypoint", AttributeValueDisplayName: "Stop", FilterType: "ROUTE"  },
    destination: { AttributeValue: "destination", AttributeValueDisplayName: "End", FilterType: "ROUTE"  }
  };  

  customSvgMarker: oneCustomSvgMarker = {scaledSize: {width: 27, height: 43}, anchor: {x: 13.5, y: 0}, svg: `<svg version="1.1" width="27px" height="43px" viewBox="0 0 27 43" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
  <defs
     id="defs864">
    <path
       id="a"
       d="m12.5 0c-6.9039 0-12.5 5.5961-12.5 12.5 0 1.8859 0.54297 3.7461 1.4414 5.4617 3.425 6.6156 10.216 13.566 10.216 22.195 0 0.46562 0.37734 0.84297 0.84297 0.84297s0.84297-0.37734 0.84297-0.84297c0-8.6289 6.7906-15.58 10.216-22.195 0.89844-1.7156 1.4414-3.5758 1.4414-5.4617 0-6.9039-5.5961-12.5-12.5-12.5z" />
  </defs>
  <g
     transform="rotate(180,12.9999,21.00025)"
     id="g870"
     style="fill:none;fill-rule:evenodd">
    <use
       fill="#ea4335"
       fill-rule="evenodd"
       xlink:href="#a"
       id="use866"
       x="0"
       y="0"
       width="100%"
       height="100%" />
    <path
       d="m 12.5,-0.5 c 7.18,0 13,5.82 13,13 0,1.8995 -0.52398,3.8328 -1.4974,5.6916 -0.91575,1.7688 -1.0177,1.9307 -4.169,6.7789 -4.2579,6.5508 -5.9907,10.447 -5.9907,15.187 0,0.74177 -0.6012,1.343 -1.343,1.343 -0.7418,0 -1.343,-0.6012 -1.343,-1.343 0,-4.7396 -1.7327,-8.6358 -5.9907,-15.187 C 2.015,20.1223 1.913,19.9605 0.9983,18.1937 0.02381,16.3329 -0.5002,14.3995 -0.5002,12.5 c 0,-7.18 5.82,-13 13,-13 z"
       stroke="#ffffff"
       id="path868" />
  </g>
  <text
     text-anchor="middle"
     dy="3.6000001"
     x="14"
     y="31"
     font-family="Roboto, Arial, sans-serif"
     font-size="16px"
     fill="#ffffff"
     id="text872"
     style="fill-rule:evenodd">dynamic text</text>
  </svg>
  `};
  customSvgMarkerRotateLeft: oneCustomSvgMarker = {scaledSize: {width: 35, height: 35}, anchor: {x: 35, y: 0}, svg: `<?xml version="1.0" encoding="UTF-8" standalone="no"?>
  <svg
     xmlns:dc="http://purl.org/dc/elements/1.1/"
     xmlns:cc="http://creativecommons.org/ns#"
     xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
     xmlns:svg="http://www.w3.org/2000/svg"
     xmlns="http://www.w3.org/2000/svg"
     xmlns:xlink="http://www.w3.org/1999/xlink"
     version="1.1"
     width="35"
     height="35"
     viewBox="0 0 35 35"
     id="svg876">
    <defs
       id="defs864">
      <path
         id="a"
         d="M 12.5,0 C 5.5961,0 0,5.5961 0,12.5 c 0,1.8859 0.54297,3.7461 1.4414,5.4617 3.425,6.6156 10.216,13.566 10.216,22.195 0,0.46562 0.37734,0.84297 0.84297,0.84297 0.46563,0 0.84297,-0.37734 0.84297,-0.84297 0,-8.6289 6.7906,-15.58 10.216,-22.195 0.89844,-1.7156 1.4414,-3.5758 1.4414,-5.4617 0,-6.9039 -5.5961,-12.5 -12.5,-12.5 z" />
    </defs>
    <g
       id="g894"
       transform="rotate(45,23.280672,25.44517)">
      <g
         transform="rotate(180,12.9999,21.00025)"
         id="g870"
         style="fill:none;fill-rule:evenodd">
        <g
           id="g887">
          <use
             fill="#ea4335"
             fill-rule="evenodd"
             xlink:href="#a"
             id="use866"
             x="0"
             y="0"
             width="100%"
             height="100%" />
        </g>
        <path
           d="m 12.5,-0.5 c 7.18,0 13,5.82 13,13 0,1.8995 -0.52398,3.8328 -1.4974,5.6916 -0.91575,1.7688 -1.0177,1.9307 -4.169,6.7789 -4.2579,6.5508 -5.9907,10.447 -5.9907,15.187 0,0.74177 -0.6012,1.343 -1.343,1.343 -0.7418,0 -1.343,-0.6012 -1.343,-1.343 0,-4.7396 -1.7327,-8.6358 -5.9907,-15.187 C 2.015,20.1223 1.913,19.9605 0.9983,18.1937 0.02381,16.3329 -0.5002,14.3995 -0.5002,12.5 c 0,-7.18 5.82,-13 13,-13 z"
           stroke="#ffffff"
           id="path868" />
      </g>
      <text
         text-anchor="middle"
         dy="3.6000001"
         x="14"
         y="31"
         font-family="Roboto, Arial, sans-serif"
         font-size="16px"
         fill="#ffffff"
         id="text872"
         style="fill-rule:evenodd">dynamic text</text>
    </g>
  </svg>
  `};
  customSvgMarkerRotateRight: oneCustomSvgMarker = {scaledSize: {width: 35, height: 35}, anchor: {x: 0, y: 0}, svg: `<?xml version="1.0" encoding="UTF-8" standalone="no"?>
  <svg
     xmlns:dc="http://purl.org/dc/elements/1.1/"
     xmlns:cc="http://creativecommons.org/ns#"
     xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
     xmlns:svg="http://www.w3.org/2000/svg"
     xmlns="http://www.w3.org/2000/svg"
     xmlns:xlink="http://www.w3.org/1999/xlink"
     xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
     xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
     version="1.1"
     width="35"
     height="35"
     viewBox="0 0 35 35"
     id="svg876"
     sodipodi:docname="marker_red_dir_inverted_rotate_right.svg"
     inkscape:version="1.0.2 (e86c870879, 2021-01-15, custom)">
    <sodipodi:namedview
       pagecolor="#ffffff"
       bordercolor="#666666"
       borderopacity="1"
       objecttolerance="10"
       gridtolerance="10"
       guidetolerance="10"
       inkscape:pageopacity="0"
       inkscape:pageshadow="2"
       inkscape:window-width="1920"
       inkscape:window-height="1017"
       id="namedview878"
       showgrid="false"
       inkscape:zoom="13.977692"
       inkscape:cx="1.2715056"
       inkscape:cy="21.732331"
       inkscape:window-x="-8"
       inkscape:window-y="-8"
       inkscape:window-maximized="1"
       inkscape:current-layer="svg876"
       fit-margin-top="0"
       fit-margin-left="0"
       fit-margin-right="0"
       fit-margin-bottom="0" />
    <defs
       id="defs864">
      <path
         id="a"
         d="M 12.5,0 C 5.5961,0 0,5.5961 0,12.5 c 0,1.8859 0.54297,3.7461 1.4414,5.4617 3.425,6.6156 10.216,13.566 10.216,22.195 0,0.46562 0.37734,0.84297 0.84297,0.84297 0.46563,0 0.84297,-0.37734 0.84297,-0.84297 0,-8.6289 6.7906,-15.58 10.216,-22.195 0.89844,-1.7156 1.4414,-3.5758 1.4414,-5.4617 0,-6.9039 -5.5961,-12.5 -12.5,-12.5 z" />
    </defs>
    <g
       id="g894"
       inkscape:transform-center-y="16.175457"
       transform="rotate(-45,7.6661005,15.91634)"
       inkscape:transform-center-x="-16.175461">
      <g
         transform="rotate(180,12.9999,21.00025)"
         id="g870"
         style="fill:none;fill-rule:evenodd"
         inkscape:transform-center-y="20.461175">
        <g
           id="g887">
          <use
             fill="#ea4335"
             fill-rule="evenodd"
             xlink:href="#a"
             id="use866"
             x="0"
             y="0"
             width="100%"
             height="100%" />
        </g>
        <path
           d="m 12.5,-0.5 c 7.18,0 13,5.82 13,13 0,1.8995 -0.52398,3.8328 -1.4974,5.6916 -0.91575,1.7688 -1.0177,1.9307 -4.169,6.7789 -4.2579,6.5508 -5.9907,10.447 -5.9907,15.187 0,0.74177 -0.6012,1.343 -1.343,1.343 -0.7418,0 -1.343,-0.6012 -1.343,-1.343 0,-4.7396 -1.7327,-8.6358 -5.9907,-15.187 C 2.015,20.1223 1.913,19.9605 0.9983,18.1937 0.02381,16.3329 -0.5002,14.3995 -0.5002,12.5 c 0,-7.18 5.82,-13 13,-13 z"
           stroke="#ffffff"
           id="path868" />
      </g>
      <text
         text-anchor="middle"
         dy="3.6000001"
         x="14"
         y="31"
         font-family="Roboto, Arial, sans-serif"
         font-size="16px"
         fill="#ffffff"
         id="text872"
         style="fill-rule:evenodd">dynamic text</text>
    </g>
  </svg>
  `};
  customSvgMarkers: oneCustomSvgMarker[] = [this.customSvgMarker, this.customSvgMarkerRotateRight, this.customSvgMarkerRotateLeft];
  shiftMarkerIndex: number;

  markerCollisionThreshold: number = 25;

  markerOptions: {
    origin?: google.maps.MarkerOptions, 
    destination?: google.maps.MarkerOptions, 
    waypoints?: google.maps.MarkerOptions
  }
  renderOptions: google.maps.DirectionsRendererOptions;

  //custom marker stuff
  originMarker: google.maps.Marker;
  // Marker drag event handler
  originDrag: any = new EventEmitter();
  destinationMarker: google.maps.Marker;
  destinationDrag: any = new EventEmitter();
  waypointsMarker: google.maps.Marker[] = [];
  infoWindow: google.maps.InfoWindow;
  sendInfoWindow: any = new EventEmitter();
  
  transmitBtnLabel = "Transmit to Vendor";
  transferBtnLabel = "Transfer";
  isTransmitDisabled = false;
  isTransferDisabled = false;
  isSpinApprDisabled = false;

  private _filters: oneFilter[];
  get filters() {
    return this._filters;
  }
  set filters(newFilters: oneFilter[]) {
    this._filters = newFilters;
    this.filtersAPPRAISER = [];
    this.filtersASSIGNMENT = [];
    this.filtersRoute = [];
    this._filters.forEach(oneFilter => {
      let filterType = (oneFilter.FilterType || "OTHER");
      if (!oneFilter.FilterType) {
        oneFilter.FilterType = filterType;
      }      
      switch(filterType) {
        case "ASSIGNMENT":
          this.filtersASSIGNMENT.push(oneFilter);
          break;
        case "APPRAISER":
          this.filtersAPPRAISER.push(oneFilter);
          break;
        case "ASSIGNMENT/APPRAISER":
        case "APPRAISER/ASSIGNMENT":
          let oneFilterAsgn: oneFilter;
          oneFilterAsgn = Object.assign({}, oneFilter);
          oneFilterAsgn.FilterType = 'ASSIGNMENT';
          this.filtersASSIGNMENT.push(oneFilterAsgn);

          let oneFilterAppr: oneFilter;
          oneFilterAppr = Object.assign({}, oneFilter);
          oneFilterAppr.FilterType = 'APPRAISER';
          this.filtersAPPRAISER.push(oneFilterAppr);
          break;
        default:
          this.filtersRoute.push(oneFilter);
      }
    })
  }
  filtersASSIGNMENT: oneFilter[];
  filtersAPPRAISER: oneFilter[];
  filtersRoute: oneFilter[];
  groupedFilters: oneGroupedFilter[] = [];
  dynamicFilters: oneFilter[];
  dynamicGroupedFilters: oneGroupedFilter[];
  
  
  _filterMatchCount: number;
  get filterMatchCount() {
    return this._filterMatchCount;
  }
  set filterMatchCount(newFilterMatchCount) {
    this._filterMatchCount = newFilterMatchCount;    
  }
  filterAsgnMatchCount: number;
  filterApprMatchCount: number;
  filterAsgnMaxCount: number;
  filterApprMaxCount: number;

  private _selectedFiltersAPPRAISER: oneFilter[];
  get selectedFiltersAPPRAISER() {  
    return this._selectedFiltersAPPRAISER;
  }
  set selectedFiltersAPPRAISER(newSelectedFiltersASSIGNMENT: oneFilter[]) {
    this._selectedFiltersAPPRAISER = newSelectedFiltersASSIGNMENT;
    this.syncSelectedFilters();  
  }

  private _selectedFiltersASSIGNMENT: oneFilter[];
  get selectedFiltersASSIGNMENT() {  
    return this._selectedFiltersASSIGNMENT;
  }
  set selectedFiltersASSIGNMENT(newSelectedFiltersASSIGNMENT: oneFilter[]) {
    this._selectedFiltersASSIGNMENT = newSelectedFiltersASSIGNMENT;
    this.syncSelectedFilters();  
  }

  private _selectedFilters: oneFilter[];
  get selectedFilters() {
    return this._selectedFilters;
  }
  set selectedFilters(newSelectedFilters: oneFilter[]) {
    //only work with the private variables,
    //to not trigger recursive events
    this._selectedFilters = newSelectedFilters;
    this._selectedFiltersAPPRAISER = [];
    this._selectedFiltersASSIGNMENT = [];
    this._selectedFilters.forEach(oneFilter => {
      if (oneFilter) {
        switch (oneFilter.FilterType) {
          case "ASSIGNMENT":
            this._selectedFiltersASSIGNMENT.push(oneFilter);
            break;
          case "APPRAISER":
            this._selectedFiltersAPPRAISER.push(oneFilter);
            break;
        }  
      }
    });
    if (this.loading === false) {
      //also save into cache, as soon as we modify
      //remember that anything in cache stored with "Persist" at the end, will survive new sessions/logout
      this.appointmentService.storageService.save("selectedFiltersCachePersist", this._selectedFilters);
    }
  }

  syncSelectedFilters = function() {
    this._selectedFilters = [].concat(this._selectedFiltersAPPRAISER, this._selectedFiltersASSIGNMENT);
    if (this.loading === false) {
      //also save into cache, as soon as we modify
      //remember that anything in cache stored with "Persist" at the end, will survive new sessions/logout
      this.appointmentService.storageService.save("selectedFiltersCachePersist", this._selectedFilters);
    }
  }


  userId: number;
  userName: string;
  userDetails: any;
  
  private _loading: boolean = false;
  get loading() {
    return this._loading;
  }
  set loading(newLoading: boolean) {
    this._loading = newLoading;
  } 

  maxMarkers: number;
  defaultMaxMarkers: number = 100;
  defaultMarkerIconSize: any = {width: 60, height: 80};
  
  defaultRadiusInMiles: number = 50;
  metersPerMile: number = 1609.344;
  
  private _mapZoom: number;
  get mapZoom() {
    return this._mapZoom;
  }
  set mapZoom(newMapZoom: number) {
    this._mapZoom = newMapZoom;
    this.storageService.save("mapZoomCache", newMapZoom);
  }  
  defaultMapZoom: number = 9;
  defaultMapZoomMax: number = 18;
  
  private _mapCenter: google.maps.LatLng;
  get mapCenter() {
    return this._mapCenter;
  }
  set mapCenter(newMapCenter: google.maps.LatLng) {
    this._mapCenter = newMapCenter;
  } 

  private _centerLocationName: string = "Location";
  set centerLocationName(newCenterLocationName: string) {
    this._centerLocationName = newCenterLocationName;
  }
  get centerLocationName() {
    return this._centerLocationName;
  }
  delayTimer: any;

  ngOnInit() {

    //some things like restoring sel filters only need to happen once at start
    this.loading = true;

    //interrupt normal GPS activity in the appointment component
    console.log("Disabled appointment GPS services, all GPS done inside mapping component now;")
    this.appointmentService.gpsServicesEnabled = false;

    this.route.paramMap.subscribe(params => {
      this.userId = parseInt(params.get('userId'));
      this.userName = params.get('userName');
      let userCache = this.storageService.get("user-details");
      if (userCache) {
        this.userDetails = JSON.parse(userCache);
      }
    });    
    this.mapsAPILoader.load().then(() => {
      console.log ('This browser is mobile: ', this.IS_MOBILE);
      let latCache: string = this.storageService.get("latCache");
      if(latCache) {
        this.lat = parseFloat(latCache);
      }

      let lngCache: string = this.storageService.get("lngCache");
      if(lngCache) {
        this.lng = parseFloat(lngCache);
      }      

      let maxMarkersCache = this.storageService.get("maxMarkersCache");
      if (maxMarkersCache) {
        this.maxMarkers = parseInt(maxMarkersCache);
      } else {
        this.maxMarkers = this.defaultMaxMarkers;
      }

      let mapZoomCache = this.storageService.get("mapZoomCache");
      if (mapZoomCache) {
        this.mapZoom = parseFloat(mapZoomCache);
      } else {
        this.mapZoom = this.defaultMapZoom;
      }

      let mapCenterCache = this.storageService.get("mapCenterCache");
      if (mapCenterCache) {
        this.mapCenter = new google.maps.LatLng(JSON.parse(mapCenterCache));
      } 
      
      let mainCircleCache = this.storageService.get("mainCircleCache");
      if (mainCircleCache) {
        let mainCircleCacheObj: oneCircle = JSON.parse(mainCircleCache);
        this.mainCircle = mainCircleCacheObj;
      }
      this.radius.nativeElement.value = (this.mainCircle ? this.mainCircle.radiusInMiles : this.defaultRadiusInMiles);

      //if we come back and we want to restore infowindows and route
      let clickedMarkersCache = this.storageService.get("clickedMarkersCache");
      if (clickedMarkersCache) {
        this.clickedMarkers = JSON.parse(clickedMarkersCache);
      } 
      let routedMarkersCache = this.storageService.get("routedMarkersCache");
      if (routedMarkersCache) {
        this.routedMarkers = JSON.parse(routedMarkersCache);
      } 

      // let filterTagsCache = this.storageService.get("filterTagsCachePersist");
      // if (filterTagsCache) {
      //   this.filterTags = JSON.parse(filterTagsCache);
      // }

      this.geoCoder = new google.maps.Geocoder;
      if (this.lat && this.lng) {
        this.setLocation(undefined, this.lat, this.lng);        
      } else {
        this.setCurrentLocation(this.setLocation, this.locationError);
      }
      this.bindAutoComplete();
    });
  }

  ngAfterViewInit() { 
    console.log ("After view init");
  }    

  public ngOnDestroy(): void {
    //normal appointment GPS activity can be done now
    this.appointmentService.gpsServicesEnabled = true;
    console.log("Enabled appointment GPS services, mapping component destroyed;")

    this.mapClickListener && this.mapClickListener.remove();
    this.zoomChangedListener && this.zoomChangedListener.remove();
    this.centerChangedListener && this.centerChangedListener.remove();
    this.boundsChangedListener && this.boundsChangedListener.remove();
    
    this.resizeSubscription$ && this.resizeSubscription$.unsubscribe();
  }

  public bindAutoComplete() {
    let autocomplete = new google.maps.places.Autocomplete(this.search.nativeElement);
    autocomplete.addListener("place_changed", () => {
      this.ngZone.run(() => {
        //get the place result
        let place: google.maps.places.PlaceResult = autocomplete.getPlace();

        //verify result
        if (place.geometry === undefined || place.geometry === null) {
          return;
        }

        //set latitude, longitude and zoom
        this.lat = place.geometry.location.lat();
        this.lng = place.geometry.location.lng();
        let addr = "<b>Address:</b>&nbsp;" + place.formatted_address;
        addr += "<br/><b>Vicinity:</b>&nbsp;" + place.vicinity;
        addr += "<br/><b>Lat:</b>&nbsp;" + place.geometry.location.lat();
        addr += "<br/><b>Long:</b>&nbsp;" + place.geometry.location.lng();
        addr += "<br/><b>Type:</b>&nbsp;" + place.types.join(',');
        let name = place.name;
        this.zoom = this.mapZoom;

        this.randomMarkers(1, this.lat, this.lng, iconType.other, this.centerLocationName, addr, 0);
        this.mainCircle.latitude = this.lat;
        this.mainCircle.longitude = this.lng;
        this.storageService.save("this.mainCircleCache", this.mainCircle);  
        //now that we have a new circle center, we update the appointments from within
        this.getAppointmentsByLocation();                    
      });
    });    
  }


  public parentizeSpinnerTooltip() {
    let containerNode = document.getElementsByClassName("ui-autocomplete-panel")[0];
    let fullscreenContainer = (containerNode ? containerNode.parentNode : 'target');  //this.googleMap.getDiv();//document.getElementsByClassName("ui-autocomplete-panel")[0];
    return (this.googleMapFullscreen === true ? fullscreenContainer || this.spinnerAppraisersLkup : 'body'); //
  }

  public parentizeGMAutocomplete() {  
    let pacContainerElements = document.getElementsByClassName("pac-container");
    if (pacContainerElements.length > 0) {
        let pacContainer = document.getElementsByClassName("pac-container")[0];
        if (this.googleMapFullscreen === false) { //pacContainer.parentElement.localName === "div") {
            console.log("Google Map NOT FULL SCREEN - pacContainer belongs to body");
            if (pacContainer.parentElement.localName !== "body") {
              document.getElementsByTagName("body")[0].appendChild(pacContainer);
            }            
        } else {
            console.log("Google Map FULL SCREEN - pacContainer belongs to target element");
            if (pacContainer.parentElement.localName !== "div") {
              document.getElementsByClassName("gm-style")[0].appendChild(pacContainer);
            }
        }
    } else {
        console.log("FULL SCREEN change - no pacContainer found");      
    }     
  }

  public radiusOnChange($event, callback) {
    this.cutDecimals('radius', 2, 800, callback);
  }

  public mainCircleUpdateRadius(newRadius) {
    this.mainCircleUpdatedManually = true;
      this.mainCircle.radiusInMiles = newRadius;
      this.mainCircle.radius = this.mainCircle.radiusInMiles * this.metersPerMile;
    this.mainCircleUpdatedManually = false;
  }

  public cutDecimals(ele, decimals, timeout, callback) {
      clearTimeout(this.delayTimer);
      this.delayTimer = setTimeout(function() {
        let trimmedVal: number = parseFloat(parseFloat(this[ele].nativeElement.value).toFixed(decimals));
        this[ele].nativeElement.value = trimmedVal;
        callback.call(this, trimmedVal);
      }.bind(this), timeout); 
  }

  /* eslint-disable */
  public IS_MOBILE = (function (a) {
    return (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a)||/1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i
        .test(
          a.substr(0,4)
        )
    );
    // @ts-ignore
  })(navigator.userAgent || navigator.vendor || window.opera)
  /* eslint-enable */ 

  backToAppointmentsList() {
    //console.log( " row=" + JSON.stringify(row ));
    this.preserveInfowindows();
    this.preserveRoute();
    this.router.navigateByUrl('/appointments');
  }


  onMaxMarkersChange($event) {
    this.storageService.save("maxMarkersCache", parseInt(this.markersMax.nativeElement.value));
  }
  setSampleMarkers = function(position) {
    // this.lat = position.coords.latitude;
    // this.lng = position.coords.longitude;
    // this.zoom = 12;
    let markersCount: any = this.markersMax.nativeElement.value;
    this.randomMarkers(markersCount ? markersCount : this.maxMarkers);       
  }.bind(this);

  showAppointments() {
    this.preserveInfowindows();
    this.preserveRoute();
    this.router.navigateByUrl('/appointments/');
  }

  zoomIn(m: oneMarker, i: AgmInfoWindow) {
    i.close();
    m.isClicked = false;
    
    if (this.googleMap.getZoom() < this.defaultMapZoomMax) {
      this.googleMap.setZoom(this.defaultMapZoomMax);
    }
    
    this.googleMap.panTo(new google.maps.LatLng(m.lat, m.lng));
  }

  showAppointmentDetails(claimNumber: string = undefined, dispatchId: string = undefined, appointmentId: string = undefined) {
    this.progressSpinnerShown = true;
    this.appointmentService.getAppointment(appointmentId)
    .subscribe(data => { 
      this.progressSpinnerShown = false;
      this.preserveInfowindows();
      this.preserveRoute();
      this.router.navigateByUrl('/appointmentdetails/' + claimNumber + '/' + dispatchId);      
    }, error => {
      this.progressSpinnerShown = false;
      console.log('Failed to load the appointment');
      this.showMessage('error', error.statusText || "Failed to load the appointment", error.message || JSON.stringify(error), true, true);
    });   
  }

  showMap() {
    this.router.navigateByUrl('/map/' + this.randomSubOne() * 1000);
  }

  dynamicSvgIconUrl(svgText: string, svgTextToReplace: string, dynamicText: string) {
    return 'data:image/svg+xml;charset=UTF-8;base64,' + btoa(svgText.replace(svgTextToReplace, dynamicText));
  }

  directionsValidate = function(directions: oneDirection[]): boolean {
    let _ret: boolean = true;
    directions.forEach(_direction => {
      if (_ret === false) {
        return false;
      }
      if (!(_direction.origin && _direction.destination )) {
        this.directionsSummary = 
          this.directionsSummaryError 
          + 'Start and/or End missing from directions request';
        _ret = false;
        //out of loop 
        return false;
      } else {
        if (_direction.waypoints.length > 25) {
          this.directionsSummary = 
            this.directionsSummaryError 
            + 'Too many waypoints in the request (' 
            + _direction.waypoints.length.toString() 
            +  '). The maximum allowed waypoints for this request is 25, plus the origin, and destination.';
          _ret = false;
          //out of loop 
          return false;
        }
      }
    });
    this.directionsValidated = _ret;
    return _ret;
  }

  getDirectionsFullscreen() {
    this.ngZone.run(
      function(){
        this.getDirections();
      }, this);  
  }

  getDirections() {
    
    this.directionsSummary = this.directionsSummaryInitialize;

    let _directions: oneDirection[] = [];
    let _direction: oneDirection = {};
    let _waypoints: oneWaypoint[] = [];
    let _waypoint: oneWaypoint;
    let _currentPosMarker: oneMarker;
    
    let _icon: any;
    
    let _markerOptionTemplate: google.maps.MarkerOptions = {
      icon: _icon,
      map: this.googleMap,
      animation: google.maps.Animation.DROP
    };
    
    //we will render all waypoints the same
    //but we could do it differently if passing an array here
    //with equal size of the waypoints array size
    this.markerOptions = {
      origin: _markerOptionTemplate,
      destination: _markerOptionTemplate,
      waypoints: _markerOptionTemplate
    };

    this.renderOptions = {
      suppressMarkers: true 
    }

    this.showDirections === false && 
    this.filteredMarkers.forEach(oneMarker => {
      if (oneMarker.label === this.centerLocationName) {
        _currentPosMarker = oneMarker;
      } 
      if (oneMarker.route) {
        switch(oneMarker.route['AttributeValue']) {
          case 'none':
            return;
          case 'origin':
          case 'destination':
            _direction[oneMarker.route['AttributeValue']] = {lat: oneMarker.lat, lng: oneMarker.lng};          
            break;
          case 'waypoint':
            _waypoint = {};
            _waypoint.location = {lat: oneMarker.lat, lng: oneMarker.lng};
            _waypoints.push(_waypoint);
            break;
          default:
            _direction[oneMarker.route['AttributeValue']] = {lat: oneMarker.lat, lng: oneMarker.lng};
            break;            
        }
      }
    });        

    if (_waypoints.length > 0 || _direction.destination) {
      if (_currentPosMarker && !_direction.origin) {
        _currentPosMarker.route = this.directionsOptionsObject.origin;
        _direction.origin = {lat: _currentPosMarker.lat, lng: _currentPosMarker.lng};
      }      
      if (!_direction.destination) {
        _direction.destination = _direction.origin;
      }
    } 

    _direction.waypoints = _waypoints;
    _directions.push(_direction);

    this.directions = (this.directionsValidate(_directions) ? _directions : []);  
    
    this.showDirections = !(this.showDirections);
    if(this.showDirections === false && this.googleMapFullscreen === true) {
      this.showDialog = false;
    }
  }

  clearDirectionsFullscreen() {
    this.ngZone.run(
      function(){
        this.clearDirections();
      }, this);  
  }  
  clearDirections() {
    this.filteredMarkers.forEach(oneMarker => {
      oneMarker.route = this.directionsOptionsObject.none;
    });

    this.destroyMarkers();
    this.directions = [];  
    this.showDirections = false;
    if(this.googleMapFullscreen === true) {
      this.showDialog = false;
    }    
  }  


  getAutomaticDirectionsFullscreen() {
    this.ngZone.run(
      function(){
        this.getAutomaticDirections();
      }, this);  
  }   
  getAutomaticDirections() {
    
    this.directionsSummary = this.directionsSummaryInitialize;
    
    let _directions: oneDirection[] = [];
    let _direction: oneDirection = {};
    let _waypoints: oneWaypoint[] = [];
    let _waypoint: oneWaypoint;
    
    let _icon: any;
    
    let _markerOptionTemplate: google.maps.MarkerOptions = {
      icon: _icon,
      map: this.googleMap,
      animation: google.maps.Animation.DROP
    };
    
    //we will render all waypoints the same
    //but we could do it differently if passing an array here
    //with equal size of the waypoints array size
    this.markerOptions = {
      origin: _markerOptionTemplate,
      destination: _markerOptionTemplate,
      waypoints: _markerOptionTemplate
    };

    this.renderOptions = {
      suppressMarkers: true 
    }

    //only if prior state was showDir false, that would go to true below
    //first preserve manual assignments of start, end, for all but current position
    this.showDirections === false 
      && this.filteredMarkers.forEach(oneMarker => {  
        if (oneMarker.route) {
          if (oneMarker.label !== this.centerLocationName) {
            switch(oneMarker.route['AttributeValue']) {
              case 'none':
                oneMarker.route = this.directionsOptionsObject.waypoint;                
              case 'waypoint':
                  _waypoint = {};
                  _waypoint.location = {lat: oneMarker.lat, lng: oneMarker.lng};
                  _waypoints.push(_waypoint);
                  break;
              case 'origin':
              case 'destination':
                _direction[oneMarker.route['AttributeValue']] = {lat: oneMarker.lat, lng: oneMarker.lng};          
                break;
              default:
                _direction[oneMarker.route['AttributeValue']] = {lat: oneMarker.lat, lng: oneMarker.lng};
                break;            
            }  
          }
        }            
    });        

    //if origin and/or destination not tagged, assign current position
    this.showDirections === false 
      && !(_direction.origin && _direction.destination) 
      && this.filteredMarkers.forEach(oneMarker => {      
        if (oneMarker.label === this.centerLocationName) {
          if (!_direction.destination) {
            oneMarker.route = this.directionsOptionsObject.destination;
            _direction.destination = {lat: oneMarker.lat, lng: oneMarker.lng};
          } 
          if (!_direction.origin) {
            oneMarker.route = this.directionsOptionsObject.origin;
            _direction.origin = {lat: oneMarker.lat, lng: oneMarker.lng};
          }          
        }
    }); 

    if (_direction.origin && _direction.destination) {
      _direction.waypoints = _waypoints;
      _directions.push(_direction);
    };

    this.directions = (this.directionsValidate(_directions) ? _directions : []);  
    this.showDirections = !(this.showDirections);
    if(this.showDirections === false && this.googleMapFullscreen === true) {
      this.showDialog = false;
    }
  }

  onDirectionsChange(event: google.maps.DirectionsResult) {
    // console.log(event);
  }

  dirResponseCounter: number = 0;
  onDirectionsResponse(event: google.maps.DirectionsResult) {
    // console.log(event);
    this.dirResponseCounter +=1;
    //for some stupid reason, event fires twice, with the same exact event payload
    //so we will only process the second time
    if (this.dirResponseCounter === 2) {
      this.directionCustomMarkers(event);
      this.dirResponseCounter = 0;
    }
  }  

  /**
   * Custom Origin and Destination Icon
   * @param map map
   * @param marker marker
   * @param markerOpts properties
   * @param content marker's infowindow content
   * @returns new marker
   * @memberof AgmDirection
   */
  setMarker(map, marker, markerOpts, content) {
    if (typeof this.infoWindow === 'undefined') {
        this.infoWindow = new google.maps.InfoWindow();
        this.sendInfoWindow.emit(this.infoWindow);
    }
    marker = new google.maps.Marker(markerOpts);
    marker['isClicked'] = false;
    // https://developers.google.com/maps/documentation/javascript/reference/marker?hl=zh-tw#MarkerOptions.clickable
    if (marker.getClickable()) {
        marker.addListener('click', () => {
            const infowindoContent = typeof markerOpts.infoWindow === 'undefined' ? content : markerOpts.infoWindow;
            if (marker.isClicked) {
              this.infoWindow.close();
            } else {
              this.infoWindow.setContent(infowindoContent);
              this.infoWindow.open(map, marker);  
            }            
            marker.isClicked = !marker.isClicked;
        });
    }
    return marker;
  }

  /**
   * This event is fired when remove markers
  */
  removeMarkers() {
    if (typeof this.originMarker !== 'undefined') {
        this.originMarker.setMap(null);
    }
    if (typeof this.destinationMarker !== 'undefined') {
        this.destinationMarker.setMap(null);
    }
    this.waypointsMarker.forEach((w) => {
        if (typeof w !== 'undefined') {
            w.setMap(null);
        }
    });
  }

  /**
   * This event is fired when destroy markers
   */
  destroyMarkers() {
    // Remove origin markers
    try {
        if (typeof this.originMarker !== 'undefined') {
            google.maps.event.clearListeners(this.originMarker, 'click');
            if (this.markerOptions.origin.draggable) {
                google.maps.event.clearListeners(this.originMarker, 'dragend');
            }
        }
        if (typeof this.destinationMarker !== 'undefined') {
            google.maps.event.clearListeners(this.destinationMarker, 'click');
            if (this.markerOptions.origin.draggable) {
                google.maps.event.clearListeners(this.destinationMarker, 'dragend');
            }
        }
        this.waypointsMarker.forEach((w) => {
            if (typeof w !== 'undefined') {
                google.maps.event.clearListeners(w, 'click');
            }
        });
        this.removeMarkers();
    }
    catch (err) {
        console.error('Can not reset custom marker.', err);
    }
  }
  
  
  directionCustomMarkers = function(response: google.maps.DirectionsResult) {
    // Custom Markers
    if (typeof this.markerOptions !== 'undefined') {
      //initialize 
      this.infoWindow = new google.maps.InfoWindow();
      this.sendInfoWindow = new EventEmitter();
      // Marker drag event handler
      this.originDrag = new EventEmitter();
      this.destinationDrag = new EventEmitter();   

      this.destroyMarkers();

      // Set custom markers
      // we handle one direction only, for now
      // let _direction: oneDirection = this.directions[0];
      // let _origin: oneDirMarker = _direction.origin;
      // let _destination: oneDirMarker = _direction.destination;
      // let _waypoints: oneWaypoint[] = _direction.waypoints;
      let _route: google.maps.DirectionsRoute;
      let _firstLeg: google.maps.DirectionsLeg;
      let _nextLeg: google.maps.DirectionsLeg;
      let _runningTotalDistance: number = 0;
      let _runningTotalDuration: number = 0;
      let _runningTripDistance: number = 0;
      let _runningTripDuration: number = 0;      
      let _tripFromTo: string = "";
      let _legFromTo: string = "";
      let _legFrom: string = "";
      let _legTo: string = "";
      let _markerCriticalDistance: number;
      let _shiftMarker: boolean;
      let _secToHoursMin1 = (n) => `${n / 3600 ^ 0} hour(s) ` + Math.round((n % 3600)/60) + ` mins`;
      let _secToHoursMin2 = (n) => `0${n / 3600 ^ 0}`.slice(-2) + ':' + ('0' + Math.round((n % 3600)/60)).slice(-2);
      let _customSvgIcon = function(customSvgMarker: oneCustomSvgMarker, charIndex: number) {
        let _blobUrl, _iconImage: google.maps.Icon;        
        _blobUrl = this.dynamicSvgIconUrl(customSvgMarker.svg, 'dynamic text', String.fromCharCode(65 + charIndex));
        // this didn't work
        // _blob = new Blob([_svg], {type: 'data:image/svg+xml'});
        // _blobUrl = URL.createObjectURL(_blob);
        _iconImage = {
          url: _blobUrl,
          anchor: new google.maps.Point(customSvgMarker.anchor.x, customSvgMarker.anchor.y) ,
          scaledSize: new google.maps.Size(customSvgMarker.scaledSize.width, customSvgMarker.scaledSize.height)         
        };
        return _iconImage;   
      }.bind(this);

      //only one route for now
      _route = response.routes[0];
      //we used default marker, first in svg collection
      this.shiftMarkerIndex = 0;
      _route.legs.forEach((_leg, _legIndex) => { 
        try {
              switch (_legIndex) {
                case 0:
                  _firstLeg = _leg;
                case _route.legs.length - 1:
                  _nextLeg = _leg;
                  break;
                default:
                  _nextLeg = _route.legs[_legIndex + 1];
                  break;
              }

              _runningTotalDistance += _leg.distance.value;
              _runningTotalDuration += _leg.duration.value;

              _legFromTo = 
                "<div class='info-window'>"
                + "<u><b>Leg "
                + (_legIndex + 1).toString() 
                + " of "
                + _route.legs.length.toString()
                + "</u></b>"
                + "<br>"
                + "<b>From:</b> " 
                  + _leg.start_address
                + "<br>"
                + "<b>To:</b> " 
                  + _nextLeg.end_address
                + "<br>"
                + "<b>Leg Distance:</b> " 
                  + _leg.distance.text
                + "&nbsp;&nbsp;&nbsp;&nbsp;"
                + "<b>Leg Time:</b> " 
                  + _leg.duration.text                  
                + "<br>"
                + "<b>Trip Distance:</b> " 
                  + (Math.round(_runningTripDistance/this.metersPerMile))
                  + " mi"
                + "&nbsp;&nbsp;&nbsp;&nbsp;"
                + "<b>Trip Time:</b> " 
                  + _secToHoursMin1(_runningTripDuration) 
                  + "<br>"                  
                + "<b>Trip+Leg Distance:</b> " 
                  + (Math.round(_runningTotalDistance/this.metersPerMile))
                + " mi"
                + "&nbsp;&nbsp;&nbsp;&nbsp;"                
                + "<b>Trip+Leg Time:</b> " 
                  + _secToHoursMin1(_runningTotalDuration) 
                + "</div>"

                _runningTripDistance += _leg.distance.value;
                _runningTripDuration += _leg.duration.value;                

              // Origin Marker, only first leg
              if (_legIndex === 0 
                  && typeof this.markerOptions.origin !== 'undefined') {              
                  this.markerOptions.origin.position = new google.maps.LatLng(_leg.start_location.lat(), _leg.start_location.lng());                                         
                  this.markerOptions.origin.icon = _customSvgIcon(this.customSvgMarker, _legIndex);

                  _legFrom = 
                  "<div class='info-window'>"
                  + "<u><b>TRIP START; Leg "
                  + (_legIndex + 1).toString() 
                  + " of "
                  + _route.legs.length.toString()
                  + "</u></b>"
                  + "<br>"
                  + "<b>From:</b> " 
                    + _leg.start_address
                  + "<br>"
                  + "<b>To:</b> " 
                    + _nextLeg.end_address
                  + "<br>"
                  + "<b>Leg Distance:</b> " 
                    + _leg.distance.text
                  + "<br>"
                  + "<b>Leg Time:</b> " 
                    + _leg.duration.text
                  + "</div>"

                  _tripFromTo =                  
                  "<div>"
                  + "<u><b>TRIP SUMMARY</b></u>"
                  + "&nbsp;&nbsp;&nbsp;&nbsp;"
                  + "<b>From:</b> " 
                    + _leg.start_address 

                  this.originMarker = this.setMarker(this.googleMap, this.originMarker, this.markerOptions.origin, _legFrom);
                  if (this.markerOptions.origin.draggable) {
                      this.originMarker.addListener('dragend', () => {
                          this.origin = this.originMarker.position;
                          this.directionDraw();
                          this.originDrag.emit(this.origin);
                      });
                  }
              }
              // Waypoints Marker, intermediate and last leg
              if (_legIndex > 0             
                  && _legIndex <= _route.legs.length - 1 
                  && typeof this.markerOptions.waypoints !== 'undefined') {
                  //waypoint always one behind to leg
                  let _waypointIndex = _legIndex-1;
                  let _waypointMarker: google.maps.Marker = this.waypointsMarker[_waypointIndex];
                  
                  _markerCriticalDistance = this.asTheCrowFlies(
                    _leg.start_location.lat(), 
                    _leg.start_location.lng(),
                    _nextLeg.start_location.lat(),
                    _nextLeg.start_location.lng()
                  );
                  _shiftMarker = (_markerCriticalDistance <= this.markerCollisionThreshold);                 

                  // If waypoints are not array then set all the same
                  if (!Array.isArray(this.markerOptions.waypoints)) {
                      this.markerOptions.waypoints.position = new google.maps.LatLng(_leg.start_location.lat(), _leg.start_location.lng()); 
                      this.markerOptions.waypoints.icon = _customSvgIcon(this.customSvgMarkers[this.shiftMarkerIndex], _legIndex);
                      this.waypointsMarker.push(this.setMarker(this.googleMap, _waypointMarker, this.markerOptions.waypoints, _legFromTo));
                  }
                  else {
                      this.markerOptions.waypoints[_waypointIndex].position = new google.maps.LatLng(_leg.start_location.lat(), _leg.start_location.lng());
                      this.markerOptions.waypoints[_waypointIndex].icon = _customSvgIcon(this.customSvgMarkers[this.shiftMarkerIndex], _legIndex);
                      this.waypointsMarker.push(this.setMarker(this.googleMap, _waypointMarker, this.markerOptions.waypoints[_waypointIndex], _legFromTo));
                  }
                  //we do this here, AFTER we consumed the svg marker, so that default marker is always first
                  if (_shiftMarker === true) {
                    this.shiftMarkerIndex += 1;
                  } else {
                    this.shiftMarkerIndex = 0;
                  }
                  //reset as we consumed all the markers reserved in the array
                  this.shiftMarkerIndex = (this.shiftMarkerIndex % this.customSvgMarkers.length);
              }
              // Destination Marker, only last leg
              if (_legIndex === _route.legs.length - 1 
                &&  typeof this.markerOptions.destination !== 'undefined') {

                _legTo = 
                  "<div class='info-window'>"
                  + "<u><b>TRIP END;"
                  + " Total Legs: "
                  + _route.legs.length.toString()
                  + "</u></b>"
                  + "<br>"
                  + "<b>Arrive at: </b> " 
                    + _nextLeg.end_address
                  + "<br>"
                  + "<b>Trip Distance: </b> " 
                    + (Math.round(_runningTotalDistance/this.metersPerMile))
                  + " mi"
                  + "<br>"
                  + "<b>Trip Time: </b> " 
                    + _secToHoursMin1(_runningTotalDuration) 
                  + "</div>";

                  _tripFromTo +=                  
                  "&nbsp;&nbsp;&nbsp;&nbsp;"
                  + "<b>To:</b> " 
                    + _nextLeg.end_address                    
                  + "&nbsp;&nbsp;&nbsp;&nbsp;"
                  + "<b>Total Legs:</b>"
                    + _route.legs.length.toString()
                  + "&nbsp;&nbsp;&nbsp;&nbsp;"
                  + "<b>Trip Distance:</b> " 
                    + (Math.round(_runningTotalDistance/this.metersPerMile))
                  + " mi"
                  + "&nbsp;&nbsp;&nbsp;&nbsp;"
                  + "<b>Trip Time:</b> " 
                    + _secToHoursMin1(_runningTotalDuration) 
                  + "</div>";

                  this.directionsSummary = _tripFromTo;
                  if (this.googleMapFullscreen === true) {
                    this.showMessage('info', 'Directions Summary', this.directionsSummary);
                  }                  
  
                this.markerOptions.destination.position = new google.maps.LatLng(_leg.end_location.lat(), _leg.end_location.lng());                  
                _markerCriticalDistance = this.asTheCrowFlies(
                  _firstLeg.start_location.lat(), 
                  _firstLeg.start_location.lng(),
                  _leg.end_location.lat(),
                  _leg.end_location.lng()
                );
                _shiftMarker = (_markerCriticalDistance <= this.markerCollisionThreshold);
                //last marker normal by default
                this.shiftMarkerIndex = 0;
                this.shiftMarkerIndex = (_shiftMarker === true ? this.shiftMarkerIndex + 1 : this.shiftMarkerIndex);                  
                this.markerOptions.destination.icon = _customSvgIcon(this.customSvgMarkers[this.shiftMarkerIndex], _legIndex + 1);
                this.destinationMarker = this.setMarker(this.googleMap, this.destinationMarker, this.markerOptions.destination, _legTo);
                if (this.markerOptions.destination.draggable) {
                    this.destinationMarker.addListener('dragend', () => {
                        this.destination = this.destinationMarker.position;
                        this.directionDraw();
                        this.destinationDrag.emit(this.destination);
                    });
                }
              }              
            }
            catch (err) {
                console.error('MarkerOptions error.', err);
            }
      });
    }
  }

  setLocation = function (position, lat, long, markCircleCenter: boolean = true) {
    if (position) {
      this.lat = position.coords.latitude;
      this.lng = position.coords.longitude;  
    } else {
      this.lat = lat;
      this.lng = long;
    }

    //show current location
    this.getAddress(this.lat, this.lng, function (name: string, addr: string, formattedAddr: string) {
      this.ngZone.run(
        function () {
          this.search.nativeElement.value = formattedAddr;
          if (markCircleCenter) {
            this.randomMarkers(1, this.lat, this.lng, iconType.other, this.centerLocationName, addr, 0);
          }
          if (this.mainCircle !== undefined) {
            if (this.mainCircle.latitude !== this.lat) {
              this.mainCircle.latitude = this.lat;
            }
            if (this.mainCircle.longitude !== this.lng) {
              this.mainCircle.longitude = this.lng;    
            }
          } else {
            this.mainCircle = {
              latitude: this.lat, 
              longitude: this.lng, 
              radius: (this.farthestMarker ? this.farthestMarker.distanceFromCenter * 1.1 : this.defaultRadiusInMiles * this.metersPerMile),
              radiusInMiles:  (this.farthestMarker ? this.farthestMarker.distanceFromCenter * 1.1/this.metersPerMile : this.defaultRadiusInMiles),
              editable: true, 
              fillColor: "green",
              fillOpacity: 0.1
            };
          }
          this.storageService.save("mainCircleCache", this.mainCircle);
          //now that we have a new circle center, we update the appointments from within
          this.getAppointmentsByLocation();
          
          //if map center was cached, once loaded and map used it, no need for it anymore;
          if (this.mapCenter) {
            this.lat = this.mapCenter.lat();
            this.lng = this.mapCenter.lng();
            this.mapCenter = undefined;          
          }
        }
        , this);
    });

  }.bind(this);

  public getFilteredMarkers = function(filterType: string): void {
    let filterCases: oneFilter[];
    let filterCasesASSIGNMENT: oneFilter[] = (this.mfASSIGNMENT ? this.mfASSIGNMENT.value || [] : []);
    let filterCasesAPPOINTMENT: oneFilter[] = (this.mfAPPRAISER ? this.mfAPPRAISER.value || [] : []);
    //filterCases = (this['mf' + filterType] ? this['mf' + filterType].value || [] : []); 
    let filterFieldsArr: string[], filterFieldDelim: string = "|", filterKey: string, filterValue: string;
    //we are looking for an OR condition, so any true case will exit code and validate marker as matching
    let goodMarker: boolean = false;
    let goodMarkerCases: boolean[] = [];
    let goodMarkerCase: boolean;
    let _lastFilterKey: string = "";
    let _filteredMarkers: oneMarker[] = [];
    let _centerLocationLabel: string = this.centerLocationName;
    if (filterCasesASSIGNMENT.length === 0 && filterCasesAPPOINTMENT.length === 0) {
      this.filterMatchCount = this.markers.length - 1;
      this.filteredMarkers = this.markers;
    } else
    {
      _filteredMarkers = this.markers.filter(function(eachMarker: oneMarker) { 
        //circle center, we are out of here
        if (eachMarker.label === _centerLocationLabel) {
          //exit for loop asap
          //only show center location if no filters
          //otherwise it will show wrong count on cluster marker          
          // if (filterCases.length > 0) {
          //   return false;
          // } else {
            return true;
          // }
        }
        goodMarker = true;
        goodMarkerCases = [];
        goodMarkerCase = false;
        _lastFilterKey = "";
        filterCases = (eachMarker.locationType === 'ASSIGNMENT' ? filterCasesASSIGNMENT : filterCasesAPPOINTMENT); 
        for (let i = 0; i < filterCases.length; i++) {
          filterFieldsArr = filterCases[i].AttributeValue.split(filterFieldDelim);
          filterKey = filterFieldsArr[0];
          filterValue = filterFieldsArr[1];
          if (_lastFilterKey === "") {
            _lastFilterKey = filterKey;
          }
                              
          //not all markers have all the properties, so undefined is a possibility
          if (eachMarker[filterKey]) {            
            if (_lastFilterKey !== filterKey) {
              goodMarkerCases.push(goodMarkerCase);
              goodMarkerCase = false;
            }   
            //evaluate filter case for one key, OR condition
            switch (typeof(eachMarker[filterKey])) {
                case 'object':
                  //for multiselect and other values that are actually objects, we need to search within the object
                  let foundDeepValue: any;
                  foundDeepValue = this.deepSearch(eachMarker[filterKey], 'AttributeValue', (k, v) => v === filterValue);
                  goodMarkerCase = (goodMarkerCase || foundDeepValue);
                  break;
                default:
                  goodMarkerCase = (goodMarkerCase || (eachMarker[filterKey].toString() === filterValue));
                  break;
            }           
          }
          _lastFilterKey = filterKey;
          //if last case
          if (i === filterCases.length - 1) {
            goodMarkerCases.push(goodMarkerCase);
          }
        }
        
        //evaluate filter cases for the marker, AND condition
        // if (goodMarkerCases.length > 0 && goodMarkerCases.toString().search("false") === -1) {
        //   return true;
        // } else {
        //   return goodMarker;
        // }
        for (let i = 0; i < goodMarkerCases.length; i++){
          goodMarker = (goodMarker && goodMarkerCases[i]); 
        }
        return goodMarker;
      }.bind(this));
      this.filteredMarkers = _filteredMarkers;      
    }
    return;
  }

  onFilterSelected(event, filterType: string) {
    this.syncSelectedFilters();
    // console.log(event.original, event.value, event.itemValue);
    this.getFilteredMarkers(filterType);
  }

  onCalendarSelect(event: any, m: oneMarker, fieldName: string) {
    console.log("Calendar entry changed", event);
    //next is used to show Update button
    m.appt.dataChanged = true;

    //this is to keep the interval under control
    let currentDate = new Date();
    let minMaxDateTime = this.minMaxDate(fieldName, event || currentDate);
    let diffTime: number;
    switch(fieldName) {
      case "startDateTime":
        diffTime = this.dateDiff(event, (m.appt.endDateTime || currentDate), "minutes");
        if (diffTime < this.minApptMinutes || diffTime > this.maxApptMinutes) {
          m.appt.endDateTime = minMaxDateTime;
        }
        break;
      case "endDateTime":
        diffTime = this.dateDiff((m.appt.startDateTime || currentDate), event, "minutes");
        if (diffTime < this.minApptMinutes || diffTime > this.maxApptMinutes) {
          m.appt.startDateTime = minMaxDateTime;
        }
        break;
      default:
    }
  }

  clearFilters(event, filterType: string, refreshDrop: boolean = true, refreshMarkers: boolean = true) { 
    // this.getAppointmentsByLocation();
    this['selectedFilters' + filterType] = [];
    this.selFilterTags = [];
    this['mf' + filterType].close(event);
    this['mf' + filterType].filterValue = "";
    setTimeout(() => {
      if (refreshMarkers) {
        this.getFilteredMarkers(filterType);
      }
      if (refreshDrop) {
        this['mf' + filterType].show();     
      }
    }, 100);    
  }

  oFoundDeepParent: any;
  deepSearch = function (object, key, predicate, returnParent: boolean = false, findAll: boolean = false) {
    let retAll: any[] = [];
    if (object.hasOwnProperty(key) && predicate(key, object[key]) === true) 
    {
      //if direct property match, return main object
      return object;
    }

    this.oFoundDeepParent = null;
    for (let i = 0; i < Object.keys(object).length; i++) {
      let value = object[Object.keys(object)[i]];
      if (typeof value === "object" && value != null) {
        //the inner search will always be performed with returnParent false
        let o = this.deepSearch(object[Object.keys(object)[i]], key, predicate, false);
        if (o != null) {
          if (returnParent === false) {
            this.oFoundDeepParent = object;
            if (findAll === false) {
              return o;
            } else {
              retAll.push(o);
            }            
          } else {
            if (findAll === false) {
              return (this.oFoundDeepParent || o);
            } else {
              retAll.push(this.oFoundDeepParent || o);
            }                        
          }
        }
      }
    }
    if (findAll && retAll.length !== 0) {
      return retAll;
    } else
    {
      return null;
    }
  }


  getAppointmentsByLocationFullscreen = function() {
    this.ngZone.run(
      function(){
        this.getAppointmentsByLocation();
      }, this);     
  }

  getAppointmentsByLocation = function(callback:any = undefined) {
    let _marker: oneMarker;
    let _filter: oneFilter;
    let _filterFieldsMapping = new filterFieldsMapping;
    let _filterFieldKey: string;    
    let _filterFieldLabel: string;
    let _filterFieldValue: string;
    let _filterDisplayKey: string;
    let _filterFieldForcedFilters: any;
    let _forcedFiltersReturn: any;
    let _forcedFilters: oneFilter[];
    let _groupedFilter: oneGroupedFilter;

    let evalFieldExpression = function(fieldExpression: string, fieldValuePlaceholder: string, fieldValue: any){
      let dynFunction = Function('"use strict";return (' + fieldExpression.replace(fieldValuePlaceholder, fieldValue) + ')');
      //pass the current context to the dynamic function
      return dynFunction.call(this);
    }.bind(this);
  
    let maxAppointments: any = this.markersMax.nativeElement.value;
    let oneAppt: Appointment;
    let markerMessage: string;
    maxAppointments = maxAppointments ? maxAppointments : this.maxMarkers;

    //if user defined any sticky server filter
    let serverFilters: oneFilter[] = [];

    //if empty, try to recover from cache, if anything there
    this.restoreSelFilterTags();
    this.selFilterTags && this.selFilterTags.forEach(eachFilterTag => {
      if (eachFilterTag.AtServer) {
        serverFilters = serverFilters.concat(eachFilterTag.Filters);
      }
    });
    let lastFilterColumn: string;
    let serverFilter: string = (serverFilters.length > 0 ? "(" : "");
      serverFilters.sort().forEach(eachFilter => {
        //added check for eachFilter, since seeing nulls
        if (eachFilter) {
          if (lastFilterColumn) {
            if (lastFilterColumn != eachFilter.AttributeValue.split('|')[0]) {
              serverFilter += ") AND (";
            } else {
              serverFilter += " OR ";
            }  
          }  
          //make sure we transform field names from hungarian to UPPER _
          serverFilter += 
            eachFilter.AttributeValue.split('|')[0].replace(new RegExp('(?<=[A-Z])(?=[A-Z][a-z])|(?<=[^A-Z])(?=[A-Z])|(?<=[A-Za-z])(?=[^A-Za-z])', 'g'),"_").toUpperCase() 
            + "='" 
            + eachFilter.AttributeValue.split('|')[1] 
            + "'";         
          //tag the filter column group 
          lastFilterColumn = eachFilter.AttributeValue.split('|')[0];
        }
      });
    serverFilter += (serverFilters.length > 0 ? ")" : "");

    //we want to restore the screen to as close as possible to where they were
    //before the refresh was called
    this.preserveInfowindows();
    this.preserveRoute();

    //clear current markers with the exception of current location
    this.markers.splice(1, this.markers.length);
    this.filteredMarkers = this.markers;
    
    //clean filters altogether
    this.filters = [];
    this.dynamicFilters = [];
    this.dynamicGroupedFilters = [];
    
    //reset selected filters, and tags
    //this.selectedFilters = (this.selectedFilters || []);
    //this.selFilterTags = (this.selFilterTags || []);

    //used to clean directions, but not anymore, since there is value in keeping the 
    //perimeter of current appraiser route compared to other appointments (maybe aquire some of them)
    // this.directions = [];
    // this.showDirections = false;

    let apptsLocationObservable = this.appointmentService.getAppointmentsByLocation(this.mainCircle, maxAppointments, (this.currentLocation ? this.userId : null), serverFilter);
    
    //once I used the current location when I entered page, the only other way to set location to current is through the top button     
    this.currentLocation = false;

    this.progressSpinnerShown = true;
    apptsLocationObservable.pipe(map(data => {
      // console.log(this.appointmentService.dataStore.appointments);
      this.appointmentService.dataStore.appointments.forEach(arrayElement  => {
        oneAppt = arrayElement;      
        _marker = {
          lat: oneAppt.locationLat,
          lng: oneAppt.locationLong,      
          label: '',
          appointmentId: oneAppt.appointmentID,
          claimNumber: oneAppt.claimNumber,
          dispatchId: oneAppt.vendorSequence,
          message: '',
          draggable: false,
          iconUrl: this.getIcon(oneAppt),
          iconSize: this.getIconSize(oneAppt),
          distanceFromCenter: oneAppt.distance,
          lob: oneAppt.lob,
          age: oneAppt.age,
          appraiserName: oneAppt.appraiserName,
          appraiserType: oneAppt.appraiserType ,
          appraiserId: oneAppt.appraiserId,
          channel: oneAppt.channel,
          startDate: oneAppt.startDate,
          startTime: oneAppt.startTime,
          status: oneAppt.status,
          locationType: oneAppt.locationType,
          route: this.directionsOptionsObject.none,
          locAppt: oneAppt,
          driveable: oneAppt.driveable
        };
        this.markers.push(_marker);
        if (this.markers.length > 1) {
          if (!this.farthestMarker || _marker.distanceFromCenter > this.farthestMarker.distanceFromCenter) {
            this.farthestMarker = _marker;
          }  
        }
      });
      this.filteredMarkers = this.markers;    
      return data;
    }))
    .pipe(map(data => {
      //process filters
      let filterKeys = Object.keys(_filterFieldsMapping);
      let _lastAssignedFilterId: number = 0;
      let _lastAssignedFilterGroup: string = "";
      let _lastAssignedFilterType: string = "";
      for (let i = 0; i < filterKeys.length; i++) {
        _filterFieldKey = filterKeys[i];
        _filterFieldLabel = _filterFieldsMapping[_filterFieldKey]["label"];
        _filterFieldValue = _filterFieldsMapping[_filterFieldKey]["modifier"];
        _filterFieldForcedFilters = _filterFieldsMapping[_filterFieldKey]["forcedFilters"];
        _filterDisplayKey =  undefined;
        if (_filterFieldForcedFilters) {
          //eval will take care of string expressions translated to values based on public property names, as in 'this.userName' becoming current signed user
          _forcedFiltersReturn = evalFieldExpression(_filterFieldForcedFilters, "nothing to look for", "nothing to replace with");
          if (_forcedFiltersReturn instanceof Array) {
            _forcedFilters = _forcedFiltersReturn;
          } else {
            _forcedFilters = [_forcedFiltersReturn];
          }
          // console.log("Forced Filters: ", _forcedFilters);
          _forcedFilters.forEach(_filter => {           
            this.dynamicFilters.push(_filter);
            _lastAssignedFilterId = _lastAssignedFilterId + 1;
            _lastAssignedFilterGroup = _filterFieldLabel;
            _lastAssignedFilterType = _filter.FilterType;
          });
        }
        //!!!!only when switching to primeng 11.x we can do multiselect with grouping
        //before we initialize a new group filter, we save the prior non-empty one
        if (_groupedFilter && _groupedFilter.items.length > 0) {
          this.dynamicGroupedFilters.push(_groupedFilter);
        } 
        _groupedFilter = {
          label: _filterFieldLabel,
          value: _filterFieldKey,
          items: []
        };  
        this.appointmentService.dataStore.appointments.forEach(arrayElement  => {
          oneAppt = arrayElement;
          let keys = Object.keys(oneAppt);
          for (let j = 0; j < keys.length; j++) {
            if (oneAppt[_filterFieldKey] && _filterFieldKey === keys[j]){
              //in case any of the labels is filterType specific, we reevaluate it here;
              let _otherMapping = _filterFieldsMapping[_filterFieldKey + oneAppt.locationType];
              let _displayDecision = (!_otherMapping || _otherMapping['display'] === undefined ? true : _otherMapping['display']);
              _filterFieldLabel = (_otherMapping ? _otherMapping["label"] : _filterFieldLabel);
              _filterDisplayKey = _filterFieldLabel + ": " + (_filterFieldValue ? evalFieldExpression(_filterFieldValue, "fieldValue", oneAppt[keys[j]]) : oneAppt[keys[j]]); 
              if(_displayDecision === true && this.dynamicFilters.filter(function(e: oneFilter) { 
                return e.AttributeValue === (keys[j] + "|" + oneAppt[keys[j]]);
              }).length === 0) {
                // filter not in filters already 
                _filter = {
                  "AttributeValueDisplayName": _filterDisplayKey,
                  "AttributeValue": keys[j] + "|" + oneAppt[keys[j]],
                  "FilterType": oneAppt.locationType,
                  "Id": _lastAssignedFilterId + 1,
                  "GroupHeader": (_lastAssignedFilterGroup === _filterFieldLabel && _lastAssignedFilterType.includes(oneAppt.locationType) ? "" : _filterFieldLabel)
                };
                this.dynamicFilters.push(_filter);
                _groupedFilter.items.push(_filter);
                _lastAssignedFilterId = _lastAssignedFilterId + 1;
                _lastAssignedFilterGroup = _filterFieldLabel;
                _lastAssignedFilterType = oneAppt.locationType;
              }                               
            }
          };
        });        
      }
      // console.log("Filters: ", this.dynamicFilters);
      // console.log("Grouped Filters", this.dynamicGroupedFilters);
      this.filters = this.dynamicFilters;
      this.groupedFilters = this.dynamicGroupedFilters;
      this.dynamicFilters = [];
      this.dynamicGroupedFilters = [];
    }))
    .subscribe(
      (data: any) => {
        this.progressSpinnerShown = false;
        console.log("getAppointmentsByLocation success;");
        // this.showMessage('success', 'Success', "Received latest assignments");
        this.restoreInfowindows();
        this.restoreRoute();
        //this.restoreSelFilterTags();
        this.restoreSelectedFilters();

        if (this.loading === true) {
          //signal restore services to not execute anymore next time
          this.loading = false;    
        }
                
        callback && callback.call(this);
        }, error => {
          this.progressSpinnerShown = false;

          //signal restore services to not execute anymore next time
          this.loading = false;

          this.showMessage('error', error.statusText || "Error", error.message || JSON.stringify(error), true, true);
          callback && callback.call(this);
        }
    ); 
  }

  getMyAppointmentsForTodayFullscreen = function() {
    this.ngZone.run(
      function(){
        this.getMyAppointmentsForToday();
      }, this); 
  }

  clearAllFilters = function() {
    let evObj = new CustomEvent('click');
    this.selectedFilters = [];
    this.clearFilters(evObj, 'ASSIGNMENT', false, false);
    this.clearFilters(evObj, 'APPRAISER', false, true);          
  }

  getMyAppointmentsForToday = function() {    
    //if we want toggle mode, uncomment next
    // if (
    //   (this.selectedFiltersASSIGNMENT && this.selectedFiltersASSIGNMENT.length > 0)
    //   || 
    //   (this.selectedFiltersAPPRAISER && this.selectedFiltersAPPRAISER.length > 0)      
    // )
    // {
    //   this.clearAllFilters();
    //   return;
    // } 
    
    let foundToday:any;
    foundToday = this.deepSearch(this.filtersASSIGNMENT, 'AttributeValue', (k, v) => v === 'startDate|TODAY');

    let foundUserAssignment:any;
    foundUserAssignment = this.deepSearch(this.filtersASSIGNMENT, 'AttributeValue', (k, v) => v === 'appraiserName|' + this.userName);

    let foundUserAppraiser:any;
    foundUserAppraiser = this.deepSearch(this.filtersAPPRAISER, 'AttributeValue', (k, v) => v === 'appraiserName|' + this.userName);

    if (foundToday && foundUserAssignment && foundUserAppraiser) {
      //replace filters with found ones    
      this.selectedFilters = Array(foundToday, foundUserAssignment, foundUserAppraiser);    
      setTimeout(() => {
        this.getFilteredMarkers();      
      }, 100);
    }
  }

  getMyAppointmentsFullscreen = function() {
    this.ngZone.run(
      function(){
        this.getMyAppointments();
      }, this); 
  }

  getMyAppointments = function() {    
    //if we want toggle mode, uncomment next
    // if (
    //   (this.selectedFiltersASSIGNMENT && this.selectedFiltersASSIGNMENT.length > 0)
    //   || 
    //   (this.selectedFiltersAPPRAISER && this.selectedFiltersAPPRAISER.length > 0)      
    // )
    // {
    //   this.clearAllFilters();
    //   return;
    // } 
    
    let foundUserAssignment:any;
    foundUserAssignment = this.deepSearch(this.filtersASSIGNMENT, 'AttributeValue', (k, v) => v === 'appraiserName|' + this.userName);

    let foundUserAppraiser:any;
    foundUserAppraiser = this.deepSearch(this.filtersAPPRAISER, 'AttributeValue', (k, v) => v === 'appraiserName|' + this.userName);


    if (foundUserAssignment && foundUserAppraiser) {
      //replace filters with found ones    
      this.selectedFilters = Array(foundUserAssignment, foundUserAppraiser);   
      setTimeout(() => {
        this.getFilteredMarkers();      
      }, 100);
    }
  }

  clickedMarkers: oneMarker[] = [];
  preserveInfowindows = function() {  
    //between two apptbyloc calls, if anyone opened markers for inquiry, we are trying to 
    //re-open them, to allow continuity in the workflow

    if (this.clickedMarkers.length > 0) {
      //if called multiple times, only first would have an effect
      //cleanup happens in restoreInfowindows
      return;
    }      

    this.filteredMarkers.forEach(oneMarker => {
      if (oneMarker.isClicked === true) {
        //since we want to cache the clicked markers,
        //we don't want to save the dynamic data structures
        //create a copy of oneMarker and drop appt, locAppt references
        let oneMarkerLightweight = Object.assign({}, oneMarker);
        oneMarkerLightweight.appt = undefined;
        oneMarkerLightweight.locAppt = undefined;
        this.clickedMarkers.push(oneMarkerLightweight);
      }
    });

    this.storageService.save("clickedMarkersCache", this.clickedMarkers);

  }
  restoreInfowindows = function() {
    let markersSerialObs;      
    this.filteredMarkers.forEach(oneMarker => {
      this.clickedMarkers.forEach(clickMarker => {
        if (oneMarker.appointmentId === clickMarker.appointmentId) {
          //make sure they open, even if they failed to toggle isClicked (like location)
          oneMarker.isClicked = false;
          if (oneMarker.label !== this.centerLocationName && oneMarker.locationType === 'ASSIGNMENT') {
            //success reaction if we subscribe
            let apptSuccess = function(data) {
              let oneAppt: any = this.appointmentService.dataStore.appointments[0];
              oneAppt.dataChanged = false;
              oneAppt.locationChanged = false;
              oneMarker.appt = oneAppt;
            }.bind(this);
            let apptError = function(error, caught) {
              // this.progressSpinnerShown = false;
              console.log('Failed to load the appointment');
              console.log(error.message);
              oneMarker.dynamicMessage = "<br><li>ERROR LOADING DETAILS</li>";
              oneMarker.dynamicMessage += "<br>" + error.message;
              this.showMessage('error', error.statusText || "Failed to load appointment", error.message || JSON.stringify(error), true, true);
              return EMPTY;      
            }.bind(this);             
            //we want to execute http calls in serial sequence
            if (markersSerialObs) {
              markersSerialObs = concat(markersSerialObs, this.markerClicked({m: oneMarker, subscribeLater: true}).pipe(map(apptSuccess, apptError)));
            } else {
              markersSerialObs = this.markerClicked({m: oneMarker, subscribeLater: true}).pipe(map(apptSuccess, apptError));
            }
          } else //appraiser and location markers 
          {
            this.markerClicked({m: oneMarker, subscribeLater: false});
          }

        }
      })
    });
    //if we just restored, clean up, so that we can check empty condition
    //during preserveInfowindows, so that we can call that anywhere, and only
    //first would execute
    this.clickedMarkers = [];
    this.storageService.remove("clickedMarkersCache");
    
    //trigger if the case     
    markersSerialObs && markersSerialObs.subscribe();
  }      


  routedMarkers: oneMarker[] = [];
  preserveRoute = function() {  
    //between two apptbyloc calls, if anyone opened markers for inquiry, we are trying to 
    //re-open them, to allow continuity in the workflow

    if (this.routedMarkers.length > 0) {
      //if called multiple times, only first would have an effect
      //cleanup happens in restoreInfowindows
      return;
    }      

    this.filteredMarkers.forEach(oneMarker => {
      if (oneMarker.route !== this.directionsOptionsObject.none) {
        //since we want to cache the clicked markers,
        //we don't want to save the dynamic data structures
        //create a copy of oneMarker and drop appt, locAppt references
        let oneMarkerLightweight = Object.assign({}, oneMarker);
        oneMarkerLightweight.appt = undefined;
        oneMarkerLightweight.locAppt = undefined;
        this.routedMarkers.push(oneMarkerLightweight);
      }
    });

    this.storageService.save("routedMarkersCache", this.routedMarkers);

  }
  restoreRoute = function() {   
    this.filteredMarkers.forEach(oneMarker => {
      this.routedMarkers.forEach(routeMarker => {
        if (oneMarker.appointmentId === routeMarker.appointmentId) {
          //make sure they open, even if they failed to toggle isClicked (like location)
          oneMarker.route = routeMarker.route;
        }
      })
    });
    //if we just restored, clean up, so that we can check empty condition
    //during preserveInfowindows, so that we can call that anywhere, and only
    //first would execute
    this.routedMarkers = [];
    this.storageService.remove("routedMarkersCache");
    
  }
  
  restoreSelFilterTags = function(atServerOnly: boolean = false) {    
    //if already populated, no need to restore
    if (this._selFilterTags) {
      return;
    }
    //restore the options first
    let filterTagsCache = this.storageService.get("filterTagsCachePersist");
    if (filterTagsCache) {
      this.filterTags = JSON.parse(filterTagsCache);
    }  
    let selFilterTagsCache = this.storageService.get("selFilterTagsCachePersist");
    if (selFilterTagsCache && JSON.parse(selFilterTagsCache).length !== 0) {
      let jsonSelFilterTags = JSON.parse(selFilterTagsCache);
      jsonSelFilterTags.map((e: oneFilterTag) => {
        e.Tag.replace(' (@Server)',''); 
        return e;
      });
      if (atServerOnly === true) {
        jsonSelFilterTags = jsonSelFilterTags.filter(function(e: oneFilterTag) { 
          return e.AtServer;
        });
      }
      this._selFilterTags = [];
      jsonSelFilterTags.forEach((e: oneFilterTag) => {
        this._selFilterTags.push(e);
      });       
    }    
  }

  restoreSelectedFilters = function() {
    let selectedFiltersCache = this.storageService.get("selectedFiltersCachePersist");
    if (selectedFiltersCache && JSON.parse(selectedFiltersCache).length !== 0) {
      this.selectedFilters = JSON.parse(selectedFiltersCache);
      setTimeout(() => {
        this.getFilteredMarkers();      
      }, 100);    
    }    
  }

  findResponseMessageCData = function(payload: string):string {
    let statusMsg:string;
    var matches = payload.match(/<!\[CDATA\[(.*?)\]\]>/s);
    if (matches && matches.length > 1) {
      statusMsg = matches[1];
    } else {
      console.log('Something off, no matches found for returned responseMsg CData');
      statusMsg = payload;
    }
    return statusMsg;
  }

  transmitToVendor(appointment$: Appointment) {
    this.isTransmitDisabled = true;
    this.progressSpinnerShown = true;

    let statusMsg:string;
    this.appointmentService.transmitAssignmentToVendor(appointment$).subscribe(
      result => {
        this.progressSpinnerShown = false;        
        statusMsg = this.findResponseMessageCData(result.toString());
        this.showMessage('success', "Transmission Confirmation", this.messageApplyCss(messageType.dispatchActions, statusMsg), true, true);
        this.isTransmitDisabled = false;
      },
      error => {
        // FIX THIS
        // handling success in the error clause based on status
        this.progressSpinnerShown = false;
        if (error.status == "200") {
          statusMsg = this.findResponseMessageCData(error.error.text);
          this.showMessage('success', "Transmission Confirmation", this.messageApplyCss(messageType.dispatchActions, statusMsg), true, true);          
        }
        else {
          this.showMessage('error', error.statusText || "Failed to transmit to vendor", error.message || JSON.stringify(error), true, true);          
        }
        this.isTransmitDisabled = false;
      });
  }  

  transferToSpinnerAppraiser(appointment$: Appointment, m: oneMarker, infoWindow: AgmInfoWindow, doConfirmation: boolean = true) {
        
    let continueTransferring = function() {
      this.transferToSpinnerAppraiser(appointment$, m, infoWindow, false);
    }.bind(this);

    if (doConfirmation === true) {
      this.confirm(
        "Please confirm that you want to transfer the assignment to " + this.appointmentService.selectedSpinnerAppraiser.fullName
        , continueTransferring
        , "Transfer Confirmation"
        , "{return;}"
        , "OK"
        , "Cancel"
        , true
      );
      return;  
    }
       
    //we want to re-open the same marker infowindow(s) and route
    this.preserveInfowindows();
    this.preserveRoute();
    
    this.isTransferDisabled = true;
    this.progressSpinnerShown = true;
    let statusMsg:string;
    this.appointmentService.transferAssignmentToUser(appointment$, this.appointmentService.selectedSpinnerAppraiser.userId).subscribe(
      result => {
        this.progressSpinnerShown = false;
        statusMsg = this.findResponseMessageCData(result.toString());
        this.showMessage('success', "Transfer Confirmation", this.messageApplyCss(messageType.dispatchActions, statusMsg), true, true);
        this.isTransferDisabled = false;
        //get appt details again, to reflect changes
        this.getAppointmentsByLocation();
      },
      error => {
        // handling success in the error clause based on status
        this.progressSpinnerShown = false;
        if (error.status == "200") {
          statusMsg = this.findResponseMessageCData(error.error.text);
          this.showMessage('success', "Transfer Confirmation", this.messageApplyCss(messageType.dispatchActions, statusMsg), true, true);          
        }
        else {
          this.showMessage('error', error.statusText || "Failed to transfer to " + this.appointmentService.selectedSpinnerAppraiser.fullName, error.message || JSON.stringify(error), true, true);
        }
        this.isTransferDisabled = false;
        //get appt details again, to reflect changes, no matter if success or fail
        this.getAppointmentsByLocation();
      });
  }

  spinAppraisers(appointment$: Appointment) {
    this.isSpinApprDisabled = true;
    //this.progressSpinnerShown = true;

    //ui-autocomplete-panel

    this.appointmentService.getSpinnerAppraisers(appointment$).subscribe(
      result => {
        //this.progressSpinnerShown = false;  
        //this.showMessage('success', "Spinner Confirmation", result.toString(), true, true);
        this.isSpinApprDisabled = false;
      },
      error => {
        // FIX THIS
        // handling success in the error clause based on status
        //this.progressSpinnerShown = false;
        if (error.status == "200") {
          this.showMessage('error', "Spinner Errored?", error.toString(), true, true);
        }
        else {
          this.showMessage('error', error.statusText || "Failed to spin appraisers", error.message || JSON.stringify(error), true, true);          
        }
        this.isSpinApprDisabled = false;
      });
  }  

  getIcon = function(oneAppt: Appointment) {
    if (oneAppt.iconUrl) {
      return oneAppt.iconUrl;
    } else {
      if (oneAppt.locationType === 'APPRAISER') {
        return iconType.appraiser;
      } else {
        return (oneAppt.lob === 'PA' || oneAppt.lob === 'CA' ? iconType.auto : iconType.home);
      }  
    }    
  }

  getIconSize = function(oneAppt: Appointment) {
    let iSize: string = oneAppt.iconSize;
    if (iSize && iSize.split("|").length > 0) {
      return {width: parseFloat(iSize.split("|")[0]), height: parseFloat(iSize.split("|")[1])};
    } else {
      if (oneAppt.locationType === 'APPRAISER') {
        return {width: 32, height: 37};
      } else {
        return {width: 32, height: 37};
      }  
    }
  }  

  locationError = function (err) {
    this.currentLocation = false;
    if(err.code == 1) {
        this.showMessage('error', err.statusText || "Location Access Denied", err.message || JSON.stringify(err), true, true);
    } else if( err.code == 2) {
        this.showMessage('error', err.statusText || "Location Position Unavailable", err.message || JSON.stringify(err), true, true);
    }
    //if browser couldn't give location, or not allowed, need to continue somehow, with default location
    this.setLocation(undefined, this.defaultLat, this.defaultLng);
    this.showMessage('warn', "Default Location", 
    // "<b>" + this.search.nativeElement.value + "</b>" + 
    "Assigned default location", true, true);    
  }.bind(this);        

  public randomSubOne(): number {
    return (Math.random() < 0.5 ? -1 : 1) * Math.random();
  }

  public randomMarkers(
      markerCount: number, 
      latitude: number = undefined, 
      longitude: number = undefined, 
      iconUrl: string = undefined, 
      label: string = undefined, 
      message: string = undefined,
      markerReplacePosition: number = undefined
  ) {
    for (let index = 0; index < markerCount; index++) {
      // let _lat = (latitude && markerCount === 1 ? latitude : latitude?latitude:this.lat + this.randomSubOne() * 0.1);
      // let _lng = (longitude && markerCount === 1 ? longitude : longitude?longitude:this.lng + this.randomSubOne() * 0.1);
      // let _distance = this.asTheCrowFlies(this.lat, this.lng, _lat, _lng);
      let randPoint, _lat, _lng, _distance;
      if (latitude && longitude) {
        _lat = latitude;
        _lng = longitude;
        _distance = 0; 
      } else {
        randPoint = this.pointInCircle({latitude: this.mainCircle.latitude, longitude: this.mainCircle.longitude}, this.mainCircle.radius);
        _lat = randPoint['randomCoords']['latitude'];
        _lng = randPoint['randomCoords']['longitude'];
        _distance = randPoint['randomDist'];  
      }
      let _marker = {
        lat: _lat,
        lng: _lng,      
        label: (label ? label : (this.markers.length + 1).toString()),
        message: (message ? message : 'Random Marker'),
        draggable: true,
        iconUrl: (iconUrl ? iconUrl : this.randomIcon()),
        distanceFromCenter: _distance,
        route: this.directionsOptionsObject.none
      }
      if (markerReplacePosition !== undefined) {
        this.markers.splice(markerReplacePosition, 1, _marker);
      } else {
        //insert at the end
        this.markers.push(_marker);
      }
      if (this.markers.length > 1) {
        if (!this.farthestMarker || _marker.distanceFromCenter > this.farthestMarker.distanceFromCenter) {
          this.farthestMarker = _marker;
        }  
      }
    }

    //after circle gets updated, we adapt the map to fit the circle
    //this.mainCircle.getBounds().then(bounds => this.googleMap.fitBounds(bounds));   
    //this.mainCircle.getBounds().then(bounds => console.log (bounds));
  } 

  EARTH_RADIUS: number = 6371000 /* meters  */;
  DEG_TO_RAD: number =  Math.PI / 180.0;
  THREE_PI: number = Math.PI*3;
  TWO_PI: number = Math.PI*2;
   
  isFloat = function (n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
  }
  
  recursiveConvert = function (input, callback){
    if (input instanceof Array) {
      return input.map((el) => this.recursiveConvert(el, callback))
    }
    if  (input instanceof Object) {
      input = JSON.parse(JSON.stringify(input))
      for (let key in input) {
        if( input.hasOwnProperty(key) ) {
          input[key] = this.recursiveConvert(input[key], callback) 
        } 
      }
      return input
    }
    if (this.isFloat(input)) { return callback(input) }
  }
  
  toRadians = function(input){
    return this.recursiveConvert(input, (val) => val * this.DEG_TO_RAD)
  }
  
  toDegrees = function(input){
    return this.recursiveConvert(input, (val) => val / this.DEG_TO_RAD)
  }
  

  pointAtDistance = function(inputCoords, distance) {
    let result = {}
    let coords = this.toRadians(inputCoords)
    let sinLat =  Math.sin(coords.latitude)
    let cosLat =  Math.cos(coords.latitude)

    /* go a fixed distance in a random direction*/
    let bearing = Math.random() * this.TWO_PI
    let theta = distance/this.EARTH_RADIUS
    let sinBearing = Math.sin(bearing)
    let cosBearing =  Math.cos(bearing)
    let sinTheta = Math.sin(theta)
    let cosTheta =    Math.cos(theta)

    result['latitude'] = Math.asin(sinLat*cosTheta+cosLat*sinTheta*cosBearing);
    result['longitude'] = coords.longitude + 
        Math.atan2( sinBearing*sinTheta*cosLat, cosTheta-sinLat*Math.sin(result['latitude'] )
    );
    /* normalize -PI -> +PI radians (-180 - 180 deg)*/
    result['longitude'] = ((result['longitude']+this.THREE_PI)%this.TWO_PI)-Math.PI

    return this.toDegrees(result)
  }

  pointInCircle = function (coord, distance) {
      let rnd =  Math.random()
      /*use square root of random number to avoid high density at the center*/
      let randomDist = Math.sqrt(rnd) * distance;
      return {randomDist: randomDist, randomCoords: this.pointAtDistance(coord, randomDist)};
  }

  resizeObservable$: Observable<Event>;
  resizeSubscription$: Subscription;
  mapClickListener: google.maps.MapsEventListener;
  zoomChangedListener: google.maps.MapsEventListener;
  centerChangedListener: google.maps.MapsEventListener;
  boundsChangedListener: google.maps.MapsEventListener;
  googleMapFullscreen: boolean = false;
  mapReadyHandler(map: google.maps.Map): void {
    this.googleMap = map;
    this.googleMap.setOptions(
      {
        disableDefaultUI: true, 
        fullscreenControl: true, 
        mapTypeControl: true,       
        mapTypeControlOptions: {
          position: google.maps.ControlPosition.BOTTOM_LEFT
        }, 
        streetViewControl: true, 
        scaleControl: true,
        controlSize: 25        
      });    
    this.mapClickListener = this.googleMap.addListener('click', ($event: google.maps.MouseEvent) => {
      console.log("Map clicked; ", $event.latLng.lat(), $event.latLng.lng());
      // this.ngZone.run(() => {
      //   this.randomMarkers(1, $event.latLng.lat(), $event.latLng.lng());
      // });
    });
    this.zoomChangedListener = this.googleMap.addListener('zoom_changed', function() {
      this.mapZoom = map.getZoom();
    }.bind(this));
    this.centerChangedListener = this.googleMap.addListener('center_changed', function() {
      this.storageService.save("mapCenterCache", map.getCenter());
    }.bind(this));     
    this.boundsChangedListener =  this.googleMap.addListener('bounds_changed', function() {
      let firstChild = this.googleMap.getDiv().firstChild;
      if (
        firstChild.clientHeight/window.innerHeight > 0.85 &&
        firstChild.clientWidth/window.innerWidth > 0.85
      ) {
        this.googleMapFullscreen = true;

        this.createFullScreenFilterButtons();
                        
        if (!this.routeDiv) {
          this.routeDiv = document.createElement("div");        
          this.mapCustomControl(this.routeDiv, "Route", "route",  this.getDirectionsFullscreen);
          this.googleMap.controls[google.maps.ControlPosition.RIGHT_CENTER].push(this.routeDiv);  
        }
        if (!this.autoRouteDiv) {
          this.autoRouteDiv = document.createElement("div");
          this.mapCustomControl(this.autoRouteDiv, "Route All", "autoRoute", this.getAutomaticDirectionsFullscreen);
          this.googleMap.controls[google.maps.ControlPosition.RIGHT_CENTER].push(this.autoRouteDiv);
        }
        if (!this.cleanRouteDiv) {
          this.cleanRouteDiv = document.createElement("div");
          this.mapCustomControl(this.cleanRouteDiv, "Clear Route", "cleanRoute", this.clearDirectionsFullscreen);
          this.googleMap.controls[google.maps.ControlPosition.RIGHT_CENTER].push(this.cleanRouteDiv);  
        }
        if (!this.resetZoomDiv) {
          this.resetZoomDiv = document.createElement("div");
          this.mapCustomControl(this.resetZoomDiv, "Reset View", "resetView", this.resetZoomFullscreen);
          this.googleMap.controls[google.maps.ControlPosition.RIGHT_CENTER].push(this.resetZoomDiv);  
        }                
      } else {
        this.googleMapFullscreen = false;
        this.googleMap.controls[google.maps.ControlPosition.RIGHT_CENTER].clear();
        this.googleMap.controls[google.maps.ControlPosition.TOP_LEFT].clear();
        this.googleMap.controls[google.maps.ControlPosition.BOTTOM_CENTER].clear();
        this.routeDiv = undefined;
        this.autoRouteDiv = undefined;
        this.cleanRouteDiv = undefined;
        this.resetZoomDiv = undefined;
        this.myApptTodayDiv = undefined;
        this.myApptDiv = undefined;
        this.allApptDiv = undefined;
        this.clearFiltersDiv = undefined;
      }
      //autocomplete has issues with alignment of drop-down with main input if fullscreen map;
      this.parentizeGMAutocomplete();      
    }.bind(this));
    
    //add location div to the top center of the map
    this.googleMap.controls[google.maps.ControlPosition.LEFT_TOP].push(this.location.nativeElement);      
  
  }  

  routeDiv: HTMLElement;
  autoRouteDiv: HTMLElement;
  cleanRouteDiv: HTMLElement;
  resetZoomDiv: HTMLElement;
  myApptTodayDiv: HTMLElement;
  myApptDiv: HTMLElement;
  allApptDiv: HTMLElement;
  locationDiv: HTMLElement;
  clearFiltersDiv: HTMLElement;
  
  mapCustomControl = function(controlDiv: Element, title: string, id: string, clickFunction) {
    // Set CSS for the control border.
    const controlUI = document.createElement("div");
    controlUI.style.backgroundColor = "#fff";
    controlUI.style.border = "2px solid #fff";
    controlUI.style.borderRadius = "3px";
    controlUI.style.boxShadow = "0 2px 6px rgba(0,0,0,.3)";
    controlUI.style.cursor = "pointer";
    controlUI.style.marginTop = "8px";
    controlUI.style.marginBottom = "22px";
    controlUI.style.textAlign = "center";
    controlUI.title = title;
    controlDiv.appendChild(controlUI);   
  
    // Set CSS for the control interior.
    const controlText = document.createElement("div");
    controlText.style.color = "rgb(25,25,25)";
    controlText.style.fontFamily = "Roboto,Arial,sans-serif";
    controlText.style.fontSize = "14px";
    controlText.style.lineHeight = "38px";
    controlText.style.paddingLeft = "5px";
    controlText.style.paddingRight = "5px";
    controlText.innerHTML = title;
    controlUI.appendChild(controlText);
  
    // Setup the click event listeners: simply set the map to Chicago.
    controlUI.addEventListener("click", clickFunction.bind(this));
  }

  createFullScreenFilterButtons = function() {
    // let foundToday:any;
    // foundToday = this.deepSearch(this.selectedFilters, 'AttributeValue', (k, v) => v === 'startDate|TODAY');
    // //recreate control on fullscreen map
    // this.googleMap.controls[google.maps.ControlPosition.BOTTOM_CENTER].clear();
    //   this.myApptTodayDiv = document.createElement("div");        
    //   this.mapCustomControl(this.myApptTodayDiv, 
    //     "My Asgns Today" 
    //     + (
    //         this.selectedFilters.length === 0
    //         ? "" 
    //         : (foundToday ? "(" + this.filterMatchCount.toString() + ")" : "")
    //       ) 
    //   ,"myApptsToday",  this.getMyAppointmentsForTodayFullscreen);
    //   this.googleMap.controls[google.maps.ControlPosition.BOTTOM_CENTER].push(this.myApptTodayDiv);

    //recreate control on fullscreen map
    this.googleMap.controls[google.maps.ControlPosition.BOTTOM_CENTER].clear();
    this.myApptDiv = document.createElement("div");        
      this.mapCustomControl(this.myApptDiv, 
        "My Asgns" 
        + (
            (!this.selectedFilters || this.selectedFilters.length === 0) 
            ? "" 
            : "(" + this.filterAsgnMatchCount.toString() + ")"
          ) 
      ,"myAppts",  this.getMyAppointmentsFullscreen);        
      this.googleMap.controls[google.maps.ControlPosition.BOTTOM_CENTER].push(this.myApptDiv);

      this.clearFiltersDiv = document.createElement("div");        
      this.mapCustomControl(this.clearFiltersDiv, 
        "Clear Filters"
      ,"clearFilters",  this.clearAllFilters);        
      this.googleMap.controls[google.maps.ControlPosition.BOTTOM_CENTER].push(this.clearFiltersDiv);      

      this.allApptDiv = document.createElement("div");        
      this.mapCustomControl(this.allApptDiv, 
        "Refresh" 
        + (
            (this.selectedFilters && this.selectedFilters.length > 0) 
            ? "" 
            : "(" + this.filterMatchCount.toString() + ")"
          )
      ,"allAppts",  this.getAppointmentsByLocationFullscreen);        
      this.googleMap.controls[google.maps.ControlPosition.BOTTOM_CENTER].push(this.allApptDiv);               
  }

  resetZoomFullscreen() {
    this.ngZone.run(
      function(){
        this.resetZoom();
      }, this);  
  } 
  resetZoom() {
    //close pop-up windows for all markers
    this.filteredMarkers.filter(function(eachMarker: oneMarker) {
      eachMarker.isClicked = false;
    }); 
    
    let googleCircle = new google.maps.Circle({
        center: new google.maps.LatLng(this.lat, this.lng),
        radius: this.mainCircle.radius  
      });
    this.googleMap.fitBounds(googleCircle.getBounds());    
    this.mapZoom = this.googleMap.getZoom();
    setTimeout(() => {
      this.googleMap.setCenter(new google.maps.LatLng(this.lat, this.lng));     
    }, 100);
    // this.mapZoom = this.defaultMapZoom;
    // this.googleMap.setZoom(this.mapZoom);
 
  }

  setCurrentCenter = function(curMarker: oneMarker) {
    // let curLocation = new google.maps.LatLng(curMarker.lat, curMarker.lng);
    // this.mainCircle.latitude = curMarker.lat;
    // this.mainCircle.longitude = curMarker.lng;
    // this.getAppointmentsByLocation(function(newCenter: google.maps.LatLng = curLocation){this.googleMap.setCenter(newCenter);});
    this.setLocation(undefined, curMarker.lat, curMarker.lng, false);
  }

  event(type,$event) {
    if (this.mainCircleUpdatedManually === true) {return};
    console.log(type,$event);
    switch(type) {
      case "radiusChange":
        this.mainCircleUpdatedManually = true;
          let radius = $event;
          let radiusInMiles: number = parseFloat($event)/this.metersPerMile;
          this.mainCircle.radiusInMiles = parseFloat(radiusInMiles.toFixed(2));
          this.mainCircle.radius = radius;
          this.storageService.save("mainCircleCache", this.mainCircle);
          setTimeout(() => {
            this.mainCircleUpdatedManually = false;
            this.getAppointmentsByLocation();      
          }, 100);
        break;
      case "centerChange":
        //since we change circle center by geocoding to a new address
        //we trigger the refresh from there, no need for this one anymore
        //this.setLocation(undefined, $event.lat, $event.lng);        
        break;
    }
    //this.radius = $event;
    //this.showHideMarkers();
  }

  radiusDragEnd($event: any) {
    console.log($event);
    //this.radiusLat = $event.coords.lat;
    //this.radiusLong = $event.coords.lng;
    //this.showHideMarkers();
  }


  onFocus(event, prefill: string = '') {
    //console.log(event.target);
    //return true;
    setTimeout(function () {
      if (typeof (event.target.select) === 'function') {
        if (event.target.value === '') {
          if (prefill && prefill !== '') {
            event.target.value = prefill;
          }
        } else {
          event.target.select();
        }
      }
    }.bind(this), 100);
    // reportsAutoComplete.handleDropdownClick();
  }

  currentMarker: oneMarker;

  markerClicked({m, $event, idx, infoWindow, callback, subscribeLater = false}: markerClickedParams) {
    let retObs;

    //it's more intuitive to turn it on first
    //to look for isClicked=true in the code that follows
    m.isClicked = !m.isClicked;

    if (m.isClicked === false) {
      infoWindow && infoWindow.close();
    } else {
      infoWindow && infoWindow.open();
      if (m.label !== this.centerLocationName && m.locationType === 'ASSIGNMENT') {        
        //need to initialize date to be shown in the start/endDateTime fields
        this.currentDate = this.plusToDate(new Date(this.appointmentService.formatDate(dateFormatType.DATETIME_HOURS_ONLY)),"hour",1);
        retObs = this.appointmentService.getAppointment(m.appointmentId);
      }
    }  

    if (m.label !== this.centerLocationName 
        && m.locationType === 'ASSIGNMENT' 
        && m.isClicked === true) {

      if (subscribeLater === false) {
        //success reaction if we subscribe
        let apptSuccess = function(data) {
          let oneAppt: any = this.appointmentService.dataStore.appointments[0];
          oneAppt.dataChanged = false;
          oneAppt.locationChanged = false;
          m.appt = oneAppt;
        }.bind(this);
        //error reaction if we subscribe
        let apptError = function(error) {
          console.log('Failed to load the appointment');
          console.log(error.message);
          m.dynamicMessage = "<br><li>ERROR LOADING DETAILS</li>";
          m.dynamicMessage += "<br>" + error.message;
          this.showMessage('error', error.statusText || "Failed to load appointment", error.message || JSON.stringify(error), true, true);      
        }.bind(this);      
        retObs.subscribe(apptSuccess, apptError);
        this.currentMarker = m;
      } else  //subscribe later
      {
        this.currentMarker = undefined;
      }    
    }
    
    if (!retObs) {
      //if only closing infoWindow
      //complete the observable
      retObs = of(1);    
    }
    //if we have a callback, execute here
    retObs.pipe(map(data => {
      callback && callback.call(this);
    }));    
    
    return retObs;
  }

  onInfoWindowClose = function($event, m: oneMarker) {
    //in case we close with the x button
    //instead of toggle by clicking marker
    if (m.isClicked === true) {
      m.isClicked = false;
    }
  }

  updateAppointment(m: oneMarker, infoWindow: AgmInfoWindow) {

    //we want to re-open the same markers when done
    this.preserveInfowindows();
    this.preserveRoute();
    
    if (!(m.appt.startDateTime && m.appt.endDateTime)) {
      this.showMessage('error', "Failed to save appointment", "Appointment start/end date/time is missing", true, true);
      return;
    }

    this.progressSpinnerShown = true;
    this.appointmentService.updateAppointment(m.appt)
    .subscribe(data => {
      this.progressSpinnerShown = false;
      let apptId: string = data['appointmentID'];
      this.showMessage('success', 'Success', "Appointment " + apptId + " update completed");
      //get appt details again, to reflect changes, no matter if success or fail
      this.getAppointmentsByLocation();
      // this.markerClicked(m, undefined, undefined, infoWindow);
    }, error => {
      this.progressSpinnerShown = false;
      console.log('Failed to save the appointment');
      console.log(error.message);
      this.showMessage('error', error.statusText || "Failed to save appointment", error.message || JSON.stringify(error), true, true);
      //get appt details again, to reflect changes, no matter if success or fail
      this.getAppointmentsByLocation();
      // this.markerClicked(m, undefined, undefined, infoWindow);
    });       

  }


  // Get Current Location Coordinates
  setCurrentLocation(successCallBack: any = undefined, failureCallBack: any = undefined) {    
    
    //assume all things go fine and we can determine current location from browser
    this.currentLocation = true;

    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((successCallBack ? successCallBack : this.setLocation), (failureCallBack ? failureCallBack : this.locationError), {enableHighAccuracy: true });
    }
  }

  mapClicked($event: MouseEvent) {
    // this.markers.push({
    //   label: (this.markers.length + 1).toString(),
    //   message: 'Dynamic Marker',
    //   lat: $event.coords.lat,
    //   lng: $event.coords.lng,
    //   draggable: true
    // });
  }  

  markerDragStart(m: oneMarker, $event: MouseEvent, infoWindow: AgmInfoWindow) {
    infoWindow.close();
  }

  markerDragEnd(m: oneMarker, $event: MouseEvent, infoWindow: AgmInfoWindow) {
    this.lat = $event.coords.lat;
    this.lng = $event.coords.lng;
    this.getAddress(this.lat, this.lng, function(name: string, addr: string, formattedAddr: string) {
      this.ngZone.run(
        function () {
          if (m.label === this.centerLocationName) {
            //move circle to new location, too
            this.mainCircle.latitude = this.lat;
            this.mainCircle.longitude = this.lng;
          } else {
            this.address = addr;
            m.label = name;
            m.message = addr;          
            infoWindow.open();      
          }
        }
        , this);      
    });  
  }

  markerMouseOver(m: oneMarker, $event: MouseEvent, infoWindow: AgmInfoWindow) {
    //infoWindow.open();
  } 

  markerMouseOut(m: oneMarker, $event: MouseEvent, infoWindow: AgmInfoWindow) {
    // setTimeout(() => {
    //infoWindow.close();      
    // }, 1000);
  } 

  getAddress(latitude, longitude, callback) {
    let addr: string;
    let formattedAddr: string;
    let name: string;
    this.geoCoder.geocode({'location': { lat: latitude, lng: longitude } }, (results, status) => {
      if (status === 'OK') {
        let foundPreferredLocationType: any;
        foundPreferredLocationType = this.deepSearch(results, 'location_type', (k, v) => v === this.defaultPreferredLocationType, true);
        foundPreferredLocationType = (foundPreferredLocationType || results[0]);       
        if (foundPreferredLocationType) {
          this.zoom = this.mapZoom;
          formattedAddr = foundPreferredLocationType.formatted_address;
          addr = "<b>Address:</b>&nbsp;" + foundPreferredLocationType.formatted_address;
          addr += "<br/><b>Location Type:</b>&nbsp;" + foundPreferredLocationType.geometry.location_type;
          addr += "<br/><b>Lat:</b>&nbsp;" + foundPreferredLocationType.geometry.location.lat();
          addr += "<br/><b>Long:</b>&nbsp;" + foundPreferredLocationType.geometry.location.lng();
          addr += "<br/><b>Type:</b>&nbsp;" + foundPreferredLocationType.types.join(',');
          name =  foundPreferredLocationType.types.join(',');
        } else {
          addr = 'Geocoder address not found';
          formattedAddr = 'Geocoder address not found';
          name = undefined;
        }
      } else {
        addr = ('Geocoder failed due to: ' + status);
        formattedAddr = ('Geocoder failed due to: ' + status);
        name = undefined;
      }
      callback.call(this, name, addr, formattedAddr);
    });
  }

  asTheCrowFlies = function(x1: number, y1: number, x2: number, y2: number): number {
    var result: number = 0;
    const RADIANS: number = 180 / 3.14159265;
    const METRES_IN_MILE: number = 1609.34;
    
    if (x1 == x2 && y1 == y2) {
      result = 0;
    } else {
      // Calculating Distance between Points
      var lt1 = x1 / RADIANS;
      var lg1 = y1 / RADIANS;
      var lt2 = x2 / RADIANS;
      var lg2 = y2 / RADIANS;
    
      // radius of earth in miles (3,958.8) * metres in a mile * position on surface of sphere...
      result = (3958.8 * METRES_IN_MILE) * Math.acos(Math.sin(lt1) * Math.sin(lt2) + Math.cos(lt1) * Math.cos(lt2) * Math.cos(lg2 - lg1));
    }
    return result; 
  }

  decodeHtml = function (html) {
    let txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
  };

  htmlEncode = function(str) {
    let buf = [];
    for (let i = str.length - 1; i >= 0; i--) {
      buf.unshift(['&#', str[i].charCodeAt(), ';'].join(''));
    }
    return buf.join('');
  };

  htmlDecode = function(str) {
    return str.replace(/&#(\d+);/g, function(match, dec) {
      return String.fromCharCode(dec);
    });
  };
  

}