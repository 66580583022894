import { Component, OnInit, AfterViewInit, ElementRef, ViewChild, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { GoogleAnalyticsService } from '../../service/google-analytics.service';
import { LoginService } from '../login.service';
import { User } from '../user';
import { UserContext } from '../userContext'; 
import { StorageService } from '../../../shared/service/storage.service';
import { environment } from '../../../../environments/environment';
import { EventEmitterService } from '../../service/event-emitter.service';

@Component({
  selector: 'eclaimsonesite-admin-login',
  templateUrl: './admin-login.component.html',
  styleUrls: ['./admin-login.component.scss']
})
export class AdminLoginComponent implements OnInit , AfterViewInit, OnDestroy {

  @ViewChild('loginContent') loginContent: ElementRef;

  public loginData: User;
  public loginContext: UserContext;
  public postLoginRoutePath:string;

  constructor(
    public router: Router ,
    public route: ActivatedRoute,
    public loginService: LoginService,
    public storageService: StorageService,
    public googleAnalyticsService: GoogleAnalyticsService,
    public eventService : EventEmitterService) { }

  ngOnInit() {
    // Set userLogout Event
    this.eventService.onUserLoginLogout(false);
    this.loginData = new User();
    this.loginData.userId = '';
    this.loginData.password = '';
    this.loginContext = new UserContext();
    this.loginContext.impersonateUser = false;
    this.loginContext.impersonateUserName = '';
    // Set user redirect router path if available.
    this.route.paramMap.subscribe( (params => {
      this.loginContext.postLoginRoutePath = params.get('routeTo');
    }));
    console.log("Route Path : " + this.loginContext.postLoginRoutePath);
    this.storageService.clearAll();
  }

  login(form) {
    this.loginService.loginAdminUser(this.loginData, this.loginContext); 
  }

  ngAfterViewInit(): void {
    setTimeout( () => {
     }, 200);
  } 

  ngOnDestroy(): void {
  }

}
