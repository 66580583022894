import { Component, OnInit, AfterViewInit, ElementRef, ViewChild, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { GoogleAnalyticsService } from '../service/google-analytics.service';
import { LoginService } from './login.service';
import { User } from './user';
import { UserContext } from './userContext'; 
import { StorageService } from '../../shared/service/storage.service';
import { environment } from '../../../environments/environment';
import { EventEmitterService } from '../service/event-emitter.service';

@Component({
  selector: 'eclaimsonesite-login',
  templateUrl: './login.component.html', 
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('loginContent') loginContent: ElementRef;

  public loginData: User;
  public loginContext: UserContext;
  public postLoginRoutePath:string;

  constructor(
    public router: Router ,
    public route: ActivatedRoute,
    public loginService: LoginService,
    public storageService: StorageService,
    public googleAnalyticsService: GoogleAnalyticsService,
    public eventService : EventEmitterService) { }
    public showImpersonateUser: boolean;
    

  ngOnInit() {
    // Set userLogout Event
    this.eventService.onUserLoginLogout(false);
    this.loginData = new User();
    this.loginData.userId = '';
    this.loginData.password = '';
    this.loginContext = new UserContext();
    this.loginContext.impersonateUser = false;
    this.loginContext.impersonateUserName = '';
    // Set user redirect router path if available.
    this.route.paramMap.subscribe( (params => {
      this.loginContext.postLoginRoutePath = params.get('routeTo');
      this.loginContext.srcApp = params.get('srcApp');//srcApp/:srcToken
      this.loginContext.srcToken = params.get('srcToken');//srcApp/:srcToken
    }));
    console.log("Route Path : " + this.loginContext.postLoginRoutePath);
    console.info("loginContext : ",this.loginContext);
    if (environment.production == false ) {
        this.loginContext.showImpersonateUser = true;
    }
    else {
       this.loginContext.showImpersonateUser = false;
    }
    //this.storageService.clearAll();    
    this.clearCacheSelectively();
  }

  //ends with persist
  _persistRegex: RegExp = new RegExp(".+persist$","gi");
  clearCacheSelectively() {
    //we want to keep all that the mapping component caches
    //for now, the rule is that all those key string end with "Persist"
    let curKey: string; let curValue: any;
    let localStorageCopy: any = Object.assign({}, window.localStorage);
    window.localStorage.clear();
    for (let i = 0; i < Object.keys(localStorageCopy).length; i++) {
      curKey = Object.keys(localStorageCopy)[i];
      if (curKey.match(this._persistRegex)) {
        console.log("Persisting localStorage key: " + curKey);
        curValue = localStorageCopy[curKey];
        window.localStorage.setItem(curKey, curValue);
      }
    }
  }

  login(form) {
    this.loginService.loginUser(this.loginData, this.loginContext); 
  }

  ngAfterViewInit(): void {
    setTimeout( () => {
     }, 200);
  } 

  ngOnDestroy(): void {
  }   

    // TODO: Remove this when we're done
    get diagnostic() { return JSON.stringify(this.loginData); }
}
