
import { Injectable } from '@angular/core';

@Injectable()
export class StorageService {

    get(key) {
        var item = window.localStorage.getItem(key);
        if (item === null) {
            item = undefined;
        }
        return item;
    }
    save(key, data) {
        window.localStorage.setItem(key, JSON.stringify(data));
    }
    remove(key) {
        window.localStorage.removeItem(key);
    }
    clearAll() {
        window.localStorage.clear();
    }
    getLength(key) {
        return window.localStorage.length;
    }
    getKeyByIndex(index) {
        return window.localStorage.key(index);
    }
    removeByKeyPrefix(keyPrefix) {
        var i = window.localStorage.length;
        while (i--) {
            var key = window.localStorage.key(i);
            if (key.indexOf(keyPrefix) === 0) {
                window.localStorage.removeItem(key);
            }
        }
    }
}