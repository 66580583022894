import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CheckWriterService } from '../shared/services/check-writer.service';
import { IVendor } from '../shared/models/ivendor';
import { ErrorResponse } from '../shared/models/error-response';
import { IKeyValuePair } from '../shared/models/ikey-value-pair';

@Component({
  selector: 'chk-writer-vendor-search',
  templateUrl: './vendor.component.html',
  styleUrls: ['./vendor.component.scss']
})
export class VendorComponent implements OnInit {

  @Input() claimNumber;

  searchText: string;

  vendorSearchResults: IVendor[];
  selectedVendor: IVendor;
  showErrorMessageDiv = false;
  vendorReturnedInSearchResult= false;
  errorMessage: string = "";
  errorResponse: ErrorResponse;
  errorOccurred: boolean = false;
  showNewVendorButton : boolean = false;
  statesMap: IKeyValuePair[];
  selectedState: string;

  constructor(public activeModal : NgbActiveModal, private restUtil: CheckWriterService) { }

  ngOnInit() {
    this.restUtil.getRefStates().subscribe((data) => {
      this.statesMap = data;
      this.errorOccurred = false;
    }, error => {
      this.errorOccurred = true;
      this.errorResponse = this.restUtil.handleErrorResponse(error);
    });
  }

  searchVendor() {
    console.log("Search vendor by : " + this.searchText + " , selected state : " + this.selectedState);
    if(!this.searchText){
      this.setErrorMessage("Please enter at least 3-4 characters for Vendor Search.");
      return;
    }
    this.restUtil.searchVendors(this.searchText, this.selectedState).subscribe( (vendors) => {
        this.vendorReturnedInSearchResult = true;
        this.errorOccurred = false;
        this.vendorSearchResults = vendors;
        console.log(" this.vendorSearchResults : " + this.vendorSearchResults);
        this.setFlags(false);
    }, error => {
      this.vendorReturnedInSearchResult = false;
      this.vendorSearchResults = [];
      this.errorResponse = this.restUtil.handleErrorResponse(error);
      console.log("Error occured while Vendor Search" + error);
      if(this.errorResponse.status == '404') {
        console.log("Show new vendor population button.");
        this.setFlags(true);
        this.setErrorMessage(this.errorResponse.message);
      } else if(this.errorResponse.status == '429') {
        console.log("Vendor search resulted in too many vendors.");
        this.errorOccurred = true;
        this.showNewVendorButton = false;
      } else {
        this.setErrorMessage("Unable to parse the Vendor Search API Response. Please contact helpdesk.");
      }
    });
  }

  onVendorSelect(vendor: IVendor){
    console.log("Vendor selected : " + vendor.providerName + ", Status : " + vendor.status);
    if(vendor.status != 'BLOCK') {
      this.errorOccurred = false;
      this.selectedVendor = vendor;
      this.activeModal.close(vendor);
    } else {
      this.setErrorMessage("Vendor cannot be selected due to Vendor Payment Blocked.");
    }
  }

  setErrorMessage(message: string) {
    this.errorOccurred = true;
    this.errorResponse = new ErrorResponse();
    this.errorResponse.message = message;
  }

  setFlags(flag:boolean){
    this.errorOccurred = flag;
    this.showNewVendorButton = flag;
  }

  showNewVendor() {
    console.log("Populate New Vendor button clicked.");
    this.activeModal.close("Show New Vendor Page");
  }

}
