/// <reference types="@types/googlemaps" />
import { Component, OnInit, ViewChild } from '@angular/core';
import { AppointmentService } from './appointment.service';
import { Appointment } from './appointment';
import { MatTable } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatTab } from '@angular/material/tabs';
import { MatSort } from '@angular/material/sort';
import { MatButtonToggle } from '@angular/material/button-toggle';
import { Observable, Observer } from 'rxjs';
import { map } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { StorageService } from '../../shared/service/storage.service';
import { User } from '../../shared/login/user';
import { EventEmitterService } from '../../shared/service/event-emitter.service';
import { LoadingIndicatorService } from '../../check-writer/shared/services/loading-indicator.service';
import { GmDistance } from '../../shared/service/GmDistance';
import { environment } from '../../../environments/environment';
import { SortEvent } from 'primeng/api';


@Component({
  selector: 'eclaimsonesite-appointment',
  templateUrl: './appointment.component.html',
  styleUrls: ['./appointment.component.scss']
})
export class AppointmentComponent implements OnInit {

  public apts$: Observable<Appointment[]>;
  public displayedColumns: string[] = ['claimantName', 'appointmentTimeFormatted', 'startDateTime', 'travelTime'];
  public appointmentType: string = 'all'; // all or scheduled
  public closedHidden: boolean = true; // true or false
  public appointmentDate: Date = new Date();
  public dataSource: MatTableDataSource<any> = null;
  public datePipe: DatePipe = new DatePipe('en-US');
  public loading: boolean = false;
  public gmDistance:GmDistance;
  public colsName: any[];
  public appointmentList: Appointment[];
  public appointmentListBackup: Appointment[];
  public selectedAppointment:Appointment;
  public IndexOfFirstRecordDisplayed:any;
  isShowTravleTime:boolean=false;
  claimToSearch:string="";
  autoSugClaims:any[];
  @ViewChild('tableData', { static: true }) dataTable: any;

  currentLocation = {lat: 0, lng: 0};

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  numOfrows: any=10;
  user: any;

  constructor(public appointmentService: AppointmentService,
    public storageService: StorageService,
    public router: Router,
    public route: ActivatedRoute,
    public eventService: EventEmitterService,
    public loadingService: LoadingIndicatorService) {
    loadingService.onLoadingChanged.subscribe(isLoading => this.loading = isLoading);
  }

  ngOnInit() {
    this.colsName = [
      { field: 'Claimant', header: 'Claimant' },
      { field: 'Appointment_time', header: 'Appointment Time' },
      { field: 'Assignment_Date', header: 'Assignment Date' },
      { field: 'Travel_Time', header: 'Travel Time' }
    ];

    var key = 'user-details';


            if (!this.storageService.get(key)) {
              this.router.navigateByUrl('/login');
            }
            else {
              this.user = JSON.parse(this.storageService.get(key));
              let appraiserId: string = this.user.userId;
              //appraiserId = '14';
              this.appointmentService.appointments.subscribe(appointments => {
                appointments.forEach(appointment =>{
                  let userInspectionLocation = appointment.inspectionLocation;
                  //let adjusterCurrentLocatoin:any;

                  //we only get current position if the mapping component is not active, since that component also retrieves
                  //appointments and initializes them through this component
                  if (this.appointmentService.gpsServicesEnabled === true &&  window.navigator && window.navigator.geolocation) {
                    window.navigator.geolocation.getCurrentPosition(
                        position => {
                          console.log(position);
                          this.currentLocation.lat=position.coords.latitude;
                          this.currentLocation.lng=position.coords.longitude;
                          //console.log("currentLocation", this.currentLocation);
                          console.log("appointment.claimNumber", appointment.claimNumber,"appointment.status", appointment.status);
                          this.callGoogleDistanceAPI(appointment,userInspectionLocation);
                },
                error => {
                  switch (error.code) {
                    case error.PERMISSION_DENIED:
                      console.log('Permission Denied');
                      break;
                    case error.POSITION_UNAVAILABLE:
                      console.log('Position Unavailable');
                      break;
                    case error.TIMEOUT:
                       console.log('Timeout');
                       break;
                  }          
                }
            );
          }
                })
                this.dataSource = new MatTableDataSource(appointments);
                this.appointmentList = appointments;
                /* configure filter */
                this.dataSource.filterPredicate = 
                (data: Appointment, filter: string) => data.claimNumber.indexOf(filter) != -1;
                this.dataSource.sort = this.sort;
                this.dataSource.paginator = this.paginator;
              });
        
              this.showAppointments();
            }

   
  }
/**
   * Set the sort after the view init since this component will
   * be able to query its view for the initialized sort.
   */
  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }
  onClaimSearch(){
    console.log("Get Appointment from API by Claim # "+this.claimToSearch);
    if(this.claimToSearch!=null && this.claimToSearch.length>0){
      this.appointmentListBackup=(this.appointmentListBackup!=null)?this.appointmentListBackup:this.appointmentList;
      this.appointmentService.getAppointementsByClaimNumber(this.claimToSearch, false);
    }else{
      this .appointmentList=this.appointmentListBackup;

    }
  }
  autoSearch(event) {
    console.log("this.autoSugClaims",this.autoSugClaims);
    //this.autoSugClaims=[];
    console.log("autoSearch",event,"claimToSearch: "+this.claimToSearch)
     this.appointmentService.getLookupResults(event.query.trim()).subscribe( ( data: any ) => { 
      console.log("getLookupResults",data);
      this.autoSugClaims=[];
      data.results.forEach(element => {
        console.log("getLookupResults",element.fieldCode);
        this.autoSugClaims.push(element.fieldCode);
      });
      this.autoSugClaims = this.removeDups(this.autoSugClaims);
      }, error => {
        console.log('Failed to get the Lookup data', error);

      });
    console.log("this.autoSugClaims",this.autoSugClaims);
    //this.autoSugClaims=['Audi','BMW','Fiat','Ford','Honda','Jaguar','Mercedes','Renault','Volvo','VW'];
  }
  removeDups(names) {
    let unique = {};
    names.forEach(function(i) {
      if(!unique[i]) {
        unique[i] = true;
      }
    });
    return Object.keys(unique);
  }
  
  showAppointments() {
    var key = 'user-details';
    let user: User = JSON.parse(this.storageService.get(key));
    let appraiserId: string = user.userId;
    this.isShowTravleTime=false;
    //appraiserId = '14';
    //this.dataSource.paginator.pageIndex=0;// = this.paginator;
    if (this.appointmentType === 'all') {
      this.appointmentService.getAppointements(appraiserId, this.closedHidden);
    }
    else {
      console.log("this.datePipe.transform (this.appointmentDate, 'yyyy-MM-dd') = " + this.datePipe.transform(this.appointmentDate, 'yyyy-MM-dd'));
      this.appointmentService.getScheduledAppointments(appraiserId, this.datePipe.transform(this.appointmentDate, 'yyyy-MM-dd'));
    }
  }

  showAppointmentDetails(event) {
    console.log({severity:'info', summary:'Car Selected', detail:'Vin: ' + event},this.selectedAppointment);
    this.appointmentService.setSelectedAppointment(this.selectedAppointment);
    //console.log(" row=" + JSON.stringify(this.selectedAppointment));
    this.router.navigateByUrl('/appointmentdetails/' + this.selectedAppointment.claimNumber+'/'+this.selectedAppointment.id6);
  }

  showMap(userId, userName) {
    //console.log({severity:'info', summary:'Showing map view', detail:'User: ' + userId}, JSON.stringify(this.user));
    //console.log(" row=" + JSON.stringify(this.selectedAppointment));
    this.router.navigateByUrl('/map/' + userId + '/' + userName);
  }

  showTravelinfo(){

  }
  /*
{ field: 'Claimant', header: 'Claimant' },
      { field: 'Appointment_time', header: 'Appointment Time' },
      { field: 'Assignment_Date', header: 'Assignment Date' },
      { field: 'Travel_Time', header: 'Travel Time' }
  */
  getFieldIdForSorting(custId: String){
    return ('Claimant'=== custId)?"id1Txt":"startDateTime";
  }
  isFieldIdSortable(custId: string){
    return !('Claimant,Assignment_Date'.indexOf(custId)>=0);
  }
  customSort(event: SortEvent) {
    let field = this.getFieldIdForSorting(event.field);
    //console.log("customSort", event.data,event.field, field);
    event.data.sort((data1, data2) => {
        let value1 = data1[field];
        let value2 = data2[field];
        let result = null;

        if (value1 == null && value2 != null)
            result = -1;
        else if (value1 != null && value2 == null)
            result = 1;
        else if (value1 == null && value2 == null)
            result = 0;
        else if (typeof value1 === 'string' && typeof value2 === 'string')
            result = value1.localeCompare(value2);
        else
            result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;

        return (event.order * result);
    });
  }
  
  public showTravelTime(event){
    this.IndexOfFirstRecordDisplayed = (this.IndexOfFirstRecordDisplayed==null)?0:this.IndexOfFirstRecordDisplayed;
    let possibLastEleNo=(this.numOfrows==null)?10:this.numOfrows;
    let lastIndex = this.IndexOfFirstRecordDisplayed+possibLastEleNo;
    lastIndex = (lastIndex<=this.appointmentList.length)?lastIndex:this.appointmentList.length;
    let currentPageAppointments = this.appointmentList.slice(this.IndexOfFirstRecordDisplayed, lastIndex);
    if (!this.dataTable.filteredValue) {
      //no filter applied
      console.log(this.appointmentList.length)
      currentPageAppointments = this.appointmentList.slice(this.IndexOfFirstRecordDisplayed, lastIndex);
      } else {
      //console.log(`Filtered count is ${this.dataTable.filteredValue.length}`);
      currentPageAppointments = this.dataTable.filteredValue;
      }
    console.log(this.appointmentList, this.dataTable,currentPageAppointments,this.dataTable.filteredValue);
    this.isShowTravleTime=true;

/////////////////////////////////////////


    currentPageAppointments.forEach(appointment =>{
        let userInspectionLocation = appointment.inspectionLocation;
        //let adjusterCurrentLocatoin:any;

        if (window.navigator && window.navigator.geolocation) {
          window.navigator.geolocation.getCurrentPosition(
              position => {
                console.log(position);
                this.currentLocation.lat=position.coords.latitude;
                this.currentLocation.lng=position.coords.longitude;
                //console.log("currentLocation", this.currentLocation);
                console.log("appointment.claimNumber", appointment.claimNumber,"appointment.status", appointment.status);
                this.callGoogleDistanceAPI(appointment,userInspectionLocation);
              },
              error => {
                switch (error.code) {
                  case error.PERMISSION_DENIED:
                    console.log('Permission Denied');
                    break;
                  case error.POSITION_UNAVAILABLE:
                    console.log('Position Unavailable');
                    break;
                  case error.TIMEOUT:
                    console.log('Timeout');
                    break;
              }          
            }
          );
        }
      }
    );//currentPageAppointments.forEach
    
  }//showTravelTime

  paginate(event){
    this.isShowTravleTime=false;
    console.log("paginate",event,"this.isShowTravleTime",this.isShowTravleTime);
    this.IndexOfFirstRecordDisplayed=event.first;
    this.numOfrows = event.rows;
  }
  // public doFilter = (value: string) => {
  //   console.info(this.dataSource);
  //   //this.dataSource.filter = value.trim().toLocaleLowerCase();
  //   this.appointmentList.filter(appointment => {
  //     return appointment.claimNumber.indexOf(value)>0;
  //   });
  // }
  callGoogleDistanceAPI(appointment:Appointment,userInspectionLocation){
    console.log("this.isShowTravleTime",this.isShowTravleTime);
    //=============================================================
    if(environment.envName==="prod" && appointment.status!="C")
    {
      let adjusterCurrentLocatoin = this.currentLocation;
          
      appointment.travelText= new Observable<string>((observer: Observer<string>) => {
        let travelText = "";
        let gmDist:GmDistance;
        var service = new google.maps.DistanceMatrixService;
        if(this.isShowTravleTime){
          travelText = "loading...";
            service.getDistanceMatrix({
              origins: [adjusterCurrentLocatoin],
              destinations: [userInspectionLocation],
              travelMode: google.maps.TravelMode.DRIVING,
              unitSystem: google.maps.UnitSystem.IMPERIAL,
              avoidHighways: false,
              avoidTolls: false,            
              drivingOptions : {trafficModel: google.maps.TrafficModel.BEST_GUESS,departureTime: new Date(Date.now() + 10000)}
            }, function(response, status) {
                console.info("response",response,"status",status,(status==="OK"));
              if(status==="OK"){
                gmDist = new GmDistance(appointment.appointmentID,response.rows[0].elements[0].distance.text,response.rows[0].elements[0].duration.text,"");
                console.info(response.rows[0].elements[0].distance,response.rows[0].elements[0].duration,response.rows[0].elements[0].duration_in_traffic);
                travelText = response.rows[0].elements[0].duration.text+" - ( "+response.rows[0].elements[0].distance.text+" )";
                gmDist.displayText=travelText;
                //this.gmDistance = gmDist;
                
              }else{
                console.info("MAP Distance Service",status);
              }
          });
        }
          
          setInterval(() => {
            observer.next(travelText.toString());
            this.storageService.save(appointment.appointmentID+"-distanceInfo", gmDist);
            }, 500);
      });                    
    }// Google Distance API CALL
//========================================      

  }//callGoogleDistanceAPI


}
