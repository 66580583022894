import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { TabViewModule } from 'primeng/tabview';
import { Router, ActivatedRoute } from '@angular/router';
import { StorageService } from '../../shared/service/storage.service';
import { RadioButtonModule } from 'primeng/radiobutton';
import { MessageModule } from 'primeng/message';
import { ErrorResponse } from '../shared/models/error-response';
import { ButtonModule } from 'primeng/button';
import { CheckWriterService } from '../shared/services/check-writer.service';
import { GCPPrinter } from '../shared/models/gcpprinter';
import { InputTextModule } from 'primeng/inputtext';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { error } from '@angular/compiler/src/util';
import { Observable, Subject, merge } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';
import { PrinterInfo } from '../shared/models/printer-info';
import { environment } from '../../../environments/environment';
import { EventEmitterService } from '../../shared/service/event-emitter.service';
import { MessageService } from 'primeng/api';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'eclaimsonesite-ezeep-print-demo',
  templateUrl: './ezeep-print-demo.component.html',
  styleUrls: ['./ezeep-print-demo.component.scss']
})
export class EzeepPrintDemoComponent implements OnInit {

  constructor(public router: Router
    , public route: ActivatedRoute
    , private storageService: StorageService
    , public radioButMod: RadioButtonModule
    , public restUtils: CheckWriterService
    , public eventService : EventEmitterService
    , private messageService : MessageService) {
     }

  ngOnInit() {
    if (!this.storageService.get("User_Id")) {
      console.log("User Id or Token not found, redirecting to admin login view.");
      this.router.navigateByUrl('/adminLogin/admin');
    } else {
      this.userId = this.storageService.get("User_Id");
      this.eventService.onUserLoginLogout(false);
      this.getGCPPrinters();
    }
  }


  isValidUser: boolean = true;
  userId: string;
  errorOccurred: boolean = false;
  errorResponse: ErrorResponse;
  showMessage: boolean = false;
  showAdminMessage : boolean = false;
  infoMessage: string = "";
  adminInfoMessage: string = "";
  filePath : string;

  // GCP Printers
  printers: any[];
  printerByName : any;
  selectedPrinter: any;
  public printersModel: any;
  printersValuesStore: string[] = [];
  printersSelectedItem: string;
  printerSelected: boolean = false;
  // Printer name for Assign printer.
  environmentName : string = environment.envName;
  demoPrintResponse : any;

  @ViewChild('instance', { static: true }) instance: NgbTypeahead;
  focus$ = new Subject<string>();
  click$ = new Subject<string>();


  onPrinterSelection(event: any) {
    console.log("Printer : " + event);

  }

  handleTabChange(event : any){
    this.showMessage = false;
    this.infoMessage = "";
  }

  getGCPPrinters() {
    console.log("Get GCP Printers...");
    this.printersModel = undefined;
    this.restUtils.getCloudPrinters().subscribe((response) => {
      console.log("Got EZeep Printers list : " + JSON.stringify(response));
      this.printers = response;
      this.printersValuesStore = this.printers.map(printItem => printItem.id);
      this.printerByName = this.printers.map(printItem => printItem.id + "_" + printItem.name);
      console.log("this.printersValuesStore.length : " + this.printersValuesStore.length);
      this.errorOccurred = false;
      this.messageService.add({severity: 'success', summary: 'Info Message', detail: 'Retrieved Cirrus Cloud Registered Printer List!'});
    }, error => {
      this.errorOccurred = true;
      this.errorResponse = new ErrorResponse();
      this.errorResponse.message = error.message;
      this.messageService.add({severity: 'error', summary: 'Error Message', detail: 'Cannot retrieve Cirrus Cloud Registered Printer List!'});
    });
  }

  search = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.instance.isPopupOpen()));
    const inputFocus$ = this.focus$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map(term => (term === '' ? this.printersValuesStore
        : this.printersValuesStore.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1)))
    );
  }

  selectedItem(item) {
    console.log("Type Ahead selected printer : " + item.item + " vs Model : " + this.printersModel);
    this.printersSelectedItem = item.item;
  }

  onTypeAheadPrinterChanged(optionSelected: string) {
    console.log("Item Searched For : " + optionSelected);
    console.log(this.printersSelectedItem);
    if (this.printersSelectedItem && this.printersSelectedItem.length != 0)
      this.printerSelected = true;
  }

  disableDemoPrintButton() {
    if(!this.printersModel) {
      return true;
    }
    return false;
  }

  sendEZeepDemoPrint() {
    console.log("Send demo print to Priner : " + this.printersModel);
    this.restUtils.submitCirrusPrintDemo(this.printersModel, this.userId, this.filePath).subscribe((resp) => {
      console.log("Send Demo print to printer : " + this.printersModel);
      if (resp != null) {
        this.showMessage = true;
        this.infoMessage = "Demo print has been issued, please check/verify for test print.";
        this.errorOccurred = false;
        this.messageService.add({severity: 'success', summary: 'Info Message', detail: 'Demo print has been issued!'});
        this.demoPrintResponse = resp;
        console.log("demoPrintResponse : " + JSON.stringify(resp));
      } else {
        this.errorOccurred = true;
        this.errorResponse = new ErrorResponse();
        this.errorResponse.message = "Unable to issue demo print, please contant DL STSGI NJ App Dev <DLSTSGINJAppDev@stsgi.com>";
        this.messageService.add({severity: 'error', summary: 'Error Message', detail: 'Demo print issue failed.'});
      }
    }, error => {
      this.errorOccurred = true;
      this.showMessage = false;
      this.errorResponse = new ErrorResponse();
      this.errorResponse.message = error.message;
      this.messageService.add({severity: 'error', summary: 'Error Message', detail: 'Unable to issue Demo print.'});
    });
  }

}
