import { Component, OnInit,  ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { StorageService } from '../../shared/service/storage.service';
import { EventEmitterService } from '../service/event-emitter.service';

@Component({
  selector: 'eclaimsonesite-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None 
})
export class HeaderComponent implements OnInit {

  isCollapsed = false;
  isUserLoggedIn = false;
  isUserHouseCounsel = false;
  isLoggedInHouseCounsel = true;
  constructor(private router: Router, private storageService: StorageService, public eventService: EventEmitterService) {
    this.eventService.userLoggedIn.subscribe( isUserAuthenticated => {
      this.isUserLoggedIn = isUserAuthenticated;
      isUserAuthenticated === true && this.ngOnInit();
    });
    this.eventService.userHouseCounsel.subscribe( isHouseCounsel => {
      this.isUserHouseCounsel = isHouseCounsel;
      this.isLoggedInHouseCounsel = (this.isUserLoggedIn ?  this.isUserHouseCounsel : true );
    });
   }

  ngOnInit() {
    this.userId = this.storageService.get("User_Id");
    if (!this.userId) {
      this.isUserLoggedIn=false;
    } else {
      this.isUserLoggedIn=true;
      this.userId = JSON.parse(this.userId);
      this.userDetails = this.storageService.get("user-details");
      if (this.userDetails) {
        this.userDetails = JSON.parse(this.userDetails);
        this.appraiserName = this.userDetails['fullName'];
        this.appraiserId = this.userDetails['userId'];
      } else {
        this.appraiserId = undefined;
        this.appraiserName = undefined;
      }
    }
  }

  public userId: string;
  public appraiserId: string;
  public appraiserName: string;
  public userDetails: any;

  goToLink(path:string){
    console.log("Routing to path : " + path);
    if(path.indexOf('logout') > -1) {
      this.isUserLoggedIn = false;
      this.isLoggedInHouseCounsel = (this.isUserLoggedIn ?  this.isUserHouseCounsel : true );
    }
    console.log("this.isUserLoggedIn : " + this.isUserLoggedIn + ", this.isUserHouseCounsel : " + this.isUserHouseCounsel + ", this.isLoggedInHouseCounsel : " + this.isLoggedInHouseCounsel);
    this.router.navigateByUrl(path);
  }

  isLogged(){
    return false;
  }

  toggleMenu() {
    console.log("Toggeling menu class");
    this.isCollapsed =!this.isCollapsed;
  }

}
