<div class="container" align="center">
  <h3 style="color: #FF6319" class="py-3" id="header">Print Confirm</h3>
  <hr style="border-top: 0px;">
  <div><strong>Did your check print successfully?</strong></div>
  
  <div class="modal-body" align="center">
    <div class="form-group" style="width: 100%;">
      <div class="row">
        <div class="col-md-12">
          <button type="button" class="btn btn-warning font-weight-bold" style="width: 35%; background-color: #FF6319;"
            (click)="onPrintSuccess()">Yes</button>
        </div>
      </div>
    </div>
    <div class="form-group" style="width: 100%;" *ngIf="showVoidAndRetry">
      <div class="row">
        <div class="col-md-12">
          <button type="button" class="btn btn-warning font-weight-bold" style="width: 35%; background-color: #FF6319;"
            (click)="onVoidAndRetry()">No, Retry Print</button>
        </div>
      </div>
    </div>
  </div>
  <div class="form-group" id="footer">
    <div class="row">
      <div class="col-md-12">
        <button type="button" class="btn btn-warning font-weight-bold" style="width: 35%; background-color: #EE8441;"
          (click)="onVoidAndCancel()">Void and Cancel</button>
      </div>
    </div>
  </div>
</div>