import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { StorageService } from '../../shared/service/storage.service';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'eclaimsonesite-appraiser',
  templateUrl: './appraiser.component.html',
  styleUrls: ['./appraiser.component.scss']
})
export class AppraiserComponent implements OnInit {

  public appraiserCellPhone:string;
  public appraiserBusinessPhone:string;
  public appraiserEmail:string;
  public pictureUrl:any;
  public appraiserFullName : string;
  public showPictureUrl:boolean =false;
 
  constructor( private route: ActivatedRoute,
    private storageService: StorageService, private sanitizer:DomSanitizer ) { }

  ngOnInit() {
    this.appraiserCellPhone = this.storageService.get('appraiserCellPhone');
    this.appraiserBusinessPhone = this.storageService.get('appraiserBusinessPhone');
    this.appraiserEmail = this.storageService.get('appraiserEmail');  
    try{ 
      this.pictureUrl = this.sanitizer.bypassSecurityTrustUrl(JSON.parse(this.storageService.get('pictureUrl')));
      this.showPictureUrl=true;
    }catch(error){
      this.showPictureUrl =false;
      console.error("No Picture URL found!");
    }

  }

  scrubPhoneNumber(phoneNumber){
    if(phoneNumber){
      return phoneNumber.replace(/\D/g,'');
    }
    else{
      return '';
    }
    
  }

  getPictureUrl = function(){
    //console.info("pictureUrl", this.pictureUrl);
    return this.pictureUrl;
  }
  
  formatPhoneNumber = function(phoneNumber){
    return this.scrubPhoneNumber(phoneNumber).replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
  }
}
