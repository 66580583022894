<div class="container">
    <!-- Only show for Extra small Devices -->
    <div class="container d-sm-none">
      <div class="row align-items-center justify-content-center">
        <div class="col-sm pb-2">
          <img src="assets/img/PR_logo.png" class="img-fluid" alt="Responsive image">
        </div>
      </div>
      <nav class="navbar navbar-expand-lg navbar-light bg-light">
        <button [ngClass]="isCollapsed ? 'navbar-toggler' : 'navbar-toggler collapsed'" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" (click)="toggleMenu()" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div [ngClass]="isCollapsed ? 'collapse navbar-collapse show' : 'navbar-collapse collapse'" id="navbarSupportedContent" (click)="toggleMenu()">
          <ul class="navbar-nav mr-auto">
            <li class="nav-item">
              <a class="nav-link" (click)="goToLink('appointments')">Appointments</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" (click)="goToLink('checkWriter')">Make Payment</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" (click)="goToLink('profile')">Profile</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" (click)="goToLink('logView')">Log View</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" (click)="goToLink('logout')">Logout {{userId}} ({{appraiserName}})</a>
            </li>                      
          </ul>
        </div>
      </nav>
    </div>
    <!-- All screen sizes except , extra small device veiwport  -->
    <div class="d-none d-sm-block">
      <div class="orange-bar"></div>
      <nav class="navbar navbar-expand-lg navbar-light bg-light">
        <img src="assets/img/header_chevron_small.png" class="chevy img-fluid" alt="Responsive image">
        <div class="logo">
          <img src="assets/img/PR_logo.png" class="img-fluid mx-auto d-block" alt="Responsive image">
        </div>
        <button [ngClass]="isCollapsed ? 'navbar-toggler' : 'navbar-toggler collapsed'" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" (click)="toggleMenu()" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div [ngClass]="isCollapsed ? 'collapse navbar-collapse show' : 'navbar-collapse collapse'" id="navbarSupportedContent" (click)="toggleMenu()">
          <ul class="navbar-nav mr-auto">
            <li class="nav-item">
              <a class="nav-link" *ngIf="!isLoggedInHouseCounsel" (click)="goToLink('appointments')">Appointments</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" *ngIf="isUserLoggedIn" (click)="goToLink('checkWriter')">Make Payment</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" *ngIf="isUserLoggedIn" (click)="goToLink('profile')">Profile</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" *ngIf="isUserLoggedIn" (click)="goToLink('logView')">Log View</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" *ngIf="isUserLoggedIn" (click)="goToLink('logout')">Logout {{userId}} ({{appraiserName}})</a>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  <div class="form-group" style="width: 40%;" align="left">
  </div>

</div>