import { IPayeeAddress } from "./ipayee-address";

export class ClaimPayee {
    name: string;
    address: IPayeeAddress;
    type: string;
    name_type: string;
    partyId : number;
    constructor(){}

    toString():string {
        return "Name - " + this.name + ", Type - " + this.type + ", address-"+this.address.addressLine1;
    }
}
