import { EventEmitter, Injectable, Component, NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { 
  HTTP_INTERCEPTORS, 
  HttpClientModule, 
  HttpClient, 
  HttpEvent, 
  HttpInterceptor, 
  HttpHandler, 
  HttpRequest
} from '@angular/common/http';

import { LoadingIndicatorService } from './loading-indicator.service';
import { Observable, EMPTY } from 'rxjs';
import { map, finalize, catchError  } from 'rxjs/operators';
@Injectable()
export class LoadingIndicatorInterceptor implements HttpInterceptor {
 
    constructor(private loadingIndicatorService: LoadingIndicatorService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler ) : Observable<HttpEvent<any>> {
      //console.log("Before: ",req.params.get("noSpinner"));
      if(req.params.get("noSpinner")==null||req.params.get("noSpinner")!="true"){
        //console.log("Comming...: ",req.params.get("noSpinner"));
        this.loadingIndicatorService.onStarted(req);
      }
      return next.handle(req).pipe(
        finalize(() => {
          this.loadingIndicatorService.onFinished(req);
        })
    );    
  }
}