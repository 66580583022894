export class UserContext{

    public impersonateUserName: string;
    public impersonateUser: boolean;
    
    public authError: boolean = false;
    public authErrorMessage: string;    
    public showImpersonateUser = true;
    public postLoginRoutePath: string;
    public srcApp:string;
    public srcToken:string;
}