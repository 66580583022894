import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'chk-writer-add-note',
  templateUrl: './add-note.component.html',
  styleUrls: ['./add-note.component.scss']
})
export class AddNoteComponent  implements OnInit {
  @Input() claimNumber;
  @Output() noteUpdated = new EventEmitter();

  noteText: string;

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit(){}

  onNoteUpdate(item: string) {
    console.log("Note text updated to : " + this.noteText);
    this.noteUpdated.emit(this.noteText);
  }

}