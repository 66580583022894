<div class="container" align="center" id="ezeepViewContainer" style="width : 70%;">
  <div align="center">
    <h3 style="color: #FF6319" class="py-3" id="header">EZeep-Demo Print</h3>
  </div>
  <p-toast position="top-right" [showTransitionOptions]="'0ms'" [hideTransitionOptions]="'0ms'"></p-toast>
  <div *ngIf="errorOccurred" style="width: 80%;">
    <div class="alert alert-danger">
      <strong>Error! </strong>{{ errorResponse.message }}
    </div>
  </div>
 
  <div class="content-section implementation">
    <p-tabView>
      <p-tabPanel header="Test EZeep Printer" [disabled]="false">
        <div *ngIf="showMessage" style="width: 65%;">
          <div class="alert alert-warning">
            <strong>Info </strong>{{ infoMessage }}
          </div>
        </div>
        <div class="form-group" align="center" style="width: 45%;">
          <div class="row" align="left">
            <p>Select the required printer to send test print.</p>
          </div>
          <div class="row" align="left">
            <div class="col-md-12">
              <label for="typeaheadPrinters" class="font-weight-bold">Printer Id (required)</label>
              <input id="typeaheadPrinters" name="typeaheadPrinters" type="text" class="form-control"
                [(ngModel)]="printersModel" [ngbTypeahead]="search" (focus)="focus$.next($event.target.value)"
                (click)="click$.next($event.target.value)" placeholder="Start typing printer id to find it"
                (selectItem)="selectedItem($event)" (blur)="onTypeAheadPrinterChanged($event.target.value)"
                #instance="ngbTypeahead" required />
            </div>
          </div>
          <div class="row" align="left">
            <div class="col-md-12">
              <label for="typeaheadPrinters" class="font-weight-bold">File Path (optional) : </label>
              <input id="filePath" name="filePath" type="text" class="form-control"
                [(ngModel)]="filePath" required />
            </div>
          </div>
          <div class="row" align="left" *ngIf="printersModel">
            <div class="col-md-12"><p>Selected Printer: {{printersModel || 'none'}}</p></div>
          </div>
        </div>
        <div class="form-group" align="center" style="width: 45%;">
          <div class="row" align="left">
            <div class="col-md-6">
              <button name="refreshPrintersButton" pButton type="button" label="Refresh Printers"
                class="ui-button-raised ui-button-secondary" (click)="getGCPPrinters()"></button>
            </div>
            <div class="col-md-6">
              <button pButton type="button" label="Demo Print" class="ui-button-raised" (click)="sendEZeepDemoPrint()"
                [disabled]="disableDemoPrintButton()"></button>
            </div>
          </div>
        </div>
        <div class="form-group" align="center" style="width: 45%;">
          <div class="row" align="left">
            <div class="col-md-12" *ngIf="demoPrintResponse != undefined">
              {{demoPrintResponse | json}}
            </div>
          </div>
        </div>
      </p-tabPanel>
    </p-tabView>
  </div>
</div>