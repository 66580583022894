<div class="container" align="center">
  <h3 style="color: #FF6319" class="py-3" id="header">Check Preview</h3>
  <div *ngIf="isErrorOccured" align="center" width="60%">
    <div class="alert alert-danger">
      <strong>Error! </strong>{{ errorObject.message }}
    </div>
  </div>
  <div class="form-group">
    <div class="row">
      <div class="col-md-12" id="printable" style="margin: 4px; max-width: 99%; border : 1px solid rgba(0, 0, 0, 0.2); border-radius: 0.3rem;">
        <pdf-viewer [src]="pdfFileUrl" [original-size]="false" [autoresize]="true" max-width="100%"></pdf-viewer>
      </div>
    </div>
  </div>
  <div class="form-group" id="footer">
    <div class="row">
      <div class="col-md-12">
        <button type="button" class="btn btn-warning font-weight-bold" style="width : 20%; background-color: #FF6319;"
          (click)="onPrint()">Print</button>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
          <a (click)="onCancel()" style="text-decoration-line: underline;">Cancel</a>
          <!--<button type="button" class="btn btn-warning font-weight-bold" style="width : 100%; background-color:lightgray; border-color: lightgray;"
          (click)="onCancel()">Cancel</button> -->
      </div>
    </div>
  </div>
</div>
