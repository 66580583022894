import { Component, OnInit, Input } from '@angular/core';
import { Vendor } from '../shared/models/vendor';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'eclaimsonesite-new-vendor-preview',
  templateUrl: './new-vendor-preview.component.html',
  styleUrls: ['./new-vendor-preview.component.scss']
})
export class NewVendorPreviewComponent implements OnInit {

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {
    console.log("Loading preview vendor form." + this.newVendor.toString());
  }

  @Input('newVendor')
  newVendor: Vendor;

  onPrint() {
    console.log("printing the new vendor form.");
    this.printNewVendor();
  }

  printNewVendor() {
    let printContents, popupWin;
    printContents = document.getElementById('new-vendor-page').innerHTML;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
          <title>${this.newVendor.vendorAddDate}</title>
          <style>
            body {background-color: white; color: black;}
          </style>
        </head>
        <body onload="window.print();window.close();">${printContents}</body>
      </html>`
    );
    popupWin.document.close();
    this.activeModal.close('PrintedVendorDoc');
  }

  clsoePreviewNewVendor(){
    this.activeModal.close("PreviewVendor Modal closed.");
  }
}
