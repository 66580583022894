import { Component, OnInit, Input } from '@angular/core';
import { IVendor } from '../shared/models/ivendor';
import { IKeyValuePair } from '../shared/models/ikey-value-pair';
import { Vendor } from '../shared/models/vendor';
import { CheckWriterService } from '../shared/services/check-writer.service';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NewVendorPreviewComponent } from '../new-vendor-preview/new-vendor-preview.component';

@Component({
  selector: 'eclaimsonesite-new-vendor',
  templateUrl: './new-vendor.component.html',
  styleUrls: ['./new-vendor.component.scss']
})

export class NewVendorComponent implements OnInit {

  @Input("claimNumber")
  claimNumber: string = "07620282262";

  statesMap: IKeyValuePair[];
  dropDownOptions: IKeyValuePair[];
  dropDownOptions2: IKeyValuePair[];
  tinTypesRef: IKeyValuePair[];

  constructor(private restUtil: CheckWriterService, private modalService: NgbModal, public activeModal: NgbActiveModal) { }

  todaysDate: String;
  ngOnInit() {
    this.dropDownOptions = YES_NO_REF_DATA;
    this.dropDownOptions2 = NO_YES_REF_DATA;
    this.tinTypesRef = TIN_TYPE_REF_DATA;

    this.restUtil.getRefStates().subscribe((data) => {
      this.statesMap = data;
    });
    // set default flags
    this.newVendor.corporate = "X";
    this.newVendor.healthProvider = " ";
    this.newVendor.attorney = "X";
    this.newVendor.soleProp = " ";
    this.newVendor.partner = "X";
    this.newVendor.tinType = "EIN";
    this.newVendor.claimNumber = this.claimNumber;
    // Set Date:
    this.newVendor.vendorAddDate = new Date().toISOString().substring(0, 10);
  }

  newVendor: Vendor = new Vendor();
  stateSelected: boolean = false;
  formSubmitAttempted: boolean = false;
  isValidForm: boolean = false;
  errorMessage: string = "";
  zip1: string;
  zip2: string;
  phonePart1: string;
  phonePart2: string;
  phonePart3: string;
  /*ngbModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    size: 'lg'
  };*/

  // SSN Regex: /^(?:\d{3}-\d{2}-\d{4}|\d{2}-\d{7})$/;

  onFormSubmit() {
    console.log("Form submitted...");
    // Validate the fields
    this.validatFormFeids();
    if (this.isValidForm) {
      this.errorMessage = "";
      console.log(" Valid form so show the preview. Vendor : " + this.newVendor.taxId);
      const modalRef = this.modalService.open(NewVendorPreviewComponent, {size : 'lg', backdrop: 'static', keyboard : false});
      modalRef.componentInstance.newVendor = this.newVendor;
      modalRef.result.then(previewNewVendorAction => {
        console.log("previewNewVendorAction : " + previewNewVendorAction);
        if(previewNewVendorAction === 'PrintedVendorDoc' && previewNewVendorAction !== 'undefined') {
          console.log("New Vendor form printed.");
          this.activeModal.close('NewVendor Printerd');
        } else {
          console.log("Preivew New Vendor cancelled.");
        }
      });
    } else{
      console.log("Form field valdation failed. Correct the errored form fields.");
      this.isValidForm = false;
      this.errorMessage = "Correct the errored fields and resubmit to preview.";
    }

  }

  previewVendor(form: any){
    this.onFormSubmit();
  }

  private validatFormFeids() {
    console.log("Validating the form feilds...");

    // if valid set isValidForm=true;
    if (this.newVendor.tinType == "SSN") {
      this.newVendor.ssn = this.newVendor.taxId;
      this.newVendor.tempTaxId = "";
    } else {
      this.newVendor.tempTaxId = this.newVendor.taxId;
    }
    // for testing
    this.isValidForm = true;
  }

  onStateSelection(item: any) {
    console.log("Selected State : " + item);
    this.newVendor.state = item;
    this.stateSelected = true;
  }

  onZipPart2Entry(zip2: string) {
    console.log("Zip2 : " + zip2);
    this.newVendor.zip = this.newVendor.zip + "-" + zip2;
    console.log("Updated Zip : " + this.newVendor.zip);
  }

  onCorpSelect(item: any) {
    console.log("corp ? " + item);
    this.newVendor.corporate = item;
  }

  onHealthProviderSelect(prov: string) {
    console.log("Provider : " + prov);
    this.newVendor.healthProvider = prov;
  }
  onAttorneySelect(att: string) {
    console.log("Attorney ? " + att);
    this.newVendor.attorney = att;
  }

  onSolePropSelect(sole: string) {
    console.log("Sole Prop : " + sole);
    this.newVendor.soleProp = sole;
    this.newVendor.partner = " "
  }

  onPartnerSelect(partner: string) {
    console.log("partner : " + partner);
    this.newVendor.partner = partner;
  }

  onTinTypeSelect(tin: any) {
    console.log("Tin : " + tin);
    this.newVendor.tinType = tin;
  }

}

const YES_NO_REF_DATA: IKeyValuePair[] = [{ 'key': 'X', 'value': 'Yes' }, { 'key': ' ', 'value': 'No' }]
const NO_YES_REF_DATA: IKeyValuePair[] = [{ 'key': ' ', 'value': 'No' }, { 'key': 'X', 'value': 'Yes' }]
const TIN_TYPE_REF_DATA: IKeyValuePair[] = [{ 'key': 'EIN', 'value': 'Employee Identification Number(EIN)' }, { 'key': 'SSN', 'value': 'Social Security Number(SSN)' }]