import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PaymentDetails } from '../shared/models/payment-details';
import { Status } from '../shared/models/status';
import { CheckWriterService } from '../shared/services/check-writer.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorResponse } from '../shared/models/error-response';
import { StorageService } from '../../shared/service/storage.service';
import { ChoosePrinterComponent } from '../choose-printer/choose-printer.component';
import { PrinterInfo } from '../shared/models/printer-info';
import { PaymentTransaction } from '../shared/models/payment-transaction';

@Component({
  selector: 'eclaimsonesite-print-preview',
  templateUrl: './print-preview.component.html',
  styleUrls: ['./print-preview.component.scss']
})
export class PrintPreviewComponent implements OnInit {

  printTranxStatus: Status;
  checkNumber: string;

  printTranxStatuses: Status[];

  constructor(public activeModal: NgbActiveModal, private restUtil: CheckWriterService, private storageService : StorageService, private modalService: NgbModal) { }


  ngOnInit() {
  }
  @Input('pdfFileUrl')
  pdfFileUrl: string;
  @Input("checkDetails")
  checkDetails: PaymentDetails;
  @Input('pdfBlob')
  pdfBlob: Blob;
  @Input('claimNumber')
  claimNumber: string;
  @Input('InputTypeInd')
  inputTypeIndc:string;
  @Input('dispatchId')
  dispatchId:string;

  printingWindow: Window;

  isErrorOccured: boolean = false;
  errorMessage: string = "";
  errorObject: ErrorResponse;

  onPrint() {
    console.log("Print issued...");
    // GCP Fall back flag status before showing the printers.
    this.restUtil.getGCPFallbackFlagStatus().subscribe((data) => {
      console.info("GCP Fallback status : " + data.Key + " , data.value : " + data.Value);
      this.isErrorOccured = false;
      if (data.Value && data.Value == "true") {
        console.log("GCP Fallback solution enabled, return from print preview screen.");
        this.printTranxStatus = new Status();
        this.printTranxStatus.actionOnModal = 'FALLBACK_ENABLED';
        this.activeModal.close(this.printTranxStatus);
      } else {
        console.log("GCP Fallback solution disabled, move on with regular printing.");
        let closeResult: string;
        const modalRef = this.modalService.open(ChoosePrinterComponent, { backdrop: 'static', keyboard: false });
        modalRef.result.then((selectedPrinter) => {
          if (selectedPrinter !== 'Adjuster closed Choose Printer Modal') {
            console.log('send print job to printer id :' + selectedPrinter.printerId);
            this.beginPrinterTransaction(selectedPrinter.printerId);
          }
        }, (reason) => {
          closeResult = `Modal Dismissed ${this.restUtil.getDismissReason(reason)}`;
          console.log("Print Preview modal closed : " + closeResult);
        });
      }

    }, error => {
      console.error("Error Occured with GCP fallback api status check, http status: " + error.status);
      // Set the error message.
      this.isErrorOccured = true;
      this.processError(error);
    });
  }

  beginPrinterTransaction(printerId : string) {
    // add print transaction.
    /*this.restUtil.processPrintTransactions(this.checkDetails, this.claimNumber, "Issued", this.inputTypeIndc, JSON.parse(this.storageService.get("X_Token")), printerId).subscribe((data) => {
      this.printTranxStatuses = data;
      this.checkNumber = data[0].checkNumber;
      console.log("Added print transaction and incremented check sequence number : " + this.checkNumber);
      console.log("Set the ActionOnModal to Print...")
      this.printTranxStatuses[1].actionOnModal = "Print";
      this.isErrorOccured = false;
      this.activeModal.close(this.printTranxStatuses[1]);
    }, error => {
      console.error("Error Occured with posting print transaction with http status: " + error.status);
      // Set the error message.
      this.isErrorOccured = true;
      this.processError(error);
    });*/

    // Feb-05-2019. Updated to use new API's.
    let paymentDetails = new PaymentTransaction();
    paymentDetails.paymentDetails = this.checkDetails;
    paymentDetails.printStatus = "Issued";
    paymentDetails.typeOfPayment = this.inputTypeIndc;
    paymentDetails.printerId = printerId;
    paymentDetails.dispatchId = this.dispatchId;
    // GCP alternate solution poc. Oct-23-2020
    this.restUtil.processCheckPrintTransaction(this.claimNumber, paymentDetails).subscribe((data) => {
      this.printTranxStatuses = data;
      this.checkNumber = data[0].checkNumber;
      console.log("Added print transaction and incremented check sequence number : " + this.checkNumber);
      console.log("Set the ActionOnModal to Print...")
      this.printTranxStatuses[1].actionOnModal = "Print";
      this.isErrorOccured = false;
      this.activeModal.close(this.printTranxStatuses[1]);
    }, error => {
      console.error("Error Occured with posting print transaction with http status: " + error.status);
      // Set the error message.
      this.isErrorOccured = true;
      this.processError(error);
    });
  }

  processError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
      this.errorObject = new ErrorResponse();
      this.errorObject.message = error.error.message;
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
        this.errorObject = error.error;
    }
    if(!this.errorObject.message) {
      this.errorObject = new ErrorResponse();
      this.errorObject.message = "Unable to process the request at this time, Please contact help desk."
    }
    // return an observable with a user-facing error message
    //return throwError(
    //  'Something bad happened; please try again later.');
  }

  onCancel() {
    console.log("Cancel issued...");
    this.printTranxStatus = new Status();
    this.printTranxStatus.actionOnModal = 'Cancel';
    // this.activeModal.close('Cancel');
    this.activeModal.close(this.printTranxStatus);
  }

  openPDFWindow(checkNumber: string) {
    let userAgent = window.navigator.userAgent;
    console.log("Opening new window in user agent : " +  userAgent);
    //alert("Opening new window in user agent : " +  userAgent);
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      //alert("Its IE on iOS");
      console.log("IE browser so provide save/download option.");
      window.navigator.msSaveOrOpenBlob(this.pdfBlob);
    } else if (userAgent.match('CriOS')) { //Chrome iOS
      console.log("Chrome ios browser...");
      var reader = new FileReader();
      //alert("Its Chrome on iOS");





      // uncomment below line to show pdf in new tab.
      //reader.onloadend = function () { window.open(reader.result);};
   
   
   
   
   
      reader.readAsDataURL(this.pdfBlob);
    } else if (userAgent.match('FxiOS')) { //FF iOS
     // alert("Its FF on iOS");
      // window.URL.createObjectURL(this.pdfBlob,{oneTimeOnly:true});
      var url = window.URL.createObjectURL(this.pdfBlob);
      let pdfWindow = window.open();
      pdfWindow.location.href = url;
		} else if (userAgent.match('EdgiOS')) { //IE iOS
      //alert("Its IE/EDGE on iOS");
      var url = window.URL.createObjectURL(this.pdfBlob);
      let pdfWindow = window.open(url);
      //pdfWindow.location.href = url;
    } else if (userAgent.match(/iPad/i) || userAgent.match(/iPhone/i)) { //Safari & Opera iOS
      //alert("Its Regular brwser on iOS");
      console.log("iPad/iPhone ios browser...");
      var url = window.URL.createObjectURL(this.pdfBlob);
      let pdfWindow = window.open();
      pdfWindow.location.href = url;
    } else {
      console.log("else block for opening the window.");
      window.open(this.pdfFileUrl);
    }
    //let pdfTab = window.open(this.pdfFileUrl,'Print_PDF','toolbar=0,scrollbars=0,location=0,statusbar=0,menubar=0,resizable=0');
    //pdfTab.location.href = this.pdfFileUrl;
    console.log("pdf loadded in another window, Close Print Preview Modal.");
    // Call API to initiate Print transaction with checkDetails.

    // After the API response close Modal with Print response.
    //this.activeModal.close('Print');
    this.activeModal.close(this.printTranxStatuses[1]);
  }

  /**
   * Prints PDF document from block.
   */
  printOcrDocument_1(){
    let userAgent = window.navigator.userAgent;
    console.log("Printer selected poc print pop up. Opening new window in user agent : " +  userAgent);
    const iframe = document.createElement('iframe');
    //iframe.style.display = 'none';
    iframe.src = URL.createObjectURL(this.pdfBlob);
    document.body.appendChild(iframe);
    iframe.contentWindow.focus();
    iframe.contentWindow.print();
    // Delete the iframe from body.
    // document.body.removeChild(iframe);
  }

}
