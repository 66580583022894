import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MatTable } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatTab } from '@angular/material/tabs';
import { MatSort } from '@angular/material/sort';

import { CheckWriterService } from '../shared/services/check-writer.service';
import { AdjusterActivity } from '../shared/models/adjuster-activity';
import { LoadingIndicatorService } from '../shared/services/loading-indicator.service';
import { StorageService } from '../../shared/service/storage.service';
import { Router } from '@angular/router';
import { ErrorResponse } from '../shared/models/error-response';
import { Status } from '../shared/models/status';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RetryPaymentComponent } from '../retry-payment/retry-payment.component';

@Component({
    selector: 'eclaimsonesite-log-view',
    templateUrl: './log-view.component.html',
    styleUrls: ['./log-view.component.scss']
})
export class LogViewComponent implements OnInit {

    adjusterId: string;
    isErrorOccured: boolean = false;
    paymentUploaded:boolean = false;
    paymentUploadedResp: Status;
    errorResponse: ErrorResponse;
    activityLogs: AdjusterActivity[] = [];
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;
    @ViewChild(MatTable, { static: true }) table: MatTable<AdjusterActivity>;

    displayedColumns: string[] = ['date', 'checkNumber', 'claimNumber', 'paymentType', 'payee', 'amount', 'printStatus', 'paymentStatus', 'irUploadStatus'];
    dataSource = new MatTableDataSource<AdjusterActivity>(this.activityLogs);
    apiCallInFlight: boolean = false;

    constructor(private storageService: StorageService, private restUtil: CheckWriterService, private loadingIndSvc: LoadingIndicatorService, public router: Router, private modalService: NgbModal) {
        loadingIndSvc.onLoadingChanged.subscribe((callInProgress) => { this.apiCallInFlight = callInProgress });
    }

    ngOnInit() {
        if (!this.storageService.get("User_Id")) {
            this.router.navigateByUrl('/login/logView');
        } else {
            this.adjusterId = JSON.parse(this.storageService.get("User_Id"));
            console.log("User Id from session : " + this.adjusterId);
            this.getUserActivityLogs();
        }
    }


    /**
     * Updates the Activity Logs for rendering them in table.
     */
    getUserActivityLogs() {
        console.log("Get Adjuster activity for : " + this.adjusterId);
        this.restUtil.getUserActivityLogs(this.adjusterId, 'No').subscribe((data) => {
            this.isErrorOccured = false;
            this.activityLogs = data;
            console.log("Updated activity logs from services, render the rows.");
            this.dataSource = new MatTableDataSource<AdjusterActivity>(data);
            this.table.renderRows();
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
            console.log("Retrieved logs size : " + this.activityLogs.length);
        }, error => {
            console.log("Adjuster LogActivity errored out : " + error.error.errorMessage);
            this.isErrorOccured = true;
            this.errorResponse = this.restUtil.handleErrorResponse(error);
        });
    }

    retryPaymentUploads() {
        console.log("Retry upload for Adjuster : " + this.adjusterId);
        this.restUtil.retryPaymentSubmission(this.adjusterId).subscribe((data) => {
            console.log("Retries payments : " + data);
            this.isErrorOccured = false;
            this.paymentUploaded = true;
            this.paymentUploadedResp = data;
            // Refresh the log view.
            // this.getUserActivityLogs();
        }, error => {
            this.paymentUploaded = false;
            this.isErrorOccured = true;
            this.errorResponse = this.restUtil.handleErrorResponse(error);
        });
    }

    openRetryPaymentsModal() {
        this.retryPaymentUploads();
        console.log("Open retry payments upload modal.")
        const retryPaymentModalRef = this.modalService.open(RetryPaymentComponent, { centered: true, size : 'lg', 'keyboard' : false, backdrop : 'static' });
        retryPaymentModalRef.componentInstance.adjusterId = this.adjusterId;
        let closeResult: string;
        retryPaymentModalRef.result.then((modalResult) => {
            console.log("Retry Payments Modal Resutl : " + modalResult);
            // Refresh the log view.
            this.getUserActivityLogs();
        }, (reason) => {
            closeResult = `Modal Dismissed ${this.restUtil.getDismissReason(reason)}`;
            console.log("Retry Payments Modal closed : " + closeResult);
        });
    }
}

