
<div class="container">
  <hr class="my-2">
  <div class="row no-gutters text-center " >
    <div class="col">
      <ul class="list-inline"> 
        <li class="list-inline-item">
          <a href="http://www.plymouthrocknj.com/utility/privacy-policy?evtvar=privacy-policy" target="_blank">Privacy &amp; Security</a>
        </li>
        <li class="list-inline-item">
          <a href="http://www.plymouthrock.com/terms" target="_blank">Terms &amp; Conditions</a>
        </li>
      </ul>
    </div>
  </div>
  <div class="row no-gutters text-center ">
    <div class="col version-copyright" style="margin-top: -18px">
      <p>Version {{CURRENT_VERSION}}, © {{objDate | date:'yyyy' }} Plymouth Rock Assurance. All Rights Reserved.</p>
    </div>
  </div>
</div>