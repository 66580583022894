import { IPayeeAddress } from './ipayee-address';
import { IKeyValuePair } from './ikey-value-pair';
import { IPayee } from './ipayee';
import { IVendor } from './ivendor';
import { ClaimPayee } from './claim-payee';

export class PaymentDetails {
    claimNumber: string;
    paymentElement: string;
    payees: IPayee[] = [];
    //payeeAddress: IPayeeAddress;
    payeeAddress: ClaimPayee;
    amount: number;
    covAAmount: number;
    covBAmount: number;
    covCAmount: number;
    covDAmount: number;
    paymentTypeCode: string;
    inPaymentOfCode: string;
    inPaymentOfMap: IKeyValuePair;
    memoContent: string;
    noteContent: string;
    vendor: IVendor;
    isExpenseReserve: boolean = false;
    constructor() {};

    toString(): string {
        return  "Claim Number : " + this.claimNumber
        + " \n Payment Element : " + this.paymentElement 
        + " \n Amount : " + this.amount 
        + " \n Payees : " + this.payees 
        + " \n Payment Type : " + this.paymentTypeCode 
        + " \n Payee Address : " + this.payeeAddress
        + " \n In Payment Of : " + this.inPaymentOfCode
        + " \n Note : " + this.noteContent
        + " \n Memo : " + this.memoContent
        + " \n Vendor: " + this.vendor
        + " \n isExpenseReserve : " + this.isExpenseReserve;
    }
}
