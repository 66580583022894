export class Status {

    statusCode: string;
    statusMessage: string;
    checkNumber: string;
    performedTask: string;
    actionOnModal: string;
    constructor() { };

    toString() {
        return "checkNumber : " + this.checkNumber 
        + ", Action : " + this.performedTask 
        + ", status code : " + this.statusCode 
        + ", status message : " + this.statusMessage
        + ", actionOnModal : " + this.actionOnModal;
    }
}
