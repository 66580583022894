<div class="container">

  <div class="mat-app-background basic-container">
        <mat-button-toggle-group name="fontStyle"  #group="matButtonToggleGroup"  [(ngModel)]="appointmentType">
          <mat-button-toggle value="all" (change)="showAppointments()">My Asgns List</mat-button-toggle>
          <mat-button-toggle value="scheduled"  (change)="showAppointments()">My Sched Asgns</mat-button-toggle>
          <mat-button-toggle value="map" (change)="showMap(user.userId, user.fullName)">Asgns Map</mat-button-toggle>
        </mat-button-toggle-group>        
  </div>

  <div class="mat-app-background basic-container" style="margin: 5px 5px 5px 5px" [hidden]="appointmentType != 'scheduled'">
          <input matInput [(ngModel)]="appointmentDate" [matDatepicker]="picker" placeholder="Choose a date" (dateChange)="showAppointments()">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
  </div>			

  <div class="mat-app-background basic-container" style="margin: 5px 5px 5px 5px" [hidden]="appointmentType != 'all'">
    <section class="example-section" >
      <mat-checkbox class="example-margin" [(ngModel)]="closedHidden" (change)="showAppointments()">Hide Closed</mat-checkbox>
    </section>    
    <!-- <input type="text" pInputText size="25" placeholder="Search by Claim#" (input)="tableData.filter($event.target.value,'id1Txt', 'contains');isShowTravleTime=false;" style="width:auto">
    <p-button class="ui-button-info" label="Show Travel Time" (click)="showTravelTime($event)"></p-button> -->

  </div>			
  <div class="ui-g basic-container">
    <!-- <div class="ui-g-3"><input type="text" pInputText pTooltip="To Search In Repository Enter 12 characters" tooltipEvent="focus" size="15" placeholder="Search by Claim#" 
                          (input)="tableData.filter($event.target.value,'id1Txt', 'contains');isShowTravleTime=false;onClaimSearch($event.target.value, tableData);" 
                          style="width:auto" >
    </div> -->
    <div class="ui-g-3">
      <p-autoComplete [(ngModel)]="claimToSearch" placeholder="Search by Claim#" [suggestions]="autoSugClaims" 
          minLength=3 styleClass="wid100" [multiple]="true"
          (completeMethod)="autoSearch($event);isShowTravleTime=false;" (onSelect)="onClaimSearch()" (onUnselect)="onClaimSearch()">
      </p-autoComplete>
    </div>
    <div class="ui-g-3"></div>
    <div class="ui-g-3"></div>
    <div class="ui-g-3"><p-button class="ui-button-info" label="Show Travel Time" (click)="showTravelTime($event)"></p-button></div>
  </div>
  <!--div>
    
  </div -->

  <p-table #tableData [columns]="colsName" [value]="appointmentList" [responsive]="true" [paginator]="true" [rows]="numOfrows" 
  selectionMode="single" [(selection)]="selectedAppointment" (onRowSelect)="showAppointmentDetails($event)"
  (sortFunction)="customSort($event)" [customSort]="true"  (onPage)="paginate($event)">
      <!-- <ng-template pTemplate="caption">List of Cars</ng-template> -->
      <ng-template pTemplate="header" let-columns>
          <tr>
              <th *ngFor="let col of columns" [pSortableColumn]="col.field">
                {{col.header}}
                <p-sortIcon [hidden]="isFieldIdSortable(col.field)" [field]="col.field"></p-sortIcon>
              </th>
          </tr>
          <!-- <tr [hidden]="tableData.totalRecords<=0"> -->
          <tr>
            <th *ngFor="let col of columns" [ngSwitch]="col.field">
                <input  *ngSwitchCase="'Claimant'" pInputText placeholder="Filter Grid by Claim#" type="text" (input)="tableData.filter($event.target.value,'id1Txt', 'contains');isShowTravleTime=false;">                
            </th>
          </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns">
          <tr [pSelectableRow]="rowData">
              <td *ngFor="let col of columns">
                  <span class="ui-column-title">{{col.header}}</span>
                  <div *ngIf="col.field=='Claimant'">
                  
                    <div class="appointment-insured" ng-click="showAppointmentDetail(appointment.appointmentID, appraiserId, appointment.startDateTime)"><strong>{{ rowData.claimantName }}</strong></div>
                    <div class="appointment-claim">Claim: {{ rowData.id1Txt }}  - {{rowData.claimSequence }} - {{rowData.vendorSequence}}</div>
                    <!-- <div class="appointment-address">{{ rowData.locationAddrLn1Txt }}</div> -->
                    <!-- <div [hidden]="rowData.locationAddrLn2xt == ''" class="appointment-address">{{ rowData.locationAddrLn2xt }}</div> -->
                    <div class="appointment-address">{{rowData.locationCityName}} {{rowData.locationStateCd}}  {{rowData.locationZipCd}}</div>
                    <div class="appointment-status" ng-show="rowData.dispatchStatus != '' ">Status: {{rowData.dispatchStatus}}</div>
                    <div class="appointment-status">Loss Type: {{ rowData.claimType }} / {{rowData.claimantNumber}}</div>
                    <div class="appointment-insured">Appraiser: <strong>{{ rowData.id4Txt }}</strong></div>                  
                </div>
                  <div *ngIf="col.field=='Appointment_time'">
                    <div class="appointment-startDateTime" ng-show="rowData.startDateTime != null ">{{ rowData.startTime}} - {{ rowData.endTime}}</div>
                  </div>
                  <div *ngIf="col.field=='Assignment_Date'">
                    <div class="appointment-startDateTime">{{ rowData.assignDateTime | date : 'MM/dd/yyyy'}}</div>
                  </div>
                  <div *ngIf="col.field=='Travel_Time'">
                    <div class="appointment-startDateTime">{{rowData.travelText | async}} away</div>
                  </div>
                  
              </td>
          </tr>
      </ng-template>
      <ng-template pTemplate="summary">
        There are {{appointmentList.length}} appointments
      </ng-template>
  </p-table>

  <div *ngIf="loading" class="loaderContainer" style="height: 100%;">
    <div class="loader loaderContainer"></div>
  </div>

</div>
  