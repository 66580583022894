import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { TabViewModule } from 'primeng/tabview';
import { Router, ActivatedRoute } from '@angular/router';
import { StorageService } from '../../shared/service/storage.service';
import { RadioButtonModule } from 'primeng/radiobutton';
import { MessageModule } from 'primeng/message';
import { ErrorResponse } from '../shared/models/error-response';
import { ButtonModule } from 'primeng/button';
import { CheckWriterService } from '../shared/services/check-writer.service';
import { GCPPrinter } from '../shared/models/gcpprinter';
import { InputTextModule } from 'primeng/inputtext';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { error } from '@angular/compiler/src/util';
import { Observable, Subject, merge } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';
import { PrinterInfo } from '../shared/models/printer-info';
import { environment } from '../../../environments/environment';
import { EventEmitterService } from '../../shared/service/event-emitter.service';
import { MessageService } from 'primeng/api';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { HttpErrorResponse } from '@angular/common/http';
import {AutoCompleteModule} from 'primeng/autocomplete';

@Component({
  selector: 'eclaimsonesite-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {

  companyGroupsRepo = [
    {id: 1, name : 'Bunker Hill(BH)', code : 'BH'}
    ,{id: 2, name : 'Mount Washington(MW)', code : 'MW'}
    ,{id: 3, name : 'New Jersery(NJ)', code : 'NJ'}
    ,{id: 4, name : 'Pilgrim(PG)', code : 'PG'}
    ,{id: 5, name : 'PlymouthRock(PR)', code : 'PR'}
  ];
  companyGroupsSettings = {};
  selectedCompanyGroups = [];
  constructor(public router: Router
    , public route: ActivatedRoute
    , private storageService: StorageService
    , public radioButMod: RadioButtonModule
    , public restUtils: CheckWriterService
    , public eventService : EventEmitterService
    , private messageService : MessageService) {
     }

  ngOnInit() {
    if (!this.storageService.get("User_Id")) {
      console.log("User Id or Token not found, redirecting to admin login view.");
      this.router.navigateByUrl('/adminLogin/admin');
    } else {
      this.eventService.onUserLoginLogout(false);
      this.adminUserId = JSON.parse(this.storageService.get("User_Id"));
      this.isUserAdminPerson(JSON.parse(this.storageService.get("User_Id")));
      // Committed below code and handled internally.
      /*this.restUtils.isUserInAdminLDAPGroups2(JSON.parse(this.storageService.get("User_Id"))).subscribe(resp => {
        if (this.extractUserFunctionalGroupData(resp)) {
          this.isUserAdmin = true;
          this.errorOccurred = false;
        } else {
          this.errorOccurred = true;
          this.errorResponse = new ErrorResponse();
          this.errorResponse.message = "Unauthorized user to access Admin functionality. please contact Security Team to request ECLAIMS_ONESITE_ADMIN group access.";
        }
      }), error => {
        this.errorOccurred = true;
        this.errorResponse = new ErrorResponse();
        this.errorResponse.message = "Unable to verify User groups authorization for Admin access. please contact DL STSGI NJ App Dev <DLSTSGINJAppDev@stsgi.com> Team.";
      }*/

      // Set settings.
      this.companyGroupsSettings = {
        singleSelection: false,
        //idField: 'item_id',
        // textField: 'item_text',
        idField: 'code',
        textField: 'name',
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',
        enableCheckAll: true,
        itemsShowLimit: 5,
        limitSelection: 5,
        allowSearchFilter: true,
        closeDropDownOnSelection: true,
        noDataAvailablePlaceholderText: 'Company Groups list unavailable.'
      };
      this.companyGroupsRepo = [
        {id: 1, name : 'Bunker Hill(BH)', code : 'BH'}
        ,{id: 2, name : 'Mount Washington(MW)', code : 'MW'}
        ,{id: 3, name : 'New Jersery(NJ)', code : 'NJ'}
        ,{id: 4, name : 'Pilgrim(PG)', code : 'PG'}
        ,{id: 5, name : 'PlymouthRock(PR)', code : 'PR'}
      ];
      // check the enabled cloud type.

      this.checkCirrusCloudEnabled();
      // Load GCP Printer for SupportUsers.
      if(!this.isSupportUser){
        this.getCloudPrintersNew();
      }
    }
  }

 /* private extractUserFunctionalGroupData(JsonUserGroups) {
    var status: boolean = false;
    try {
      var userGroups = JsonUserGroups.usergroups.groups["0"].groupName;
        // console.log("User Groups Length :" + userGroups.length); // Prints JSON object!
      for (var i = 0; i < userGroups.length; i++) {
        //console.log("groupName: " + userGroups[i]);
        if (environment.ALLOWED_ADMIN_USER_GROUPS.some(x => x === userGroups[i])) {
          //console.log("user is in the group: " + userGroups[i]);
          status = true;
        }
      }
    } catch (error) {
      console.log(error);
      return false;
    }
    return status;
  } */

  private isUserAdminPerson(userId: string) {
    this.restUtils.isUserAdminPersonnel(userId).subscribe((data) => {
      if (data) {
        if(data.admin && data.admin === "true") {
          this.isUserAdmin = true;
          this.errorOccurred = false;
          this.messageService.add({severity: 'info', summary: 'Info Message', detail: 'User is an Admin User!'});
        } 
        if (data.support && data.support === "true") {
          this.isSupportUser = true;
          this.errorOccurred = false;
          this.messageService.add({severity: 'info', summary: 'Info Message', detail: 'User is a Support User!'});
        }
      } else {
        this.errorOccurred = true;
        this.errorResponse = new ErrorResponse();
        this.errorResponse.message = "Unauthorized user to access Admin functionality. please contact Security Team to request ECLAIMS_ONESITE_ADMIN/ECLAIMS_ONESITE_SUPPORT LDAP group access.";
        this.messageService.add({severity: 'error', summary: 'Error Message', detail: 'User not an Admin/Support User!'});
      }
    }), error => {
      this.errorOccurred = true;
      this.errorResponse = new ErrorResponse();
      this.errorResponse.message = "Unable to verify User authorization access. please contact DL STSGI NJ App Dev <DLSTSGINJAppDev@stsgi.com> Team.";
      this.messageService.add({severity: 'error', summary: 'Error Message', detail: 'Unable to verify user authorization.'});
    };
  }

  isUserAdmin = false;
  isSupportUser = false;
  disablePanels: boolean = true;
  isValidUser: boolean = true;
  adjusterId: string;
  checkSequence: string;
  adjusterGroups : string[];
  errorOccurred: boolean = false;
  errorResponse: ErrorResponse;
  showMessage: boolean = false;
  showAdminMessage : boolean = false;
  infoMessage: string = "";
  adminInfoMessage: string = "";

  // GCP Printers
  printers: GCPPrinter[];
  selectedPrinter: any;
  public printersModel: any;
  printersValuesStore: string[] = [];
  printersSelectedItem: string;
  printerSelected: boolean = false;
  adjusterPrinterName: string;
  existingPrinterId: string;
  adjusterExistingPrinters: PrinterInfo[];
  selectedExistingPrinter: PrinterInfo;
  updatedAdjusterPrinterName : string;
  // Printer name for Assign printer.
  environmentName : string = environment.envName;

  isCirrusCloudEnabled : boolean = true;
  cloudAdjusterId : string;
  printerByName : any;
  cloudPrinters: PrinterInfo[];
  filteredCloudPrinters: PrinterInfo[];
  adminUserId : string;

  @ViewChild('instance') instance: NgbTypeahead;
  focus$ = new Subject<string>();
  click$ = new Subject<string>();

  disableActivateButton() {
    if (!this.adjusterId || !this.isUserAdmin) {
      return true;
    }
    return false;
  }

  disableAssignPrinterButton() {
    if (!this.adjusterId || !this.printersModel || !this.adjusterPrinterName) {
      return true;
    }
    return false;
  }

  disableAddProfileButton() {
    if (!this.adjusterId || !this.checkSequence || !this.selectedCompanyGroups || this.selectedCompanyGroups.length < 1) {
      return true;
    }
    return false;
  }

  activateProfile(event: any) {
    console.log("Activating adjuster profile.");
    this.showAdminMessage = false;
    this.restUtils.activateUserProfile(this.adjusterId).subscribe(data => {
      console.log("response : " + data);
      if (data) {
        console.log("User Activation successful.");
        this.showAdminMessage = true;
        this.adminInfoMessage = "User Profile Activation complete.";
        this.messageService.add({severity: 'success', summary: 'Success Message', detail: 'User Profile Activation complete!'});
      } else {
        this.showAdminMessage = true;
        this.adminInfoMessage = "User Profile Activation incomplete. Please contact DL STSGI NJ App Dev <DLSTSGINJAppDev@stsgi.com> Team.";
      }
      this.errorOccurred = false;
    }, error => {
      console.error("Error OCcured with service call with http status: " + error);
      // Set the error message.
      this.errorOccurred = true;
      this.showAdminMessage = false;
      this.errorResponse = new ErrorResponse();
      this.errorResponse.message = error.message;
    });

  }

  addUserProfile(event: any) {
    console.log("Adding User profile.");
    var userProfile = {
      id : this.adjusterId,
      groups : this.selectedCompanyGroups.map(function (item) {return item.code}),
      checkSequence : this.checkSequence
    }
    this.showAdminMessage = false;
    this.restUtils.addUserProfile(userProfile).subscribe(data => {
      console.log("Add User Profile response : " + data);
      if (data) {
        console.log("User addition successful.");
        this.showAdminMessage = true;
        this.adminInfoMessage = "User Profile addition complete.";
        this.messageService.add({severity: 'success', summary: 'Info Message', detail: 'User Profile added!'});
      } else {
        this.showAdminMessage = true;
        this.adminInfoMessage = "User Profile addition incomplete. Please contact DL STSGI NJ App Dev <DLSTSGINJAppDev@stsgi.com> Team.";
        this.messageService.add({severity: 'warn', summary: 'Warning Message', detail: 'User Profile Addition incomplete!'});
      }
      this.errorOccurred = false;
    }, error => {
      console.error("Error Occured with service call with http status: " + error.status);
      // Set the error message.
      this.errorOccurred = true;
      this.showAdminMessage = false;
      this.errorResponse = new ErrorResponse();
      this.errorResponse.message = error.message;
      this.messageService.add({severity: 'error', summary: 'Error Message', detail: 'User Profile Addition Failed!'});
    });
  }

  assignAdjusterPrinter(event: any) {
    this.adjusterPrinterName = this.environmentName.toLocaleUpperCase() + "-" + this.adjusterId.toLocaleUpperCase() + "-" + this.adjusterPrinterName;
    console.log("Assigning printer : " + this.adjusterPrinterName);
    this.restUtils.assignAdjusterPrinter(this.adjusterId, this.printersModel, this.adjusterPrinterName).subscribe((resp) => {
      console.log("Assigned Printer to user : " + resp);
      if (resp != null && resp.statusMessage && resp.statusMessage.includes("DONE")) {
        this.showMessage = true;
        this.infoMessage = "Printer has been added to Adjuster.";
        this.messageService.add({severity: 'success', summary: 'Success Message', detail: 'Printer has been added to Adjuster!'});
        this.errorOccurred = false;
        this.adjusterPrinterName = null;
      } else {
        this.errorOccurred = true;
        this.errorResponse = new ErrorResponse();
        this.errorResponse.message = "Unable to add printer to adjuster, please contant DL STSGI NJ App Dev <DLSTSGINJAppDev@stsgi.com>";
      }
    }, error => {
      this.errorOccurred = true;
      this.showMessage = false;
      this.errorResponse = new ErrorResponse();
      this.errorResponse.message = error.message;
      this.messageService.add({severity: 'error', summary: 'Error Message', detail: 'Unable to add printer to adjuster!'});
    });

  }

  updateExistingPrinterMapping() {
    this.updatedAdjusterPrinterName = this.environmentName.toLocaleUpperCase() + "-" + this.adjusterId.toLocaleUpperCase() + "-" + this.updatedAdjusterPrinterName;
    console.log("Update existing printer : " + this.selectedExistingPrinter.printerId + " Adj ID : " + this.adjusterId + ", printer name : " +
    this.updatedAdjusterPrinterName);
    this.restUtils.updateAssignAdjusterPrinter(this.adjusterId, this.selectedExistingPrinter.printerId, this.updatedAdjusterPrinterName).subscribe((resp) => {
      console.log("Updated Existing Assigned Printer : " + resp);
      if (resp != null && resp.statusMessage && resp.statusMessage.includes("DONE")) {
        this.getAdjusterAssignedPrinters();
        this.showMessage = true;
        this.infoMessage = "Adjuster Printer name has been updated.";
        this.messageService.add({severity: 'success', summary: 'Success Message', detail: 'Adjuster Printer name has been updated!'});
        this.errorOccurred = false;
        this.updatedAdjusterPrinterName = null;
      } else {
        this.errorOccurred = true;
        this.errorResponse = new ErrorResponse();
        this.errorResponse.message = "Unable to update printer name of adjuster, please contant DL STSGI NJ App Dev <DLSTSGINJAppDev@stsgi.com>";
      }
    }, error => {
      this.errorOccurred = true;
      this.showMessage = false;
      this.errorResponse = new ErrorResponse();
      this.errorResponse.message = error.message;
      this.messageService.add({severity: 'error', summary: 'Error Message', detail: 'Unable to update printer name!'});
    });

  }

  checkProfileStatus(){
    this.showAdminMessage = false;
    this.adminInfoMessage = "";
    this.restUtils.http.get<any>(environment.ECLAIMS_ONESITE_WS_ADMIN_URL+"util/user/"+this.adjusterId+"/status").subscribe((data) => {
      console.info("token " + data);
      if(data.status && data.status === "Active"){
        this.adminInfoMessage = "User Profile Active.";
        this.messageService.add({severity: 'info', summary: 'Info Message', detail: 'User Profile Active!'});
      } else {
        this.adminInfoMessage = "User Profile Inactive.";
        this.messageService.add({severity: 'info', summary: 'Info Message', detail: 'User Profile InActive!'});
      }
      this.showAdminMessage = true;
      this.errorOccurred = false;
    }, error => {
      console.error(error);
      this.errorOccurred = true;
      this.errorResponse = new ErrorResponse();
      this.errorResponse.message = "Unable to verify user status at this time. Try again later. If issue persits, please contant DL STSGI NJ App Dev <DLSTSGINJAppDev@stsgi.com>";
    });
    
  }

  deleteAssignedAdjusterPrinter() {
    console.log("Delete existing printer mapping : " + this.selectedExistingPrinter.printerId + " of " + this.adjusterId);
    this.errorOccurred = false;
    this.restUtils.removeAdjusterPrinter(this.adjusterId, this.selectedExistingPrinter.printerId).subscribe(), error => {
      this.errorOccurred = true;
      this.showMessage = false;
      this.errorResponse = new ErrorResponse();
      this.errorResponse.message = error.message;
    }
    if(!this.errorOccurred) {
      this.getAdjusterAssignedPrinters();
      this.showMessage = true;
      this.infoMessage = "Removed Printer from adjuster assigned printers.";
      this.messageService.add({severity: 'success', summary: 'Success Message', detail: 'Removed Printer from adjuster assigned printers.'});
    }
  }

  disableUpdatePrinterButton() {
   //if(this.selectedExistingPrinter)
    //  console.log("this.selectedExistingPrinter : " + this.selectedExistingPrinter.printerId);
    if (!this.selectedExistingPrinter || !this.adjusterId || !this.updatedAdjusterPrinterName) {
      return true;
    }
    return false;
  }

  disableDeletePrinterButton() {
    // Check required conditions to set the flag.
    if(!this.selectedExistingPrinter || !this.cloudAdjusterId) {
      return true;
    }
    return false;
  }

  onPrinterSelection(event: any) {
    console.log("Printer : " + event);

  }

  onExistingPrinterSelection() {
    console.info(this.selectedExistingPrinter);

  }

  handleTabChange(event : any){
    this.showMessage = false;
    this.infoMessage = "";
  }

  tabViewChanged(event: any) {
    console.log("Tab view changed  to : " + event.index);
    this.showMessage = false;
    this.infoMessage = "";
    if (event.index == 0 || event.index == 3) {
      console.log("Load gcp printers...");
      this.getCloudPrintersNew();
    } else if (event.index == 1 || event.index == 2) {
      this.getAdjusterAssignedPrinters();
    }
  }


  getGCPPrinters() {
    console.log("Get GCP Printers...");
    this.restUtils.getGCPPrinters().subscribe((response) => {
      console.log("Got GCP Printers list.");
      this.printers = response;
      this.printersValuesStore = this.printers.map(printItem => printItem.id);
      console.log("this.printersValuesStore.length : " + this.printersValuesStore.length);
      this.errorOccurred = false;
      this.messageService.add({severity: 'success', summary: 'Info Message', detail: 'Retrieved Google Cloud Registered Printer List!'});
    }, error => {
      this.errorOccurred = true;
      this.errorResponse = new ErrorResponse();
      this.errorResponse.message = error.message;
      this.messageService.add({severity: 'error', summary: 'Error Message', detail: 'Cannot retrieve Google Cloud Registered Printer List!'});
    });
  }

  getAdjusterAssignedPrinters() {
    if ( (!this.adjusterId && !this.isCirrusCloudEnabled) || (!this.cloudAdjusterId && this.isCirrusCloudEnabled)) {
      console.log("Adjuster Id missing, Enter Adjuster Id to get existing printers mapping for update.");
      this.infoMessage = "Adjuster Id missing, Enter Adjuster Id to get existing printers mapping for update.";
      this.showMessage = true;
    } else {
      this.showMessage = false;
      this.selectedExistingPrinter = undefined;
      console.log("Get adjusters existing priners : " + this.isCirrusCloudEnabled ? this.cloudAdjusterId : this.adjusterId)
      this.restUtils.getAdjusterAssignedPrinters_Admin(this.isCirrusCloudEnabled ? this.cloudAdjusterId : this.adjusterId).subscribe((response) => {
        console.log("Got assigned Printers list.");
        this.adjusterExistingPrinters = response;
        console.log("this.adjusterExistingPrinters.length : " + this.adjusterExistingPrinters.length);
        this.errorOccurred = false;
        this.messageService.add({severity: 'success', summary: 'Info Message', detail: 'Refreshed Adjuster Printer List!'});
      }, error => {
        this.errorOccurred = true;
        this.errorResponse = new ErrorResponse();
        this.errorResponse.message = error.message;
        this.messageService.add({severity: 'error', summary: 'Error Message', detail: 'Cannot refresh Adjuster Printer List!'});
      });
    }
  }

  search = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.instance.isPopupOpen()));
    const inputFocus$ = this.focus$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map(term => (term === '' ? this.printersValuesStore
        : this.printersValuesStore.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1)))
    );
  }

  selectedItem(item) {
    console.log("Type Ahead selected printer : " + item.item + " vs Model : " + this.printersModel);
    this.printersSelectedItem = item.item;
  }

  onTypeAheadPrinterChanged(optionSelected: string) {
    console.log("Item Searched For : " + optionSelected);
    console.log(this.printersSelectedItem);
    if (this.printersSelectedItem && this.printersSelectedItem.length != 0)
      this.printerSelected = true;
  }

  disableDemoPrintButton() {
    if(!this.isCirrusCloudEnabled) {
      return !this.printersModel ? true : false;
    } else if (this.isCirrusCloudEnabled) 
      return !this.selectedCloudPrinter ? true : false;
  }

  sendDemoPrint() {
    console.log("Send demo printer to Priner : " + this.printersModel);
    this.restUtils.sendGCPDemoPrintJob(this.printersModel).subscribe((resp) => {
      console.log("Send Demo print to printer : " + this.printersModel);
      if (resp != null && resp.statusCode && resp.statusCode.includes("SUCCESS")) {
        this.showMessage = true;
        this.infoMessage = "Demo print has been issued, please check/verify for test print.";
        this.errorOccurred = false;
        this.messageService.add({severity: 'success', summary: 'Info Message', detail: 'Demo print has been issued!'});
      } else {
        this.errorOccurred = true;
        this.errorResponse = new ErrorResponse();
        this.errorResponse.message = "Unable to issue demo print, please contant DL STSGI NJ App Dev <DLSTSGINJAppDev@stsgi.com>";
        this.messageService.add({severity: 'error', summary: 'Error Message', detail: 'Demo print issue failed.'});
      }
    }, error => {
      this.errorOccurred = true;
      this.showMessage = false;
      this.errorResponse = new ErrorResponse();
      this.errorResponse.message = error.message;
      this.messageService.add({severity: 'error', summary: 'Error Message', detail: 'Unable to issue Demo print.'});
    });
  }

  onGroupSelect(item: any) {
    console.log(this.selectedCompanyGroups);
  }

  onGroupDeSelect(items: any) {
    if (items instanceof Array) {
      items.forEach(item => {
        this.updateUnSelectedGroup(item);
      });
    } else {
      this.updateUnSelectedGroup(items);
    }
  }

  onGroupDeSelectAll() {
    console.log("Unselect all groups...");
    this.selectedCompanyGroups = [];
    console.log("onGroupDeSelectAll::Groups array length : " + this.selectedCompanyGroups.length);
  }

  updateUnSelectedGroup(groupCode: any) {
    console.log("Remove group : " + groupCode);
    this.selectedCompanyGroups = this.selectedCompanyGroups.filter(data => data !== groupCode);
    console.log("updateUnSelectedGroup:: Groups array length : " + this.selectedCompanyGroups.length);
  }

  // Cirrus related functions.

  cirrusTabViewChanged(event: any) {
    console.log("Tab view changed  to : " + event.index);
    this.showMessage = false;
    this.infoMessage = "";
    if (event.index == 0 || event.index == 3) {
      console.log("Loading cloud printers...");
      this.getCirrusCloudPrinters();
    } else if (event.index == 1 || event.index == 2) {
      this.getAdjusterAssignedPrinters();
    }
  }

  getCloudPrinters(){
    console.log("Get Cloud Printers...");
    this.printersModel = undefined;
    this.restUtils.getCloudPrinters().subscribe((response) => {
      console.log("Got Active Cloud Printers list : " + JSON.stringify(response));
      this.printersModel = response;
      this.printersValuesStore = this.printers.map(printItem => printItem.id);
      this.printerByName = this.printers.map(printItem => printItem.id + "_" + printItem.name);
      console.log("this.printersValuesStore.length : " + this.printersValuesStore.length);
      this.errorOccurred = false;
      this.messageService.add({severity: 'success', summary: 'Info Message', detail: 'Retrieved Cirrus Cloud Printer List!'});
    }, error => {
      this.errorOccurred = true;
      this.errorResponse = new ErrorResponse();
      this.errorResponse.message = error.message;
      this.messageService.add({severity: 'error', summary: 'Error Message', detail: 'Cannot retrieve Cirrus Cloud Printer List!'});
    });
  }

  sendCirrusDemoPrint() {
    console.log("Send demo print to Printer : " + this.selectedCloudPrinter);
    this.restUtils.submitCirrusPrintDemo(this.selectedCloudPrinter.printerId, this.adminUserId, undefined).subscribe((resp) => {
      console.log("Send Demo print to printer : " + this.printersModel);
      if (resp != null) {
        this.showMessage = true;
        this.infoMessage = "Cirrus Demo print has been issued, please check/verify for test print.";
        this.errorOccurred = false;
        this.messageService.add({severity: 'success', summary: 'Info Message', detail: 'Cirrus Demo print has been issued!'});
        console.log("Cirrus demo Print Response : " + JSON.stringify(resp));
      } else {
        this.errorOccurred = true;
        this.errorResponse = new ErrorResponse();
        this.errorResponse.message = "Unable to issue demo print, please contant DL STSGI NJ App Dev <DLSTSGINJAppDev@stsgi.com>";
        this.messageService.add({severity: 'error', summary: 'Error Message', detail: 'Cirrus Demo print issue failed.'});
      }
    }, error => {
      this.errorOccurred = true;
      this.showMessage = false;
      this.errorResponse = new ErrorResponse();
      this.errorResponse.message = error.message;
      this.messageService.add({severity: 'error', summary: 'Error Message', detail: 'Unable to issue Cirrus Demo print.'});
    });
  }

  checkCirrusCloudEnabled(){
    this.restUtils.getPropertyEntryValue('eclaimsonesite.cirrus_print.enabled').subscribe(resp => {
      if(resp != null && resp != undefined && resp.Value == 'false')
        this.isCirrusCloudEnabled = false;
        console.log("Cloud Type Cirrus enabled : " + this.isCirrusCloudEnabled);
    }, error => {
      this.errorOccurred = true;
      this.showMessage = false;
      this.errorResponse = new ErrorResponse();
      this.errorResponse.message = error.message;
      this.messageService.add({severity: 'error', summary: 'Error Message', detail: 'Unable to issue Cirrus Demo print.'});
    });
  }

  getCloudPrintersNew(){
    if(this.isCirrusCloudEnabled){
      console.log("Get Cirrus cloud registered printers...");
      this.getCirrusCloudPrinters();

    } else {
      console.log("Get GCP cloud registered printers...");
      this.getGCPPrinters();
    }
  }

  getCirrusCloudPrinters() {
    console.log("Get Cloud Printers...");
    this.cloudPrinters = undefined;
    this.selectedCloudPrinter = undefined;
    this.restUtils.getCloudPrinters().subscribe((response) => {
      //console.log("Got Cirrus Printers list : " + JSON.stringify(response));
      this.cloudPrinters = response;
      this.filteredCloudPrinters = response;
      this.errorOccurred = false;
      this.messageService.add({severity: 'success', summary: 'Info Message', detail: 'Retrieved Cirrus Cloud Printer List!'});
    }, error => {
      this.errorOccurred = true;
      this.errorResponse = new ErrorResponse();
      this.errorResponse.message = error.message;
      this.messageService.add({severity: 'error', summary: 'Error Message', detail: 'Cannot retrieve Cirrus Cloud Printer List!'});
    });
  }

  selectedCloudPrinter : PrinterInfo;

  filterCloudPrinters(event) {
    let filtered : any[] = [];
    let query = event.query;
    for(let i = 0; i < (this.cloudPrinters && this.cloudPrinters.length); i++) {
      let printer = this.cloudPrinters[i];
      if (printer.printerName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
          filtered.push(printer);
      }
    }
    this.filteredCloudPrinters = filtered;
  }

  disableCirrusAssignPrinterButton() {
    if (!this.cloudAdjusterId || !this.selectedCloudPrinter || !this.adjusterPrinterName) {
      return true;
    }
    return false;
  }

  disableCirrusUpdatePrinterButton() {
    //if(this.selectedExistingPrinter)
     //  console.log("this.selectedExistingPrinter : " + this.selectedExistingPrinter.printerId);
     if (!this.selectedExistingPrinter || !this.cloudAdjusterId || !this.adjusterPrinterName) {
       return true;
     }
     return false;
   }

   addOrUpdateAdjusterCloudPrinter(action:string) {
    // Commented the Adjuster and env from the printer name as its no longer needed.
    // let printerName = this.environmentName.toLocaleUpperCase() + "-" + this.cloudAdjusterId.toLocaleUpperCase() + "-" + this.adjusterPrinterName;
    let printerName = this.adjusterPrinterName;
    // Update the printer name for the adjuster.
    let tempSelectedPrinter = new PrinterInfo();
    tempSelectedPrinter = Object.assign(tempSelectedPrinter, (action == 'add') ? this.selectedCloudPrinter : this.selectedExistingPrinter);
    console.log(tempSelectedPrinter);
    if(action != 'remove') {
      tempSelectedPrinter.printerName = printerName;
      tempSelectedPrinter.adjusterId = this.cloudAdjusterId;
    }
    // For Remove action mark the printer isActive=false.
    if(action == 'remove')  tempSelectedPrinter.active=false;
    console.log("Assigning/Update printer : " + printerName);
    this.restUtils.updateAdjusterCloudPrinter(tempSelectedPrinter, this.adminUserId).subscribe((resp) => {
      console.log(action + " action Printer to user : " + resp);
      if (resp != null && resp.length > 0) {
        this.showMessage = true;
        this.infoMessage = "Printer action " + action + " has been completed.";
        this.messageService.add({severity: 'success', summary: 'Success Message', detail: 'Adjuster ' + action + ' Printer done!'});
        this.errorOccurred = false;
        this.adjusterPrinterName = null;
        this.getAdjusterAssignedPrinters();
      } else {
        this.errorOccurred = true;
        this.errorResponse = new ErrorResponse();
        this.errorResponse.message = "Unable to added/updated/remove printer to/from adjuster, please contant DL STSGI NJ App Dev <DLSTSGINJAppDev@stsgi.com>";
      }
    }, error => {
      this.errorOccurred = true;
      this.showMessage = false;
      this.errorResponse = new ErrorResponse();
      this.errorResponse.message = error.message;
      this.messageService.add({severity: 'error', summary: 'Error Message', detail: 'Unable to added/updated/remove cloud printer to adjuster!'});
    });

  }

}


interface CompanyGroups {
  name: string,
  code: string
}