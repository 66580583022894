import { ClaimData } from "./claim-data";

export class LetterData {
    letterCode              : string;
    claimNumber				: string;
	vehicleLocation			: string;
	storageChargesPerDay 	: number;
	storageChargesUpTo 		: string;
	deliveryMethod			: string;
	faxNumber				: string;
	email					: string;
	attentionTo				: string;
	claimUnit 				: ClaimData;
	tranxId					: number;
	userId					: string;
	underWritingCompany		: string;

    constructor() {};

    toString() : string {
        return "LetterData { claimNumber : " + this.claimNumber
        + ", vehicleLocation : " + this.vehicleLocation
        + ", storageChargesPerDay : " + this.storageChargesPerDay
        + ", storageChargesUpTo : " + this.storageChargesUpTo
        + ", deliveryMethod : " + this.deliveryMethod
        + ", faxNumber : " + this.faxNumber
        + ", email : " + this.email
        + ", attentionTo : " + this.attentionTo
        + ", tranxId : " + this.tranxId
        + ", userId : " + this.userId
        + ", underWritingCompany : " + this.underWritingCompany;
    }
}
