import { Component, OnInit, Inject, Injectable } from '@angular/core';
import { CheckWriterService } from '../shared/services/check-writer.service';
import { AdjusterProfile } from '../shared/models/adjuster-profile';
import { Router } from '@angular/router';
import { StorageService } from '../../shared/service/storage.service';

@Component({
  selector: 'eclaimsonesite-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
@Injectable()
export class ProfileComponent implements OnInit {

  adjusterId: string;
  adjuster: AdjusterProfile = new AdjusterProfile();
  isErrorOccured: boolean = false;
  isHouseCounsel: boolean = false;
  isNotHouseCounsel: boolean = false;
  errorMessage: string="";
  hCClasses: string;
  invertHCClasses: string;
  constructor(private storageService: StorageService, public router: Router, private restUtil: CheckWriterService) { }

  ngOnInit() {
    if (!this.storageService.get("User_Id")) {
      this.router.navigateByUrl('/login/profile');
    } else {
      this.adjusterId = JSON.parse(this.storageService.get("User_Id"));
      console.log("User Id from session : " + this.adjusterId + " with token : " + this.storageService.get('X_Token'));
      // Get Adjusrer details.
      //this.restUtil.getUserDetails(this.adjusterId).subscribe((data) => {
      this.restUtil.getUserDetailsWithToken(this.adjusterId, JSON.parse(this.storageService.get('X_Token'))).subscribe((data) => {
        console.log("User Details Response : " + data);
        this.adjuster = data;
        this.setHouseCounseInd();
      }, err => {
        console.log("Error with User Details API : " + err.status);
        this.isErrorOccured=true;
        this.errorMessage=this.restUtil.handleErrorResponse(err).message;
      });
    }
  }

  private setHouseCounseInd() {
    if(this.adjuster) {
      if(this.adjuster.houseCounsel == "Y") {
        this.isHouseCounsel=true;
        this.hCClasses = "itemSelected";
        this.invertHCClasses = "itemNotSelected";
      } else {
        this.isHouseCounsel=false;
        this.hCClasses = "itemNotSelected";
        this.invertHCClasses = "itemSelected";
      }
    }
    console.log("Is house counsel ? " + this.isHouseCounsel);
  }
}
