export class AdjusterProfile {

    id: string;
    name: string;
    phone: string;
    fax: string;
    email: string;
    homePhone: string;
    houseCounsel: string;
    number: string;

    constructor() { };

    toString() {
        return "Adjuster ID : " + this.id 
        + ", Name : " + this.name 
        + ", Adjuster Number : " + this.number 
        + ", HouseCounsel : " + this.houseCounsel 
        + ", email : " + this.email 
        + ", Phone : " + this.phone;
    }
}
