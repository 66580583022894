<div class="container" align="center">
  <h3 style="color: #FF6319" class="py-3" id="header">Payment Options</h3>
  <hr style="border-top: 0px;">
  <div><strong>Please see available Payment options for this Claim and choose the desired option by clicking the
      button.</strong></div>

  <div class="modal-body" align="center" style="width: 75%;">
    <div *ngIf="isDirectDepositOptedIn" class="form-group">
      <div class="row" align="left">
        <div class="col-md-7">
          <span>Direct Deposit Account : {{directDepositAccount}}</span>
        </div>
        <div class="col-md-5">
          <button type="button" class="btn btn-warning font-weight-bold" style="background-color: #FF6319;"
            (click)="onPaymentSelection('EFT_DD')" [disabled]="disableButtons">Virtual - Direct Deposit</button>
        </div>
      </div>
    </div>
    <div *ngIf="isZelleDepositOptedIn" class="form-group">
      <div class="row" align="left">
        <div class="col-md-7">
          <span>Zelle Account : {{zelleDepositAccount}}</span>
        </div>
        <div class="col-md-5">
          <button type="button" class="btn btn-warning font-weight-bold" style="background-color: #FF6319;"
            (click)="onPaymentSelection('Zelle')" [disabled]="disableButtons">Virtual - Zelle Deposit</button>
        </div>
      </div>
    </div>
    <div *ngIf="isDeluxeDepositOptedIn" class="form-group">
      <div class="row" align="left">
        <div class="col-md-7">
          <span>Deluxe email : {{deluxeDepositAccount}}</span>
        </div>
        <div class="col-md-5">
          <button type="button" class="btn btn-warning font-weight-bold" style="background-color: #FF6319;"
            (click)="onPaymentSelection('Deluxe')" [disabled]="disableButtons">Virtual - Deluxe Deposit</button>
        </div>
      </div>
    </div>
    <!-- Added conditional check to disable the Paper check when Deluxe is eligible. Deluxe Project Aug-2021 -->
    <div class="form-group">
      <div class="row" align="left">
        <div class="col-md-7">
          <span>Print paper check.</span>
        </div>
        <div class="col-md-5">
          <button type="button" class="btn btn-warning font-weight-bold" style="background-color: lightgray;"
            (click)="onPaymentSelection('Paper')" [disabled]="disableButtons">Paper - Manual Check</button>
        </div>
      </div>
    </div>
  </div>
  <div class="form-group" id="footer">
    <div class="row">
      <div class="col-md-12">
        <a (click)="onPaymentSelection('none')" style="text-decoration-line: underline;">Cancel</a>
      </div>
    </div>
  </div>
</div>