import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CheckWriterService } from '../shared/services/check-writer.service';
import { PaymentCheck } from '../shared/models/payment-check';
import { Router } from '@angular/router';

@Component({
  selector: 'eclaimsonesite-print-confirm',
  templateUrl: './print-confirm.component.html',
  styleUrls: ['./print-confirm.component.scss']
})
export class PrintConfirmComponent implements OnInit {

  constructor(public activeModal: NgbActiveModal, private restUtil: CheckWriterService, public router: Router) { }

  @Input("showVoidAndRetryIn")
  showVoidAndRetryIn : string;

  showVoidAndRetry : boolean = true;

  ngOnInit() {
    console.log("showVoidAndRetryIn param : " + this.showVoidAndRetryIn);
    if(this.showVoidAndRetryIn && this.showVoidAndRetryIn == 'false')
      this.showVoidAndRetry = false;

    console.log("Set the timeout to route to logview...");
    setTimeout(() => {
      console.log("Print Confirm Modal Timedout, close the modal.");
      this.activeModal.close('TimedOut');
    }, 240000);
  }

  //@Input('paymentCheck')
  //paymentCheck: PaymentCheck;

  onPrintSuccess() {
    console.log("PrintSuccess");
    this.activeModal.close('PrintSuccess');
  }

  onVoidAndRetry() {
    console.log("VoidAndRetry...");
    this.activeModal.close('VoidAndRetry');
  }

  onVoidAndCancel() {
    console.log("VoidAndCancel issued...");
    this.activeModal.close('VoidAndCancel');
  }

}
