
<div class="modal-header" style="justify-content : center;"> 
  <h4 id="modal-basic-title">Add Stream Note</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Note Modal Closed')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" align="center">
    <div class="form-group" style="width: 80%;" >
      <div class="row">
        <label for="note" class="font-weight-bold ml-3">Note</label>
        <div class="col-md-12" align="left">
          <textarea name="note" rows="4" cols="40" maxlength="250" placeholder="Enter Note here for {{claimNumber}}" [(ngModel)]="noteText" (change)="onNoteUpdate($event.target.value)"></textarea>
        </div>
      </div>
      <div class="row">&nbsp;</div>
      <div class="row">
        <div class="col-md-12">
          <button type="button" class="btn btn-warning  font-weight-bold" style="background-color:#FF6319; width : 50%;" (click)="activeModal.close(noteText)">Save Note</button>
        </div>
      </div>
    </div>
</div>
<div class="modal-footer" style="justify-content : center;" >
  <a (click)="activeModal.close('Note Modal Cancelled')" style="text-decoration-line: underline;">Cancel</a>
</div>

  
  